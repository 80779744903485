import { Component, OnInit, ElementRef, Inject } from '@angular/core';

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl, EmailValidator } from '@angular/forms';

import { roleMasterMenuname } from '../../../enums/roleMasterMenuName';

import { PermissionLinks } from '../../../enums/permissionLinks';

import { roleMasterIconClass } from '../../../enums/roleMasterMenuName';

import { RoleMasterService } from '../../../services/rolemaster.service';
import * as _ from 'lodash';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../../environments/environment';


declare var $: any;

@Component({
  selector: 'app-add-role-modal',
  templateUrl: './add-role-modal.component.html',
  styleUrls: ['./add-role-modal.component.css'],
})
export class AddRoleModalComponent implements OnInit {


  loggedInUserInfo: any;
  isGuestSuperAdmin: boolean;

  addRoleForm: FormGroup;
  public menuName: string;
  masterMenu: any = [];
  boardEvaluationMenu: any = [];
  upsiMenu: any = [];
  ncdMenu:any =[];
  governanceMenu: any = [];
  complianceMenu: any = [];
  documentManagementMenu: any = [];
  lawAndRegulationMenu: any = [];
  adminMenu: any = [];
  dashboardMenu: any = [];
  gemMenu: any = [];
  addedRoles: any = [];
  finalRoleList: any = [];
  isfinalRoleListEmpty: boolean = true;
  roleNamePatternRegEx: any = "^[a-zA-Z][a-zA-Z\-\_\/ ]*$";
  checkboxClickSelected: boolean;


  isPSSConfig = environment.Service.PSS ;
  isGovevaConfig = environment.Service.GovEva ;
  isUPSIConfig = environment.Service.UPSI && environment.FinalMenu.UPSI ;;
  isAllConfig = environment.Service.All ;
  isNCDConfig = environment.Service.NCD && environment.FinalMenu.NCD ;;
  isGEMConfig = environment.Service.GEM ;
  isLawsConfig = environment.FinalMenu.Laws ;
  // isGovernance = environment.Service.Governance;
  isMeetingMakerWithoutAgenda = environment.Service.MeetingMakerWithoutAgenda;
  isMeetingMakerWithAgenda = environment.Service.MeetingMakerWithAgenda;

  isBoardEvaluationConfig = environment.Service.BoardEvaluation;

  isViewAllActive = false;
  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private roleMasterService: RoleMasterService, private elementRef: ElementRef, @Inject(DOCUMENT) document) {
    this.addRoleForm = this.fb.group({
      roleName: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.pattern(this.roleNamePatternRegEx)])
    })

  }

  ngOnInit() {

    this.loggedInUserInfo = JSON.parse(localStorage.getItem("user")).TypeOfUser;
    if (this.loggedInUserInfo === PermissionLinks.GuestSuperAdmin) {
      this.isGuestSuperAdmin = true;
    } else {
      this.isGuestSuperAdmin = false;
    }

    this.checkboxClickSelected = false;
    this.roleMasterService.roleActivityCheckboxList()
      .then(response => {
        //console.log("response", response);
        if (response.status == 0) {
          let resources = response.Resources;
          var resourcesCache = response.Resources;

          if (!this.isGuestSuperAdmin) {
            _.remove(resources, function (resourceElement: any) { return resourceElement.MenuDisplayName == 'Roadmap' });
          }

          // if UPSI is not selected then Email templates should be inside Compaliance Menu
          if(this.isAllConfig ){
            _.remove(resources , function(resourceElement : any){
              return ((resourceElement.ParentMenuName == roleMasterMenuname.UPSI) && (resourceElement.Menu == roleMasterMenuname.EmailTemplate))             
            })
          } else {           

            if (this.isPSSConfig && !(this.isGovevaConfig || this.isAllConfig || this.isUPSIConfig || this.isBoardEvaluationConfig)) {  //|| this.isBoardEvaluationConfig             

              _.remove(resources, function (resourceElement: any) { return (resourceElement.Menu == roleMasterMenuname.EmailTemplate) } )
              _.remove(resources, function (resourceElement: any) { return ((resourceElement.ParentMenuName != roleMasterMenuname.Master) && (resourceElement.ParentMenuName != roleMasterMenuname.Compliance) && (resourceElement.ParentMenuName != roleMasterMenuname.DocumentManagement) && (resourceElement.ParentMenuName != roleMasterMenuname.LawsAndRegulations)  && (resourceElement.ParentMenuName != roleMasterMenuname.UPSI))});
            }

            if (this.isGovevaConfig && !(this.isPSSConfig || this.isAllConfig || this.isUPSIConfig || this.isBoardEvaluationConfig)) { //|| this.isBoardEvaluationConfig
              // console.log("resources - isGovevaConfig")
              _.remove(resources, function (resourceElement: any) { return ((resourceElement.ParentMenuName == roleMasterMenuname.Compliance) || (resourceElement.ParentMenuName == roleMasterMenuname.DocumentManagement) || (resourceElement.ParentMenuName == roleMasterMenuname.UPSI)) });
            }

            if (this.isBoardEvaluationConfig  && !(this.isGovevaConfig || this.isPSSConfig || this.isAllConfig || this.isUPSIConfig )) { //this.isBoardEvaluationConfig && 
               console.log("resources - Board Evaluation")
              _.remove(resources, function (resourceElement: any) { return ((resourceElement.ParentMenuName != roleMasterMenuname.BoardEvaluation) && (resourceElement.ParentMenuName != roleMasterMenuname.Master)) });
            }

            if (this.isUPSIConfig && !(this.isGovevaConfig || this.isPSSConfig || this.isAllConfig || this.isBoardEvaluationConfig)) { //|| this.isBoardEvaluationConfig
              // console.log("resources - UPSI")
              _.remove(resources, function (resourceElement: any) { return !((resourceElement.ParentMenuName == roleMasterMenuname.Master) || (resourceElement.ParentMenuName == roleMasterMenuname.UPSI)) });

              _.remove(resources, function (resourceElement: any) {
                return ((resourceElement.ParentMenuName == roleMasterMenuname.Master) && !((resourceElement.Menu == roleMasterMenuname.RoleMenu) || (resourceElement.Menu == roleMasterMenuname.UserMenu) || (resourceElement.Menu == roleMasterMenuname.CompanyMenu) || (resourceElement.Menu == roleMasterMenuname.UPSIMaster) || (resourceElement.Menu == roleMasterMenuname.UPSIParameter)))
              })

              // console.log("resources - UPSI")
            }

            if((this.isUPSIConfig && this.isPSSConfig) && !this.isBoardEvaluationConfig){ //&& !this.isBoardEvaluationConfig
              // _.remove(resources, function (resourceElement: any) { return ((resourceElement.Menu == roleMasterMenuname.EmailTemplate)|| (resourceElement.ParentMenuName == roleMasterMenuname.GEM) ) } )
              _.remove(resources, function (resourceElement: any) { return (resourceElement.Menu == roleMasterMenuname.EmailTemplate ) } )

            }

          }          
         
          // _.remove(resources, function (resourceElement: any) { return resourceElement.MenuDisplayName == 'Roadmap' });

          if (_.size(resources)) {

            resources.forEach(resource => {
              switch (resource.ParentMenuName) {
                case roleMasterMenuname.Master:
                  this.masterMenu.push(resource);
                  break;
                case roleMasterMenuname.BoardEvaluation:
                  this.boardEvaluationMenu.push(resource);
                  break;
                case roleMasterMenuname.Governance:
                  if (resource.MenuDisplayName == "Assessment") {
                    if (this.isGuestSuperAdmin) {
                      _.remove(resource.ChildResources, function (childElement: any) { return (childElement.DisplayName == 'Execute' || childElement.DisplayName == 'Review') });
                    } else {
                      _.remove(resource.ChildResources, function (childElement: any) { return (childElement.DisplayName == 'IC Execute' || childElement.DisplayName == 'IC Review' || childElement.DisplayName == 'View Only' || childElement.DisplayName == 'Edit') });
                    }
                  }
                  this.governanceMenu.push(resource);
                  console.log("ROLLEEEEEEEE")
                  console.log(this.governanceMenu)
                  break;
                case roleMasterMenuname.Compliance:
                  this.complianceMenu.push(resource);
                  break;
                case roleMasterMenuname.UPSI:
                  this.upsiMenu.push(resource);
                  //console.log("this.uose menu", this.upsiMenu);
                  break;
                case roleMasterMenuname.DocumentManagement:
                  this.documentManagementMenu.push(resource);
                  break;
                case roleMasterMenuname.Admin:
                  // this.adminMenu.push(resource);
                  this.adminMenu = resource.ChildResources;
                  break;
                case roleMasterMenuname.Dashboard:
                  //console.log("resource",resource)                       
                  this.dashboardMenu = resource.ChildResources;
                  break;
                case roleMasterMenuname.GEM:
                  // console.log("resource", resource)
                  this.gemMenu.push(resource);

                  break;
                case roleMasterMenuname.LawsAndRegulations:

                  this.lawAndRegulationMenu.push(resource);
                  //console.log("this.lawAndRegulationMenu", this.lawAndRegulationMenu[0].MenuDisplayName)
                  break;
                case roleMasterMenuname.NCD:
                  this.ncdMenu.push(resource);
                  break;
              }


            });

            //console.log("this.gemMenu", this.gemMenu)

          }

          //console.log("dashboards", this.dashboardMenu)

        } else {
          //console.log("error getting resources list")
        }

      })

  }

  LeftMenuClick(subMenu: string) {

    if(this.isViewAllActive){
      this.viewAll();
    }
    console.error("sklog> LeftMenuClick subMenu:",subMenu);

    switch(subMenu){
      case "Master" : {
        $('#masterMenu').addClass("show");
        break;
      }
      case "boardEvaluationMenu" : {
        $('#boardEvaluationMenu').addClass("show");
        break;
      }
      case "governanceMenu" : {
        $('#governanceMenu').addClass("show");
        break;
      }
      case "complianceMenu" : {
        $('#complianceMenu').addClass("show");
        break;
      }
      case "upsiMenu" : {
        $('#upsiMenu').addClass("show");
        break;
      }
      case "ncdMenu" : {
        $('#ncdMenu').addClass("show");
        break;
      }
      case "documentManagementMenu" : {
        $('#documentManagementMenu').addClass("show");
        break;
      }
      case "adminMenu" : {
        $('#adminMenu').addClass("show");
        break;
      }
      case "dashboardMenu" : {
        $('#dashboardMenu').addClass("show");
        break;
      }
      case "gemMenu" : {
        $('#gemMenu').addClass("show");
        break;
      }
      case "lawAndRegulationMenu" : {
        $('#lawAndRegulationMenu').addClass("show");
        break;
      }
      default : {
        break;
      }

      
    }
    
    $('.rolemaster-viewall').css('position', '');
    $('.rolemaster-viewall').css('float', '');
  }


  cancel() {
    this.activeModal.dismiss('dismissed')
  }

  viewAll() {
    console.error("sklog> View All this.isViewAllActive:",this.isViewAllActive)
    if(this.isViewAllActive){
      this.isViewAllActive = false;

      $('#masterMenu').removeClass("show");
      $('#boardEvaluationMenu').removeClass("show");
      $('#governanceMenu').removeClass("show");
      $('#complianceMenu').removeClass("show");
      $('#documentManagementMenu').removeClass("show");
      $('#adminMenu').removeClass("show");
      $('#dashboardMenu').removeClass("show");
      $('#gemMenu').removeClass("show");
      $('#upsiMenu').removeClass("show");
      $('#ncdMenu').removeClass("show");
      $('#lawAndRegulationMenu').removeClass("show");

      $('#masterSubMenu').removeClass("subMenuViewAll");
      $('#boardEvaluationSubMenu').removeClass("subMenuViewAll");
      $('#governanceSubMenu').removeClass("subMenuViewAll");
      $('#complianceSubMenu').removeClass("subMenuViewAll");
      $('#documentManagementSubMenu').removeClass("subMenuViewAll");
      $('#adminSubMenu').removeClass("subMenuViewAll");
      $('#dashboardSubMenu').removeClass("subMenuViewAll");
      $('#gemSubMenu').removeClass("subMenuViewAll");
      $('#upsiSubMenu').removeClass("subMenuViewAll");
      $('#ncdSubMenu').removeClass("subMenuViewAll");
      $('#lawAndRegulationSubMenu').removeClass("subMenuViewAll");
  
      //$('dropdown').attr('aria-expanded', 'false');
      //$('.rolemaster-viewall').css('position', 'relative');
      //$('.rolemaster-viewall').css('display', 'block');
      //$('.rolemaster-viewall').css('float', 'right'); //display: block;

    }else{
      this.isViewAllActive = true;

      $('#masterMenu').addClass("show");
      $('#boardEvaluationMenu').addClass("show");
      $('#governanceMenu').addClass("show");
      $('#complianceMenu').addClass("show");
      $('#documentManagementMenu').addClass("show");
      $('#adminMenu').addClass("show");
      $('#dashboardMenu').addClass("show");
      $('#gemMenu').addClass("show");
      $('#upsiMenu').addClass("show");
      $('#ncdMenu').addClass("show");
      $('#lawAndRegulationMenu').addClass("show");

      $('#masterSubMenu').addClass("subMenuViewAll");
      $('#boardEvaluationSubMenu').addClass("subMenuViewAll");
      $('#governanceSubMenu').addClass("subMenuViewAll");
      $('#complianceSubMenu').addClass("subMenuViewAll");
      $('#documentManagementSubMenu').addClass("subMenuViewAll");
      $('#adminSubMenu').addClass("subMenuViewAll");
      $('#dashboardSubMenu').addClass("subMenuViewAll");
      $('#gemSubMenu').addClass("subMenuViewAll");
      $('#upsiSubMenu').addClass("subMenuViewAll");
      $('#ncdSubMenu').addClass("subMenuViewAll");
      $('#lawAndRegulationSubMenu').addClass("subMenuViewAll");
  
      //$('dropdown').attr('aria-expanded', 'true');
      // $('.rolemaster-viewall').css('position', 'relative');
      // $('.rolemaster-viewall').css('display', 'block');
      // $('.rolemaster-viewall').css('float', 'right'); //display: block;
    }
    //var ele = document.getElementById('masterMenu');
    //ele.classList.add("show");

    
    

  }


  // viewAll() {
  //   // var ele = document.getElementById('masterMenu');
  //   // ele.classList.add("show");
  //   // myDrop.open()
  //   $('#masterMenu').addClass("show");
  //   // $('dropdown').attr('aria-expanded', 'true');
  //   $('#governanceMenu').addClass("show");
  //   $('#complianceMenu').addClass("show");
  //   $('#documentManagementMenu').addClass("show");
  //   $('#adminMenu').addClass("show");
  //   $('#dashboardMenu').addClass("show");
  //   $('#gemMenu').addClass("show");
  //   $('#upsiMenu').addClass("show");
  //   $('#ncdMenu').addClass("show");

  //   $('#lawAndRegulationMenu').addClass("show");

  //   $('.rolemaster-viewall').css('position', 'relative');
  //   $('.rolemaster-viewall').css('float', 'right');
  // }


  Submenu(event) {
    console.error("sklog> event:",event);
    event.stopPropagation();
    //console.log("div clicked");
  }



  //select All checkbox Clicked Event
  ParentMenuCheckboxClicked(checkboxMenuId, subMenuId, menulist) {

    let self = this;
    //for master menu
    var parentHtmlElement = document.getElementById(subMenuId);
    let totalIconSelected = 0;  //total icons which have been selected in parent menu
    let totalIconForParentmenu = 0; //total icons with respect to parentmenu

    menulist.forEach(menu => {
      if (menu.MenuDisplayName == undefined) {
        var inputHtmlElement = document.getElementById(menu.DisplayName);
        var MenuDisplayName = menu.DisplayName;
      } else {
        var inputHtmlElement = document.getElementById(menu.MenuDisplayName);
        var MenuDisplayName = menu.MenuDisplayName;
      }
      let parentHtmlElement = inputHtmlElement.parentElement;
      let icontags = parentHtmlElement.getElementsByTagName("i");
      if (_.size(icontags)) {
        //console.log("_.size(self.finalRoleList)",_.size(self.finalRoleList));
        if (_.size(self.finalRoleList)) {
          for (var i = 0; i < _.size(icontags); i++) {
            if (icontags[i].classList.contains('icon-selected')) {
              totalIconSelected++;
            }
          }
        }
      }
      if (menu.MenuDisplayName != 'UPSI Detail All') {
        totalIconForParentmenu = totalIconForParentmenu + _.size(icontags)
      }
    })

    //console.log("IconSelected", totalIconSelected, totalIconForParentmenu);


    if (totalIconSelected == 0 || totalIconSelected == totalIconForParentmenu) {
      // _.remove(menulist, function(element){ return _.get(element, 'MenuDisplayName') == 'UPSI Detail All'})

      menulist.forEach(menu => {
        if (menu.MenuDisplayName == undefined) {
          var inputHtmlElement = document.getElementById(menu.DisplayName);
          var MenuDisplayName = menu.DisplayName;
        } else {
          var inputHtmlElement = document.getElementById(menu.MenuDisplayName);
          var MenuDisplayName = menu.MenuDisplayName;
        }
        let parentHtmlElement = inputHtmlElement.parentElement;
        let icontags = parentHtmlElement.getElementsByTagName("i");
        let IconSelected = 0;
        if (_.size(icontags)) {

          // console.log("_.size(self.finalRoleList)", _.size(self.finalRoleList));
          if (_.size(self.finalRoleList)) {
            for (var i = 0; i < _.size(icontags); i++) {
              if (icontags[i].classList.contains('icon-selected')) {
                IconSelected++;
              }
            }
          }
        }
        // if (menu.MenuDisplayName != 'UPSI Detail All') {
          this.checkboxHandling(menu, IconSelected, MenuDisplayName);
        // }
      })


      //console.log("added roles", this.addedRoles);
    }
    else  // if from menu few submenus have been selected 
    {

      //console.log("in between")

      menulist.forEach(menu => {

        if (menu.MenuDisplayName == undefined) {
          var inputHtmlElement = document.getElementById(menu.DisplayName);
          var MenuDisplayName = menu.DisplayName;
        } else {
          var inputHtmlElement = document.getElementById(menu.MenuDisplayName);
          var MenuDisplayName = menu.MenuDisplayName;
        }

        //console.log("index", this.addedRoles.findIndex(role => role.MenuDisplayName == MenuDisplayName))




        //will check whether that icon is selected or not

        //console.log("menu.ChildResources", menu.ChildResources);

        if (menu.ChildResources == undefined) {

          let iconElement = document.getElementById(menu.Url);

          const isIconElementPresent = _.find(this.finalRoleList, function (roleElement) {
            return roleElement.childUrl == menu.Url;
          })

          if (this.addedRoles.findIndex(role => role.MenuDisplayName == MenuDisplayName) < 0) {
            this.addedRoles.push({ "MenuDisplayName": MenuDisplayName, "id": menu.Url })
          }

          if (_.isNil(isIconElementPresent)) {
            if (iconElement.classList.contains("icon-unselected")) {
              iconElement.classList.remove("icon-unselected");
              iconElement.classList.add("icon-selected");
              iconElement.classList.remove("rolemaster-icon-hide");
              iconElement.classList.add("rolemaster-icon-display");
              this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': menu.Url });
            }
          }


        } else {


          menu.ChildResources.forEach(childElement => {
            let iconElement = document.getElementById(childElement.Url);

            const isIconElementPresent = _.find(this.finalRoleList, function (roleElement) {
              return roleElement.childUrl == childElement.Url;
            })


            if (this.addedRoles.findIndex(role => role.MenuDisplayName == MenuDisplayName) < 0) {
              this.addedRoles.push({ "MenuDisplayName": MenuDisplayName, "id": childElement.Url })
            }


            //if icon is not selected it will add it to the list.
            if (_.isNil(isIconElementPresent)) {
              if (iconElement.classList.contains("icon-unselected")) {
                iconElement.classList.remove("icon-unselected");
                iconElement.classList.add("icon-selected");
                iconElement.classList.remove("rolemaster-icon-hide");
                iconElement.classList.add("rolemaster-icon-display");
                this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': childElement.Url });
              }
            }

          })
        }
        // if (menu.MenuDisplayName != 'UPSI Detail All') {
        var checkboxMenuHTMLElement = <HTMLInputElement>document.getElementById(MenuDisplayName)

        checkboxMenuHTMLElement.indeterminate = false;

        checkboxMenuHTMLElement.checked = true;
        // }
      })

      var selectALLcheckboxHtmlElement = <HTMLInputElement>document.getElementById(checkboxMenuId)
      selectALLcheckboxHtmlElement.checked = true;

    }


    if (_.size(this.finalRoleList)) {
      this.isfinalRoleListEmpty = false;
    } else {
      this.isfinalRoleListEmpty = true;
    }
    this.selectAllCheckBoxStatus(checkboxMenuId, menulist);
  }

  // will try to resuse this function
  checkboxHandling(menu, IconSelected, MenuDisplayName) {

    if (menu.ChildResources == undefined) {

      if (IconSelected > 0) {

        let iconElement = document.getElementById(menu.Url);


        iconElement.classList.remove("icon-selected");
        iconElement.classList.add("icon-unselected");
        iconElement.classList.remove("rolemaster-icon-display");
        iconElement.classList.add("rolemaster-icon-hide");

        this.finalRoleList.splice(this.finalRoleList.findIndex(role => role.childUrl == menu.Url), 1);
        this.addedRoles.splice(this.addedRoles.findIndex(role => role.MenuDisplayName == MenuDisplayName), 1);

        var menucheckbox = <HTMLInputElement>document.getElementById(MenuDisplayName)
        //console.log("menucheckbox", menucheckbox)
        menucheckbox.checked = false;


      } else if (IconSelected == 0) {
        let iconElement = document.getElementById(menu.Url);

        iconElement.classList.remove("icon-unselected");
        iconElement.classList.add("icon-selected");
        iconElement.classList.remove("rolemaster-icon-hide");
        iconElement.classList.add("rolemaster-icon-display");

        this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': menu.Url });
        this.addedRoles.push({ 'MenuDisplayName': MenuDisplayName, 'id': menu.Url });
        var menucheckbox = <HTMLInputElement>document.getElementById(MenuDisplayName)
        menucheckbox.checked = true;

      }


    }//for rest of resources
    else if (menu.ChildResources) {

      //console.log("apart from admin and dashboard");

      //here it will assign selected or unselected class for icons 

      if (IconSelected > 0 && (IconSelected < _.size(menu.ChildResources))) {
        menu.ChildResources.forEach(childElement => {
          let iconElement = document.getElementById(childElement.Url);

          const isIconElementPresent = _.find(this.finalRoleList, function (roleElement) {
            return roleElement.childUrl == childElement.Url && menu.MenuDisplayName;
          })

          if (_.isNil(isIconElementPresent)) {
            if (iconElement.classList.contains("icon-unselected")) {
              iconElement.classList.remove("icon-unselected");
              iconElement.classList.add("icon-selected");
              iconElement.classList.add("rolemaster-icon-display");
              this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': childElement.Url });
            }
          }

        })

        var menucheckbox = <HTMLInputElement>document.getElementById(MenuDisplayName)
        //console.log("menucheckbox", menucheckbox)
        menucheckbox.indeterminate = false;
        menucheckbox.checked = true;

      } else if (IconSelected > 0 && (IconSelected == _.size(menu.ChildResources))) {

        var count = 0;

        menu.ChildResources.forEach(childElement => {
          let iconElement = document.getElementById(childElement.Url);

          const isIconElementPresent = _.find(this.finalRoleList, function (roleElement) {
            return roleElement.childUrl == childElement.Url;
          })

          iconElement.classList.remove("icon-selected");
          iconElement.classList.add("icon-unselected");
          iconElement.classList.remove("rolemaster-icon-display");
          iconElement.classList.add("rolemaster-icon-hide");
          this.finalRoleList.splice(this.finalRoleList.findIndex(role => role.childUrl == childElement.Url), 1);

          var b = _.filter(this.finalRoleList, function (role) {
            if (role.childUrl == childElement.Url) {
              return role
            }
          }).length;

          count = count + b;

        })

        if (count == 0) {
          this.addedRoles.splice(this.addedRoles.findIndex(role => role.MenuDisplayName == MenuDisplayName), 1);
        }

        var menucheckbox = <HTMLInputElement>document.getElementById(MenuDisplayName)
        //console.log("menucheckbox", menucheckbox)
        menucheckbox.checked = false;
        menucheckbox.indeterminate = false;

      } else if (IconSelected == 0) {
        let individual = <HTMLInputElement>document.getElementById(MenuDisplayName)
        menu.ChildResources.forEach(childElement => {
          if (MenuDisplayName != "UPSI Detail All") {
            document.getElementById(MenuDisplayName).setAttribute('val', 'true')
            let iconElement = document.getElementById(childElement.Url);
            iconElement.classList.remove("icon-unselected");
            iconElement.classList.add("icon-selected");
            iconElement.classList.remove("rolemaster-icon-hide");
            iconElement.classList.add("rolemaster-icon-display");
            this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': childElement.Url });

            if (this.addedRoles.findIndex(addedRole => addedRole.MenuDisplayName == MenuDisplayName) < 0) {
              this.addedRoles.push({ 'MenuDisplayName': MenuDisplayName, 'id': childElement.Url });
            }
          }
        })
        if (MenuDisplayName != "UPSI Detail All") {
          var menucheckbox = <HTMLInputElement>document.getElementById(MenuDisplayName)
          menucheckbox.checked = true;
          menucheckbox.indeterminate = false;
        }
      }
    }

    // }
  }

  //submenu Checkbox Clicked function --Ex Rolemaster checkbox() --- selectAllCheckboxId & menuArray have been passed to decide the status of selectall checkbox
  @Inject(DOCUMENT) document
  checkboxClicked(menu, event, MenuDisplayName, selectAllCheckboxId, menuArray) {
    let inputHtmlElement = event.target;

    let self = this;
    let parentHtmlElement = inputHtmlElement.parentElement;
    let icontags = parentHtmlElement.getElementsByTagName("i");
    let IconSelected = 0;

    if (_.size(icontags)) {

      if (_.size(self.finalRoleList)) {
        for (var i = 0; i < _.size(icontags); i++) {
          if (icontags[i].classList.contains('icon-selected')) {
            IconSelected++;
          }
        }
      }
    }

    //console.log("IconSelected", IconSelected);



    //for admin and dashboard
    if (menu.ChildResources == undefined) {

      if (IconSelected > 0) {

        let iconElement = document.getElementById(menu.Url);

        //console.log("class selected added")

        iconElement.classList.remove("icon-selected");
        iconElement.classList.add("icon-unselected");
        iconElement.classList.remove("rolemaster-icon-display");
        iconElement.classList.add("rolemaster-icon-hide");

        this.finalRoleList.splice(this.finalRoleList.findIndex(role => role.childUrl == menu.Url), 1);
        this.addedRoles.splice(this.addedRoles.findIndex(role => role.MenuDisplayName == MenuDisplayName), 1);


      } else if (IconSelected == 0) {
        let iconElement = document.getElementById(menu.Url);

        iconElement.classList.remove("icon-unselected");
        iconElement.classList.add("icon-selected");
        iconElement.classList.remove("rolemaster-icon-hide");
        iconElement.classList.add("rolemaster-icon-display");

        this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': menu.Url });
        this.addedRoles.push({ 'MenuDisplayName': MenuDisplayName, 'id': menu.Url });

      }

      //console.log("this.finalroleList", this.finalRoleList);
      //console.log("for admin and dashboard");

    }//for rest of resources
    else if (menu.ChildResources) {

      //console.log("apart from admin and dashboard");

      //here it will assign selected or unselected class for icons 

      // SELECTING ROLES
      if (IconSelected > 0 && (IconSelected < _.size(menu.ChildResources))) {
        menu.ChildResources.forEach(childElement => {
          let iconElement = document.getElementById(childElement.Url);
          //console.log('iconElement', iconElement)

          const isIconElementPresent = _.find(this.finalRoleList, function (roleElement) {
            return roleElement.childUrl == childElement.Url;
          })

          if (_.isNil(isIconElementPresent) && (menu.Menu != "UPSI Detail All" && menu.Menu != "UPSI Detail Individual")) {
            if (iconElement.classList.contains("icon-unselected")) {
              document.getElementById(MenuDisplayName).setAttribute('val', 'true')
              iconElement.classList.remove("icon-unselected");
              iconElement.classList.add("icon-selected");
              iconElement.classList.add("rolemaster-icon-display");
              this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': childElement.Url });
            }
          }


        })
      } else if (IconSelected > 0 && (IconSelected == _.size(menu.ChildResources))) {
        //DESELECTING ROLES
        var count = 0;
        document.getElementById(menu.MenuDisplayName).removeAttribute('val');
        menu.ChildResources.forEach(childElement => {
          let iconElement = document.getElementById(childElement.Url);
          //console.log('iconElement--- ', iconElement)

          const isIconElementPresent = _.find(this.finalRoleList, function (roleElement) {
            return roleElement.childUrl == childElement.Url;
          })
          document.getElementById(MenuDisplayName).removeAttribute('val')
          iconElement.classList.remove("icon-selected");
          iconElement.classList.add("icon-unselected");
          iconElement.classList.remove("rolemaster-icon-display");
          iconElement.classList.add("rolemaster-icon-hide");
          this.finalRoleList.splice(this.finalRoleList.findIndex(role => role.childUrl == childElement.Url), 1);

          var b = _.filter(this.finalRoleList, function (role) {
            if (role.childUrl == childElement.Url) {
              return role
            }
          }).length;

          count = count + b;

        })

        if (count == 0) {
          this.addedRoles.splice(this.addedRoles.findIndex(role => role.MenuDisplayName == MenuDisplayName), 1);
        }

      } else if (IconSelected == 0) {
        //UPSI COmmented
        if (this.isUPSIConfig && document.getElementById('UPSI Detail All').getAttribute('val') == "true" && MenuDisplayName == "UPSI Detail Individual") {
          document.getElementById('UPSI Detail All').removeAttribute('val')
          document.getElementById("UPSI Detail Individual").setAttribute('val', "true")
          let uspiAll = <HTMLInputElement>document.getElementById('UPSI Detail All')
          uspiAll.checked = false
          let deSelectChildrenIcon = uspiAll.nextElementSibling.children
          for (let index = 0; index < deSelectChildrenIcon.length; index++) {
            deSelectChildrenIcon[index].classList.remove("icon-selected");
            deSelectChildrenIcon[index].classList.add("icon-unselected");
            deSelectChildrenIcon[index].classList.add("rolemaster-icon-hide");
            deSelectChildrenIcon[index].classList.remove("rolemaster-icon-display");
            let indexOfUPSIAll = _.findIndex(self.finalRoleList, function (element) { return _.get(element, 'childUrl') == deSelectChildrenIcon[index].id })
            if (indexOfUPSIAll != -1) {
              let indexOfaddRole = _.findIndex(self.addedRoles, function (element) { return _.get(element, 'MenuDisplayName') == "UPSI Detail All" })
              if (indexOfaddRole != -1) {
                self.addedRoles.splice(indexOfaddRole, 1)
              }
              self.finalRoleList.splice(indexOfUPSIAll, 1)
            }
          }
          menu.ChildResources.forEach(childElement => {
            let iconElement = document.getElementById(childElement.Url);
            iconElement.classList.remove("icon-unselected");
            iconElement.classList.add("icon-selected");
            iconElement.classList.remove("rolemaster-icon-hide");
            iconElement.classList.add("rolemaster-icon-display");
            this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': childElement.Url });

            if (this.addedRoles.findIndex(addedRole => addedRole.MenuDisplayName == MenuDisplayName) < 0) {
              this.addedRoles.push({ 'MenuDisplayName': MenuDisplayName, 'id': childElement.Url });

            }
          })
        } else if (this.isUPSIConfig && document.getElementById('UPSI Detail Individual').getAttribute('val') == "true" && MenuDisplayName == "UPSI Detail All") {
          document.getElementById('UPSI Detail Individual').removeAttribute('val')
          document.getElementById("UPSI Detail All").setAttribute('val', "true")
          let uspiAll = <HTMLInputElement>document.getElementById('UPSI Detail Individual')
          uspiAll.checked = false
          let deSelectChildrenIcon = uspiAll.nextElementSibling.children
          for (let index = 0; index < deSelectChildrenIcon.length; index++) {
            deSelectChildrenIcon[index].classList.remove("icon-selected");
            deSelectChildrenIcon[index].classList.add("icon-unselected");
            deSelectChildrenIcon[index].classList.add("rolemaster-icon-hide");
            deSelectChildrenIcon[index].classList.remove("rolemaster-icon-display");
            let indexOfUPSIAll = _.findIndex(self.finalRoleList, function (element) { return _.get(element, 'childUrl') == deSelectChildrenIcon[index].id })
            if (indexOfUPSIAll != -1) {
              let indexOfaddRole = _.findIndex(self.addedRoles, function (element) { return _.get(element, 'MenuDisplayName') == 'UPSI Detail Individual' })
              if (indexOfaddRole != -1) {
                self.addedRoles.splice(indexOfaddRole, 1)
              }
              self.finalRoleList.splice(indexOfUPSIAll, 1)
            }

          }
          menu.ChildResources.forEach(childElement => {
            let iconElement = document.getElementById(childElement.Url);
            iconElement.classList.remove("icon-unselected");
            iconElement.classList.add("icon-selected"); 
            iconElement.classList.remove("rolemaster-icon-hide");
            iconElement.classList.add("rolemaster-icon-display");
            this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': childElement.Url });

            if (this.addedRoles.findIndex(addedRole => addedRole.MenuDisplayName == MenuDisplayName) < 0) {
              this.addedRoles.push({ 'MenuDisplayName': MenuDisplayName, 'id': childElement.Url });

            }
          })
        } 
        else {


          document.getElementById(menu.MenuDisplayName).setAttribute('val', 'true')
          menu.ChildResources.forEach(childElement => {
            let iconElement = document.getElementById(childElement.Url);
            iconElement.classList.remove("icon-unselected");
            iconElement.classList.add("icon-selected");
            iconElement.classList.remove("rolemaster-icon-hide");
            iconElement.classList.add("rolemaster-icon-display");
            this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': childElement.Url });

            if (this.addedRoles.findIndex(addedRole => addedRole.MenuDisplayName == MenuDisplayName) < 0) {
              this.addedRoles.push({ 'MenuDisplayName': MenuDisplayName, 'id': childElement.Url });

            }
          })
        }

      }

    }


    if (_.size(this.finalRoleList)) {
      this.isfinalRoleListEmpty = false;
    } else {
      this.isfinalRoleListEmpty = true;
    }


    this.selectAllCheckBoxStatus(selectAllCheckboxId, menuArray);

  }


  selectAllCheckBoxStatus(selectAllCheckboxId, menuArray) {

    var totalIconSelected = 0;
    var totalIcons = 0

    var selectAllCheckboxHTMlElement = <HTMLInputElement>document.getElementById(selectAllCheckboxId);

    menuArray.forEach(menu => {

      if (menu.MenuDisplayName == undefined) {
        totalIcons++;
        if (this.finalRoleList.findIndex(role => role.childUrl == menu.Url) >= 0) {
          totalIconSelected++;
        }

      }
      else {
        menu.ChildResources.forEach(chlidElement => {
          totalIcons++;
          if (this.finalRoleList.findIndex(role => role.childUrl == chlidElement.Url) >= 0) {
            totalIconSelected++;
          }
        })
      }
    })


    //console.log("how much selected", totalIconSelected, totalIcons);

    if (totalIconSelected == 0) {
      selectAllCheckboxHTMlElement.indeterminate = false;
      selectAllCheckboxHTMlElement.checked = false;
    } else if (totalIconSelected > 0 && totalIconSelected < totalIcons) {
      selectAllCheckboxHTMlElement.indeterminate = true;
      selectAllCheckboxHTMlElement.checked = false;
    } else if (totalIconSelected > 0 && totalIconSelected == totalIcons) {
      selectAllCheckboxHTMlElement.indeterminate = false;
      selectAllCheckboxHTMlElement.checked = true;
    }



  }

  mouseoverDisplay(menuElement, event, parentMenuName, MenuDisplayName) {
    var htmlMenuElement = event.target;
    event.target.style.textDecoration = "none!important";
    let icontags = htmlMenuElement.getElementsByTagName("i");
    let self = this;

    // if(menuElement.ParentMenuName == roleMasterMenuname.Master){

    if (_.size(icontags)) {
      for (var i = 0; i < _.size(icontags); i++) {
        // if(_.size(self.finalRoleList)){

        //   self.finalRoleList.forEach(roleElement => {
        //     if(roleElement.childUrl == icontags[i].id){
        //       icontags[i].classList.remove("rolemaster-icon-hide");
        //       icontags[i].classList.add("rolemaster-icon-display");
        //     }else{
        //       icontags[i].classList.remove("rolemaster-icon-display");
        //       icontags[i].classList.add("rolemaster-icon-hide");
        //     }
        // });

        // }else{
        //   if(icontags[i].classList.contains("rolemaster-icon-hide")){
        //     icontags[i].classList.remove("rolemaster-icon-hide");
        //     icontags[i].classList.add("rolemaster-icon-display");
        //   }
        // }


        icontags[i].classList.remove("rolemaster-icon-hide");
        icontags[i].classList.add("rolemaster-icon-display");

      }
    }

    // }else{  

    //   if (_.size(icontags)) {
    //     for (var i = 0; i < _.size(icontags); i++) {
    //       //console.log(icontags[i].style.display);
    //       if (icontags[i].style.display == "none") {
    //         //console.log("inside");
    //         icontags[i].style.display = "";
    //         icontags[i].style.display = "block";
    //       } else {
    //         //console.log("outside");
    //         icontags[i].style.display = "";
    //         icontags[i].style.display = "none";
    //       }

    //     }
    //     event.stopPropagation();
    //   }
    // }

  }

  mouseleaveDisplay(menuElement, event, parentMenuName, MenuDisplayName) {
    var htmlMenuElement = event.target;
    let icontags = htmlMenuElement.getElementsByTagName("i");

    let self = this;
    // if (menuElement.ParentMenuName == roleMasterMenuname.Master) {

    if (_.size(icontags)) {

      //console.log("_.size(self.finalRoleList)",_.size(self.finalRoleList));
      if (_.size(self.finalRoleList)) {

        for (var i = 0; i < _.size(icontags); i++) {
          //console.log("icontags[i].id",icontags[i].id);
          if (self.finalRoleList.findIndex(roleElement => roleElement.childUrl == icontags[i].id) >= 0) {
            icontags[i].classList.remove("rolemaster-icon-hide");
            icontags[i].classList.add("rolemaster-icon-display");
          } else {
            icontags[i].classList.remove("rolemaster-icon-display");
            icontags[i].classList.add("rolemaster-icon-hide");
          }
        }

        // for (var i = 0; i < _.size(icontags); i++) {

        // var i=0;
        // self.finalRoleList.forEach(roleElement => {

        //     if(roleElement.childUrl == icontags[i].id){
        //       //console.log("selected- icon display class on leave")
        //       icontags[i].classList.remove("rolemaster-icon-hide");
        //       icontags[i].classList.add("rolemaster-icon-display");
        //       i++;
        //     }else{
        //       //console.log("unselected- icon hide class on leave")
        //       icontags[i].classList.remove("rolemaster-icon-display");
        //       icontags[i].classList.add("rolemaster-icon-hide");
        //       i++;
        //     }

        // });

        // for (var i = 0; i < _.size(icontags); i++) {
        //   //console.log("id - " ,icontags[i].id)
        // }

        // }

      } else {

        for (var i = 0; i < _.size(icontags); i++) {
          if (icontags[i].classList.contains("rolemaster-icon-display")) {
            icontags[i].classList.remove("rolemaster-icon-display");
            icontags[i].classList.add("rolemaster-icon-hide");
          }
        }
      }

    }

    // } else {

    //   if (_.size(icontags)) {
    //     for (var i = 0; i < _.size(icontags); i++) {

    //       //console.log(icontags[i].style.display);

    //       if (icontags[i].style.display == "none") {
    //         //console.log("inside");
    //         icontags[i].style.display = "";
    //         icontags[i].style.display = "block";
    //       } else {
    //         //console.log("outside");
    //         icontags[i].style.display = "";
    //         icontags[i].style.display = "none";
    //       }
    //     }
    //     //console.log(icontags)
    //   }

    // }

  }


  iconClicked(event, menuElement, iconType, MenuDisplayName, childDetails, selectAllCheckboxId, menuArray) {
    event.stopPropagation();

    //View Icon Validation
    if (childDetails.IconName == "view") {
      if (menuElement.ChildResources == undefined) {
        // this will be for dashboard
        this.IconClickedFunctionExtension(event, menuElement, iconType, MenuDisplayName, childDetails, selectAllCheckboxId, menuArray);

      } else {
        //if any of url is in finalrole list then do not do not anything with view
        var iconSelected = 0;
        menuElement.ChildResources.forEach(element => {
          if ((element.IconName != "view") && this.finalRoleList.findIndex(role => role.childUrl == element.Url) >= 0) {
            //console.log("cannot click view as others have been selected");
            iconSelected++;
          }

        })

        if (iconSelected == 0) {
          this.IconClickedFunctionExtension(event, menuElement, iconType, MenuDisplayName, childDetails, selectAllCheckboxId, menuArray);
        }
      }
    } else {
      this.IconClickedFunctionExtension(event, menuElement, iconType, MenuDisplayName, childDetails, selectAllCheckboxId, menuArray);
    }
  }


  IconClickedFunctionExtension(event, menuElement, iconType, MenuDisplayName, childDetails, selectAllCheckboxId, menuArray) {
    var htmlMenuElement = event.target;

    // if (this.finalRoleList.indexOf(childDetails.Url) < 0) {
    if (this.finalRoleList.findIndex(role => role.childUrl == childDetails.Url) < 0) {

      if (this.addedRoles.findIndex(addedRole => addedRole.MenuDisplayName == MenuDisplayName) < 0) {
        this.addedRoles.push({ 'MenuDisplayName': MenuDisplayName, 'id': childDetails.Url });
      }

      this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': childDetails.Url });

      if (_.size(this.finalRoleList)) {
        this.isfinalRoleListEmpty = false;
      } else {
        this.isfinalRoleListEmpty = true;
      }

      //---------------------------View Icon Selection based on edit , add , delete

      // if((childDetails.IconName == "add" || childDetails.IconName == "edit" || childDetails.IconName == "delete")){
      // if ((childDetails.IconName != "view")) {
        if ((childDetails.IconName != "view" && childDetails.IconName !== "edit" && childDetails.IconName !== "sendToAssessee" && childDetails.IconName !== 'status')) {

        //console.log("view icon should get selected");
        if (menuElement.ChildResources == undefined) {

        } else {
          menuElement.ChildResources.forEach(element => {
            if (element.IconName == "view") {

              if (this.finalRoleList.findIndex(role => role.childUrl == element.Url) < 0) {

                var viewIconHTMLElement = document.getElementById(element.Url);
                viewIconHTMLElement.classList.remove("icon-unselected");
                viewIconHTMLElement.classList.add("icon-selected");

                viewIconHTMLElement.classList.add("rolemaster-icon-display");
                this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': element.Url });

              }

            }
          })
        }

      }

      //-----------------------------------------------------------------------


      //console.log("this.finalrolelist", this.finalRoleList);
      // htmlMenuElement.style.display = "block";
      htmlMenuElement.classList.remove("icon-unselected");
      htmlMenuElement.classList.add("icon-selected");

      htmlMenuElement.classList.add("rolemaster-icon-display");




      // } else if (this.finalRoleList.indexOf(childDetails.Url) >= 0) {
    } else if (this.finalRoleList.findIndex(role => role.childUrl == childDetails.Url) >= 0) {
      //console.log("icon-unselected  is not present");
      //console.log("findindex else final role list",this.finalRoleList.findIndex(role => role.childUrl == childDetails.Url))
      //console.log("this icon was  present --- >> now applying deselection class")

      // htmlMenuElement.style.display =  "none";
      // this.finalRoleList.splice(this.finalRoleList.indexOf(childDetails.Url), 1);

      this.finalRoleList.splice(this.finalRoleList.findIndex(role => role.childUrl == childDetails.Url), 1);

      htmlMenuElement.classList.remove("icon-selected");
      htmlMenuElement.classList.add("icon-unselected");
      htmlMenuElement.classList.add("rolemaster-icon-hide");
      //console.log("this.finalrolelist", this.finalRoleList);


      var count = 0;

      if (menuElement.ChildResources == undefined) {

        var b = _.filter(this.finalRoleList, function (role) {
          if (role.childUrl == menuElement.Url) {
            return role
          }
        }).length;
        count = count + b;

      } else {
        menuElement.ChildResources.forEach(childElement => {
          var b = _.filter(this.finalRoleList, function (role) {
            if (role.childUrl == childElement.Url) {
              return role
            }
          }).length;
          count = count + b;
        });

      }

      if (count == 0) {
        //console.log("this.addedRoles.findIndex(role => role.childUrl == childDetails.Url)",this.addedRoles.findIndex(role => role.childUrl == childDetails.Url))
        this.addedRoles.splice(this.addedRoles.findIndex(role => role.MenuDisplayName == MenuDisplayName), 1);
      }

      if (_.size(this.finalRoleList)) {
        this.isfinalRoleListEmpty = false;
      } else {
        this.isfinalRoleListEmpty = true;
      }

      //console.log("count",count);

    }




    let self = this;
    let parentHtmlElement = htmlMenuElement.parentElement;
    let icontags = parentHtmlElement.getElementsByTagName("i");
    //console.log("icontags", icontags);
    let IconSelected = 0;

    if (_.size(icontags)) {

      //console.log("_.size(self.finalRoleList)",_.size(self.finalRoleList));
      if (_.size(self.finalRoleList)) {
        for (var i = 0; i < _.size(icontags); i++) {
          if (icontags[i].classList.contains('icon-selected')) {
            IconSelected++;
          }
        }
      }
    }

    var checkboxElement = <HTMLInputElement>document.getElementById(MenuDisplayName);
    if (IconSelected > 0 && IconSelected == _.size(icontags)) {
      checkboxElement.indeterminate = false;
      checkboxElement.checked = true;
    } else if (IconSelected > 0 && IconSelected < _.size(icontags)) {
      checkboxElement.checked = false;
      checkboxElement.indeterminate = true;
    } else if (IconSelected == 0) {
      checkboxElement.checked = false;
      checkboxElement.indeterminate = false;
    }

    // this.addedRoles.push({'_id':menuElement._id,'MenuDisplayName': menuElement.MenuDisplayName});
    // this.addedRoles.push(MenuDisplayName);


    this.selectAllCheckBoxStatus(selectAllCheckboxId, menuArray);
    //console.log("this.addedRoles",this.addedRoles);
    //console.log("this.finalrolelist", this.finalRoleList);

  }


  removeAddedRoleList(index, addedRole) {
    this.addedRoles.splice(index, 1);
    //console.log("this.addedroles", this.addedRoles);
    //console.log("roles", addedRole)
    //console.log("finalroleafter removing", this.finalRoleList);

    this.finalRoleList = _.filter(this.finalRoleList, function (roles) {
      if (addedRole.MenuDisplayName != roles.MenuDisplayName) {
        return roles
      }
    })



    var addedRoleHtmlElement = document.getElementById(addedRole.id).parentElement;
    //console.log("addedRoleHtmlElement", addedRoleHtmlElement);

    let iconElementsToUnselect = addedRoleHtmlElement.getElementsByTagName("i");
    var checkboxElement = <HTMLInputElement>document.getElementById(addedRole.MenuDisplayName);
    //console.log("iconElement", checkboxElement);
    checkboxElement.checked = false;
    checkboxElement.indeterminate = false;



    //console.log("iconElementsToUnselect", iconElementsToUnselect);
    if (_.size(iconElementsToUnselect)) {
      for (var i = 0; i < _.size(iconElementsToUnselect); i++) {
        iconElementsToUnselect[i].classList.remove("icon-selected");
        iconElementsToUnselect[i].classList.add("icon-unselected");
        iconElementsToUnselect[i].classList.remove("rolemaster-icon-display");
        iconElementsToUnselect[i].classList.add("rolemaster-icon-hide");
      }
    }

    if (_.size(this.finalRoleList)) {
      this.isfinalRoleListEmpty = false;
    } else {
      this.isfinalRoleListEmpty = true;
    }



    //console.log("iconElement ---->", checkboxElement.parentElement);
    let selectAllContainingDiv = checkboxElement.parentElement;

    let parentElement = selectAllContainingDiv.parentElement;
    let totalIconstags = parentElement.getElementsByTagName("i");
    var totalIconSelected = 0;
    // remove select all remaining iconstags 

    for (var j = 0; j < _.size(totalIconstags); j++) {
      //console.log("totalIconstags[j].classList.contains['icon-selected']", totalIconstags[j].classList.contains['icon-selected']);
      if (totalIconstags[j].classList.contains('icon-selected')) {
        totalIconSelected++;
      }
    }

    //console.log(parentElement.getElementsByTagName("i"));

    //console.log("totalIconSelected , _.size(totalIconstags)", totalIconSelected, _.size(totalIconstags));

    var DivElement = parentElement.getElementsByTagName("div")[0];
    var selectAllCheckboxHTMLElement = DivElement.getElementsByTagName("input")[0];

    if (totalIconSelected == 0) {
      selectAllCheckboxHTMLElement.indeterminate = false;
      selectAllCheckboxHTMLElement.checked = false;
      //console.log("0");
    } else if (totalIconSelected > 0 && totalIconSelected < _.size(totalIconstags)) {
      selectAllCheckboxHTMLElement.indeterminate = true;
      selectAllCheckboxHTMLElement.checked = false;
      //console.log("inde");

    } else if (totalIconSelected > 0 && totalIconSelected == _.size(totalIconstags)) {
      selectAllCheckboxHTMLElement.indeterminate = false;
      selectAllCheckboxHTMLElement.checked = true;
      //console.log("=");

    }
  }

  SubmitRole() {
    let inputRoleName = this.addRoleForm.get('roleName').value.trim();

    let finalRoleActivities = [];
    this.finalRoleList.forEach(roleList => {
      finalRoleActivities.push(roleList.childUrl);
    });
    //console.log("inputRoleName-finalRoleActivities", inputRoleName, finalRoleActivities);

    this.roleMasterService.addNewRole(inputRoleName, finalRoleActivities)
      .then(response => {
        if (response.status == 0) {
          //console.log("successfully added role");
          this.activeModal.close(response);
        } else {
          //console.log("role not added");
          this.activeModal.close(response);
        }
      }, error => {}
      // console.log("")
    )
  }

  CancelModal() {
    this.activeModal.dismiss('dismissed');
  }

}
