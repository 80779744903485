<style>
  .input-box,
  .input-box-msg {
    position: relative;
    height: 40px;
  }

  input.real-input,
  .fake-input,
  .fake-input1,
  .fake-input2 {
    display: block;
    width: 100%;
    height: 30px;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    line-height: 1.25;
    color: #74708d;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border-radius: 0.25rem;
    position: absolute;
    text-align: left;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }

  /* input.real-input,
  .fake-input:hover {
    border: 1px solid #0190fe;
  }
  .form-group.input-box:hover .fake-input,
  .form-group.input-box:hover .fake-input input:focus {
    border: 1px solid #0190fe;
  } */

  /* input.real-input,
  .fake-input1:hover {
    border: 1px solid #0190fe;
  }
  .form-group.input-box-msg:hover .fake-input1,
  .form-group.input-box-msg:hover .fake-input1 input:focus {
    border: 1px solid #0190fe;
  } */

  /* input.real-input,
  .fake-input2:hover {
    border: 1px solid #0190fe;
  }
  .form-group.input-box:hover .fake-input2,
  .form-group.input-box:hover .fake-input2 input:focus {
    border: 1px solid #0190fe;
  } */

  .form-group.input-box .fake-input,
  .form-group.input-box-msg .fake-input1,
  .form-group.input-box .fake-input2 {
    opacity: 0.6;
  }

  input.real-input {
    opacity: 0;
    background: transparent;
  }
</style>

<section>
  <div>
    <div class="inner-wrap">
      <p>
        <img
          src="assets/modules/dummy-assets/common/img/GovEVA.png"
          alt="GovEVA"
        />
      </p>
      <h2>Change Password</h2>
      <form [formGroup]="resetPasswordForm">
        <div class="form-group input-box">
          <div id="fake-input" class="fake-input2">
            <span class="blinking-cursor"></span>Password
          </div>

          <div class="password-wrapper">
            <input
              [type]="passwordVisible2 ? 'text' : 'password'"
              class="form-control"
              autocomplete="off"
              (focusin)="blinkCur2()"
              (focusout)="blinkCurOut2()"
              (keyup)="RemoveSavePassword2()"
              formControlName="oldPassword"
              placeholder="Old Password"
            />

            <i
              class="fa"
              [ngClass]="passwordVisible2 ? 'fa-eye' : 'fa-eye-slash'"
              (click)="togglePasswordVisibility2()"
            ></i>
            <!-- Eye icon -->
          </div>
          <div
            style="color: red; text-align: left; font-size:12PX"
            *ngIf="
              resetPasswordForm.get('oldPassword').touched &&
              resetPasswordForm.controls['oldPassword'].hasError('required')
            "
          >
            Please enter old password.
          </div>
        </div>

        <div class="form-group input-box-msg">
          <div id="fake-input" class="fake-input1">
            <span class="blinking-cursor"></span>New Password
          </div>
          <div class="password-wrapper">
            <input
              [type]="passwordVisible1 ? 'text' : 'password'"
              class="form-control"
              autocomplete="off"
              (focusin)="blinkCur1()"
              (focusout)="blinkCurOut1()"
              placeholder="New Password"
              (keyup)="RemoveSavePassword1()"
              formControlName="password"
            />

            <i
              class="fa"
              [ngClass]="passwordVisible1 ? 'fa-eye' : 'fa-eye-slash'"
              (click)="togglePasswordVisibility1()"
            ></i>
            <!-- Eye icon -->
          </div>
          <div
            style="color: red"
            *ngIf="resetPasswordForm.controls['password'].hasError('pattern')"
          >
            <ul style="padding-left: 6%; text-align: left; font-size:12PX">
              <li>Please enter a minimum of 8 characters.</li>
              <li>At least one lowercase and one uppercase letter.</li>
              <li>At least one number.</li>
              <li>At least one special character.</li>
            </ul>
          </div>
        </div>
        <div class="form-group input-box">
          <div id="fake-input" class="fake-input">
            <span class="blinking-cursor"></span>Re-enter New Password
          </div>
          <div class="password-wrapper">
            <input
              [type]="passwordVisible ? 'text' : 'password'"
              class="form-control"
              autocomplete="off"
              (focusin)="blinkCur()"
              (focusout)="blinkCurOut()"
              (keyup)="RemoveSavePassword()"
              formControlName="confirmPassword"
              placeholder="Re-enter New Password"
            />

            <i
              class="fa"
              [ngClass]="passwordVisible ? 'fa-eye' : 'fa-eye-slash'"
              (click)="togglePasswordVisibility()"
            ></i>
            <!-- Eye icon -->
          </div>
          <div
            style="color: red; text-align: left; font-size:12PX"
            *ngIf="
              resetPasswordForm.get('password').touched &&
              resetPasswordForm.get('confirmPassword').touched &&
              resetPasswordForm.get('password').value !==
                resetPasswordForm.get('confirmPassword').value
            "
          >
            Passwords entered do not match.
          </div>
          <br />
        </div>
        <div>
          <button
            class="btn btn-primary btn-block mr-2 mb-2"
            [disabled]="!resetPasswordForm.valid"
            (click)="submitPassword()"
          >
            Reset Password
          </button>
        </div>
      </form>
      <!-- <form [formGroup]="resetPasswordForm">
                    <div class="form-group input-box">                            
                            <div id="fake-input" class="fake-input2"><span class="blinking-cursor"></span>Password</div>
                            <input class="real-input" (focusin)="blinkCur2()"  (focusout)="blinkCurOut2()"    (keyup)="RemoveSavePassword2()" placeholder="Old Password" type="text" formControlName="oldPassword">
                            <div style="color:red;padding-top: 32px;" *ngIf="resetPasswordForm.get('oldPassword').touched && resetPasswordForm.controls['oldPassword'].hasError('required')">Please enter old password.</div>
                        </div>
                <div class="form-group input-box-msg">                   
                    <div id="fake-input" class="fake-input1"><span class="blinking-cursor"></span>New Password</div>
                    <input class="real-input" (focusin)="blinkCur1()"  (focusout)="blinkCurOut1()"    (keyup)="RemoveSavePassword1()" placeholder="New Password" type="text" formControlName="password">
                    <div style="color:red;padding-top: 32px;" *ngIf="resetPasswordForm.controls['password'].hasError('pattern')">Please enter atleast one lowercase, one uppercase, one number, one special character and minimum length
                        should be 8.</div>
                </div>
                <div class="form-group input-box">                   
                    <div id="fake-input" class="fake-input"><span class="blinking-cursor"></span>Re-enter New Password</div>
                    <input class="real-input"  (focusin)="blinkCur()"  (focusout)="blinkCurOut()"    (keyup)="RemoveSavePassword()" placeholder="Re-enter New Password" type="text" formControlName="confirmPassword">
                    <div style="color:red;padding-top: 32px;" *ngIf="resetPasswordForm.get('password').touched && resetPasswordForm.get('confirmPassword').touched && resetPasswordForm.get('password').value !== resetPasswordForm.get('confirmPassword').value">Password does snot match</div>
                    <br>                
                </div>
                <div>
                    <button class="btn btn-primary btn-block mr-2 mb-2" [disabled]="!resetPasswordForm.valid" (click)="submitPassword()">Reset Password</button>
                </div>
            </form> -->
    </div>
  </div>
</section>
