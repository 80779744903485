// @ts-nocheck
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../services/login.service';
import { RocketChatService } from '../services/rocketChat.service';
import { LastLoginDetailsService } from '../services/last-login-details.service';
import * as CryptoJS from 'crypto-js';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyMasterService } from '../services/companymaster.service';
import { rocketChatRemove } from '../enums/rocketChatRemove';
import { safeJsonStringify } from '../shared/utils';
import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { OTPLoginService } from '../services/otp-login.service';
import { NotificationComponent } from '../otp-login/notification/notification.component';

declare var $: any;
declare var jQuery: any;
declare var swal: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginConfig: any = {};
  companyList: any = [];
  selectedCompanyId: any;
  chatEngineURL: any;
  eva: any;
  returnUrl: any;
  listOfCompanyDetails: any;
  loginModel: any;
  userName: string;
  isADCompany: boolean = false;
  LoginDisabled: boolean = false;
  isCompanyListDisable: boolean = true;
  isEmailForCompany: string = 'Please enter a valid email id or username';
  companyConfig: any;
  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
  loginForm = new FormGroup({
    companyName: new FormControl('', [Validators.required]),
    userName: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });

    passwordVisible: boolean = false;  // Flag to track visibility of the password
  constructor(
    public activeModal: NgbActiveModal,
    public otpLoginService: OTPLoginService,
    private router: Router,
    private companyService: CompanyMasterService,
    private loginService: LoginService,
    private rocketChatService: RocketChatService,
    private route: ActivatedRoute,
    private lastloginService: LastLoginDetailsService,
    private socialAuthService: SocialAuthService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    $('body').removeClass('dashboard-page');

    var sessionValue = sessionStorage.getItem('SessionName');
    if (sessionValue != 'userLoggedIn') {
      this.loginService.logOut();
    }

    const self = this;
    setTimeout(function () {
      self.activeModal.dismiss('dismiss');
      self.userInput.nativeElement.focus(); 
    }, 100);

    var token = localStorage.getItem('token');

    //added on 4Apr

    if (_.size(this.route.snapshot.queryParams['emailId'])) {
      this.userName = this.route.snapshot.queryParams['emailId'];
      this.loginForm.controls.userName.updateValueAndValidity();
      this.getCompaniesByEmailId();
    }

    var returnURL = this.returnUrl;
    var splitQueryParameters = '';
    if (returnURL != undefined) {
      splitQueryParameters = returnURL.split('?');
    }
    this.redirectPath = decodeURIComponent(`${window.location.hash.split('returnUrl=')[1]}`)

    if (_.isNil(token)) {
      this.getCompanies();
      this.hideTopBar();
    }
    //following condition occures when user login through pss and jumps to goveva menus
    else if (splitQueryParameters.length === 2) {
      var queryParameters = splitQueryParameters[1];
      var parameters = queryParameters.split('&');
      if (parameters.length === 2) {
        var IsgoEvaUser = parameters[0].split('=');
        var goEvaUserEmail = parameters[1].split('=');
        if (IsgoEvaUser[1]) {
          var loginWithoutPassword = {
            UserName: goEvaUserEmail[1],
          };
          //login from PSS and click on the goveva menu then [login into Goveva system without password and redirect to request page [This request coming from PSS]
          this.loginService
            .loginWithoutPassword(loginWithoutPassword)
            .subscribe((data) => {
              // @ts-ignore
              if (data.status == 0) {
                // @ts-ignore
                localStorage.setItem('user', safeJsonStringify(data.user));
                // @ts-ignore
                localStorage.setItem('token', data.token);
                // @ts-ignore
                localStorage.setItem(
                  'resources',
                  safeJsonStringify(data.resources)
                );
                this.router.navigate([
                  'structure',
                  {
                    outlets: { 'structure-outlet': [splitQueryParameters[0]] },
                  },
                ]);
              }
            });
        }
      }
    } else if (splitQueryParameters.length === 3) {
      let pssLink = splitQueryParameters.length - 1;
      window.open(pssLink.toString());
    } else {
      //if already login
      this.router.navigate([
        'structure',
        { outlets: { 'structure-outlet': 'dashboards/alpha' } },
      ]);
    }
  }



  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  getInputValueChange(event: Event): string {
    // @ts-ignore
    return (event.target ? (event.target as HTMLInputElement) : event).value;
  }

  clearUserSession() {
    let loginData: any;
    loginData = {
      EmailId: this.userName, // Company Email,
      CompanyId: this.selectedCompanyId, // Company Id
    };

    this.loginService.clearSession(loginData).subscribe((response) => {
      console.log('clearSession response', response);
    });
  }

  secretKey = 'Secret message';

  encrypt(value: string): string {
    return CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
  }

  decrypt(textToDecrypt: string) {
    return CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(
      CryptoJS.enc.Utf8
    );
  }

  blinkCur() {
    var inputValue = this.loginForm.controls.password.value;
    var numChars = inputValue.length;
    var showText = '';
    var i;

    for (i = 0; i < numChars; i++) {
      showText += '&#8226;';
    }

    $('.fake-input').html(
      '<span class="blinking-cursor">' + showText + '|</span>'
    );
  }
  blinkCurOut() {
    $('.fake-input span').text('');

    var inputValue = this.loginForm.controls.password.value;
    var numChars = inputValue.length;
    var showText = '';
    var i;

    if (inputValue.length > 0) {
      for (i = 0; i < numChars; i++) {
        showText += '&#8226;';
      }

      $('.fake-input').html(showText);
    } else {
      $('.fake-input').html('Password');
    }
  }
  RemoveSavePassword() {
    var inputValue = this.loginForm.controls.password.value;
    var numChars = inputValue.length;
    var showText = '';
    var i;

    for (i = 0; i < numChars; i++) {
      showText += '&#8226;';
    }

    $('.fake-input').html(showText);
  }

  getCompanies() {
    this.loginService.companyListForUserManagement().subscribe((companies) => {
      if (companies.status != 0) {
        this.router.navigate(['companySetup']);
      }
    });
  }

  clearToken(loginModel) {
    this.loginService
      .clearTokenByUserAgent(loginModel)
      .subscribe((tokenResult) => {
        if (tokenResult.status == 0) {
          console.log('user token cleared');
        }
      });
  }

  getCompaniesByEmailId() {
    //get array of company Id using email id
    this.listOfCompanyDetails = [];
    this.companyList = [];
    let emailId = this.userName;
    var CopyEmailAddress = '';

    if (emailId != '') {
      try {
        this.isEmailForCompany =
          'Either due to low bandwidth or API unavailable';
        return this.loginService
          .getCompanyIdsByEmailId(emailId)
          .subscribe((companyIds) => {
            if (companyIds.status == 0) {
              if (companyIds.companyIds.length > 0) {
                localStorage.setItem('CopyEmailAddress', emailId);
                CopyEmailAddress = localStorage.getItem('CopyEmailAddress');
                this.loginService
                  .getCompanyListByIds(companyIds)
                  .subscribe((companies) => {
                    // @ts-ignore
                    if (companies.status == 0) {
                      this.isCompanyListDisable = false;
                      // @ts-ignore
                      this.listOfCompanyDetails = companies.companyList;
                      // @ts-ignore
                      this.companyList = companies.companyList.map(company =>{
                        return {
                          label: company.CompanyName,
                          value: company._id,
                        };
                      });
                      //console.log(this.companyList)
                    } else {
                      this.companyList = [];
                    }
                  });
              } else {
                this.isCompanyListDisable = true;
                this.isEmailForCompany =
                  'Either due to low bandwidth or API unavailable';
              }
            } else {
              this.isEmailForCompany = 'Please enter valid email id';
              localStorage.removeItem('CopyEmailAddress');
            }
          });
      } catch (error) {
        console.log('error occured', error);
        this.isEmailForCompany =
          'Either due to low bandwidth or API unavailable';
      }
    }

    if (emailId == 'superadmin@gmail.com' || emailId == '') {
      this.companyConfig = {
        authLogin: 'Inactive',
        twoFactor: 'Inactive',
        normalLogin: true,
      };
    }
  }

  login(loginType = 'normal') {
    if (this.loginForm.status == 'VALID' && loginType == 'normal') {
      localStorage.setItem('isAuthLogin', 'no');
      this.callLogin();
    } else if ('loginByGoogle' == loginType) {
      this.socialAuthService
        .signIn(GoogleLoginProvider.PROVIDER_ID)
        .then((userData) => {
          localStorage.setItem('isAuthLogin', 'yes');
          this.callLogin(userData);
        })
        .catch((error) => {
          console.log(' sign in data : ', error);
        });
    }
  }

  callLogin(socialLogin = null) {
    const agent = window.navigator.userAgent.toLowerCase();
    if (agent) {
      var notification_type = 'Browser';
    } else {
      var notification_type = 'Ipad';
    }

    if (this.isADCompany) {
      this.loginModel = {
        UserName: this.loginForm.controls.userName.value,
        Password: this.encrypt(this.loginForm.controls.password.value),
        CompanyId: this.selectedCompanyId,
        NotificationType: notification_type,
        socialLogin,
      };
    } else {
      this.loginModel = {
        UserName: _.toLower(this.loginForm.controls.userName.value),
        Password: this.encrypt(this.loginForm.controls.password.value),
        CompanyId: this.selectedCompanyId,
        NotificationType: notification_type,
        socialLogin,
      };
    }

    const self = this;
    this.clearToken(this.loginModel);
    if (
      _.toLower(this.loginForm.controls.userName.value) ==
      'superadmin@gmail.com'
    ) {
      this.loginFunction();
    } else {
      if (this.isADCompany) {
        this.loginForADUser();
      } else {
        this.loginFunction();
      }
    }
  }

  loginFunction() {
    this.LoginDisabled = true;
    this.loginService.login(this.loginModel).subscribe((data) => {
      if (data.status == 202) {
        localStorage.removeItem('userToken');
        localStorage.setItem('emailId', this.loginModel.UserName);
        localStorage.setItem('companyId', this.loginModel.CompanyId);
        localStorage.setItem('mobileNumber', data.MobileNumber);
        this.otpLoginService.clearUserCredential();
        this.otpLoginService.setUserCredential(data);
        localStorage.setItem('userToken', data.userToken);
        localStorage.setItem('companyEVA', null);
        this.redirectPath = decodeURIComponent(`${window.location.hash.split('returnUrl=')[1]}`)
        console.log('this.redirectPath', this.redirectPath)

        if (this.redirectPath == 'undefined') {
          this.router.navigate(['Submit/LoginOTP']);
          console.log('login 1')
        }
        else {
          this.router.navigate(['Submit/LoginOTP'], { queryParams: {returnUrl: this.redirectPath}});  
          console.log('login 2')
        }

        const modalRef = this.modalService.open(NotificationComponent);
        modalRef.componentInstance.notify = {
          status: 'Success',
          message: data.message,
        };
      } else {
        const self = this;
        let listOfCompany = _.groupBy(self.listOfCompanyDetails, '_id');
        let companyNameAndValue = this.companyList.find(function (company) {
          return company.value == self.selectedCompanyId;
        });
        let companyData = _.first(listOfCompany[self.selectedCompanyId]);
        this.chatEngineURL = _.get(companyData, 'ChatEngineURL');
        this.eva = _.get(companyData, 'EVA');
        if (data.status == 0) {
          this.LoginDisabled = false;

          if (rocketChatRemove.isRemove.toString() != 'true') {
            let loginChatModel: any;
            loginChatModel = {
              user: data.user.RocketChatUserName,
              password: data.user.RocketChatPassword,
            };

            this.rocketChatService
              .loginToRocketChat(loginChatModel, this.chatEngineURL)
              .then((rocketChatData) => {
                if (rocketChatData.status == 'success') {
                  // Switching to the subsidiary data ----start
                  localStorage.setItem('isSubsidiary', 'false');
                  // Switching to the subsidiary data ----end
                  localStorage.setItem(
                    'companyDetailsGoveva',
                    safeJsonStringify(companyNameAndValue)
                  );
                  localStorage.setItem(
                    'companyDetails',
                    safeJsonStringify({
                      companyName: companyNameAndValue.label,
                      companyID: companyNameAndValue.value,
                    })
                  );
                  localStorage.setItem('user', safeJsonStringify(data.user));
                  localStorage.setItem('companyEVA', this.eva);
                  if (data.user.FirstTimeLogin) {
                    localStorage.setItem(
                      'rocketAuth',
                      rocketChatData.data.authToken
                    );
                    localStorage.setItem(
                      'rocketUserId',
                      rocketChatData.data.userId
                    );
                    localStorage.setItem('rocketBaseURL', this.chatEngineURL);

                    this.router.navigate(['/changePassword']);
                  } else {
                    localStorage.setItem('token', data.token);
                    localStorage.setItem(
                      'resources',
                      safeJsonStringify(data.resources)
                    );

                    localStorage.setItem(
                      'rocketAuth',
                      rocketChatData.data.authToken
                    );
                    localStorage.setItem(
                      'rocketUserId',
                      rocketChatData.data.userId
                    );
                    localStorage.setItem('rocketBaseURL', this.chatEngineURL);

                    this.showTopBar();

                    this.returnUrl =
                      this.route.snapshot.queryParams['returnUrl'] || '/';

                    if (this.returnUrl != '/') {
                      try {
                        var check64 =
                          btoa(atob(this.returnUrl)) == this.returnUrl;
                      } catch (err) {
                        var check64 = false;
                      }
                      if (check64 == true) {
                        this.returnUrl = atob(this.returnUrl);
                      } else {
                        this.returnUrl = this.returnUrl;
                      }
                      if (_.includes(this.returnUrl, 'http')) {
                        window.open(this.returnUrl, '_self');
                      } else {
                        const urlAfterSplit = this.returnUrl.split(':');
                        const lastElement =
                          urlAfterSplit[urlAfterSplit.length - 1];
                        const redirectUrl = _.first(lastElement.split(')'));
                        this.router.navigate([
                          'structure',
                          { outlets: { 'structure-outlet': redirectUrl } },
                        ]);
                      }
                    } else {
                      this.router.navigate([
                        'structure',
                        { outlets: { 'structure-outlet': 'dashboards/alpha' } },
                      ]);
                    }
                  }
                }
              });
          } else {
            // Switching to the subsidiary data ----start
            localStorage.setItem('isSubsidiary', 'false');
            // Switching to the subsidiary data ----end
            localStorage.setItem(
              'companyDetailsGoveva',
              safeJsonStringify(companyNameAndValue)
            );
            localStorage.setItem(
              'companyDetails',
              safeJsonStringify({
                companyName: companyNameAndValue.label,
                companyID: companyNameAndValue.value,
              })
            );
            localStorage.setItem('user', safeJsonStringify(data.user));
            localStorage.setItem('companyEVA', this.eva);
            sessionStorage.setItem('SessionName', 'userLoggedIn');

            if (data.user.FirstTimeLogin) {
              localStorage.setItem('rocketBaseURL', this.chatEngineURL);
              // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
              localStorage.setItem('token', data.token);
              this.router.navigate(['/changePassword']);
            } else {
              localStorage.setItem('token', data.token);
              localStorage.setItem(
                'resources',
                safeJsonStringify(data.resources)
              );

              localStorage.setItem('rocketBaseURL', this.chatEngineURL);

              self.showTopBar();

              self.returnUrl =
                self.route.snapshot.queryParams['returnUrl'] || '/';

              if (self.returnUrl != '/') {
                try {
                  var check64 = btoa(atob(this.returnUrl)) == this.returnUrl;
                } catch (err) {
                  var check64 = false;
                }
                if (check64 == true) {
                  this.returnUrl = atob(this.returnUrl);
                } else {
                  this.returnUrl = this.returnUrl;
                }

                if (
                  _.includes(self.returnUrl, 'http') ||
                  _.includes(self.returnUrl, 'https')
                ) {
                  window.open(self.returnUrl, '_self');
                } else {
                  const urlAfterSplit = self.returnUrl.split(':');
                  const lastElement = urlAfterSplit[urlAfterSplit.length - 1];
                  const redirectUrl = _.first(lastElement.split(')'));

                  this.router.navigate([
                    'structure',
                    { outlets: { 'structure-outlet': redirectUrl } },
                  ]);
                }
              } else {
                self.router.navigate([
                  'structure',
                  { outlets: { 'structure-outlet': 'dashboards/alpha' } },
                ]);
                // .then(() => {
                //   location.reload();
                // });
              }
            }
          }
        } else {
          this.LoginDisabled = false;
          $('#alertTitle').css('color', '#f00');
          $('#alertTitle').text('Failure!');
          $('#alertMessage').css('color', '#f00');
          $('#messagePopUp').modal('toggle');
          $('#alertMessage').text(data.message);
          this.messagePopUpTimeoutToogle();
          this.socialAuthService
            .signOut()
            .then((resData) => {
              console.log('resData', resData);
            })
            .catch((error) => {
              console.log('errorerrorerror', error);
            });
          self.router.navigate(['/login']);
        }
      }
    });
  }

  loginForADUser() {
    this.loginService.loginForADUser(this.loginModel).subscribe(
      (data) => {
        const self = this;
        let listOfCompany = _.groupBy(self.listOfCompanyDetails, '_id');
        let companyNameAndValue = this.companyList.find(function (company) {
          return company.value == self.selectedCompanyId;
        });
        let companyData = _.first(listOfCompany[self.selectedCompanyId]);
        this.chatEngineURL = _.get(companyData, 'ChatEngineURL');
        this.eva = _.get(companyData, 'EVA');
        if (data.status == 0) {
          if (rocketChatRemove.isRemove.toString() != 'true') {
            let loginChatModel: any;
            loginChatModel = {
              user: data.user.RocketChatUserName, //FirstName + data.user.LastName,
              password: data.user.RocketChatPassword, //this.loginForm.controls.password.value//"Demo@1234"
            };

            this.rocketChatService
              .loginToRocketChat(loginChatModel, this.chatEngineURL)
              .then((rocketChatData) => {
                if (rocketChatData.status == 'success') {
                  // Switching to the subsidiary data ----start
                  localStorage.setItem('isSubsidiary', 'false');
                  // Switching to the subsidiary data ----end
                  localStorage.setItem(
                    'companyDetailsGoveva',
                    safeJsonStringify(companyNameAndValue)
                  );
                  localStorage.setItem(
                    'companyDetails',
                    safeJsonStringify({
                      companyName: companyNameAndValue.label,
                      companyID: companyNameAndValue.value,
                    })
                  );
                  localStorage.setItem('user', safeJsonStringify(data.user));
                  localStorage.setItem('companyEVA', this.eva);
                  localStorage.setItem('token', data.token);
                  localStorage.setItem(
                    'resources',
                    safeJsonStringify(data.resources)
                  );

                  localStorage.setItem(
                    'rocketAuth',
                    rocketChatData.data.authToken
                  );
                  localStorage.setItem(
                    'rocketUserId',
                    rocketChatData.data.userId
                  );
                  localStorage.setItem('rocketBaseURL', this.chatEngineURL);

                  this.showTopBar();

                  this.returnUrl =
                    this.route.snapshot.queryParams['returnUrl'] || '/';
                  if (this.returnUrl != '/') {
                    try {
                      var check64 =
                        btoa(atob(this.returnUrl)) == this.returnUrl;
                    } catch (err) {
                      var check64 = false;
                    }
                    if (check64 == true) {
                      this.returnUrl = atob(this.returnUrl);
                    } else {
                      this.returnUrl = this.returnUrl;
                    }
                    if (_.includes(this.returnUrl, 'http')) {
                      window.open(this.returnUrl, '_self');
                    } else {
                      const urlAfterSplit = this.returnUrl.split(':');
                      const lastElement =
                        urlAfterSplit[urlAfterSplit.length - 1];
                      const redirectUrl = _.first(lastElement.split(')'));
                      this.router.navigate([
                        'structure',
                        { outlets: { 'structure-outlet': redirectUrl } },
                      ]);
                      // .then(() => {
                      //   location.reload();
                      // });
                    }
                  } else {
                    this.router.navigate([
                      'structure',
                      { outlets: { 'structure-outlet': 'dashboards/alpha' } },
                    ]);
                    // .then(() => {
                    //   location.reload();
                    // });
                  }
                }
              });
          } else {
            // Switching to the subsidiary data ----start
            localStorage.setItem('isSubsidiary', 'false');
            // Switching to the subsidiary data ----end
            localStorage.setItem(
              'companyDetailsGoveva',
              safeJsonStringify(companyNameAndValue)
            );
            localStorage.setItem(
              'companyDetails',
              safeJsonStringify({
                companyName: companyNameAndValue.label,
                companyID: companyNameAndValue.value,
              })
            );
            localStorage.setItem('user', safeJsonStringify(data.user));
            localStorage.setItem('companyEVA', this.eva);
            sessionStorage.setItem('SessionName', 'userLoggedIn');
            localStorage.setItem('token', data.token);
            localStorage.setItem(
              'resources',
              safeJsonStringify(data.resources)
            );

            localStorage.setItem('rocketBaseURL', this.chatEngineURL);

            this.showTopBar();

            this.returnUrl =
              this.route.snapshot.queryParams['returnUrl'] || '/';
            if (this.returnUrl != '/') {
              try {
                var check64 = btoa(atob(this.returnUrl)) == this.returnUrl;
              } catch (err) {
                var check64 = false;
              }
              if (check64 == true) {
                this.returnUrl = atob(this.returnUrl);
              } else {
                this.returnUrl = this.returnUrl;
              }
              if (_.includes(this.returnUrl, 'http')) {
                window.open(this.returnUrl, '_self');
              } else {
                const urlAfterSplit = this.returnUrl.split(':');
                const lastElement = urlAfterSplit[urlAfterSplit.length - 1];
                const redirectUrl = _.first(lastElement.split(')'));
                this.router.navigate([
                  'structure',
                  { outlets: { 'structure-outlet': redirectUrl } },
                ]);
                // .then(() => {
                //   location.reload();
                // });
              }
            } else {
              this.router.navigate([
                'structure',
                { outlets: { 'structure-outlet': 'dashboards/alpha' } },
              ]);
              // .then(() => {
              //   location.reload();
              // });
            }
          }
        } else {
          $('#alertTitle').css('color', '#f00');
          $('#alertTitle').text('Failure!');
          $('#alertMessage').css('color', '#f00');
          $('#messagePopUp').modal('toggle');

          $('#alertMessage').text(
            'Your last session was terminated incorrectly or is currently active, do you want to kill last session?'
          );
          this.messagePopUpTimeoutToogle();
        }
      },
      (exception) => {
        console.log('exception', exception);
      }
    );
  }

  forgotPassword() {
    this.router.navigate(['/requestPassword']);
  }

  messagePopUpTimeoutToogle() {
    setTimeout(function () {
      var popUpClass = $('#messagePopUp').attr('class');
      if (popUpClass == 'modal fade show') {
        $('#messagePopUp').modal('toggle');
      }
    }, 5000);
  }

  hideTopBar() {
    $('.cat__top-bar').hide();
    $('.cat__menu-left').hide(); //hide the sidebar
    $('.cat__top-bar__right').hide(); //hide the logout icon
    $('.cat__footer').hide();
  }

  showTopBar() {
    $('.cat__menu-left').show();
    $('.cat__top-bar__right').show();
    $('.cat__top-bar').show();
    $('.cat__footer').show();
  }

  companySelection(companyId) {
    this.companyService.getcompanyDetailsNonValidateURL(companyId).subscribe(
      (companyData) => {
        // @ts-ignore
        if (_.size(companyData) > 0) {
          // @ts-ignore
          if (
            !_.isUndefined(companyData.companyData) &&
            _.size(companyData.companyData) > 0
          ) {
            // @ts-ignore
            this.isADCompany =
              companyData.companyData.IsADSubscribedFlag || false;
          }
        }

        if (this.isADCompany) {
          this.loginForm.controls.userName.clearValidators();
          this.loginForm.controls.userName.setValidators([Validators.required]);
          this.loginForm.controls.userName.updateValueAndValidity();
        } else {
          this.loginForm.controls.userName.clearValidators();
          this.loginForm.controls.userName.setValidators([
            Validators.required,
            Validators.email,
          ]);
          this.loginForm.controls.userName.updateValueAndValidity();
        }
      },
      (error) => {
        // console.log("runtimeError : " , error)
      }
    );

    try {
      this.companyService
        .getCompanyConfig(companyId)
        .subscribe((resData: any) => {
          // resData.configData = JSON.parse(
          //   this.commonFunction.decrypt(resData.configData)
          // );

          if (
            resData.configData &&
            this.loginForm.value.userName != 'superadmin@gmail.com'
          ) {
            this.companyConfig = {
              ...this.companyConfig,
              ...resData.configData,
              normalLogin: true,
            };
          } else {
            this.companyConfig = {
              authLogin: 'Inactive',
              twoFactor: 'Inactive',
              normalLogin: true,
            };
          }
        });
    } catch (error) {
      console.log('error', error);
    }
  }
}
