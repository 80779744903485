import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormArray,
  FormBuilder,
  Validators,
  FormControl,
  EmailValidator,
} from '@angular/forms';
import { ForgotPasswordService } from './../services/forgot-password.service';
import { Router } from '@angular/router';

import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { NotificationComponent } from './../forgot-password/notification/notification.component';
import * as _ from 'lodash';
import { Notification } from 'rxjs';
// import { SuccessNotificationComponent } from './../success-notification/success-notification.component';

import { rocketChatRemove } from '../enums/rocketChatRemove';
import { CommonFunction } from './../helpers/commonFunction';
import { LoginService } from '../services/login.service';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-reset-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {
  public resetPasswordForm: FormGroup;
  passwordVisible: boolean = false;  // Flag to track visibility of the password
  passwordVisible1: boolean = false;  // Flag to track visibility of the password
  passwordVisible2: boolean = false;  // Flag to track visibility of the password

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private forgotPasswordService: ForgotPasswordService,
    private commonFunction: CommonFunction,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    $('body').removeClass('dashboard-page');
    $('.cat__menu-left').hide();
    this.hideTopBar();

    this.resetPasswordForm = new FormGroup(
      {
        oldPassword: new FormControl('', [
          Validators.required,
          // Validators.minLength(8),
          // Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%\*#?&])[a-zA-Z0-9$@$!%\*#?&]{8,}")
        ]),
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(
            '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*#?&])[a-zA-Z0-9$@$!%*#?&]{8,}'
          ),
        ]),
        confirmPassword: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(
            '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*#?&])[a-zA-Z0-9$@$!%*#?&]{8,}'
          ),
        ]),
      },
      passwordMatchValidator
    );
    // console.log(this.resetPasswordForm.get('password'))

    function passwordMatchValidator(g: FormGroup) {
      return g.get('password').value === g.get('confirmPassword').value
        ? null
        : { mismatch: true };
    }
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }
  togglePasswordVisibility1() {
    this.passwordVisible1 = !this.passwordVisible1;
  }
  togglePasswordVisibility2() {
    this.passwordVisible2 = !this.passwordVisible2;
  }

  hideTopBar() {
    $('.cat__top-bar').hide();
    $('.cat__menu-left').hide(); //hide the sidebar
    $('.cat__top-bar__right').hide(); //hide the logout icon
    $('.cat__footer').hide();
  }

  showTopBar() {
    $('.cat__menu-left').show();
    $('.cat__top-bar__right').show();
    $('.cat__top-bar').show();
    $('.cat__footer').show();
  }

  blinkCur2() {
    var inputValue = this.resetPasswordForm.get('oldPassword').value;
    var numChars = inputValue.length;
    var showText = '';
    var i;

    for (i = 0; i < numChars; i++) {
      showText += '&#8226;';
    }

    $('.fake-input2').html(
      '<span class="blinking-cursor">' + showText + '|</span>'
    );
    // $('.fake-input span').text('|');
  }
  blinkCurOut2() {
    $('.fake-input1 span').text('');

    var inputValue = this.resetPasswordForm.get('oldPassword').value;
    var numChars = inputValue.length;
    var showText = '';
    var i;

    if (inputValue.length > 0) {
      for (i = 0; i < numChars; i++) {
        showText += '&#8226;';
      }

      $('.fake-input2').html(showText);
    } else {
      $('.fake-input2').html('Old Password');
    }
  }
  RemoveSavePassword2() {
    var inputValue = this.resetPasswordForm.get('oldPassword').value;
    var numChars = inputValue.length;
    var showText = '';
    var i;

    for (i = 0; i < numChars; i++) {
      showText += '&#8226;';
    }

    $('.fake-input2').html(showText);
  }

  blinkCur1() {
    var inputValue = this.resetPasswordForm.get('password').value;
    var numChars = inputValue.length;
    var showText = '';
    var i;

    for (i = 0; i < numChars; i++) {
      showText += '&#8226;';
    }

    $('.fake-input1').html(
      '<span class="blinking-cursor">' + showText + '|</span>'
    );
    // $('.fake-input span').text('|');
  }
  blinkCurOut1() {
    $('.fake-input1 span').text('');

    var inputValue = this.resetPasswordForm.get('password').value;
    var numChars = inputValue.length;
    var showText = '';
    var i;

    if (inputValue.length > 0) {
      for (i = 0; i < numChars; i++) {
        showText += '&#8226;';
      }

      $('.fake-input1').html(showText);
    } else {
      $('.fake-input1').html('New Password');
    }
  }
  RemoveSavePassword1() {
    var inputValue = this.resetPasswordForm.get('password').value;
    var numChars = inputValue.length;
    var showText = '';
    var i;

    for (i = 0; i < numChars; i++) {
      showText += '&#8226;';
    }

    if (this.resetPasswordForm.controls['password'].hasError('pattern')) {
      $('.input-box-msg').css('height', '100px');
    } else {
      $('.input-box-msg').css('height', '40px');
    }

    $('.fake-input1').html(showText);
  }

  blinkCur() {
    var inputValue = this.resetPasswordForm.get('confirmPassword').value;
    var numChars = inputValue.length;
    var showText = '';
    var i;

    for (i = 0; i < numChars; i++) {
      showText += '&#8226;';
    }

    $('.fake-input').html(
      '<span class="blinking-cursor">' + showText + '|</span>'
    );
    // $('.fake-input span').text('|');
  }
  blinkCurOut() {
    $('.fake-input span').text('');

    var inputValue = this.resetPasswordForm.get('confirmPassword').value;
    var numChars = inputValue.length;
    var showText = '';
    var i;

    if (inputValue.length > 0) {
      for (i = 0; i < numChars; i++) {
        showText += '&#8226;';
      }

      $('.fake-input').html(showText);
    } else {
      $('.fake-input').html('Re-enter New Password');
    }
  }
  RemoveSavePassword() {
    var inputValue = this.resetPasswordForm.get('confirmPassword').value;
    var numChars = inputValue.length;
    var showText = '';
    var i;

    for (i = 0; i < numChars; i++) {
      showText += '&#8226;';
    }

    $('.fake-input').html(showText);
  }

  submitPassword() {
    const self = this;
    const localObject = JSON.parse(localStorage.getItem('user'));
    // Decripted Mail Id
    var decEmailId = this.commonFunction.decrypt(localObject.EmailId);

    if (rocketChatRemove.isRemove.toString() != 'true') {
      const passwordObject = {
        // 'emailId': localObject.EmailId,
        emailId: decEmailId,
        oldPassword: self.resetPasswordForm.get('oldPassword').value,
        password: self.resetPasswordForm.get('password').value,
        companyId: localObject.CompanyId,
        rocketChatAuthToken: localStorage.getItem('rocketAuth'),
        rocketChatUserId: localStorage.getItem('rocketUserId'),
        rocketChatBaseURL: localStorage.getItem('rocketBaseURL'),
      };
      this.forgotPasswordService
        .changePassword(passwordObject)
        .then((response) => {
          if (!response.status) {
            const modalRef = this.modalService.open(NotificationComponent);
            modalRef.componentInstance.notify = {
              status: 'Success',
              message: 'Password changed successfully',
            };

            localStorage.clear();
            this.router.navigate(['login'], {
              queryParams: {
                returnUrl: '/structure/(structure-outlet:dashboards/alpha)',
              },
            });
          } else {
            const modalRef = this.modalService.open(NotificationComponent);
            modalRef.componentInstance.notify = {
              status: 'Failure',
              message: response.message,
            };
            if (response.locked) {
              this.loginService.logOut().subscribe((response) => {
                localStorage.clear();

                this.router.navigate(['/login']);
              });
            }
          }
        });
    } else {
      const passwordObject = {
        // 'emailId': localObject.EmailId,
        emailId: decEmailId,
        oldPassword: self.resetPasswordForm.get('oldPassword').value,
        password: self.resetPasswordForm.get('password').value,
        companyId: localObject.CompanyId,
        rocketChatBaseURL: localStorage.getItem('rocketBaseURL'),
        isRemoveRocketChat: rocketChatRemove.isRemove,
      };
      this.forgotPasswordService
        .changePassword(passwordObject)
        .then((response) => {
          if (!response.status) {
            const modalRef = this.modalService.open(NotificationComponent);
            modalRef.componentInstance.notify = {
              status: 'Success',
              message: 'Password changed successfully',
            };
            localStorage.clear();
            // localStorage.removeItem('user');
            // localStorage.removeItem('rocketBaseURL');
            this.router.navigate(['login'], {
              queryParams: {
                returnUrl: '/structure/(structure-outlet:dashboards/alpha)',
              },
            });
          } else {
            const modalRef = this.modalService.open(NotificationComponent);
            modalRef.componentInstance.notify = {
              status: 'Failure',
              message: response.message,
            };
            if (response.locked) {
              this.loginService.logOut().subscribe((response) => {
                localStorage.clear();

                this.router.navigate(['/login']);
              });
            }
          }
        });
    }
  }
}
