<section class="card">
  <div *ngIf="showSpinner" class="modal-backdrop fade in" style="background-color: transparent"></div>

  <div class="card-header">
    <span class="cat__core__title">
      <strong>Questionnaire Setup</strong>
    </span>
  </div>

  <div *ngIf="showSpinner" class="center-all">
    <i class="fa fa-spinner fa-spin" style="font-size: 35px; color: #07a7a5"></i>
  </div>

  <div class="card-block watermark">
    <div class="row">
      <div class="col-lg-12">
        <form [formGroup]="entityForm" class="well" autocomplete="off">
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-2">
              </div>
              <div class="col-md-4">
              </div>
            </div>

            <div class="row">
              <div class="col-lg-2">
                <label class="form-control-label" for="l0">Select Entity
                  <span style="color: red;">*</span>
                </label>
              </div>
              <div class="form-group  col-lg-10">
                <select class="form-control" [formControl]="entityForm.controls['evaluationType']" name="evaluationType"
                  [(ngModel)]="evaluationType" style="padding:6px;">
                  <!--(change)="getEvaluationCriteriaByEvalIdList(evaluationType)"-->
                  <option [ngValue]="undefined" selected [disabled]="true">select</option>
                  <option *ngFor="let evalType of evaluationTypeList" value="{{evalType._id}}">
                    {{evalType.EvaluationType}}</option>
                </select>
                <div
                  *ngIf="entityForm.controls['evaluationType'].hasError('required') && entityForm.controls['evaluationType'].touched"
                  class="form-control-error-custom p-0 mt-1">
                  Entity is required.
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-2">
                <label class="form-control-label">Evaluation Questionnaire Name
                  <span style="color: red;">*</span>
                </label>
              </div>
              <div class="col-lg-10">
                <div class="form-group">
                  <input class="form-control" type="text" placeholder=" Evaluation Questionnaire Name"
                    (keyup)="evaluationNameChangeEvent($event)" (change)="evaluationNameChangeEvent($event)"
                    name="evaluationName" [formControl]="entityForm.controls['evaluationName']"
                    [(ngModel)]="evaluationName">
                  <div
                    *ngIf="entityForm.controls['evaluationName'].hasError('required') && entityForm.controls['evaluationName'].touched"
                    class="form-control-error-custom">
                    Evaluation Questionnaire Name is required.
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-2">
                <label class="form-control-label">Score Range
                  <span style="color: red;">*</span>
                </label>
              </div>
              <div class="ml-3 mt-1">1 -</div>
              <div class="col-lg-3 ml-2 p-0">
                <div class="form-group">
                  <input class="form-control" type="text" name="scoreRange"
                    [formControl]="entityForm.controls['scoreRange']" [(ngModel)]="scoreRange"
                    (change)="checkScoreRangeValue($event)" (keyup)="onlyNumber($event)" (keydown)="onlyNumber($event)"
                    (blur)="test()" required>
                  <div
                    *ngIf="entityForm.controls['scoreRange'].hasError('required') && entityForm.controls['scoreRange'].touched "
                    class="form-control-error-custom">
                    Score Range is required
                  </div>
                  <div *ngIf="scoreRangeLimit" class="form-control-error-custom">
                    Score Range must be less than 15 and greater than 0
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-2">
                <label class="form-control-label">Score Particulars
                  <span style="color: red;">*</span>
                </label>
              </div>
              <div formGroupName="arrayOfScoreParticular" class="row col-lg-10">
                <div *ngFor="let item of arrayOfScoreParticular; let i = index;">
                  <div style="display: inline-flex;margin-top: 2%;">
                    <label style="margin-left:5%">{{(i+1
                      < 10 ) ? ( "0" + (i+1)) : i+1}}</label> <input style="margin-left:5%"
                          formControlName="{{item.scoreParticulars}}" class="form-control" type="text"
                          placeholder="text" id="{{item.scoreParticulars}}" required="required"
                          (change)="scoreParticularsValue($event,i+1)">
                  </div>
                  <div style="margin-left:5%"
                    *ngIf="entityForm.controls.arrayOfScoreParticular.controls[item.scoreParticulars].hasError('required') && entityForm.controls.arrayOfScoreParticular.controls[item.scoreParticulars].touched "
                    class="form-control-error-custom">
                    {{(((i+1)
                    < 10 ) ? ( "0" + (i+1)) : (i+1)) + " " }}Score Particular is required </div>
                      <div style="margin-left:5%"
                        *ngIf="entityForm.controls.arrayOfScoreParticular.controls[item.scoreParticulars].hasError('sameScoreParticular') && entityForm.controls.arrayOfScoreParticular.controls[item.scoreParticulars].touched "
                        class="form-control-error-custom">
                        {{(((i+1)
                        < 10 ) ? ( "0" + (i+1)) : (i+1)) + " " }}Same Score Particular entered </div>

                      </div>


                  </div>
                </div> <br>
                <div class="row">
                  <div class="col-lg-2">
                    <label class="form-control-label">Is Anonymous</label>
                    <span style="color: red;">*</span>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label class="form-check-label">
                        <input type="radio" [formControl]="entityForm.controls['isAnonymous']" style="position:absolute"
                          name="isAnonymous" value="true" class="form-check-input">Yes</label>
                      <label class="form-check-label">
                        <input type="radio" [formControl]="entityForm.controls['isAnonymous']" style="position:absolute"
                          name="isAnonymous" value="false" class="form-check-input">No</label>

                      <div
                        *ngIf="entityForm.controls['isAnonymous'].hasError('required') && entityForm.controls['isAnonymous'].touched"
                        class="form-control-error-custom">
                        IsAnonymous is required.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-2">
                    <span>Select Criteria</span>
                    <span style="color: red;">*</span>
                  </div>
                  <!-- <span *ngIf="selectedAssessmentType=='RFactor'" style="color: red">Note: Please select 'Relative' and 'Response' Category for execution of R-Factor</span> -->
                  <br>
                  <div class="col-lg-10">
                    <div class="margin-bottom-50"></div>
                    <div style="position:relative">
                      <div class="col-lg-12 ver-scroll"
                        style="position:relative; overflow-y: scroll; height: 170px;overflow-x: hidden; border: 1px solid rgba(0,0,0,.125); padding-top: 15px;">
                        <div class="row form-group">
                          <div class="col-lg-3" *ngFor="let criteria of criterias">
                            <div class="row">
                              <div class="col-lg-8">
                                <label class="form-check-label custom-tooltip">
                                  <input id="{{criteria._id}}" [disabled]="criteria.isEnable"
                                    [formControl]="entityForm.controls['criteriaName']" name="criteriaName"
                                    type="checkbox" value="{{criteria._id}}" (change)="checkSelectedCriteria($event)">
                                  <span class="">{{criteria.EvaluationCriteria}}</span>
                                </label>
                              </div>
                              <div class="col-lg-1" style="padding: 5px 0px">
                              </div>
                              <!-- % -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-lg-9"></div>
                  <div class="col-lg-3" style="text-align:right">
                    <button class="btn btn-sm btn-primary" type="submit" (click)="saveCriteria()"
                      [disabled]="criteriaIsEmpty">Save
                      Criteria</button>
                  </div>
                </div>
                <div class="row">
                  <div>
                    <span>Criteria List</span>
                  </div>
                  <br />
                  <!-- <input type="checkbox" id="equalCriteriaCheck" [checked]="equalCriteria" (click)="onEqualcriteria()" /> -->

                  <input id="equalCriteriaCheck" type="checkbox"
                    [checked]="equalCriteria"
                    (click)="onEqualcriteria()"
                    style="display: block;margin: 0 5px 0 35px;position: relative;top: 2px;">
                  Equal weights to all criteria
                  <div class="table-responsive table-bordered margin-bottom-50">
                    <table datatable id="selectedCriteriatable" [dtTrigger]="dtTrigger"
                      class="table table-hover compact cell-border nowrap table-striped dataTable"
                      style="background:transparent" width="100%">
                      <thead>
                        <tr>
                          <th style="width:60%">Criteria Name</th>
                          <th style="width:10%">Criteria Weight(%)</th>
                          <th style="width:5%">Select Question</th>
                          <th style="width:5%">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let selectedCriteria of selectedCriteriaListData; let countindex = index">
                          <td style="width: 60%">{{selectedCriteria.EvaluationCriteria}}</td>
                          <td style="width: 10%">
                            <div>
                              <input type="number" step="0.25" min="0.25" [(ngModel)]="selectedCriteria.criteriaWeight"
                                name="categoryWeightage" [formControl]="entityForm.controls['categoryWeightage']"
                                class="form-control" [readOnly]="equalCriteria"
                                (change)="categoryWeightCalculation(countindex,selectedCriteria.criteriaWeight)">
                            </div>
                          </td>
                          <td style="width: 5%;text-align: center;">
                            <button type="button" class="btn btn-sm btn-primary"
                              (click)="showCriteriaQuetionsPopup(selectedCriteria)">{{selectedNameToShow(selectedCriteria)
                              ? "Select" : "Update"}}</button>
                            <!-- <button type="button" class="btn btn-sm btn-primary" *ngIf="questionListIsNotEmpty" (click)="showCriteriaQuetionsPopup(selectedCriteria)">Update</button>-->
                          </td>
                          <td
                            style="width: 5%;text-align: center; color: #74708d; cursor:pointer;vertical-align:middle">
                            <div class="icmn-bin" (click)="confirmDeleteCriteria(selectedCriteria._id)"></div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12">
                    <div *ngIf="greaterCriteriaWeight || invalidCriteriaWeight"
                      style="float:right;color: #fb434a;font-size: 80%;z-index: 1;">
                      <!-- Total Category weight must be 100. -->
                      Sum of Criteria weight should be equal 100%.
                    </div>
                    <!-- <div *ngIf="invalidCriteriaWeight" style="float:right;color: #fb434a;font-size: 80%;z-index: 1;">
                    No of selected categories and total weight should be equal.
                  </div> -->
                  </div>
                </div>
                <br>

                <div class="modal-footer">
                  <!-- <button type="button" class="btn btn-sm btn-primary" *ngIf="!isAssigned" style="float:right" [disabled]="!(!(sendEntityFormForReview) && (entityForm.valid) && !(greaterCriteriaWeight) && !(invalidCriteriaWeight))"
                    (click)="submitEntity()">Send For Review</button> -->
                  <button type="button" class="btn btn-sm btn-primary" style="float:right"
                    [disabled]="!(!(sendEntityFormForReview) && (entityForm.valid) && (entityForm.controls.arrayOfScoreParticular.valid)  && !(greaterCriteriaWeight) && !(invalidCriteriaWeight))  || disableSavebutton"
                    (click)="sendForReview()">Save to Questionnaire Bank</button>
                </div>
              </div>
              <!--[disabled]="!entityForm.valid"-->
        </form>
        <!--question dialogbox-->
        <div class="modal fade modal-size-large" id="EntityQuestionSetup" tabindex="-1" role="dialog" aria-labelledby=""
          aria-hidden="true" style="display: none;">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">

              <div class="modal-header">
                <h5 class="modal-title" id="Header">Select Criteria Questions</h5>
                <button type="button" class="close " style="right:5px;position:absolute" data-dismiss="modal"
                  (click)="closeForm('EntityQuestionSetup')" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div class="modal-body">
                <div>
                  <!--|| (selectedAssessmentType=='RFactor' && categoryName!='Relative' && categoryName!='Ratings')"> -->
                  <div class="form-group">
                    <div class="row">
                      <div class="col-lg-12">
                        <label class="form-control-label col-sm-12" style="font-weight: bold;">Questions
                          selected</label>
                        <div class="cat__core__widget cat__core__widget--border">
                          <div
                            style="max-height:200px;width: 100%!important; min-height: 100px; overflow-y: auto;float: left;">
                            <table class="table table-hover nowrap table-striped dataTable "
                              style="margin: 0px !important;">
                              <thead>
                                <tr>
                                  <th style="padding: 2px 10px; width:4%;">
                                    <!-- <input type="checkbox" id="chkTagsAll" title="Select All" (change)="question_Changed($event)" name="TagsQ"> -->
                                  </th>
                                  <th style="padding: 2px 10px; width:86%;">Question List</th>
                                </tr>
                              </thead>
                              <tbody style="border-bottom: 1px solid #e4e9f0;">
                                <tr *ngFor="let question of questions">
                                  <td style="padding: 2px 10px; width:8%;">
                                    <input type="checkbox" id="{{question._id}}" [checked]="question.isChecked"
                                      (change)="question_Changed($event)" name="TagsQ" value="{{question._id}}">
                                  </td>
                                  <td style="padding: 2px 10px; width:60%;white-space: normal;">
                                    {{question.questionText}}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!--arrows up and down-->
                    <div>
                      <div class="col-lg-12" style="padding: 0px;">
                        <div class="row pb-3 pt-3">
                          <div class="col-lg-6" style="text-align: right;">
                            <button title="Add question!" id="AddQuestions" type="button" class="btn btn-sm btn-primary"
                              (click)="addQuestions()">
                              <i class="fa fa-chevron-down"></i>
                            </button>
                          </div>
                          <div class="col-lg-6">
                            <button title="Remove question!" id="RemoveQuestions" type="button"
                              class="btn btn-sm btn-primary" (click)="removeQuestions()">
                              <i class="fa fa-chevron-up"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!--selected quetions-->
                    <div>
                      <table class="table table-hover nowrap dataTable table-striped" style="margin: 0px !important;">
                        <!-- || (selectedAssessmentType=='RFactor' && categoryName!='Ratings')"> -->
                        <thead>
                          <tr>
                            <th style="padding: 2px 10px; width:4%;">
                              <!-- <input type="checkbox" id="chkSelectQuestionAll" title="Select All" (change)="selectedQuestion_Changed($event)" name="selectQ"> -->
                            </th>
                            <th style="padding: 2px 10px; width:86%;" class="pl-5">Selected Questions</th>
                          </tr>
                        </thead>
                        <tbody style="border-bottom: 1px solid #e4e9f0;overflow: overlay;">
                          <!-- display: block; -->
                          <tr *ngFor="let selectedQuestion of selectedQuestionsList; let j = index">
                            <td style="padding: 2px 10px; width:4%;">
                              <input type="checkbox" id="{{selectedQuestion._id}}"
                                [checked]="selectedQuestion.isChecked" name="selectQ"
                                (change)="selectedQuestionCheckboxChange(selectedQuestion, $event)">
                            </td>
                            <td style="padding: 2px 10px; width:86%;white-space: normal;">
                              <label class="form-control-label custom-tooltip">
                                <span>{{selectedQuestion.questionText}}</span>
                              </label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
              </div>

              <div class="modal-footer">
                <button id="Save" type="button" class="btn btn-sm btn-primary" [disabled]="selectedQuestionListEmpty"
                  (click)="saveSelectedQuestions()">Save</button>
                <!-- <button id="Update" style="display:none;" type="button" class="btn btn-sm btn-primary">Update</button> -->
                <button type="button" class="btn btn-sm btn-primary" data-dismiss="modal"
                  (click)="cancleSelectedQuestionList()">Cancel</button>
              </div>

            </div>
          </div>
        </div>

        <!--delete criteria-->
        <div class="modal fade" id="criteriaDeletionMessageModal" role="dialog"
          aria-labelledby="criteriaDeletionMessageModal">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="criteriaDeletionMessageModal">
                  <i class="fa fa-question-circle-o mr-2"></i>Confirm
                </h5>
                <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Are you sure you want to delete this criteria?</p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" (click)="deleteCriteria()">Confirm </button>
                <button type="button" class="btn btn-default btn-sm" data-dismiss="modal"
                  (click)="closeForm('criteriaDeletionMessageModal')">Cancel</button>
              </div>
            </div>
          </div>
        </div>

        <!--save review dialog-->
        <div class="modal fade" id="saveReviewDialogBox" role="dialog" aria-labelledby="saveReviewDialogBox">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="saveReviewDialogBox">
                  <i class=" mr-2"></i>Send For Review
                </h5>
                <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="modal-body">
                <ng-select [items]="userListToBind" (selected)="userSelected($event)" placeholder="Select reviewers"
                  [ngModel]="selectedUsers" (deselected)="multipleUserDeselection($event)" [multiple]="true">
                </ng-select>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" [disabled]="!enableSaveButton"
                  (click)="sendForReview()">Send</button>
                <button type="button" class="btn btn-default btn-sm" data-dismiss="modal"
                  (click)="closeForm('saveReviewDialogBox')">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>