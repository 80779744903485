import {
  Component,
  OnInit,
  ViewChild,
  Renderer2,
  AfterViewInit,
  OnChanges,
  DoCheck,
  AfterViewChecked,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  FormBuilder,
  FormsModule,
} from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { BoardEvaluationService } from '../../../services/boardEvaluation.service';
import { RoadmapModuleService } from '../../../services/roadmapModule.service';
import { TypeOfUser } from '../../../enums/TypeOfUser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

import * as _ from 'lodash';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
declare var $: any;

@Component({
  selector: 'app-questionnaire-assignment',
  templateUrl: './questionnaire-assignment.component.html',
  styleUrls: ['./questionnaire-assignment.component.css'],
})
export class QuestionnaireAssignmentComponent implements OnInit {
  questionAssignmentForm: FormGroup;
  period: any;
  evalutionFormId: any;
  evaluationQuestionnaireList: any;
  evaluationFormTitle: any;
  evaluationEntity: any;
  showAssesseeDiv: boolean = false;
  assesseeDetailsArray: FormArray;
  assessee: any;
  reminderInterval: any;
  assessors: any;
  reportsFor: any;
  users: any = [];
  userListToBind: any = [];
  userListForAccessors: any = [];
  selectedUserToSend: any = [];
  // assesseeForm: FormGroup;
  assesseeArray: Array<any> = [];
  assesseeAttribute: any;
  assesseeList: any = [];
  assessorsList: any = [];
  reportsList: any = [];
  reportSelected: boolean = false;
  divArray: any = [];
  userListWIthEntityListToBind: any = [];
  evaluationDataList: any = [];
  editFormAssesseeDetailsList: any = [];
  assigmentStatusFormId: any = null;
  endDate: any;
  isFormInEditMode: boolean = false;
  triggerFrequency: any;
  // selectedMonth: any;
  // selectedYear: any;
  accessorsClone: any = [];
  editDropDownList: any = [];
  years: any = [];
  months: any = [];
  asseseeBlank: boolean = false;
  notpresentassessee: boolean = false;
  notpresentassessor: boolean = false;
  notpresentReport: boolean = false;
  notpresentReportfor: boolean = false;
  assessorarray: any = [];
  count = 0;
  disableSavebutton: boolean = false;
  disableupdatebutton: boolean = false;
  numList: Array<string> = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  todayDate: Date = new Date();
  yearRange: any[];
  currentYear: number;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private boardEvaluationService: BoardEvaluationService,
    private roadmapService: RoadmapModuleService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.todayDate = new Date();
    var self = this;
    $('body').removeClass('dashboard-page');
    this.dtOptions = {
      destroy: true,
      pagingType: 'full_numbers',
      paging: true,
      stateSave: true,
      stateDuration: -1,
      pageLength: 10,
      search: true,
      language: {
        //made changes for datatables for next previous button
        paginate: {
          first: '<<',
          last: '>>',
          next: '>',
          previous: '<',
        },
      },
    };

    this.questionAssignmentForm = new FormGroup({
      period: new FormControl('', [Validators.required]),
      endDate: new FormControl('', [Validators.required]),
      triggerFrequency: new FormControl('', [
        Validators.required,
        Validators.min(0),
      ]),
      reminderInterval: new FormControl('', [
        Validators.required,
        Validators.min(0),
      ]),
    });

    this.route.params.subscribe((params) => {
      if (params && params.id != undefined) {
        this.evalutionFormId = params.id;
      } else {
        this.evalutionFormId = params.evaluationFormId;
        this.assigmentStatusFormId = params.assignStatusFormId;
        this.isFormInEditMode = true;
      }
    });

    this.getDates();
    this.getUserList(function () {
      if (_.size(self.assigmentStatusFormId)) {
        var formObject = {
          evalutionFormId: self.evalutionFormId,
          assigmentStatusFormId: self.assigmentStatusFormId,
        };
        self.getAssignmentStatusFormDetails(formObject);
      } else {
        self.getFormDetailsByFomId(self.evalutionFormId);
      }
    });
    var today = new Date(),
      yyyy = today.getFullYear()
      this.currentYear = yyyy;
      this.calculateYearRange();
  }

  today() {
    var now = new Date();
    return {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate(),
    };
  }

  getDates() {
    var date = new Date();
    var currentYear = date.getFullYear();

    //set values for year dropdown
    for (var i = 0; i <= 1000; i++) {
      this.years.push(currentYear + i);
    }

    //set values for month dropdown
    this.months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
  }

  clearEndDate() {
    this.questionAssignmentForm.controls.endDate.reset();
    this.questionAssignmentForm.controls.endDate.updateValueAndValidity();
  }

  clickable() {
    //console.log("clearance");
  }

  selectedEndDateChange() {
    //console.log("endDate",this.endDate);
    var date = new Date(this.endDate)
    this.endDate = { month: date.getMonth() + 1, day: date.getDate(), year: date.getFullYear() }
  }

  initialiseAssesseeArray(): FormGroup {
    const group = new FormGroup({});
    return group;
  }

  // get assignment Status Form Details by id
  getAssignmentStatusFormDetails(formObject) {
    var self = this;
    this.boardEvaluationService
      .getAssignmentStatusFormDetailsById(formObject)
      .then((response) => {
        //console.log("response", response);
        this.evaluationFormTitle =
          response.formDetails[0].EvaluationFormId.EvaluationFormTitle;
        this.evaluationEntity =
          response.formDetails[0].EvaluationFormId.EvaluationTypeID.EvaluationType;
        this.period = response.formDetails[0].PeriodParameter;
        this.triggerFrequency = response.formDetails[0].ReminderFrequency
          ? response.formDetails[0].ReminderFrequency
          : 1;
        this.reminderInterval = response.formDetails[0].ReminderInterval
          ? response.formDetails[0].ReminderInterval
          : 0;
        var date = new Date(response.formDetails[0].EndDate);
        this.endDate = {
          month: date.getMonth() + 1,
          day: date.getDate(),
          year: date.getFullYear(),
        };

        this.editFormAssesseeDetailsList =
          response.formDetails[0].AssesseeDetails;
        //console.log("this.editFormAssesseeDetailsList=====================", this.editFormAssesseeDetailsList)
        if (_.size(this.editFormAssesseeDetailsList)) {
          _.forEach(
            this.editFormAssesseeDetailsList,
            function (asseeseeDetails) {
              var selectedAssesse = {};
              var selectedAssessors = [];
              var selectedReportsFor = [];
              var selectedReportsForType = [];
              var asseseeDetailsDropDownObject = {};
              var isPublished;
              // var isPublished = _.size(asseeseeDetails.IsPublished) ? asseeseeDetails.IsPublished : true
              if (_.isUndefined(asseeseeDetails.IsPublished)) {
                isPublished = true;
              } else {
                isPublished = asseeseeDetails.IsPublished;
              }
              var findElement = _.findIndex(
                self.userListWIthEntityListToBind,
                function (user) {
                  return _.get(user, 'value') == asseeseeDetails.AssesseeId;
                }
              );
              if (findElement >= 0) {
                selectedAssesse = {
                  label: self.userListWIthEntityListToBind[findElement].label,
                  value: asseeseeDetails.AssesseeId,
                };
                asseseeDetailsDropDownObject['selectedAssesse'] =
                  asseeseeDetails.AssesseeId;
              }

              if (_.size(asseeseeDetails.Assessors)) {
                selectedAssessors = asseeseeDetails.Assessors.map(function (
                  assessor
                ) {
                  return {
                    label:
                      assessor.AssessorId.FirstName +
                      ' ' +
                      assessor.AssessorId.LastName,
                    value: assessor.AssessorId._id,
                  };
                });
                var selectedAssessorsForedit = selectedAssessors.map(function (
                  element
                ) {
                  return element.value;
                });
                asseseeDetailsDropDownObject['selectedAssessors'] =
                  selectedAssessorsForedit;
              }

              if (_.size(asseeseeDetails.ReportsTo)) {
                _.forEach(
                  asseeseeDetails.ReportsTo,
                  function (reportToElement) {
                    selectedReportsFor.push({
                      label:
                        reportToElement.ReportsToId.FirstName +
                        ' ' +
                        reportToElement.ReportsToId.LastName,
                      value: reportToElement.ReportsToId._id,
                    });

                    selectedReportsForType.push(reportToElement.ReportType);
                  }
                );
                var selectedReportsForedit = selectedReportsFor.map(function (
                  element
                ) {
                  return element.value;
                });
                asseseeDetailsDropDownObject['selectedReportsFor'] =
                  selectedReportsForedit;
              }
              //console.log("asseseeDetailsDropDownObject", asseseeDetailsDropDownObject)
              self.editDropDownList.push(asseseeDetailsDropDownObject);
              self.divArray.push({
                assessee: self.userListWIthEntityListToBind,
                assessors: self.userListToBind,
                reportsFor: self.userListToBind,
                id: '',
                selectedAssesse: selectedAssesse,
                selectedAssessors: selectedAssessors,
                selectedReportsFor: selectedReportsFor,
                selectedReportsForType: selectedReportsForType,
                isPublished: isPublished,
              });
            }
          );

          this.showAssesseeDiv = true;
        }

        //console.log("self.divArray", self.divArray)
        //console.log("editDropDownList", self.editDropDownList)
      });
  }

  getFormDetailsByFomId(formId) {
    this.boardEvaluationService
      .getFormDetailsByFormId(formId)
      .then((response) => {
        if (response.status == 0) {
          //console.log("response", response)
          this.evaluationQuestionnaireList = response.EvaluationQuestionnaire;
          this.evaluationFormTitle =
            response.EvaluationQuestionnaire[0].EvaluationFormTitle;
          this.evaluationEntity =
            response.EvaluationQuestionnaire[0].EvaluationTypeID.EvaluationType;
          //console.log("this.evaluationEntity", this.evaluationEntity)
        } else {
        }
      });
  }

  addAssessee() {
    this.count = this.count + 1;
    // console.log("this.count", this.count)
    this.notpresentassessee = false;
    this.notpresentassessor = false;
    this.notpresentReport = false;
    this.notpresentReportfor = false;
    this.showAssesseeDiv = true;
    // this.accessorsClone = _.cloneDeep(this.userListToBind);
    // var formArray: FormArray = <FormArray>this.questionAssignmentForm.controls.assesseData;
    // var formArraySize = _.size(formArray.controls);

    // formArray.push(this.initialiseAssesseeArray());
    // var formGroup: FormGroup = <FormGroup>formArray.controls[formArraySize];
    // formGroup.addControl("id" + formArraySize, new FormControl('', []))
    // formGroup.addControl("assessee" + formArraySize, new FormControl('', [Validators.required]))
    // formGroup.addControl("assessors" + formArraySize, new FormControl('', [Validators.required]))
    // formGroup.addControl("reportsFor" + formArraySize, new FormControl('', [Validators.required]))

    // this.assesseeAttribute = { 'assessee': '', 'assessors': '', 'reportsFor': '', 'id': '' };
    // this.assesseeArray.push(this.assesseeAttribute);
    // //console.log("formArray", formArray, this.questionAssignmentForm)

    // this.divArray.push({ 'assessee': this.userListToBind, 'assessors': this.userListToBind, 'reportsFor': this.userListToBind, 'id': '', 'selectedAssesse': [], 'selectedAssessors': [], 'selectedReportsFor': [], 'selectedReportsForType': [] })

    this.divArray.push({
      assessee: _.cloneDeep(this.userListWIthEntityListToBind),
      assessors: _.cloneDeep(this.userListToBind),
      reportsFor: _.cloneDeep(this.userListToBind),
      id: '',
      selectedAssesse: [],
      selectedAssessors: [],
      selectedReportsFor: [],
      selectedReportsForType: [],
      isPublished: false,
    });
    this.editDropDownList.push({
      selectedAssesse: '',
      selectedAssessors: [],
      selectedReportsFor: [],
    });
    //console.log("reports", this.divArray)

    // //console.log("reports for", formGroup.controls['reportsFor' + formArraySize].value);
    // if (formGroup.controls['reportsFor' + formArraySize].value == '') {
    //   // this.reportsList = [];
    // }
  }

  deleteAssessee(assessee, index) {
    // //console.log("this.assesseeArray",this.assesseeArray,index)
    // this.assesseeArray.splice(index,1)
    this.count = this.count - 1;
    // console.log("this.count", this.count)
    this.divArray.splice(index, 1);
    this.editDropDownList.splice(index, 1);

    //console.log("this.assesseeList", this.assesseeList);
    //console.log("this.assessorsList", this.assessorsList);
    //console.log("this.divArray", this.divArray);
  }

  checkAssessee(item: any) {
    this.notpresentassessee = false;
    // console.log("item", item.value)
    if (item.value) {
      this.notpresentassessee = true;
    } else {
      this.notpresentassessee = false;
    }
  }

  checkAssessor() {
    // console.log(this.divArray);
    var ok: any;
    for (var i = 0; i < this.divArray.length; i++) {
      if (
        this.divArray[i].selectedAssessors.length == 0 ||
        this.divArray[i].selectedAssesse.length == 0 ||
        this.divArray[i].selectedReportsFor.length == 0 ||
        this.divArray[i].selectedReportsForType.length !=
          this.divArray[i].selectedReportsFor.length
      ) {
        this.notpresentassessor = false;
        i = this.divArray.length;
      } else {
        this.notpresentassessor = true;
      }
    }
    // console.log(this.notpresentassessor)
    // this.notpresentassessor = true;
    // this.notpresentassessor = false;

    // if (item.value.length == 0) {
    //   this.notpresentassessor = false;
    // }
    // if (item.value && item.value.length != 0) {
    //   this.notpresentassessor = true;
    // } else {
    //   this.notpresentassessor = false;
    // }
  }

  checkReport(item: any) {
    this.notpresentReport = false;
    // console.log("item for report", item.value)
    // console.log("report item.value.length", item.value.length)
    if (item.value.length == 0) {
      this.notpresentReport = false;
    }
    if (item.value && item.value.length != 0) {
      this.notpresentReport = true;
    } else {
      this.notpresentReport = false;
    }
  }

  assesseeSelected(item: any, i) {
    // var accessorClone = _.cloneDeep(this.userListToBind);
    //console.log("accessorClone",accessorClone)
    this.divArray[i].selectedAssesse = item;
    // var findUser = _.findIndex(accessorClone, function (assesorList) {
    //   return _.get(assesorList, 'value') == item.value
    // })

    // if (findUser >= 0) {
    //   var assesorsArray = [];
    //   //console.log(this.divArray[i].assessors)

    //   accessorClone.splice(findUser, 1);
    //   //console.log("accessorClone",accessorClone);
    //   this.divArray[i].assessors = accessorClone;
    // } else {
      this.divArray[i].assessors = this.userListToBind.filter(ele => ele.value !== item.value);
    // }
  }

  refreshValue(event, i) {
    //console.log("called")
    this.divArray[i].assessors = this.userListToBind;
  }

  multipleassesseeDeselection(item: any, i) {
    //console.log("item", item, i)
    // let indexOfItem = this.assesseeList.findIndex(o => ((o.value === item.value) && (o.index == i)));

    let indexI = this.divArray[i].selectedAssesse.findIndex(
      (o) => o.value === item.value
    );
    this.divArray[i].selectedAssesse.splice(indexI, 1);
    // //console.log("indexOfItem", indexOfItem)
    //console.log("this.divArray[i].", this.divArray[i])
    // this.assesseeList.splice(indexOfItem, 1);
    // //console.log('this.assesseeList after deselection', this.assesseeList)
  }

  assessorsSelected(item: any, i) {
    // item.index = i;
    // this.assessorsList.push(item);
    this.divArray[i].selectedAssessors.push(item);
    // //console.log("assessorsList", this.assessorsList)
  }

  multipleassessorsDeselection(item: any, i) {
    // let indexOfItem = this.assessorsList.findIndex(o => o.value === item.value);
    // //console.log("indexOfItem", indexOfItem)

    let indexI = this.divArray[i].selectedAssessors.findIndex(
      (o) => o.value === item.value
    );
    this.divArray[i].selectedAssessors.splice(indexI, 1);

    // this.assessorsList.splice(indexOfItem, 1);
    // //console.log('this.assessorsList after deselection', this.assessorsList)
  }

  reportsSelected(item: any, i) {
    this.notpresentReportfor = false;
    // item.index = i;

    // var formArray: FormArray = <FormArray>this.questionAssignmentForm.controls.assesseData;

    // var formGroup: FormGroup = <FormGroup>formArray.controls[i];

    // formGroup.addControl((item.value + i ), new FormControl('', [Validators.required]) )
    // //console.log("this.questionAssignmentForm",this.questionAssignmentForm)
    // this.reportsList.push(item);
    // //console.log("this.reportsList", this.reportsList)
    // this.reportSelected = true;

    this.divArray[i].selectedReportsFor.push(item);
    //console.log("this.divarray", this.divArray[i])
  }

  multiplereportsDeselection(item: any, i) {
    // let indexOfItem = this.reportsList.findIndex(o => ((o.value === item.value) && (o.index == i)));
    // //console.log("indexOfItem", indexOfItem);

    let indexOfItem = this.divArray[i].selectedReportsFor.findIndex(
      (o) => o.value === item.value
    );

    this.divArray[i].selectedReportsFor.splice(indexOfItem, 1);

    this.divArray[i].selectedReportsForType.splice(indexOfItem, 1);

    // this.reportsList.splice(indexOfItem, 1);
    // //console.log('this.reportsList after deselection', this.reportsList)
  }

  // multipleUserDeselection(item: any) {
  //   let indexOfItem = this.selectedUserToSend.findIndex(o => o.value === item.value);
  //   //console.log("indexOfItem", indexOfItem)
  //   this.selectedUserToSend.splice(indexOfItem, 1);
  //   //console.log('this.selectedUsers after deselection', this.selectedUserToSend)
  // }

  getUserList(callback) {
    this.roadmapService.getUserList().then((response) => {
      //console.log('checking for the user list', response);
      if (response.status == 0) {
        //only active user will be filtered
        response.result = response.result.filter(function (o) {
          return o.IsActive != false;
        });
        //filtered the list of IC Checker and IC Maker role.
        this.users = _.filter(response.result, function (user) {
          return (
            user.TypeOfUser == TypeOfUser.Director ||
            user.TypeOfUser == TypeOfUser.KMP
          );
        });
        this.userListToBind = this.users.map(function (user) {
          return {
            label: user.FirstName + ' ' + user.LastName,
            value: user._id,
          };
        });

        this.userListForAccessors = this.users.map(function (user) {
          return {
            label: user.FirstName + ' ' + user.LastName,
            value: user._id,
          };
        });
        //console.log("this.users ", this.users)

        this.getEvaluationTypeList(function () {
          callback();
        });
      } else {
        callback();
      }
    });
  }

  getEvaluationTypeList(callback) {
    var self = this;
    this.userListWIthEntityListToBind = this.users.map(function (user) {
      return { label: user.FirstName + ' ' + user.LastName, value: user._id };
    });

    this.boardEvaluationService
      .getEvaluationTypeList()
      .then((updateEvalData) => {
        if (updateEvalData.status == 0) {
          this.evaluationDataList = updateEvalData.evalTypeList; //put the evaluation type list data
          _.forEach(this.evaluationDataList, function (data) {
            //console.log("this.userListToBind", data);
            self.userListWIthEntityListToBind.push({
              label: data.EvaluationType,
              value: data._id,
            });
          });
          callback();
        } else {
          callback();
        }
      });
  }

  Assign() {
    this.disableSavebutton = true;
    //console.log("Final Div Array", this.divArray)

    var assesseeDetails = [];
    // var date = new Date(this.endDate);
    this.endDate = this.questionAssignmentForm.controls.endDate.value
    var endDate = new Date(
      this.endDate.year,
      this.endDate.month - 1,
      this.endDate.day
    );
    // var endDate = { month: date.getMonth() + 1, day: date.getDate(), year: date.getFullYear() }

    _.forEach(this.divArray, function (element) {
      // let assesseeData : any;

      // "AssesseeId": { type: objectId, required: [true, requiredMessage], ref: 'UserMaster' },
      // "Assessors": [
      //     { "AssessorId": { type: objectId, required: [true, requiredMessage], ref: 'UserMaster'} }
      //              ],
      // "ReportsFor" : [
      //     { "ReportsForId": { type : objectId, required: [true, requiredMessage], ref: 'UserMaster'} ,
      //        "ReportType" : { type : String , required: [true, requiredMessage]} }
      //              ],

      let Assessors = [];
      let ReportsFor = [];
      _.forEach(element.selectedAssessors, function (assessors) {
        Assessors.push({ AssessorId: assessors.value });
      });

      if (_.size(element.selectedReportsFor)) {
        var i = 0;
        var reportsForSize = _.size(element.selectedReportsFor);
        for (i; i < reportsForSize; i++) {
          ReportsFor.push({
            ReportsToId: element.selectedReportsFor[i].value,
            ReportType: element.selectedReportsForType[i],
          });
        }
      }

      //console.log(" element.selectedAssesse", element.selectedAssesse)
      assesseeDetails.push({
        AssesseeId: element.selectedAssesse.value,
        Assessors: Assessors,
        ReportsTo: ReportsFor,
        IsPublished: element.isPublished,
      });
    });
    // console.log("this.selectedMonth,this.selectedYear",this.selectedMonth,this.selectedYear)
    var userInfo = JSON.parse(localStorage.getItem('user'));

    var assesseeObject = {
      EvaluationFormId: this.evalutionFormId,
      PeriodParameter: this.period,
      // "Month":this.selectedMonth,
      // "Year":this.selectedYear,
      AssesseeDetails: assesseeDetails,
      ReminderFrequency: this.questionAssignmentForm.controls.triggerFrequency.value?
      this.questionAssignmentForm.controls.triggerFrequency.value : 0,
      ReminderInterval: this.questionAssignmentForm.controls.reminderInterval.value?
      this.questionAssignmentForm.controls.reminderInterval.value : 0,
      CreatedBy: userInfo._id,
      EndDate: endDate,
    };

    this.boardEvaluationService
      .createAssigneeStatus(assesseeObject)
      .then((response) => {
        this.disableSavebutton = false;
        //console.log("response from assigneeStatus", response);

        const modalRef = this.modalService.open(ConfirmationPopupComponent, {
          backdrop: 'static',
        });
        modalRef.componentInstance.Message = response.message;
        modalRef.result.then(
          (resp) => {
            //console.log("response", resp);
            this.router.navigate([
              'structure',
              { outlets: { 'structure-outlet': ['evaluationForms'] } },
            ]);
          },
          (error) => {
            //console.log("error", error);

            this.router.navigate([
              'structure',
              { outlets: { 'structure-outlet': ['evaluationForms'] } },
            ]);
          }
        );
      });
  }

  updateAssign() {
    this.disableupdatebutton = true;
    //console.log("Final Div Array", this.divArray)

    var assesseeDetails = [];

    _.forEach(this.divArray, function (element) {
      let Assessors = [];
      let ReportsFor = [];
      _.forEach(element.selectedAssessors, function (assessors) {
        Assessors.push({ AssessorId: assessors.value });
      });

      if (_.size(element.selectedReportsFor)) {
        var i = 0;
        var reportsForSize = _.size(element.selectedReportsFor);
        for (i; i < reportsForSize; i++) {
          ReportsFor.push({
            ReportsToId: element.selectedReportsFor[i].value,
            ReportType: element.selectedReportsForType[i],
          });
        }
      }
      //console.log(" element.selectedAssesse", element.selectedAssesse)
      assesseeDetails.push({
        AssesseeId: element.selectedAssesse.value,
        Assessors: Assessors,
        ReportsTo: ReportsFor,
        IsPublished: element.isPublished,
      });
    });

    var userInfo = JSON.parse(localStorage.getItem('user'));
    var endDate = new Date(
      this.endDate.year,
      this.endDate.month - 1,
      this.endDate.day
    );

    var assesseeObject = {
      EvaluationFormId: this.evalutionFormId,
      PeriodParameter: this.period,
      AssesseeDetails: assesseeDetails,
      LastEditedBy: userInfo._id,
      ReminderFrequency: this.triggerFrequency,
      assigmentStatusFormId: this.assigmentStatusFormId,
      ReminderInterval: this.questionAssignmentForm.controls.reminderInterval
        .value
        ? this.questionAssignmentForm.controls.reminderInterval.value
        : 0,
      EndDate: endDate,
    };

    this.boardEvaluationService
      .updateAssigneeStatus(assesseeObject)
      .then((response) => {
        this.disableupdatebutton = false;
        //console.log("response from assigneeStatus", response);

        const modalRef = this.modalService.open(ConfirmationPopupComponent, {
          backdrop: 'static',
        });
        modalRef.componentInstance.Message = response.message;
        modalRef.result.then(
          (resp) => {
            //console.log("response", resp);
            this.router.navigate([
              'structure',
              { outlets: { 'structure-outlet': ['evaluationMonitoring'] } },
            ]);
          },
          (error) => {
            //console.log("error", error);
            this.router.navigate([
              'structure',
              { outlets: { 'structure-outlet': ['evaluationMonitoring'] } },
            ]);
          }
        );
      });
  }

  setReportType(index, count, value, name) {
    this.notpresentReportfor = false;
    if (_.size(this.divArray[index].selectedReportsForType[count])) {
      this.divArray[index].selectedReportsForType[count] = value;
    } else {
      this.divArray[index].selectedReportsForType.push(value);
    }

    if (value) {
      this.notpresentReportfor = true;
    } else {
      this.notpresentReportfor = false;
    }
    this.checkAssessor();
  }

  getInputValueChange(event){}

  onlyNumber(ev) {
    var keyCode = window.event ? ev.keyCode : ev.which;
    //codes for 0-9
    if (
      (keyCode > 47 && keyCode < 58) ||
      (keyCode > 95 && keyCode < 106) ||
      (keyCode > 34 && keyCode < 41) ||
      keyCode == 16 ||
      keyCode == 46 ||
      keyCode == 9
    ) {
      if (
        ((keyCode > 47 && keyCode < 58) || (keyCode > 95 && keyCode < 106)) &&
        this.numList.indexOf(ev.key) == -1
      ) {
        ev.preventDefault();
      }
      //restrict up to 10 digits
      if ((ev.target.value + ev.key).length > 1) {
        ev.preventDefault();
      }
      //
    } else {
      if (keyCode != 0 && keyCode != 8 && keyCode != 13 && !ev.ctrlKey) {
        ev.preventDefault();
      }
    }    
}


calculateYearRange(){
  this.yearRange = [];
  var currentYear = this.currentYear - 2;
  console.log(this.currentYear + "++++++++++++++++++++++++++++++=" );
  for(var i = 0; i< 3; i++){
    this.yearRange.push((currentYear + i) + '-' + (currentYear + (1 + i)) )
  }
}
}

export class NgbdDatepickerPopup {
  model: NgbDateStruct;
}