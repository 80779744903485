import { Component, OnInit, Input } from '@angular/core';

import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

import { BoardEvaluationService } from '../../../services/boardEvaluation.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-assesors-list-popup',
  templateUrl: './assesors-list-popup.component.html',
  styleUrls: ['./assesors-list-popup.component.css'],
})
export class AssesorsListPopupComponent implements OnInit {
  @Input('ObjectToSend') ObjectToSend: any;
  assesorList: any = [];

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  constructor(
    public activeModal: NgbActiveModal,
    private boardEvaluationService: BoardEvaluationService
  ) {}

  ngOnInit() {
    $('#assesorTable').DataTable().destroy();

    //console.log("ObjectToSend",this.ObjectToSend);
    this.boardEvaluationService
      .getStatusOfAssesorsList(this.ObjectToSend)
      .then((response) => {
        //console.log("response", response);
        this.assesorList = response.AssesorsList;
        this.dtTrigger.next();
      });
  }

  close() {
    this.activeModal.close();
  }
}
