<section class="card">
  <div class="card-header">
    <span class="cat__core__title">
      <strong>Questionnaire Assignment</strong>
    </span>
  </div>

  <div class="card-block watermark">
    <div class="row">
      <div class="col-lg-12">
        <form
          [formGroup]="questionAssignmentForm"
          class="well"
          autocomplete="off"
        >
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-2"></div>
              <div class="col-md-4"></div>
            </div>
            <div class="row mb-2">
              <div class="col-lg-2">
                <label class="form-control-label">Questionnaire Name : </label>
              </div>
              <div class="form-group col-lg-10">
                {{ evaluationFormTitle }}
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-lg-2">
                <label class="form-control-label" for="l0">End Date</label>
                <span style="color: red">*</span> :
              </div>
              <!-- <div class="col-lg-4">
                <div class="form-group" style="width: 50%">
                  <div
                    class="input-group"
                    (click)="$event.stopPropagation()"
                    (document:click)="a.close()"
                  >
                    <input
                      class="form-control"
                      style="width: 40%"
                      name="endDate"
                      [value]="endDate"
                      (change)="endDate = getInputValueChange($event)"
                      [minDate]="today()"
                      [attr.readonly]="true"
                      [formControl]="questionAssignmentForm.controls['endDate']"
                      style="padding: 6px; z-index: 0; background-color: white"
                      placeholder="dd-mm-yyyy"
                      ngbDatepicker
                      #a="ngbDatepicker"
                      (click)="a.toggle()"
                      (keyup)="selectedEndDateChange()"
                    />
                    <button
                      class="input-group-addon"
                      (click)="clearEndDate()"
                      type="button"
                    >
                      <span
                        class="fa fa-close"
                        style="width: 1.2rem; height: 1rem; cursor: pointer"
                      ></span>
                    </button>
                    <button
                      class="input-group-addon"
                      type="button"
                      (click)="
                        a.toggle(); clickable(); $event.stopPropagation()
                      "
                    >
                      <span
                        class="fa fa-calendar"
                        style="width: 1.2rem; height: 1rem; cursor: pointer"
                      ></span>
                    </button>

                    <div
                      *ngIf="
                        questionAssignmentForm.controls['endDate'].hasError(
                          'required'
                        ) && questionAssignmentForm.controls['endDate'].touched
                      "
                      class="form-control-error-custom"
                    >
                      EndDate is required.
                    </div>
                  </div>
                </div>
              </div> -->
              <form class="form-inline">
                <div class="form-group">
                  <div class="input-group">
                    <input class="form-control" placeholder="yyyy-mm-dd"
                           name="endDate" ngbDatepicker #d="ngbDatepicker"
                           [formControl]="questionAssignmentForm.controls['endDate']" [minDate]="today()" (change)="endDate = getInputValueChange($event)"
                           (keyup)="selectedEndDateChange()"
                    >
                    <div class="input-group">
                      <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                            <span class="fa fa-calendar"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="row mb-2">
              <div class="col-lg-2">
                <label class="form-control-label"
                  >Reminder To Assessor <span style="color: red">*</span> :
                </label>
              </div>
              <div class="form-group col-lg-4">
                <div>
                  <input
                    type="number"
                    style="width: 50%; float: left"
                    class="form-control"
                    id="triggerFrequency"
                    name="triggerFrequency"
                    [value]="triggerFrequency"
                    (change)="triggerFrequency = getInputValueChange($event)"
                    [formControl]="
                      questionAssignmentForm.controls['triggerFrequency']
                    "
                    name="triggerFrequency"
                  />
                  <span style="padding-left: 1%">[ Days Before End Date ]</span>
                </div>
                <div
                  *ngIf="
                    questionAssignmentForm.controls[
                      'triggerFrequency'
                    ].hasError('required') &&
                    questionAssignmentForm.controls['triggerFrequency'].touched
                  "
                  class="form-control-error-custom p-0"
                >
                  Reminder is required.
                </div>

                <div
                  *ngIf="
                    questionAssignmentForm.controls[
                      'triggerFrequency'
                    ].hasError('min')
                  "
                  class="form-control-error-custom"
                >
                  Please enter value greater than 0.
                </div>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-lg-2">
                <label class="form-control-label"
                  >Reminder Frequency <span style="color: red">*</span> :
                </label>
              </div>
              <div class="form-group col-lg-4">
                <input
                  type="number"
                  class="form-control"
                  [formControl]="
                    questionAssignmentForm.controls['reminderInterval']
                  "
                  [value]="reminderInterval"
                  (change)="reminderInterval = getInputValueChange($event)"
                  style="width: 50%"
                  maxlength="1"
                  (keyup)="onlyNumber($event)"
                />
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-lg-2">
                <label class="form-control-label"
                  >Period <span style="color: red">*</span> :
                </label>
              </div>
              <div class="form-group col-lg-4">
                <input type="text" style="width: 50%" class="form-control" id="start" name="start" placeholder="Enter Period" [(ngModel)]="period"
                  [formControl]="questionAssignmentForm.controls['period']" name="period">
                <div *ngIf="questionAssignmentForm.controls['period'].hasError('required') && questionAssignmentForm.controls['period'].touched"
                  class="form-control-error-custom p-0">
                  period is required.
                </div>
                <!-- <select class="form-control" style="width: 50%"  [formControl]="questionAssignmentForm.controls['period']" name="period" 
                [(ngModel)]="period"
                >
                  <option selected [disabled]="true">Select Period</option>
                  <option *ngFor="let year of yearRange" [value]="year">
                    {{year}}
                  </option>
                  <div *ngIf="questionAssignmentForm.controls['period'].hasError('required') && questionAssignmentForm.controls['period'].touched"
                  class="form-control-error-custom p-0">
                  period is required.
                </div>
                </select> -->
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-lg-2">
                <label class="form-control-label">Entity : </label>
              </div>
              <div class="form-group col-lg-10">
                {{ evaluationEntity }}
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-lg-2">
                <label class="form-control-label">Add Assessee : </label>
              </div>
              <div class="form-group col-lg-10">
                <button
                  type="button"
                  class="btn btn-sm btn-primary"
                  (click)="addAssessee()"
                >
                  Add Assessee
                </button>
              </div>
            </div>
            <div *ngIf="showAssesseeDiv">
              <form #signupForm="ngForm">
                <div *ngFor="let a of divArray; let i = index">
                  <div>
                    <div class="row mt-4">
                      <div class="col-lg-2">
                      </div>
                      <div class="col-lg-10">
                        <div class="margin-bottom-50"></div>
                        <div
                          style="cursor: pointer"
                          (click)="deleteAssessee(a, i)"
                          *ngIf="!a.isPublished"
                        >
                          <button
                            type="button"
                            class="close"
                            style="right: 5px; position: absolute"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div style="position:relative">
                          <div class="col-lg-8 ver-scroll" style="position:relative; border: 1px solid rgba(0,0,0,.125); padding-top: 15px;">
                            <div class="row form-group">
                              <div class="col-lg-2">
                                <span>Assessee : </span>
                              </div>
                              <div class="form-group col-lg-10">
                                <ng-select [items]="a.assessee" bindLabel="label" bindValue="value" [ngModel]="editDropDownList[i]?.selectedAssesse" name="name" #name="ngModel" [disabled]="a.isPublished"
                                  [ngModelOptions]="{standalone: true}" (change)="assesseeSelected($event,i)" placeholder="Select Assessee"
                                  (click)="checkAssessor()" required></ng-select>
                                <div *ngIf="name?.touched || name.pristine">
                                  <div
                                    *ngIf="name.errors?.required"
                                    class="form-control-error-custom p-0"
                                  >
                                    Assessee is required.
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row form-group">
                              <div class="col-lg-2">
                                <span>Assessors : </span>
                              </div>
                              <div class="form-group col-lg-10">
                                <!-- <ng-select [items]="divArray[i].assessors" (selected)="assessorsSelected($event,i)" placeholder="Select Assessors" (deselected)="multipleassessorsDeselection($event,i)"
                                formControlName="{{'assessors'+i}}" [multiple]="true"></ng-select> -->
                                <!-- <span *ngFor="let t of a.assessors">{{t.value}},</span> -->
                                <ng-select [items]="divArray[i].assessors" bindLabel="label" bindValue="value" [ngModel]="editDropDownList[i]?.selectedAssessors" name="name1" #nameuser="ngModel" [disabled]="(a.isPublished)"
                                  [ngModelOptions]="{standalone: true}" (add)="assessorsSelected($event,i)" placeholder="Select Assessors"
                                  (remove)="multipleassessorsDeselection($event,i)" [multiple]="true" (click)="checkAssessor()"
                                  required></ng-select>
                                <div *ngIf="nameuser?.touched || nameuser.pristine">
                                  <div *ngIf="nameuser.errors?.required" class="form-control-error-custom p-0">
                                    Assessors is required.
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row form-group">
                              <div class="col-lg-2">
                                <span>Reports For : </span>
                              </div>
                              <div class="form-group col-lg-10">
                                <ng-select [items]="a.reportsFor" bindLabel="label" bindValue="value" [ngModel]="editDropDownList[i]?.selectedReportsFor" name="name2" #namereport="ngModel"
                                  [disabled]="(a.isPublished)" [ngModelOptions]="{standalone: true}" (add)="reportsSelected($event,i)"
                                  placeholder="Select Reports For" (remove)="multiplereportsDeselection($event,i)" [multiple]="true"
                                  (click)="checkAssessor()" required>
                                </ng-select>
                                <div
                                  *ngIf="
                                    namereport?.touched || namereport.pristine
                                  "
                                >
                                  <div
                                    *ngIf="namereport.errors?.required"
                                    class="form-control-error-custom p-0"
                                  >
                                    report is required.
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-2">
                                <span>Report Type : </span>
                              </div>
                              <div class="col-lg-10">
                                <table class="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Report For</th>
                                      <th>Type</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let reportsLists of a.selectedReportsFor;let count = index;">
                                      <td>{{reportsLists.label}}</td>
                                      <td>
                                        <div class="form-group">
                                          <label class="form-check-label">
                                            <input
                                              type="radio"
                                              class="form-check-input"
                                              [disabled]="a.isPublished"
                                              [name]="
                                                'reportType' +
                                                reportsLists.value +
                                                i +
                                                count
                                              "
                                              [ngModel]="
                                                a.selectedReportsForType[count]
                                              "
                                              [ngModelOptions]="{
                                                standalone: true
                                              }"
                                              value="Detail"
                                              (click)="
                                                setReportType(
                                                  i,
                                                  count,
                                                  'Detail',
                                                  reportControl
                                                )
                                              "
                                              required
                                              #reportControl="ngModel"
                                            />Detail
                                          </label>
                                          <label class="form-check-label">
                                            <input
                                              type="radio"
                                              class="form-check-input"
                                              [disabled]="a.isPublished"
                                              [name]="
                                                'reportType' +
                                                reportsLists.value +
                                                i +
                                                count
                                              "
                                              [ngModel]="
                                                a.selectedReportsForType[count]
                                              "
                                              [ngModelOptions]="{
                                                standalone: true
                                              }"
                                              value="Summary"
                                              (click)="
                                                setReportType(
                                                  i,
                                                  count,
                                                  'Summary',
                                                  Summary
                                                )
                                              "
                                            />Summary
                                          </label>
                                          <div
                                            *ngIf="reportControl.errors"
                                            class="form-control-error-custom p-0"
                                          >
                                            please select type for report
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div
                    class="mb-2"
                    style="border-bottom: 1px solid lightgray"
                  ></div>
                </div>
              </form>
            </div>

            <div
              *ngIf="!isFormInEditMode && divArray.length > 0"
              class="float-right"
            >
              <button
                [appHasPermission]="{
                  Url: '/api/v1/secure/boardEvaluationFormAssign/editAllEvaluationFormAssign',
                  method: 'put',
                  menu: 'EvaluationType'
                }"
                class="btn btn-sm btn-primary"
                [disabled]="
                  !notpresentassessor ||
                  !questionAssignmentForm.valid ||
                  disableSavebutton
                "
                (click)="Assign()"
              >
                Save
              </button>
            </div>
            <div
              *ngIf="isFormInEditMode && divArray.length > 0"
              class="float-right"
            >
              <button
                [appHasPermission]="{
                  Url: '/api/v1/secure/boardEvaluationFormAssign/editAllEvaluationFormAssign',
                  method: 'put',
                  menu: 'EvaluationType'
                }"
                class="btn btn-sm btn-primary"
                [disabled]="
                  !questionAssignmentForm.valid || disableupdatebutton
                "
                (click)="updateAssign()"
              >
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row" style="width: 100%; text-align: left">
      <span style="color: #ff0000">*</span> Indicates Mandatory field
    </div>
  </div>
</section>
