<section class="card">
  <div class="card-header">
    <span class="cat__core__title">
      <strong>User Master</strong>
    </span>
  </div>

  <div class="card-block watermark" *ngIf="!isVisibleForChecker" style="padding: 15px;">
    <div class="row">
      <div class="col-lg-12">

        <button class="btn btn-sm btn-primary mb-2 pull-left" type="button" (click)="createUserModal()">Create
          User</button>
        <div class="table-responsive margin-bottom-50" *ngIf="userListStatus">
          <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
            class="table table-hover table-bordered compact cell-border nowrap table-striped dataTable" id="userDetailsTable"
            style="cursor: pointer;">
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Name</th>
                <th>Type Of User</th>
                <th>Designation</th>
                <th>Mobile Number</th>
                <th>Email</th>
                <th>Roles</th>
                <th> Locked</th>
                <th>Active</th>
                <th>Status</th>
                <th>
                  Action</th>
                <th>
                  Edit</th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let user of userList; let i=index" [ngClass]="{'highlight' : user.IsAuthorize == false}">
                <td>{{i+1}}</td>
                <td>{{user.Title}} {{user.FirstName}} {{user.LastName}}</td>
                <td>{{user.userType}}</td>
                <td>{{user.Designation}}</td>
                <td>{{maskMobile(user.MobileNumber)}}</td>
                <td>{{maskEmail(user.EmailId)}}</td>
                <td>{{user.Roles}}</td>
                <td   style="text-align:center!important">
                  <i class="fa fa-lock" *ngIf="(user.Lock != undefined) && (user.Lock)"                          
                  (click)="(user.IsActive) && unlockUser(user)"
                  title="User locked"></i>
                  <i class="fa fa-unlock" *ngIf="(user.Lock != undefined) && !(user.Lock)"
                  [ngClass]="{'disabled_deactivate': user.IsActive}"></i>
                  <i class="fa fa-check" *ngIf="user.Lock == undefined"
                  [ngClass]="{'disabled_deactivate': user.IsActive}" 
                  (click)="(!user.IsActive)"
                  title="Set locking rules"></i>

              </td>
                <td *ngIf="user.IsActive">Active</td>
                <td *ngIf="!user.IsActive">Deactive</td>
                <td *ngIf="user.IsAuthorize">Authorize</td>
                <td *ngIf="!user.IsAuthorize">Unauthorized</td>
                <td
                  [appHasPermission]="{ 'Url': '/api/v1/secure/userMaster/deactivate', 'method':'delete', 'menu': 'UserMaster' }">

                  <!-- <i class="fa fa-check" [ngClass]="{'disabled_deactivate': user.IsActive || user.Roles == 'Admin'}"
                    (click)="(!user.IsActive && user.Roles != 'Admin') && activateUser(user) "
                    title="Activate User"></i>

                  <i class="icmn-bin" [ngClass]="{'disabled_deactivate': !user.IsActive || user.Roles == 'Admin'}"
                    (click)="(user.IsActive && user.Roles != 'Admin') && deactivateUser(user) "
                    title="Deactivate User"></i> -->

                  <i class="fa fa-check" [ngClass]="{'disabled_deactivate': user.IsActive }"
                    (click)="(!user.IsActive ) && activateUser(user) "
                    title="Activate User"></i>

                  <i class="icmn-bin" [ngClass]="{'disabled_deactivate': !user.IsActive }"
                    (click)="(user.IsActive ) && deactivateUser(user) "
                    title="Deactivate User"></i>
                </td>
                <td style="text-align: center; color: #74708d; cursor:pointer;"
                  [appHasPermission]="{ 'Url': '/api/v1/secure/user/editUser', 'method':'put', 'menu': 'UserMaster' }">
                  <div class="fa fa-pencil" [ngClass]="{'disabled_deactivate': !user.IsActive}"
                    (click)="user.IsActive && editUserModal(user)"></div>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="!userListStatus">
          <h3>User List is not available.</h3>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isVisibleForChecker">
    <nav ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false" style="padding: 15px;" style="padding: 15px;">
      <ng-container ngbNavItem>
        <a ngbNavLink>Authorized Users</a>
        <ng-template ngbNavContent *ngIf='isVisibleAuthUser' class="pt-3">
          <div class="card-block watermark">
            <div class="row">
              <div class="col-lg-12">

                <button class="btn btn-sm btn-primary mb-2 pull-left" type="button" (click)="createUserModal()"
                  [appHasPermission]="{ 'Url': '/api/v1/user/register', 'method':'post', 'menu': 'UserMaster' }">Create
                  User</button>
                &nbsp; &nbsp; <button style="margin-left: 40px;" class="btn btn-sm btn-primary mb-2 pull-left" type="button"
                  (click)="userBulkUpload()"
                  [appHasPermission]="{ 'Url': '/api/v1/user/register', 'method':'post', 'menu': 'UserMaster' }">Bulk
                  Upload</button>
                <div class="table-responsive margin-bottom-50" *ngIf="userListStatus">
                  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                    class="table table-hover table-bordered compact cell-border nowrap table-striped dataTable" id="authUserDetailsTable"
                    style="cursor: pointer;"
                    [appHasPermission]="{ 'Url': '/api/v1/secure/userMaster/getUserList', 'method':'get', 'menu': 'UserMaster' }">
                    <thead>
                      <tr>
                        <th>Sr No</th>
                        <th>Name</th>
                        <th>Type Of User</th>
                        <th>Designation</th>
                        <th>Mobile Number</th>
                        <th>Email</th>
                        <th>Roles</th>
                        <th *ngIf="isAdminUser">Locked</th>
                        <th>Active</th>
                        <th>Status</th>
                        <th
                          [appHasPermission]="{ 'Url': '/api/v1/secure/userMaster/deactivate', 'method':'delete', 'menu': 'UserMaster' }">
                          Action</th>
                        <th
                          [appHasPermission]="{ 'Url': '/api/v1/secure/user/editUser', 'method':'put', 'menu': 'UserMaster' }">
                          Edit</th>
                          <th *ngIf="isAdminUser">
                            Password Reset
                          </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr *ngFor="let user of authroizedUserList; let i=index">
                        <td>{{i+1}}</td>
                        <td>{{user.Title}} {{user.FirstName}} {{user.LastName}}</td>
                        <td>{{user.userType}}</td>
                        <td>{{user.Designation}}</td>
                        <td>{{maskMobile(user.MobileNumber)}}</td>
                        <td>{{maskEmail(user.EmailId)}}</td>
                        <td>{{user.Roles}}</td>
                        <td  *ngIf="isAdminUser" style="text-align:center!important">
                            <i class="fa fa-lock" *ngIf="(user.Lock != undefined) && (user.Lock)"                          
                            (click)="(user.IsActive) && unlockUser(user)"
                            title="User locked"></i>
                            <i class="fa fa-unlock" *ngIf="(user.Lock != undefined) && !(user.Lock)"
                            [ngClass]="{'disabled_deactivate': user.IsActive}"></i>
                            <i class="fa fa-check" *ngIf="user.Lock == undefined"
                            [ngClass]="{'disabled_deactivate': user.IsActive}" 
                            (click)="(!user.IsActive)"
                            title="Set locking rules"></i>

                        </td>
                        <td *ngIf="user.IsActive">Active</td>
                        <td *ngIf="!user.IsActive">Deactive</td>
                        <td *ngIf="user.IsAuthorize">Authorize</td>
                        <td *ngIf="!user.IsAuthorize">Unauthorized</td>
                        <td
                          [appHasPermission]="{ 'Url': '/api/v1/secure/userMaster/deactivate', 'method':'delete', 'menu': 'UserMaster' }">
                          <i class="fa fa-check"
                            [ngClass]="{'disabled_deactivate': user.IsActive || user.Roles == 'Admin'}"
                            (click)="(!user.IsActive && user.Roles != 'Admin') && activateUser(user) "
                            title="Activate User"></i>

                          <i class="icmn-bin" [ngClass]="{'disabled_deactivate': !user.IsActive || user.Roles == 'Admin'}"
                            (click)="(user.IsActive && user.Roles != 'Admin') && deactivateUser(user) "
                            title="Deactivate User"></i>

                          <i class="fa fa-times closebtn" style="color: red;" *ngIf="!user.IsActive" 
                            (click)="deleteUser(user)"
                            title="Delete User"></i>

                        </td>
                        <td style="text-align: center; color: #74708d; cursor:pointer;"
                          [appHasPermission]="{ 'Url': '/api/v1/secure/user/editUser', 'method':'put', 'menu': 'UserMaster' }">
                          <div class="fa fa-pencil" [ngClass]="{'disabled_deactivate': !user.IsActive}"
                            (click)="user.IsActive && editUserModal(user)"></div>

                        </td>
                        <td *ngIf="isAdminUser" style="text-align: center!important">
                          <div class="fa fa-refresh" (click)="resetPasswordForUser(user)"></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div *ngIf="!userListStatus">
                  <h3>User List is not available.</h3>
                </div>
              </div>
            </div>
          </div>

        </ng-template>
      </ng-container>
      <ng-container ngbNavItem>
        <a ngbNavLink>Unauthorized Users</a>
        <ng-template ngbNavContent *ngIf='isVisibleAuthUser' class="pt-3">
          <div class="card-block watermark">
            <div class="row">
              <div class="col-lg-12">

                <div class="table-responsive margin-bottom-50" *ngIf="userListStatus">
                  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtUnAuthTrigger"
                    class="table table-hover table-bordered compact cell-border nowrap table-striped dataTable"
                    id="unAuthUserDetailsTable" style="cursor: pointer;"
                    [appHasPermission]="{ 'Url': '/api/v1/secure/userMaster/authorize', 'method':'get', 'menu': 'UserMaster' }">
                    <thead>
                      <tr>
                        <th>Sr No</th>
                        <th>Name</th>
                        <th>Type Of User</th>
                        <th>Designation</th>
                        <th>Mobile Number</th>
                        <th>Email</th>
                        <th>Roles</th>
                        <th>Active</th>
                        <th>Status</th>
                        <th
                          [appHasPermission]="{ 'Url': '/api/v1/secure/userMaster/authorize', 'method':'get', 'menu': 'UserMaster' }">
                          Edit</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr *ngFor="let user of unAuthroizedUserList; let i=index">
                        <td>{{i+1}}</td>
                        <td>{{user.Title}} {{user.FirstName}} {{user.LastName}}</td>
                        <td>{{user.userType}}</td>
                        <td>{{user.Designation}}</td>
                        <td>{{maskMobile(user.MobileNumber)}}</td>
                        <td>{{maskEmail(user.EmailId)}}</td>
                        <td>{{user.Roles}}</td>
                        <td *ngIf="user.IsActive">Active</td>
                        <td *ngIf="!user.IsActive">Deactive</td>
                        <td *ngIf="user.IsAuthorize">Authorize</td>
                        <td *ngIf="!user.IsAuthorize">Unauthorized</td>
                        <td style="text-align: center; color: #74708d; cursor:pointer;"
                          [appHasPermission]="{ 'Url': '/api/v1/secure/userMaster/authorize', 'method':'get', 'menu': 'UserMaster' }">
                          <div class="fa fa-pencil" [ngClass]="{'disabled_deactivate': !user.IsActive}"
                            (click)="user.IsActive && editUserModal(user)"></div>

                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div *ngIf="!userListStatus">
                  <h3>User List is not available.</h3>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </nav>
    
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
  <!-- message pop up 04 Jan-->


  <div class="modal fade" id="messagePopUpforDeactivateUser" tabindex="-1" role="dialog" aria-labelledby=""
    aria-hidden="true" style="top: 50px;">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div id="alertTitles" class="modal-header" style="font-size: 20px; font-weight: bold; padding: 5px 15px;">
          Title!
        </div>
        <div class="modal-body" style="padding: 10px 15px;">
          <div id="alertMessages" style="font-size: 16px;">
            Message..
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="messagePopUp" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true"
    style="top: 50px;">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div id="alertTitle" class="modal-header" style="font-size: 20px; font-weight: bold; padding: 5px 15px;">
          Title!
        </div>
        <div class="modal-body" style="padding: 10px 15px;">
          <div id="alertMessage" style="font-size: 16px;">
            Message..
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="modal" id="BulkUpload" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false"
    aria-labelledby="" aria-hidden="true" style="top: 50px;">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Upload Excel File</h5>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <i class="fa fa-times"></i>
          </button>

        </div>
        <div class="modal-body">
          <div class="row">

            <div class="col-sm-12">
              <div class="input-group" style="margin-left: 5px;" title="Download Format File">
                <a href="{{excelFileUserBulk}}" class="btn btn-sm btn-primary">Download Format File
                  <span class="fa fa-download"></span>
                </a>
              </div>
            </div>


          </div>
          <br>
          <div class="row">


            <div class="col-sm-12">
              <input type="file" id="fileInput" accept=".xlsx" class='form-control' (change)="fileChanged($event)">
              <span style="color: red" *ngIf="fileSizeError">File size is greater than 2MB</span>
              <span style="color: red" *ngIf="fileExtensionError">Please uplaod excel file only.</span>

            </div>
          </div>
        </div>
        <div class="modal-footer">

          <button type="button" (click)="upload()" [disabled]="isUploadNotValid" class="btn btn-sm btn-primary"
            data-dismiss="modal">
            Upload</button>
          <button type="button" class="btn btn-sm btn-primary" data-dismiss="modal">
            <i class="fa fa-times"></i>Cancel</button>
        </div>
      </div>
    </div>
  </div>


  <div class="modal" id="notInsertdata" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false"
    aria-labelledby="" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document" style="max-width: 97% !important;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="myModalLabelForDeletion">
            Below Records are not uploaded</h5>
          <button aria-label="Close" (click)= "refreshThePage()" class="close" data-dismiss="modal" type="button">
            <i class="fa fa-times"></i>
          </button>

        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-12">
                <div class="col-sm-12">
                    <div class="input-group" style="margin-left: 5px;position: relative;top: 63px;left: 187px;" title="Fresh file">
                        Please copy unsaved data to the fresh file. &nbsp;&nbsp;&nbsp;&nbsp; <a href="{{excelFileUserBulk}}" class="btn btn-sm btn-primary">
                        <span class="fa fa-download"></span>&nbsp;Fresh File
                      </a>
                    </div>
                  </div>

              <table datatable [dtOptions]="dtnotInsertdata" [dtTrigger]="dtnotInsertdataTrigger"
                class="table table-hover compact cell-border nowrap stripe" id="CsvUploadtable"
                style="cursor: pointer;">
                <thead>
                  <tr>
                    <th style="min-width: 200px">Error Messages</th>
                    <th>Type <span style="color: red">(*Required)</span></th>
                    <th>Title <span style="color: red">(*Required)</span></th>
                    <th>First Name <span style="color: red">(*Required)</span></th>
                    <th>Last Name <span style="color: red">(*Required)</span></th>
                    <th>Email Id <span style="color: red">(*Required)</span></th>
                    <th>Mobile No. <span style="color: red">(*Required)</span></th>
                    <th>User Role <span style="color: red">(*Required)</span></th>
                    <th>DIN <span style="color: red"></span></th>
                    <th>Notification Type <span style="color: red">(*Required)</span></th>
                    <th>PAN  <span style="color: red">(*Required)</span></th>
                    <th>AD ID  <span style="color: red"></span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let notinserted of notinserteddata; let count=index" style="line-height: 25px;">
                    <td style="min-width: 200px">
                      <li *ngFor="let msg of notinserted.error_msg">
                        <span>{{msg}}</span>

                      </li>
                    </td>
                    <td tabindex="1">{{notinserted.TypeOfUser }}</td>
                    <td tabindex="1">{{notinserted.Title }}</td>
                    <td tabindex="1">{{notinserted.FirstName   }}</td>
                    <td tabindex="1">{{notinserted.LastName }}</td>
                    <td tabindex="1">{{notinserted.EmailId  }}</td>
                    <td tabindex="1">{{notinserted.MobileNumber }}</td>
                    <td tabindex="1">{{(notinserted.Roles)?notinserted.Roles[0].RoleName:"" }}</td>
                    <td tabindex="1">{{notinserted.DIN }}</td>
                    <td tabindex="1">{{notinserted.NotificationType }}</td>
                    <td tabindex="1">{{notinserted.PAN }}</td>
                    <td tabindex="1">{{notinserted.ADID }}</td>
                
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="modal-footer">

          <button type="button" (click)= "refreshThePage()" class="btn btn-sm btn-primary" data-dismiss="modal">
            Ok</button>
        </div>
      </div>
    </div>
  </div>
</section>