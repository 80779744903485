import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BoardEvaluationService } from '../../../services/boardEvaluation.service';
import { BoardEvaluationExecutionService } from '../../../services/boardEvaluationExecution.service';
import { DataTableDirective } from 'angular-datatables';
import { ReviewStatus } from './../../../enums/roadmapReviewStatus';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { RoadmapModuleService } from '../../../services/roadmapModule.service';
import { TypeOfUser } from '../../../enums/TypeOfUser';

import * as _ from 'lodash';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { ReviewDetailsPopupComponent } from '../review-details-popup/review-details-popup.component';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-evaluation-forms',
  templateUrl: './evaluation-forms.component.html',
  styleUrls: ['./evaluation-forms.component.css'],
})
export class EvaluationFormsComponent implements OnInit {
  EvaluationFormListData = [];
  users: any = [];
  userListToBind: any = [];
  selectedUserToSend: any = [];
  enableSaveButton: boolean = false;
  formId: any;
  Reviewers = [];
  selectedUsers: any;
  endDate: any;
  questionCommentsArrayForReviewer: any = [];
  reviewerList: any;
  evaluationFormEndDate: any;
  minEndDate: any;
  EvaluationFormId: any;
  errorMessage: any;
  headerSuccessFlag: boolean = false;
  headerErrorFlag: boolean = false;
  headerSuccessMessage: string = 'Success !';
  headerErrorMessage: string = 'Failure !';

  questionnaireToDelete: any;
  showSpinner: boolean = false;

  constructor(
    private router: Router,
    private boardEvaluationService: BoardEvaluationService,
    private boardEvaluationExecutionService: BoardEvaluationExecutionService,
    private roadmapService: RoadmapModuleService,
    private modalService: NgbModal
  ) {}

  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};
  dtQuestionnaireOptions: DataTables.Settings = {};

  dtQuestionnaireTrigger: Subject<any> = new Subject();
  approvedtTrigger: Subject<any> = new Subject();

  ngOnInit() {
    $('body').removeClass('dashboard-page');

    this.dtOptions = {
      destroy: true,
      pagingType: 'full_numbers',
      paging: true,
      // scrollY: "440px",
      // scrollCollapse: true,
      stateSave: true,
      stateDuration: -1,
      pageLength: 10,
      search: true,
      language: {
        //made changes for datatables for next previous button
        paginate: {
          first: '<<',
          last: '>>',
          next: '>',
          previous: '<',
        },
      },
    };

    this.dtQuestionnaireOptions = {
      destroy: true,
      pagingType: 'full_numbers',
      paging: true,
      // scrollY: "440px",
      // scrollCollapse: true,
      stateSave: true,
      stateDuration: -1,
      pageLength: 10,
      search: true,
      language: {
        //made changes for datatables for next previous button
        paginate: {
          first: '<<',
          last: '>>',
          next: '>',
          previous: '<',
        },
      },
    };

    $('#questionnaireTable').DataTable().destroy();

    this.getEvaluationFormDetailList();
  }

  closeForm(id) {
    $('#' + id).modal('toggle');
  }

  today() {
    var now = new Date();
    return {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate(),
    };
  }

  clearEndDate() {
    this.endDate = null;
  }

  clickable() {
    ////console.log("clearance");
  }

  selectedEndDateChange(endDate) {
    ////console.log("EvaluationFormListData", this.EvaluationFormListData)
    ////console.log("this.endDate", endDate);
    var dateObject = {
      EvaluationFormId: this.formId,
      endDate: '',
    };
    this.boardEvaluationService
      .updateEvaluationFormEndDate(dateObject)
      .then((response) => {
        if (response.status == 0) {
        } else {
        }
      });
  }

  navigateToFormSetup() {
    this.router.navigate([
      'structure',
      { outlets:  { 'structure-outlet': ['evaluateFormSetup'] } },
    ]);
  }

  //evaluation details
  getEvaluationFormDetailList() {
    // $('#entityMasterTable').DataTable().destroy();
    this.boardEvaluationService
      .getEvaluationFormDetailList()
      .then((response) => {
        if (response.status == 0) {
          ////console.log("response", response.message)
          this.EvaluationFormListData = response.EvaluationFormList;
          this.dtQuestionnaireTrigger.next();
        } else {
          this.EvaluationFormListData = response.EvaluationFormList;
          ////console.log("response", response.message);
          this.dtQuestionnaireTrigger.next();
        }
      });
  }

  editFormEndDate(formDetails) {
    console.log('hiii')
    ////console.log("formDetails", formDetails);
    $('#editEvaluationFormEndDate').modal('toggle');
    var date = new Date(formDetails.EndDate);
    this.evaluationFormEndDate = {
      month: date.getMonth() + 1,
      day: date.getDate(),
      year: date.getFullYear(),
    };
    // this.evaluationFormEndDate = moment(formDetails.EndDate)

    //  this.minEndDate = this.evaluationFormEndDate;
    this.EvaluationFormId = formDetails._id;
  }

  evaluationFormEndDateChange(event) {
    this.evaluationFormEndDate = event.target.value;
    ////console.log("this.evaluationFormEndDate", this.evaluationFormEndDate)
  }

  evaluationQuestionnaireReviewStatus(formDetails) {
    var filterStatusForReview = _.filter(
      formDetails.Reviewers,
      function (review) {
        return review.ReviewerStatus == ReviewStatus.Review;
      }
    );

    if (_.size(filterStatusForReview)) {
      return ReviewStatus.Review;
    } else {
      return ReviewStatus.Approve;
    }
  }

  editEvaluationForm(evaluationFormId) {
    ////console.log("evaluationFormId", evaluationFormId)
    this.router.navigate([
      'structure',
      {
        outlets: {
          'structure-outlet': ['evaluateFormSetup', evaluationFormId],
        },
      },
    ]);
  }

  confirmEvaluationDeletionPopup(evaluationFormId) {
    $('#deletionConfimMessageModel').modal('toggle');
    ////console.log("evaluationFormId",evaluationFormId)
    this.questionnaireToDelete = evaluationFormId;
  }

  deleteQuestionnaire() {
    this.boardEvaluationService
      .deleteQuestionnaireById(this.questionnaireToDelete)
      .then((deleteEntity) => {
        if (deleteEntity.status == 0) {
          $('#deletionConfimMessageModel').modal('toggle');
          this.questionnaireToDelete = '';
          const modalRef = this.modalService.open(ConfirmationPopupComponent, {
            backdrop: 'static',
          });

          modalRef.componentInstance.Message = deleteEntity.message;
          modalRef.result.then(
            (resp) => {
              ////console.log("response", resp);
              this.ngOnInit();
            },
            (error) => {
              this.ngOnInit();
              ////console.log("error", error);
            }
          );
        } else {
          $('#deletionConfimMessageModel').modal('toggle');
          this.questionnaireToDelete = '';
          const modalRef = this.modalService.open(ConfirmationPopupComponent, {
            backdrop: 'static',
          });
          modalRef.componentInstance.Message = deleteEntity.message;
          modalRef.result.then(
            (resp) => {
              ////console.log("response", resp);
              this.ngOnInit();
            },
            (error) => {
              this.ngOnInit();
              ////console.log("error", error);
            }
          );
        }
      });
  }

  updateEndDate() {
    var formObject = {
      EvaluationFormId: this.EvaluationFormId,
      endDate: new Date(
        this.evaluationFormEndDate.year,
        this.evaluationFormEndDate.month - 1,
        this.evaluationFormEndDate.day
      ),
      // endDate : moment(this.evaluationFormEndDate)
    };
    ////console.log("formObject", formObject, this.evaluationFormEndDate);

    this.boardEvaluationService
      .updateEvaluationFormEndDate(formObject)
      .then((response) => {
        ////console.log("response", response)

        if (response.status == 0) {
          // this.activeModal.close();
          const modalRef = this.modalService.open(ConfirmationPopupComponent, {
            backdrop: 'static',
          });

          modalRef.componentInstance.Message = response.message;
          modalRef.result.then(
            (resp) => {
              ////console.log("response", resp);
              this.ngOnInit();
            },
            (error) => {
              this.ngOnInit();
              ////console.log("error", error);
            }
          );
        } else {
          // this.activeModal.close();
          const modalRef = this.modalService.open(ConfirmationPopupComponent, {
            backdrop: 'static',
          });

          modalRef.componentInstance.Message = response.message;
          modalRef.result.then(
            (resp) => {
              ////console.log("response", resp);
              this.ngOnInit();
            },
            (error) => {
              ////console.log("error", error);
              this.ngOnInit();
            }
          );
        }
      });
  }

  selectUser(formId, criteriaDetails) {
    this.formId = formId;
    var self = this;

    this.selectedUserToSend = [];
    this.selectedUsers = '';
    ////console.log("criteriaDetails", criteriaDetails)
    // var i=0;
    // for(i;i< criteriaDetails.length;i++){
    //   var questionlist = criteriaDetails[i].EvaluationQuestion[i].EvaluationQuestionId
    //   ////console.log("questionlist",questionlist)
    // }
    // var questionCommentsArrayForReviewer = [];
    this.questionCommentsArrayForReviewer = [];

    _.forEach(criteriaDetails, function (criteria) {
      _.forEach(criteria.EvaluationQuestion, function (question) {
        self.questionCommentsArrayForReviewer.push({
          EvaluationQuestionId: question.EvaluationQuestionId,
          commentText: '',
        });
      });
    });

    ////console.log("questionCommentsArrayForReviewer", self.questionCommentsArrayForReviewer);

    $('#saveReviewDialogBox').modal('toggle');
    this.roadmapService.getUserList().then((response) => {
      ////console.log('checking for the user list', response);
      if (response.status == 0) {
        //only active user will be filtered
        response.result = response.result.filter(function (o) {
          return o.IsActive != false;
        });
        //filtered the list of IC Checker and IC Maker role.
        this.users = _.filter(response.result, function (user) {
          return (
            user.TypeOfUser == TypeOfUser.Director ||
            user.TypeOfUser == TypeOfUser.KMP
          );
        });

        this.userListToBind = this.users.map(function (user) {
          return {
            label: user.FirstName + ' ' + user.LastName,
            value: user._id,
          };
        });

        ////console.log("this.users ", this.users)
      }
    });
  }

  userSelected(item: any) {
    this.selectedUserToSend.push(item);
    ////console.log('this.selectedUsers', this.selectedUserToSend);
    if (_.size(this.selectedUserToSend)) {
      this.enableSaveButton = true;
    } else {
      this.enableSaveButton = false;
    }
  }

  multipleUserDeselection(item: any) {
    let indexOfItem = this.selectedUserToSend.findIndex(
      (o) => o.value === item.value
    );
    this.selectedUserToSend.splice(indexOfItem, 1);
    ////console.log('this.selectedUsers after deselection', this.selectedUserToSend)
    if (_.size(this.selectedUserToSend)) {
      this.enableSaveButton = true;
    } else {
      this.enableSaveButton = false;
    }
  }

  sendForReview() {
    this.showSpinner = true;
    $('#saveReviewDialogBox').modal('toggle');
    var self = this;
    ////console.log("selectedUserToSend", this.selectedUserToSend)
    this.Reviewers = [];
    _.forEach(this.selectedUserToSend, function (element) {
      self.Reviewers.push({
        ReviewerId: element.value,
        Comments: self.questionCommentsArrayForReviewer,
      });
      ////console.log("reviewerId", self.Reviewers)
    });

    this.Reviewers = _.uniqBy(this.Reviewers, 'ReviewerId');

    var reviewObject = {
      _id: this.formId,
      Reviewers: this.Reviewers,
    };
    this.boardEvaluationService
      .sendForReviewEvalutionQuestionnaire(reviewObject)
      .then((updateEvalData) => {
        if (updateEvalData.status == 0) {
          this.showSpinner = false;
          ////console.log("updateEvalData", updateEvalData);

          const modalRef = this.modalService.open(ConfirmationPopupComponent, {
            backdrop: 'static',
          });
          modalRef.componentInstance.Message = updateEvalData.message;
          modalRef.result.then(
            (resp) => {
              ////console.log("response", resp);
              this.ngOnInit();
              // this.getEvaluationFormDetailList();
            },
            (error) => {
              ////console.log("error", error);
              this.ngOnInit();
              // this.getEvaluationFormDetailList();
            }
          );
        } else {
          this.showSpinner = false;
          const modalRef = this.modalService.open(ConfirmationPopupComponent, {
            backdrop: 'static',
          });
          modalRef.componentInstance.Message = updateEvalData.message;
          modalRef.result.then(
            (resp) => {
              // ////console.log("response", resp);
              this.ngOnInit();
              // this.getEvaluationFormDetailList();
            },
            (error) => {
              // ////console.log("error", error);
              this.ngOnInit();
              // this.getEvaluationFormDetailList();
            }
          );
        }
      });
  }

  assignForm(evaluationFormId) {
    // ////console.log("evaluationFormId",evaluationFormId);
    this.router.navigate([
      'structure',
      {
        outlets: {
          'structure-outlet': ['questionnaireAssignment', evaluationFormId],
        },
      },
    ]);
  }

  approveStatus(evaluationFormId) {
    $('#approveDialogBox').modal('toggle');
    this.approvedtTrigger.next();
  }

  // disabledAssignButton(evaluationFormData){

  //   var filteredElement = _.filter(evaluationFormData.Reviewers , function(reviewer){
  //     return ((reviewer.ReviewerStatus == ReviewStatus.Reject) || (reviewer.ReviewerStatus == ReviewStatus.Review))
  //   })

  //   // ////console.log("filteredElement,EvaluationFormTitle",filteredElement,evaluationFormData,evaluationFormData.valuationFormTitle);

  //   if(_.size(evaluationFormData.Reviewers)){

  //     if(_.size(filteredElement)){
  //       return true;
  //     }else{
  //       if(evaluationFormData.isAssigned){
  //         return true;
  //       }else{
  //         return false;
  //       }
  //     }
  //   }else{
  //     return true;
  //   }

  // }

  reviewPopupDetails(evaluationFormData) {
    const modalRef = this.modalService.open(ReviewDetailsPopupComponent, {
      backdrop: 'static',
    });

    modalRef.componentInstance.formDetails = evaluationFormData;
    modalRef.result.then(
      (resp) => {
        ////console.log("response", resp);
      },
      (error) => {
        ////console.log("error", error);
      }
    );
  }
}
