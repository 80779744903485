import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthGuard } from '../authGuard/auth.guard';
import { RouteGuard } from '../routeGaurd/route.guard';
import { BiscommunicationComponent } from './BIS/biscommunication/biscommunication.component';
import { CompanymasterComponent } from './companymaster/companymaster.component';
import { RolemasterComponent } from './rolemaster/rolemaster.component';
import { DashboardsAlpha } from './dashboards/alpha.page';
import { UINotificationComponent } from './notifications/notifications-UI.component';
import { GfmmoduleComponent } from './gfmmodule/gfmmodule.component';
import { GfmDashboardComponent } from './govevaFileManager/gfm-dashboard/gfm-dashboard.component';
import { ViewSmtpComponent } from '../smtp/view-smtp/view-smtp.component';
import { DataImportComponent } from '../data-import/data-import.component';
import { EntityMasterComponent } from './boardEvaluation/entity-master/entity-master.component';
import { EvaluateCategoryComponent } from './boardEvaluation/evaluate-category/evaluate-category.component';
import { QuestionMasterBoardEvalComponent } from './boardEvaluation/questionMasterBoardEval/questionMasterBoardEval.component';
import { EvaluationReviewComponent } from './boardEvaluation/evaluation-review/evaluation-review.component';
import { EvaluationFormsComponent } from './boardEvaluation/evaluation-forms/evaluation-forms.component';
import { SummaryReportComponent } from './boardEvaluation/summary-report/summary-report.component';
import { IndividualReportListComponent } from './boardEvaluation/individual-report-list/individual-report-list.component';
import { EvaluationFormListComponent } from './boardEvaluation/evaluation-form-list/evaluation-form-list.component';
import { EvaluationMonitoringComponent } from './boardEvaluation/evaluation-monitoring/evaluation-monitoring.component';
import { EntityFormSetupComponent } from './boardEvaluation/entity-form-setup/entity-form-setup.component';
import { QuestionnaireAssignmentComponent } from './boardEvaluation/questionnaire-assignment/questionnaire-assignment.component';
import { QuestionnaireReviewComponent } from './boardEvaluation/questionnaire-review/questionnaire-review.component';


export const strucRoutes: Routes =
    [
        { path: 'dashboards/alpha', canActivate: [AuthGuard, RouteGuard], component: DashboardsAlpha, outlet: 'structure-outlet' },
        { path: '', redirectTo: 'dashboards/alpha', pathMatch: 'full' },
        { path: 'BIS/biscommunication', canActivate: [AuthGuard], component: BiscommunicationComponent, outlet: 'structure-outlet' },
        { path: 'companymaster', canActivate: [AuthGuard, RouteGuard], component: CompanymasterComponent, outlet: 'structure-outlet' },
        { path: 'rolemaster', canActivate: [AuthGuard, RouteGuard], component: RolemasterComponent, outlet: 'structure-outlet' },
        // { path: 'questionMasterBoardEval', component: QuestionMasterBoardEvalComponent, outlet: 'structure-outlet' },
        { path: 'UInotifications/:id', canActivate: [AuthGuard], component: UINotificationComponent, outlet: 'structure-outlet' },
        { path: '**', redirectTo: 'dashboards/alpha', pathMatch: 'full' },
        { path: 'GFM', component: GfmmoduleComponent, outlet: 'structure-outlet' },
        { path: 'gfmDashboard', component: GfmDashboardComponent, outlet: 'structure-outlet' },
        { path: 'conmpany-config', component: ViewSmtpComponent, outlet: 'structure-outlet' },
        { path: 'data-import', component: DataImportComponent, outlet: 'structure-outlet' },
        { path: 'entityMaster', component: EntityMasterComponent, outlet: 'structure-outlet' },
        { path: 'evaluateCategory', component: EvaluateCategoryComponent, outlet: 'structure-outlet' },
        { path: 'questionMasterBoardEval', component: QuestionMasterBoardEvalComponent, outlet: 'structure-outlet' },
        
        { path: 'evaluateFormSetup', component: EntityFormSetupComponent, outlet: 'structure-outlet' },
        { path: 'evaluateFormSetup/:evaluationFormId', component: EntityFormSetupComponent, outlet: 'structure-outlet' },
       
        { path: 'evaluationReview', component: EvaluationReviewComponent, outlet: 'structure-outlet' },
        { path: 'evaluationForms', component: EvaluationFormsComponent, outlet: 'structure-outlet' },
        { path: 'evaluationMonitoring', component: EvaluationMonitoringComponent, outlet: 'structure-outlet' },

         
        { path: 'evaluationFormList', component: EvaluationFormListComponent, outlet: 'structure-outlet' },

        { path: 'individualReportList', component: IndividualReportListComponent, outlet: 'structure-outlet' },
        { path: 'summaryReport', component: SummaryReportComponent, outlet: 'structure-outlet' },
        { path: 'questionnaireAssignment/:evaluationFormId', component: QuestionnaireAssignmentComponent, outlet: 'structure-outlet' },
        { path: 'questionnaireReview/:formDetails/:ReviewerId', component: QuestionnaireReviewComponent, outlet: 'structure-outlet' },
        

    ];

export const structureRouting: any = RouterModule.forChild(strucRoutes);


// this.router.navigate([{ outlets: {modal: 'modal/user' + '/' + 'this.id'} }]); passing parameters in routes

