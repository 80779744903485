import {
  Component,
  OnInit,
  ViewChild,
  Renderer2,
  AfterViewChecked,
  Input,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  FormBuilder,
} from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Router, Routes } from '@angular/router';
import { Subject } from 'rxjs';
import { TypeOfUser } from '../../../enums/TypeOfUser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BoardEvaluationExecutionService } from '../../../services/boardEvaluationExecution.service';
import { BoardEvaluationService } from '../../../services/boardEvaluation.service';
import { CompanyMasterService } from '../../../services/companymaster.service';
import { environment } from '../../../../environments/environment';

import { RoadmapModuleService } from '../../../services/roadmapModule.service';

import * as html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import * as _ from 'lodash';
declare var $: any;
import * as moment from 'moment';


import 'rxjs/add/operator/map';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { AssesorsListPopupComponent } from '../assesors-list-popup/assesors-list-popup.component';

declare var jQuery: any;
declare var Chart: any;

@Component({
  selector: 'app-evaluation-monitoring',
  templateUrl: './evaluation-monitoring.component.html',
  styleUrls: ['./evaluation-monitoring.component.css'],
})
export class EvaluationMonitoringComponent implements OnInit {
  @Input('companyLogo') companyLogo: any;
  evaluationMonitoringTypeDatas: any;
  evaluationFormId: any;
  idToAssignee: any;
  assigneeDetails: any;
  assigneeId = [];
  users: any = [];
  userListToBind: any = [];
  enableSaveButton: boolean = false;
  selectedUserToSend: any;
  updateEvaluationAssignee: any;
  selectedAssignee: any;
  selectedUsers: any;
  userInfo: any;
  selectedPeriod: any = '';
  complogo: any;

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtOptionsAssessor: DataTables.Settings = {};
  dtTriggerAssessor: Subject<any> = new Subject();

  dtOptionsReportsDownload: DataTables.Settings = {};
  dtTriggerReportsDownload: Subject<any> = new Subject();

  todayDate: Date = new Date();
  isAnonymous: boolean = false;
  periodList: any = [];
  periodParameterListToBind: any = [];

  selectedListToPublish: any = [];
  showSpinner: boolean = false;

  // variables for report downloading
  averageScoresForReportDownload: any;
  assigneeDetailsForReportDownload: any;
  calculatedCriteriaScoreArrayForReportDownload: any;
  assesseeReportForForReportDownload: any;
  scoreRangeForReportDownload: any;
  scoreParticularsForReportDownload: any;
  formTitleForReportDownload: any;
  averageScoreForReportDownload: any;
  evaluationTypeForReportDownload: any;
  averageCriteriaQuestionAnswerForReportDownload: any = [];
  averageWeightedScoreForReportDownload: any;
  evaluationNameForReportDownload: any;
  totalAverageWeightedScoreForReportDownload: any;
  reportPeriodParameterForReportDownload: any;
  reportFormat: any;
  weigthedBarGraphOptions: Object;
  periodParameterForDownload: any;
  companyName: string = "";
  subsidiaryCompanyName: string = "";
  loggedinUserId: string = "";
  loggedinUserProfilePic: string = "";
  base64Code: any = "";
  isRoleReportView:any;
  isRolereportDownload:any;
  isRolereportSendToAssessee:any;
  loadURL: string;

  constructor(private boardEvaluationService: BoardEvaluationService, private roadmapService: RoadmapModuleService, private modalService: NgbModal, private router: Router, private companyMasterService: CompanyMasterService) { }

  ngOnInit() {
    $('body').removeClass('dashboard-page');

    this.dtOptions = {
      destroy: true,
      pagingType: 'full_numbers',
      paging: true,
      stateSave: true,
      stateDuration: -1,
      pageLength: 10,
      search: true,
      language: {
        //made changes for datatables for next previous button
        paginate: {
          first: '<<',
          last: '>>',
          next: '>',
          previous: '<',
        },
      },
    };

    this.userInfo = JSON.parse(localStorage.getItem('user'));

    this.dtOptionsAssessor = {
      destroy: true,
      pagingType: 'full_numbers',
      paging: true,
      stateSave: true,
      stateDuration: -1,
      pageLength: 10,
      search: true,
      language: {
        //made changes for datatables for next previous button
        paginate: {
          first: '<<',
          last: '>>',
          next: '>',
          previous: '<',
        },
      },
    };

    this.boardEvaluationService.getPeriodParameterList().then((response) => {
      //console.log("response",response);
      this.periodList = response.PeriodList;

      // _.forEach(this.periodList,function(period){
      //   self.periodParameterListToBind.push( { "label" : period.PeriodParameter ,"value" : period.PeriodParameter })
      // })

      this.periodParameterListToBind = this.periodList.map(function (period) {
        return { label: period.PeriodParameter, value: period.PeriodParameter };
      });
      // $('#evaluationMonitoring').DataTable().destroy();

      //console.log("this.periodParameterListToBind",this.periodParameterListToBind);
    });
    this.getDetails();

    
    const resourcesValue = JSON.parse(localStorage.getItem('resources'));
    let allChildResourcesArray = [];
    _.forEach(resourcesValue, function (data) {
      allChildResourcesArray = _.concat(allChildResourcesArray, data.ChildResources)
    })

    let reportView=["/api/v1/secure/boardEvaluationFormMonitoring/getAllEvaluationFormMonitoring","/api/v1/secure/boardEvaluationFormMonitoringReports/getAllEvaluationFormMonitoring"];
    let reportDownload=["/api/v1/secure/boardEvaluationFormMonitoring/downloadAllEvaluationFormMonitoring","/api/v1/secure/boardEvaluationFormMonitoringReports/downloadAllEvaluationFormMonitoring"];
    let reportSendToAssessee=["/api/v1/secure/boardEvaluationFormMonitoring/editEvaluationFormMonitoring","/api/v1/secure/boardEvaluationFormMonitoringReports/reportsEvaluationFormMonitoring"];
    console.log("allChildResourcesArray",allChildResourcesArray);


    const userRoles = JSON.parse(localStorage.getItem('user'))['Roles'];
    const userRoleArray = [];
    userRoles.forEach((item)=>{
      userRoleArray.push(item['RoleName']);
    });

    if (userRoleArray.includes('BoardEval Admin')) {

    this.isRoleReportView = false;
    this.isRolereportDownload = false;

    }

    else {
    this.isRoleReportView = allChildResourcesArray.filter((o) => reportView.includes(o.Url)).length > 0;
    this.isRolereportDownload = allChildResourcesArray.filter((o) => reportDownload.includes(o.Url)).length > 0;

    }

    this.isRolereportSendToAssessee = allChildResourcesArray.filter((o) => reportSendToAssessee.includes(o.Url)).length > 0
 
  }

  ngAfterViewInit() {
    this.companyName = JSON.parse(localStorage.getItem('user')).CompanyName;

    this.companyMasterService.getCompanyList().then((company) => {
      // console.log("company", company)
      if (company.status == 0) {
        if (
          company.companyData.CompanyLogo != null &&
          company.companyData.CompanyLogo != ''
        ) {
          this.base64Code =
            environment.serverIP + company.companyData.CompanyLogo;
          this.complogo = company.companyData.CompanyLogo;
          // console.log("this.complogo", this.complogo)
          // console.log("this.base64Code+++++++++", this.base64Code)
          $('#clientCompanyLogo').attr('src', this.base64Code);
        } else {
          this.base64Code = '../../assets/images/company-icon.svg';
          // this.base64Code = this.companyLogo;
          // console.log("this.base64Code_____________", this.base64Code);
          // $("#clientCompanyLogo").attr("src", this.base64Code);
        }
      }
    });
  }

  periodParameterSelection(item: any) {
    //console.log("item", item);
    this.selectedPeriod = item.value;
    //console.log("this.selectedPeriod", this.selectedPeriod);
    this.selectedListToPublish = [];
    this.showSpinner = true;

    this.getDetailsByPeriodParameter(item.value);
  }

  closeForm(id) {
    $('#' + id).modal('toggle');
  }

  getDetails() {
    $('#evaluationMonitoring').DataTable().destroy();
    this.boardEvaluationService
      .getListOfAssigneeForMonitoring()
      .then((response) => {
        if (response.status == 0) {
          this.evaluationMonitoringTypeDatas = response.AssigneeFormDetails;
          //console.log("this.evaluationMonitoringTypeDatas", response.AssigneeFormDetails);
          setTimeout(() => {
            this.dtTrigger.next();
          });
          // this.ngAfterViewChecked();
        } else {
        }
      });
  }

  getDetailsByPeriodParameter(period) {
    $('#evaluationMonitoring').DataTable().destroy();
    this.boardEvaluationService
      .getListOfAssigneeStatusFormByPeriodParameter(period)
      .then((response) => {
        if (response.status == 0) {
          this.showSpinner = false;
          this.evaluationMonitoringTypeDatas = response.AssigneeFormDetails;
          //console.log("this.evaluationMonitoringTypeDatas", response.AssigneeFormDetails);
          setTimeout(() => {
            this.dtTrigger.next();
          });
          // this.ngAfterViewChecked();
        } else {
          this.showSpinner = false;
        }
      });
  }

  // ngAfterViewChecked (){
  //   // $('#evaluationMonitoring').DataTable().destroy();
  //   this.dtTrigger.next();
  // }

  updateSelectedAssignee(selectedAssignees) {
    var self = this;

    // this.updateEvaluationAssignee = updateEvaluationAssignees;
    this.selectedAssignee = selectedAssignees;
    //console.log("this.selectedAssignee", this.selectedAssignee)
    $('#saveReviewDialogBox').modal('toggle');
    this.roadmapService.getUserList().then((response) => {
      //console.log('checking for the user list', response);
      if (response.status == 0) {
        //only active user will be filtered
        response.result = response.result.filter(function (o) {
          return o.IsActive != false;
        });
        //filtered the list of IC Checker and IC Maker role.
        this.users = _.filter(response.result, function (user) {
          return (
            user.TypeOfUser == TypeOfUser.Director ||
            user.TypeOfUser == TypeOfUser.KMP
          );
        });

        this.userListToBind = this.users.map(function (user) {
          return {
            label: user.FirstName + ' ' + user.LastName,
            value: user._id,
          };
        });

        var index = _.findIndex(self.userListToBind, function (userList) {
          return (
            _.get(self.selectedAssignee, 'AssigneeId') ==
            _.get(userList, 'value')
          );
        });

        if (index >= 0) {
          this.userListToBind.splice(index, 1);
          //console.log("index", index)
        }
        //console.log("this.users ", this.users)
      }
    });
  }

  check(data, userArray) {
    if (_.size(userArray)) {
      var index = _.findIndex(userArray, function (user) {
        // return _.get(data , 'AssigneeId') == _.get(user , '_id')
        return _.get(data, 'AssessorId') == _.get(user, '_id');
      });

      if (index >= 0) {
        return (
          userArray[index].Title +
          ' ' +
          userArray[index].FirstName +
          ' ' +
          userArray[index].LastName
        );
      } else {
        return 'XXX';
      }
    } else {
      return 'XXX';
    }
  }

  userSelected(item: any) {
    //console.log("item", item)
    this.selectedUserToSend = item;
    //console.log('this.selectedUsers', this.selectedUserToSend);
    if (_.size(this.selectedUserToSend) > 0) {
      this.enableSaveButton = true;
    } else {
      this.enableSaveButton = false;
    }
  }

  // multipleUserDeselection(item: any) {
  //   let indexOfItem = this.selectedUserToSend.findIndex(o => o.value === item.value);
  //   this.selectedUserToSend.splice(indexOfItem, 1);
  //   //console.log('this.selectedUsers after deselection', this.selectedUserToSend)
  //   if (_.size(this.selectedUserToSend)) {
  //     this.enableSaveButton = true
  //   } else {
  //     this.enableSaveButton = false
  //   }
  // }

  updateAssignee() {
    $('#saveReviewDialogBox').modal('toggle');
    var self = this;

    //console.log("updateEvaluationAssignee", this.updateEvaluationAssignee);
    // this.evaluationFormId = this.updateEvaluationAssignee.evaluationFormDetails.EvaluationFormId;
    this.idToAssignee = this.selectedUserToSend.value;
    // this.assigneeDetails = this.updateEvaluationAssignee.evaluationFormDetails.assigneeDetails;
    // this.assigneeDetails.forEach(function (element) {
    //   self.assigneeId.push({
    //     AssigneeId: element.AssigneeId,
    //   })
    // });
    //console.log("this.assigneeId", this.assigneeId)
    //console.log("this.selectedUserToSend", this.selectedUserToSend);
    //console.log("this.selectedAssignee", this.selectedAssignee)
    // $('#evaluationMonitoring').DataTable().destroy();
    var assigneeObject = {
      asignedFormId: this.selectedAssignee.AssignedFormId,
      idToAssign: this.idToAssignee,
      asigneeId: this.selectedAssignee.AssigneeId,
    };
    //console.log("assigneeObject", assigneeObject)
    this.boardEvaluationService
      .updateEvaluationMonitoringAssignee(assigneeObject)
      .then((response) => {
        if (response.status == 0) {
          // this.dtTrigger.next();
          //$('#saveReviewDialogBox').modal('toggle');
          //console.log("form saved succesfully", response);
          $('#alertTitle').text('Success!');
          $('#alertTitle').css('color', 'black');
          $('#alertMessage').text(response.message);
          $('#alertMessage').css('color', 'black');
          $('#messagePopUp').modal('toggle');
          $('#evaluationMonitoring').DataTable().destroy();
          this.messagePopUpTimeoutToogle();
          //console.log("response", response)
          this.selectedUserToSend = null;
          this.getDetails();
        } else {
          //$('#saveReviewDialogBox').modal('toggle');
          $('#alertTitle').css('color', '#f00');
          $('#alertTitle').text('Failure!');
          $('#alertMessage').css('color', '#f00');
          $('#alertMessage').text(response.message);
          $('#messagePopUp').modal('toggle');
          this.messagePopUpTimeoutToogle();
          this.getDetails();
        }
      });
  }

  messagePopUpTimeoutToogle() {
    setTimeout(function () {
      var popUpClass = $('#messagePopUp').attr('class');
      if (popUpClass == 'modal fade show') {
        $('#messagePopUp').modal('toggle');
      }
    }, 2000);
  }

  publish() {
    this.showSpinner = true;
    //console.log("publish API",this.evaluationMonitoringTypeDatas)

    this.userInfo = JSON.parse(localStorage.getItem('user'));

    var AssigneeArray = [];

    _.forEach(this.selectedListToPublish, function (selectedElement) {
      AssigneeArray.push(selectedElement.elmentObject);
    });

    var publishObject = {
      // "AssigneeArray" : this.evaluationMonitoringTypeDatas,
      AssigneeArray: AssigneeArray,
      CreatedBy: this.userInfo._id,
    };

    this.boardEvaluationService
      .publishMonitoringScreen(publishObject)
      .then((response) => {
        this.showSpinner = false;
        //console.log("response", response);

        const modalRef = this.modalService.open(ConfirmationPopupComponent, {
          backdrop: 'static',
        });
        modalRef.componentInstance.Message = response.message;
        modalRef.result.then(
          (resp) => {
            //console.log("response", resp);
            this.selectedListToPublish = [];
            this.getDetails();
            // this.ngOnInit();
          },
          (error) => {
            //console.log("error", error);
            this.selectedListToPublish = [];
            // this.ngOnInit();
            this.getDetails();
          }
        );
      });
  }

  assesseeDetails() {
    $('#assesseeDialogBox').modal('toggle');
  }

  checkboxAssesseeChangeEvent(event, count, elementDetails) {
    //console.log("event",event);
    //console.log("count", count);
    //console.log("id", event.target.id)
    // id for knowing which the list is getting selected , elmentObject is the row element object
    var selectedObjectWithPosition = {
      id: event.target.id,
      elmentObject: elementDetails,
    };

    if (_.size(this.selectedListToPublish)) {
      var objectIndex = _.findIndex(
        this.selectedListToPublish,
        function (element) {
          return _.get(element, 'id') == event.target.id;
        }
      );
      //console.log("objectIndex",objectIndex);

      if (objectIndex >= 0) {
        this.selectedListToPublish.splice(objectIndex, 1);
        //console.log("this.selectedListToPublish", this.selectedListToPublish);
      } else {
        this.selectedListToPublish.push(selectedObjectWithPosition);
        //console.log("this.selectedListToPublish", this.selectedListToPublish);
      }
    } else {
      this.selectedListToPublish.push(selectedObjectWithPosition);
      //console.log("this.selectedListToPublish", this.selectedListToPublish);
    }
  }

  convertImgToDataURLviaCanvas(url, callback, outputFormat) {
    if (this.base64Code == '../../assets/images/company-icon.svg') {
      // console.log("+++++++++++++++")
      callback(undefined);
    } else {
      var img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      // console.log("dataURL" , url)
      // console.log("dataURL, onload")
      img.onload = function () {
        var canvas = document.createElement('canvas');
        // console.log("canvas created")
        var ctx = canvas.getContext('2d');
        var dataURL;
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        dataURL = canvas.toDataURL(outputFormat);
        // console.log("dataURL",dataURL)
        callback(dataURL);
        canvas = null;
      };
    }
  }

  downloadReport(formDetails) {
    this.showSpinner = true;

    var evalueeId = formDetails.AssigneeDetails._id;

    var evaluationFormId = formDetails.EvaluationFormId;
    var evaluationTypeId = formDetails.EntityTypeId;
    var periodParameter = formDetails.PeriodParameter;

    var evalueeId = formDetails.AssigneeDetails._id;
    var self = this;
    var evaluationFormId = formDetails.EvaluationFormId;
    var evaluationTypeId = formDetails.EntityTypeId;
    var periodParameter = formDetails.PeriodParameter;

    var object = {
      "AssesseeId": evalueeId,
      "EvaluationFormId": evaluationFormId,
      "EvaluationTypeID": evaluationTypeId,
      "PeriodParameter": periodParameter
    }
    var isDownload = true;

    this.router.navigate(['structure', { outlets: { 'structure-outlet': ['individualReport', evalueeId, evaluationFormId, evaluationTypeId, periodParameter, isDownload] } }]);


    // this.boardEvaluationService.getIndividualReport(object).then(response => {
    //   console.log("this.base64Code",this.base64Code);
    //   this.convertImgToDataURLviaCanvas(this.base64Code, function (base64ImageData) {
    //     self.generateIndividualReport(self, response, base64ImageData);
    //   }, undefined);

    // })

    this.boardEvaluationService.getIndividualReport(object).then((response) => {
      console.log('this.base64Code', this.base64Code);
      this.convertImgToDataURLviaCanvas(
        this.base64Code,
        function (base64ImageData) {
          // console.log("Self", self);
          // console.log("response", base64ImageData);
          self.generateIndividualReport(self, response, base64ImageData);
        },
        undefined
      );
    });
  }

  generateIndividualReport(selfObject, response, companyLogoBase64) {
    console.log('down load response', selfObject, response, companyLogoBase64);

    var pdfInMM = 210; // width of A4 in mm
    var pageCenter = pdfInMM / 2;
    var doc = new jsPDF('landscape', 'mm', [400, 400], true);

    if (_.size(response.evaluationFormDetails.reportsTo)) {
      var reportToDetails = _.filter(
        response.evaluationFormDetails.reportsTo,
        function (element) {
          return element.ReportsToId == selfObject.userInfo._id;
        }
      );
      // //console.log("reportToDetails", reportToDetails);
      if (_.size(reportToDetails)) {
        selfObject.reportFormat = reportToDetails[0].ReportType;
        // //console.log(" this.reportFormat", self.reportFormat)
      }
    }

    this.evaluationTypeForReportDownload =
      response.evaluationFormDetails.EvaluationTypeID.EvaluationType;
    this.averageScoresForReportDownload =
      response.evaluationFormDetails.averageWeightedScore;
    this.assigneeDetailsForReportDownload =
      response.evaluationFormDetails.answerArray;
    this.calculatedCriteriaScoreArrayForReportDownload =
      response.evaluationFormDetails.answerArray[0].calculatedCriteriaScore;
    this.assesseeReportForForReportDownload =
      response.evaluationFormDetails.assesseeReportFor;
    this.scoreRangeForReportDownload =
      response.evaluationFormDetails.evaluationFormDetails.ScoreRange;
    this.scoreParticularsForReportDownload =
      response.evaluationFormDetails.evaluationFormDetails.ScoreParticulars;
    this.formTitleForReportDownload =
      response.evaluationFormDetails.evaluationFormDetails.EvaluationFormTitle;
    this.averageScoreForReportDownload =
      response.evaluationFormDetails.evaluationFormDetails.averageScore;
    this.periodParameterForDownload =
      response.evaluationFormDetails.periodParameter;

    // assigneeDetails

    let firstScoreParticular = _.first(this.scoreParticularsForReportDownload);
    // console.log("firstScoreParticular", firstScoreParticular);
    let lastScoreParticular = _.last(this.scoreParticularsForReportDownload);
    // console.log("lastScoreParticular", lastScoreParticular);

    this.averageCriteriaQuestionAnswerForReportDownload =
      response.evaluationFormDetails.averageQuestionAnswerArrayAcrossDirectors;
    // //console.log(" this.averageCriteriaQuestionAnswer", this.averageCriteriaQuestionAnswerForReportDownload)

    this.averageWeightedScoreForReportDownload =
      response.evaluationFormDetails.averageWeightedScore;

    this.drawWeigthedBarGraph(this.averageWeightedScoreForReportDownload);

    this.evaluationNameForReportDownload =
      response.evaluationFormDetails.evaluationFormDetails.EvaluationFormTitle;
    this.totalAverageWeightedScoreForReportDownload =
      response.evaluationFormDetails.totalAverageWeightedScore;
    this.reportPeriodParameterForReportDownload =
      response.evaluationFormDetails.periodParameter;
    for (
      var i = 0;
      i < this.calculatedCriteriaScoreArrayForReportDownload.length;
      i++
    ) {
      this.calculatedCriteriaScoreArrayForReportDownload[i].averageScore =
        response.evaluationFormDetails.averageScore[i];
      this.calculatedCriteriaScoreArrayForReportDownload[
        i
      ].averageWeightedScore =
        response.evaluationFormDetails.averageWeightedScore[i];
    }

    var docWidth = doc.internal.pageSize.getWidth();
    var docHeight = doc.internal.pageSize.getHeight();

    // var textWidth = doc.getStringUnitWidth("QUESTIONNAIRE TITLE" + " " + ":" + " " + this.formTitleForReportDownload) * doc.internal.getFontSize() / doc.internal.scaleFactor;

    var headSplitTitle = doc.splitTextToSize(
      'EVALUATION FOR :' + ' ' + this.evaluationNameForReportDownload,
      doc.internal.pageSize.width - 15 - 15
    );
    var splitPeriodParameter = doc.splitTextToSize(
      'PERIOD : ' + ' ' + this.periodParameterForDownload,
      doc.internal.pageSize.width - 15
    );
    var splitQuestionnaireTitle = doc.splitTextToSize(
      'QUESTIONNAIRE TITLE' + ' ' + ':' + ' ' + this.formTitleForReportDownload,
      doc.internal.pageSize.width / 2 - 15
    );
    var splitEntityTitle = doc.splitTextToSize(
      'ENTITY' + ' ' + ':' + ' ' + this.evaluationTypeForReportDownload,
      doc.internal.pageSize.width / 2 - 15
    );
    var spiltAssesseeTitle = doc.splitTextToSize(
      'ASSESSEE' + ' ' + ':' + ' ' + this.assesseeReportForForReportDownload,
      doc.internal.pageSize.width / 2 - 15
    );
    var spiltScoringTitle = doc.splitTextToSize(
      'SCORING RANGE' +
        ' ' +
        ':' +
        ' ' +
        _.get(firstScoreParticular, 'Key') +
        ' - ' +
        _.get(lastScoreParticular, 'Key'),
      doc.internal.pageSize.width / 2 - 15
    );

    var splitAverageSummaryAcrossCriteria = doc.splitTextToSize(
      'AVERAGE SUMMARY SCORE PER EVALUATION CRITERIA',
      doc.internal.pageSize.width - 15
    );

    doc.setFontSize(14);
    doc.setFont(undefined, 'bold');
    doc.text(headSplitTitle, 15, 20);

    doc.setFont(undefined, 'normal');
    doc.setFontSize(12);
    var periodOptions = {
      align: 'right',
    };

    const docHeadHeight = headSplitTitle.length * doc.getLineHeight() + 20;

    //  var canvas = document.createElement("canvas");
    //  var ctx = canvas.getContext("2d");

    //  var imagedata = canvas.toDataURL(this.base64Code);

    var img1 = new Image();
    // console.log("companyLogoBase64", companyLogoBase64)
    if (companyLogoBase64 == undefined) {
    } else {
      doc.addImage(companyLogoBase64, 'JPEG', 230, 4, 50, 22, 'right');
    }

    doc.text(
      splitPeriodParameter,
      doc.internal.pageSize.width - 15,
      docHeadHeight,
      {
        align: 'right',
      }
    );

    const headHeight =
      splitPeriodParameter.length * doc.getLineHeight() + docHeadHeight;

    docHeight = doc.internal.pageSize.getHeight();
    if (this.addPageJsPDF(docHeight, headHeight)) {
      doc.addPage([400, 400], 'landscape');
    }

    doc.text(splitQuestionnaireTitle, 15, headHeight);

    var heightQuestionnaireTitle =
      splitQuestionnaireTitle.length * doc.getLineHeight() + headHeight;
    docHeight = doc.internal.pageSize.getHeight();
    if (this.addPageJsPDF(docHeight, heightQuestionnaireTitle)) {
      doc.addPage([400, 400], 'landscape');
    }

    var scoreTableRows = [];
    _.forEach(this.scoreParticularsForReportDownload, function (scoreElement) {
      scoreTableRows.push([scoreElement.Key, scoreElement.Value]);
    });

    // table of score particulars
    // @ts-ignore
    doc.autoTable({
      margin: { top: 1, left: doc.internal.pageSize.width / 2 - 15 + 2 },
      Theme: 'grid',
      head: this.headScoreParticularRows(),
      body: this.bodyScoreParticularRows(
        _.size(this.scoreParticularsForReportDownload),
        this.scoreParticularsForReportDownload
      ),
      headStyles: {
        // cellPadding: 2,
        lineWidth: 0.5,
        textColor: [0, 0, 0, 0], //Black
      },
      bodyStyles: {
        // fontSize: 12,
        lineWidth: 0.5,
        lineColor: [228, 228, 228],
        borderColor: [6, 107, 175, 0.75],
        // lineWidth : 2
      },
      columnStyles: {
        text: {
          cellWidth: 'wrap',
        },
        fillColor: [228, 228, 228],
      },
      startY: headHeight,
      pageBreak: 'avoid',
      rowPageBreak: 'avoid',
      tableWidth: '100%',
      // tableOptions
    });

    // @ts-ignore
    let finalY = doc.previousAutoTable.finalY;

    doc.text(splitEntityTitle, 15, heightQuestionnaireTitle);
    var heightAssesseeTitle =
      splitEntityTitle.length * doc.getLineHeight() + heightQuestionnaireTitle;
    docHeight = doc.internal.pageSize.getHeight();

    if (this.addPageJsPDF(docHeight, heightAssesseeTitle)) {
      doc.addPage([400, 400], 'landscape');
      heightAssesseeTitle = 20;
    }

    doc.text(spiltAssesseeTitle, 15, heightAssesseeTitle);
    var heightScoringTitle =
      spiltAssesseeTitle.length * doc.getLineHeight() + heightAssesseeTitle;

    docHeight = doc.internal.pageSize.getHeight();
    if (this.addPageJsPDF(docHeight, heightScoringTitle)) {
      doc.addPage([400, 400], 'landscape');
      heightScoringTitle = 20;
    }

    doc.text(spiltScoringTitle, 15, heightScoringTitle);

    var criteriaTableStartingYParameter;
    if (finalY > heightScoringTitle) {
      criteriaTableStartingYParameter = finalY + 10;
    } else {
      criteriaTableStartingYParameter = heightScoringTitle + 10;
    }

    doc.text(
      splitAverageSummaryAcrossCriteria,
      pageCenter,
      criteriaTableStartingYParameter
    );
    var heightAverageSummaryAcrossCriteria =
      splitAverageSummaryAcrossCriteria.length * doc.getLineHeight() +
      criteriaTableStartingYParameter;
    docHeight = doc.internal.pageSize.getHeight();

    // table for just criteria
    // @ts-ignore
    doc.autoTable({
      startY: heightAverageSummaryAcrossCriteria - 5,
      head: this.headCategoryDetailsRows(
        this.reportFormat,
        this.assigneeDetailsForReportDownload
      ),
      body: this.bodyCategoryDetailsRows(
        this.reportFormat,
        this.calculatedCriteriaScoreArrayForReportDownload,
        this.assigneeDetailsForReportDownload
      ),
      headStyles: {
        // cellPadding: 2,
        lineWidth: 0.5,
        textColor: [0, 0, 0, 0], //Black
      },
      bodyStyles: {
        // fontSize: 12,
        lineWidth: 0.5,
        lineColor: [228, 228, 228],
        borderColor: [6, 107, 175, 0.75],
        // lineWidth : 2
      },
      columnStyles: {
        text: {
          cellWidth: 'wrap',
        },
        fillColor: [228, 228, 228],
        srNo: { cellWidth: 5 },
        criteria: { cellWidth: 40 },
      },
      // bodyStyles: {
      //   // fontSize: 12,
      //   lineColor: [0, 0, 0, 0]
      // },
      margin: { top: 1 },
      pageBreak: 'avoid',
      rowPageBreak: 'avoid',
      tableWidth: '100%',
      styles: { overflow: 'linebreak' },
      // columnStyles: { srNo: { cellWidth: 5 }, criteria: { cellWidth: 40 } },
      // tableOptions
    });

    // @ts-ignore
    let secondY = doc.previousAutoTable.finalY;
    $('document').ready(function () {
      var HTML_Width = $('#weightedBarChart').width();
      var HTML_Height = $('#weightedBarChart').height();
      var docWidth = doc.internal.pageSize.getWidth();
      var docHeight = doc.internal.pageSize.getHeight();
      var top_left_margin = 15;
      var PDF_Width = docWidth;
      var PDF_Height = PDF_Width * 1.5;

      var totalPDFPages = doc.getNumberOfPages();
      // console.log("totalPDFPages", totalPDFPages, secondY, docHeight)
      // var weightageDiv  = <HTMLElement> document.getElementById('weightedBarChart')

      // weightageDiv.style.visibility = 'visible'
      setTimeout(() => {
        html2canvas
          .default(document.getElementById('weightedBarChart'), {
            scale: 5,
            
            backgroundColor: 'white',
            imageTimeout: 20000,
            allowTaint: true,
            useCORS: true,
          })
          .then((canvas) => {
            // console.log("clone function triggered later")
            var ctx = canvas.getContext('2d');
            // console.log("with out .cloneNode", document.getElementById('weightedBarChart'))
            // console.log(".cloneNode", document.getElementById('weightedBarChart').cloneNode)
            ctx.imageSmoothingEnabled = false;

            var imgData = canvas.toDataURL('image/png', 1.0);

            var width = doc.internal.pageSize.getWidth();
            var height = doc.internal.pageSize.getHeight();
            var yPositionOfImage;
            // console.log("(docHeight - secondY) < HTML_Height", (docHeight - secondY) < HTML_Height);
            if (docHeight - secondY < HTML_Height) {
              doc.addPage([400, 400], 'landscape');
              yPositionOfImage = 20;
            } else {
              yPositionOfImage = secondY + 5;
            }

            doc.addImage(
              imgData,
              'PNG',
              pageCenter / 2,
              yPositionOfImage,
              width * 0.5,
              200 * 0.5
            );

            for (var i = 1; i <= totalPDFPages; i++) {
              doc.addPage([400, 400], 'landscape');
              doc.addImage(
                imgData,
                'PNG',
                top_left_margin,
                -(PDF_Height * i) + top_left_margin * 4,
                width * 0.5,
                200 * 0.5
              );
            }

            var graph = document.getElementById('weightedBarChart');
            graph.innerHTML = '';

            var spiltquestionSummary = doc.splitTextToSize(
              'AVERAGE SCORE PER CRITERIA, PER QUESTION',
              doc.internal.pageSize.width / 2 - 15
            );
            doc.text(spiltquestionSummary, pageCenter, 10);
            const questionSummaryHeight =
              spiltquestionSummary.length * doc.getLineHeight();

            // @ts-ignore
            doc.autoTable({
              margin: { top: 5 },
              head: selfObject.headCategoryWithQuestionRows(
                selfObject.scoreParticularsForReportDownload
              ),
              body: selfObject.bodyCategoryWithQuestionRows(
                selfObject.calculatedCriteriaScoreArrayForReportDownload,
                selfObject.scoreParticularsForReportDownload
              ),
              // bodyStyles: {
              //   lineColor: [0, 0, 0, 0]
              // },
              headStyles: {
                // cellPadding: 2,
                lineWidth: 0.5,
                textColor: [0, 0, 0, 0], //Black
              },
              bodyStyles: {
                // fontSize: 12,
                lineWidth: 0.5,
                lineColor: [228, 228, 228],
                borderColor: [6, 107, 175, 0.75],
                // lineWidth : 2
              },
              columnStyles: {
                text: {
                  cellWidth: 'wrap',
                },
                fillColor: [228, 228, 228],
                srNo: { cellWidth: 3 },
                criteria: { cellWidth: 50 },
              },

              startY: questionSummaryHeight,
              // pageBreak : 'avoid',
              rowPageBreak: 'avoid',
              tableWidth: '100%',
              // columnStyles: { srNo: { cellWidth: 3 }, criteria: { cellWidth: 50 } },
            });

            if (this.reportFormat != 'SummarySummary') {
              // @ts-ignore
              let thirdY = doc.previousAutoTable.finalY;
              var ypositionOfCommentTable;
              // console.log("(docHeight - secondY) < HTML_Height", (docHeight - secondY) < HTML_Height);
              if (docHeight - thirdY < HTML_Height) {
                doc.addPage([400, 400], 'landscape');
                ypositionOfCommentTable = 20;
              } else {
                ypositionOfCommentTable = thirdY + 5;
              }

              var spiltCommentSummary = doc.splitTextToSize(
                'COMMENTS',
                doc.internal.pageSize.width / 2 - 15
              );
              doc.text(spiltCommentSummary, pageCenter, 10);
              const commentSummaryHeight =
                spiltCommentSummary.length * doc.getLineHeight();

              // @ts-ignore
              doc.autoTable({
                margin: { top: 5 },
                head: selfObject.headCommentRows(
                  selfObject.assigneeDetailsForReportDownload
                ),
                body: selfObject.bodyCommentsRows(
                  selfObject.assigneeDetailsForReportDownload
                ),
                headStyles: {
                  // cellPadding: 2,
                  lineWidth: 0.5,
                  textColor: [0, 0, 0, 0], //Black
                },
                bodyStyles: {
                  // fontSize: 12,
                  lineWidth: 0.5,
                  lineColor: [228, 228, 228],
                  borderColor: [6, 107, 175, 0.75],
                  // lineWidth : 2
                },

                columnStyles: {
                  text: {
                    cellWidth: 'wrap',
                  },
                  fillColor: [228, 228, 228],
                  // srNo: { cellWidth: 3 }, criteria: { cellWidth: 50 }
                },

                startY: commentSummaryHeight,
                // pageBreak : 'avoid',
                rowPageBreak: 'avoid',
                tableWidth: '100%',
              });
            }

            setTimeout(() => {
              selfObject.showSpinner = false;
              doc.save(selfObject.formTitleForReportDownload + '.pdf');
            }, 5000);
          });
      }, 5000);
    });
  }

  addPageJsPDF(docHeight, currentHeight) {
    // //console.log("docHeight",docHeight)
    // //console.log("currentHeight",currentHeight)
    if (docHeight <= currentHeight) {
      return true;
    } else {
      return false;
    }
  }

  headScoreParticularRows() {
    return [
      {
        Key: 'SCORE',
        Value: 'DESCRIPTION',
      },
    ];
  }

  bodyScoreParticularRows(rowCount, ScoreParticularBody) {
    rowCount = rowCount || 0;
    let body = [];
    if (rowCount > 0) {
      for (var j = 0; j < rowCount; j++) {
        body.push({
          Key: ScoreParticularBody[j].Key ? ScoreParticularBody[j].Key : '',
          Value: ScoreParticularBody[j].Value
            ? ScoreParticularBody[j].Value
            : '',
        });
      }
    }
    return body;
  }

  headCategoryDetailsRows(reportFormat, assigneeDetailsForReportDownload) {
    if (reportFormat == 'Summary') {
      return [
        {
          srNo: 'SR. No.',
          criteria: 'Criteria',
          weightage: 'Weightage (%)',
          averageScoreAcrossDirectors: 'Average Score Across Directors',
          weightedAverageScore: 'Weighted Average Score',
        },
      ];
    } else {
      var object = {
        srNo: 'SR. No.',
        criteria: 'Criteria',
        weightage: 'Weightage (%)',
      };

      var i = 0;
      for (i; i < _.size(assigneeDetailsForReportDownload); i++) {
        object['Director' + (i + 1)] = assigneeDetailsForReportDownload[i]
          .AssigneeDetails
          ? assigneeDetailsForReportDownload[i].AssigneeDetails.Title +
            ' ' +
            assigneeDetailsForReportDownload[i].AssigneeDetails.FirstName +
            ' ' +
            assigneeDetailsForReportDownload[i].AssigneeDetails.LastName
          : 'Director' + (i + 1);
      }

      object['averageScoreAcrossDirectors'] = 'Average Score Across Directors';
      object['weightedAverageScore'] = 'Weighted Average Score';

      return [object];
    }
  }

  bodyCategoryDetailsRows(
    reportFormat,
    calculatedCriteriaScoreArrayForReportDownload,
    assigneeDetailsForReportDownload
  ) {
    let body = [];
    var self = this;
    if (_.size(calculatedCriteriaScoreArrayForReportDownload)) {
      var i = 0;
      if (reportFormat == 'Summary') {
        for (
          i;
          i < _.size(calculatedCriteriaScoreArrayForReportDownload);
          i++
        ) {
          body.push({
            srNo: i + 1,
            criteria:
              calculatedCriteriaScoreArrayForReportDownload[i]
                .EvaluationCriteria,
            weightage:
              calculatedCriteriaScoreArrayForReportDownload[i].CriteriaWeight,
            averageScoreAcrossDirectors:
              calculatedCriteriaScoreArrayForReportDownload[i].averageScore,
            weightedAverageScore:
              calculatedCriteriaScoreArrayForReportDownload[i]
                .averageWeightedScore.averageWeightedScore,
          });
        }

        body.push({
          srNo: 'FINAL SCORE',
          weightedAverageScore: self.totalAverageWeightedScoreForReportDownload,
        });
      } else {
        for (
          i;
          i < _.size(calculatedCriteriaScoreArrayForReportDownload);
          i++
        ) {
          var object = {
            srNo: i + 1,
            criteria:
              calculatedCriteriaScoreArrayForReportDownload[i]
                .EvaluationCriteria,
            weightage:
              calculatedCriteriaScoreArrayForReportDownload[i].CriteriaWeight,
          };
          // object["Director" + (i + 1)] =

          var count = 0;
          for (
            count;
            count < _.size(assigneeDetailsForReportDownload);
            count++
          ) {
            object['Director' + (count + 1)] =
              assigneeDetailsForReportDownload[count].calculatedCriteriaScore[
                i
              ].categoryScore;
          }

          object['averageScoreAcrossDirectors'] =
            calculatedCriteriaScoreArrayForReportDownload[i].averageScore;
          object['weightedAverageScore'] =
            calculatedCriteriaScoreArrayForReportDownload[
              i
            ].averageWeightedScore.averageWeightedScore;

          body.push(object);
        }

        body.push({
          srNo: 'FINAL SCORE',
          weightedAverageScore: self.totalAverageWeightedScoreForReportDownload,
        });
      }
    }

    return body;
  }

  headCategoryWithQuestionRows(scoreParticularsForReportDownload) {
    var object = {
      srNo: 'SR. No.',
      criteria: 'Criteria',
    };
    var i = 0;
    for (i; i < _.size(scoreParticularsForReportDownload); i++) {
      object[scoreParticularsForReportDownload[i].Value] =
        scoreParticularsForReportDownload[i].Value;
    }
    object['averageScorePerSection'] = 'Average score per Section';

    return [object];
  }

  bodyCategoryWithQuestionRows(
    calculatedCriteriaScoreArrayForReportDownload,
    scoreParticularsForReportDownload
  ) {
    let body = [];
    var i = 0;
    if (_.size(calculatedCriteriaScoreArrayForReportDownload)) {
      for (i; i < _.size(calculatedCriteriaScoreArrayForReportDownload); i++) {
        var object = {
          srNo: i + 1,
          criteria:
            calculatedCriteriaScoreArrayForReportDownload[i].EvaluationCriteria,
        };
        var count = 0;
        for (
          count;
          count < _.size(scoreParticularsForReportDownload);
          count++
        ) {
          object[scoreParticularsForReportDownload[count].Value] = '';
        }

        body.push(object);

        var questionCriteriaCount = 0;
        // //console.log("calculatedCriteriaScoreArrayForReportDownload[i].Answers",calculatedCriteriaScoreArrayForReportDownload[i].Answers)
        for (
          questionCriteriaCount;
          questionCriteriaCount <
          _.size(calculatedCriteriaScoreArrayForReportDownload[i].Answers);
          questionCriteriaCount++
        ) {
          var questionObject = {
            srNo: i + 1 + '.' + (questionCriteriaCount + 1),
            criteria:
              calculatedCriteriaScoreArrayForReportDownload[i].Answers[
                questionCriteriaCount
              ].QuestionText,
          };
          // //console.log("questionObject",questionObject)
          var questionScoreCount = 0;
          for (
            questionScoreCount;
            questionScoreCount < _.size(scoreParticularsForReportDownload);
            questionScoreCount++
          ) {
            questionObject[
              scoreParticularsForReportDownload[questionScoreCount].Value
            ] = this.showAverageQuestionAnswer(
              calculatedCriteriaScoreArrayForReportDownload[i].Answers[
                questionCriteriaCount
              ].QuestionId,
              questionScoreCount
            );
          }
          // //console.log("questionObject",questionObject)
          body.push(questionObject);
        }

        body.push({
          criteria: 'SUB TOTAL',
          averageScorePerSection:
            calculatedCriteriaScoreArrayForReportDownload[i].averageScore,
        });
      }

      // //console.log("body inside", body)
    }
    // //console.log("body outside", body)
    return body;
  }

  headCommentRows(assigneeDetailsForReportDownload) {
    return [
      {
        srNo: 'SR. No.',
        commentsProvider: 'Comments Provided By',
        comments: 'Comments',
      },
    ];
  }

  bodyCommentsRows(assigneeDetailsForReportDownload) {
    console.log(
      'assigneeDetailsForReportDownload[i]',
      assigneeDetailsForReportDownload
    );
    let body = [];
    var self = this;
    var i = 0;

    for (i; i < _.size(assigneeDetailsForReportDownload); i++) {
      console.log(
        'assigneeDetailsForReportDownload[i]',
        assigneeDetailsForReportDownload[i]
      );
      body.push({
        srNo: i + 1,
        commentsProvider: assigneeDetailsForReportDownload[i].AssigneeDetails
          ? assigneeDetailsForReportDownload[i].AssigneeDetails.Title +
            ' ' +
            assigneeDetailsForReportDownload[i].AssigneeDetails.FirstName +
            ' ' +
            assigneeDetailsForReportDownload[i].AssigneeDetails.LastName
          : 'Director' + (i + 1),
        comments: assigneeDetailsForReportDownload[i].comments,
      });
    }

    return body;
  }

  showAverageQuestionAnswer(questionId, scoreCount) {
    scoreCount = scoreCount + 1;
    if (_.size(this.averageCriteriaQuestionAnswerForReportDownload)) {
      var questionElementFound = _.filter(
        this.averageCriteriaQuestionAnswerForReportDownload,
        function (element) {
          return element.QuestionId == questionId;
        }
      );

      if (_.size(questionElementFound)) {
        if (
          questionElementFound[0].score >= scoreCount &&
          questionElementFound[0].score < scoreCount + 1
        ) {
          return questionElementFound[0].score;
        } else {
          return '';
        }
      } else {
        return ' ';
      }
    } else {
      return ' ';
    }
  }

  drawWeigthedBarGraph(averageScoreArray) {
    var newBarData = [];

    //console.log(" [newBarData]", [newBarData]);
    //.-------------------------------------------------
    this.weigthedBarGraphOptions = {
      chart: {
        type: 'column',
        renderTo: 'weightedBarChart',
      },
      title: {
        text: '',
      },

      xAxis: {
        type: 'category',
        title: {
          text: 'Weighted Average Score',
        },
        labels: {
          style: {
            fontSize: '10px',
            fontFamily: 'Verdana, sans-serif',
          },
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Scores',
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        pointFormat: '{point.y:.2f}',
      },
      series: [
        {
          name: 'Entity',
          data: averageScoreArray.map(function (point) {
            return [point.EvaluationCriteria, point.averageWeightedScore];
          }),
          dataLabels: {
            rotation: -90,
            color: '#FFFFFF',
            align: 'right',
            format: '{point.y:.2f}', // one decimal
            y: 10, // 10 pixels down from the top
            style: {
              fontSize: '10px',
              fontFamily: 'Verdana, sans-serif',
            },
          },
        },
      ],
    };
  }

  // PDF Download Section Ends Here
  viewReport(formDetails) {
    //console.log("viewReport",formDetails);

    var evalueeId = formDetails.AssigneeDetails._id;

    var evaluationFormId = formDetails.EvaluationFormId;
    var evaluationTypeId = formDetails.EntityTypeId;
    var periodParameter = formDetails.PeriodParameter;

    this.router.navigate([
      'structure',
      {
        outlets: {
          'structure-outlet': [
            'individualReport',
            evalueeId,
            evaluationFormId,
            evaluationTypeId,
            periodParameter,
          ],
        },
      },
    ]);
  }

  assesorsPopup(formDetails) {
    var objectToSend = {
      EvaluationTypeID: formDetails.EntityTypeId,
      AssesseeId: formDetails.AssigneeDetails._id,
      EvaluationFormId: formDetails.EvaluationFormId,
      PeriodParameter: formDetails.PeriodParameter,
    };

    const modalRef = this.modalService.open(AssesorsListPopupComponent, {
      backdrop: 'static',
    });

    modalRef.componentInstance.ObjectToSend = objectToSend;
    modalRef.result.then(
      (resp) => {
        //console.log("response", resp);
      },
      (error) => {
        //console.log("error", error);
      }
    );
  }

  updateReportToGenerate(formDetails) {
    this.showSpinner = true;
    var objectToSend = {
      EvaluationTypeID: formDetails.EntityTypeId,
      AssesseeId: formDetails.AssigneeDetails._id,
      EvaluationFormId: formDetails.EvaluationFormId,
      PeriodParameter: formDetails.PeriodParameter,
    };

    this.boardEvaluationService
      .updateReportsToGenerate(objectToSend)
      .then((response) => {
        //console.log("response", response)
        if (response.status == 0) {
          this.showSpinner = false;
          $('#alertTitle').text('Success!');
          $('#alertTitle').css('color', 'black');
          $('#alertMessage').text(response.message);
          $('#alertMessage').css('color', 'black');
          $('#messagePopUp').modal('toggle');
        } else {
          this.showSpinner = false;
          $('#alertTitle').css('color', '#f00');
          $('#alertTitle').text('Failure!');
          $('#alertMessage').css('color', '#f00');
          $('#alertMessage').text(response.message);
          $('#messagePopUp').modal('toggle');
        }
        setTimeout("$('#messagePopUp').modal('hide');", 2000);
      });
  }

  navigateToAssignment(formDetails) {
    var assignStatusFormId;
    var evaluationFormId;
    evaluationFormId = formDetails.EvaluationFormId;
    assignStatusFormId = formDetails._id;
    //console.log("evaluationFormId",evaluationFormId);
    //console.log("assignStatusFormId",assignStatusFormId);
    // ['structure', { outlets: { 'structure-outlet': ['questionnaireAssignment', evaluationFormId] } }]
    this.router.navigate([
      'structure',
      {
        outlets: {
          'structure-outlet': [
            'questionnaireAssignment',
            evaluationFormId,
            assignStatusFormId,
          ],
        },
      },
    ]);
  }
}
