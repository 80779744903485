<section class="card">

  <div class="card-header">
    <span class="cat__core__title">
      <strong>Evaluation Answer and Submission</strong>
    </span>
  </div>

  <div class="card-block watermark">

    <div class="row table-responsive table-bordered margin-bottom-50">
      <table datatable [dtOptions]="dtOptions" id="assingedFormTable" [dtTrigger]="dtTrigger"
        class="table table-hover compact cell-border nowrap table-striped dataTable" style="background:transparent"
        width="100%">
        <thead>
          <tr>
            <th style="width:5%">Sr. No.</th>
            <th style="width:50%">Title</th>
            <th style="width:10%">Period</th>
            <th style="width:30%">Assessee</th>
            <th style="width:5%"
              [appHasPermission]="{ 'Url': '/api/v1/secure/boardEvaluationFormExecution/executeEvaluationFormExecution', 'method':'put', 'menu': 'EvaluationFormExecution' }">
              Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let form of assignedFormList;let count = index;">
            <td style="width:5%;text-align: center">{{count + 1}}</td>
            <td style="width:50%;white-space:normal;">{{form.EvaluationFormDetails[0].EvaluationFormTitle ?
              form.EvaluationFormDetails[0].EvaluationFormTitle : ""}}</td>
            <td style="width:10%">{{form._id?.PeriodParameter}}</td>
            <td style="width:30%">
              <!-- {{form.newAssesseDetails ? form.newAssesseDetails : " "}} -->
              <div>
                <span
                  [appHasPermission]="{ 'Url': '/api/v1/secure/boardEvaluationFormExecution/executeEvaluationFormExecution', 'method':'put', 'menu': 'EvaluationFormExecution' }"><button
                    (click)="executeSelected(count)" [id]='form._id.EvaluationFormId + count + "completed"'
                    class="btn btn-sm btn-primary">Evaluate Selected</button></span>
              </div>
              <div *ngFor="let dis of form.newAssesseDetails"
                style="margin-bottom:2%;padding-bottom: 2%;margin-top:2%;padding-top: 2%">
                <input type="checkbox" [disabled]="dis.IsFormEvaluated" [id]="dis._id + dis.AssesseeId"
                  (change)="executionFormExecutionList($event, count, dis.IsFormEvaluated, dis, form)"
                  [checked]="dis.IsFormEvaluated">
                {{dis.newAssesseDetails}}
                <span class="pull-right"> <label class="form-check-label"
                    [ngStyle]="{'çolor' : dis.IsFormEvaluated ? 'green' : 'yellow'}">{{dis.IsFormEvaluated ? "Completed"
                    : "Pending"}}</label></span>
              </div>

            </td>
            <td style="width:5%"
              [appHasPermission]="{ 'Url': '/api/v1/secure/boardEvaluationFormExecution/executeEvaluationFormExecution', 'method':'put', 'menu': 'EvaluationFormExecution' }">
              <button class="btn btn-sm btn-primary" [id]='form._id.EvaluationFormId + count'
                (click)="executeAssignedForm(form, count)">Evaluate All{{disabledEvaluateAll(form, count)}}</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</section>
