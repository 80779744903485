<section class="card">
  <div class="card-header">
    <span class="cat__core__title">
      <strong>Question Master</strong>
    </span>
  </div>
  <div class="card-block watermark">
    <div class="row">
      <div class="col-lg-12">
        <button type="button" class="btn btn-primary btn-sm mb-3" data-toggle="modal" data-target="#questionMasterModal" (click)="getEntityListDetails()"
          [appHasPermission]="{ 'Url': '/api/v1/secure/boardEvaluationQuestion/addEvaluationQuestion', 'method':'post', 'menu': 'EvaluationQuestion' }">
          Add Question
        </button>
        <div class="table-responsive table-bordered margin-bottom-50">
          <table
            datatable
            [dtOptions]="dtOptions"
            id="questionMasterTable"
            [dtTrigger]="dtTrigger"
            class="table table-hover compact cell-border nowrap table-striped dataTable"
            style="background: transparent"
            width="100%"
          >
            <thead>
              <tr>
                <th style="width:5%">Sr. No.</th>
                <th style="width:10%">Criteria</th>
                <th style="width:65%">Question</th>
                <th [appHasPermission]="{ 'Url': '/api/v1/secure/boardEvaluationQuestion/editEvaluationQuestion', 'method':'put', 'menu': 'EvaluationQuestion' }">Edit</th>
                <th [appHasPermission]="{ 'Url': '/api/v1/secure/boardEvaluationQuestion/deleteEvaluationQuestion', 'method':'delete', 'menu': 'EvaluationQuestion' }">Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let question of questionMasterList;let count=index">
                <td style="text-align:center;width:5%">{{count + 1}}</td>
                <td style="width:10%">{{question.EvaluationCriteriaID.EvaluationCriteria}}</td>
                <td style="width: 65%;word-wrap: break-word;white-space:normal">{{question.QuestionText.trim()}}</td>
                <td style="width: 5%;text-align: center;color: #74708d;cursor: pointer;" (click)="editQuestionDetails(question)" [appHasPermission]="{ 'Url': '/api/v1/secure/boardEvaluationQuestion/editEvaluationQuestion', 'method':'put', 'menu': 'EvaluationQuestion' }">
                  <div class="fa fa-pencil"></div>
                </td>
                <td
                  style="
                    width: 5%;
                    text-align: center;
                    color: #74708d;
                    cursor: pointer;
                  "
                  data-toggle="modal"
                  data-target="#deleteQuestionMasterModal"
                  [appHasPermission]="{
                    Url: '/api/v1/secure/boardEvaluationQuestion/deleteEvaluationQuestion',
                    method: 'delete',
                    menu: 'EvaluationQuestion'
                  }"
                  data-toggle="modal"
                  (click)="deleteQuestionIdToPass(question)"
                >
                  <div class="fa fa-trash-o"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Modal for adding new Role-->
        <div
          class="modal fade"
          id="questionMasterModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="questionMasterModalLabel"
        >
          <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
              <div
                class="modal-header"
                style="justify-content: unset !important"
              >
                <h4 class="modal-title" id="questionMasterModalLabel">
                  Add Question
                </h4>
                <button
                  type="button"
                  class="close"
                  style="right: 5px; position: absolute"
                  data-dismiss="modal"
                  (click)="closeForm()"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div class="modal-body">
                <form [formGroup]="questionForm">
                  <div class="row pt-1 pb-1">
                    <label class="col-md-4"
                      >Criteria <sup style="color: red">*</sup>:</label
                    >
                    <div class="col-sm-8">
                      <select class="form-control" [formControl]="questionForm.controls['evaluationCriteria']">
                        <option value="">Select</option>
                        <option
                          *ngFor="
                            let evaluationCriteriaData of evaluationevalCriteriaList
                          "
                          value="{{ evaluationCriteriaData._id }}"
                        >
                          {{ evaluationCriteriaData.EvaluationCriteria }}
                        </option>
                      </select>
                      <div
                        *ngIf="
                          questionForm.controls['evaluationCriteria'].hasError(
                            'required'
                          ) &&
                          questionForm.controls['evaluationCriteria'].touched
                        "
                        class="form-control-error-custom"
                      >
                        Select criteria
                      </div>
                    </div>
                  </div>
                  <div class="row pt-1 pb-1">
                    <label class="col-md-4"
                      >Question <sup style="color: red">*</sup>:</label
                    >
                    <div class="col-sm-8">
                      <textarea
                        placeholder="Enter Question text"
                        class="form-control"
                        name="questionText"
                        (keyup)="questionFormquestionTextChange($event)"
                        (change)="questionFormquestionTextChange($event)"
                        [formControl]="questionForm.controls['questionText']"
                        type="text"
                      ></textarea>
                      <div
                        *ngIf="
                          questionForm.controls['questionText'].hasError(
                            'required'
                          ) && questionForm.controls['questionText'].touched
                        "
                        class="form-control-error-custom"
                      >
                        Question text is required
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div class="modal-footer">
                <div style="width: 100%; text-align: left">
                  <span style="color: #ff0000">*</span> Indicates Mandatory
                  field
                </div>
                <button
                  type="button"
                  class="btn btn-primary btn-sm"
                  [disabled]="!questionForm.valid || disableSavebutton"
                  (click)="submitQuestionDetails()"
                >
                  Save
                </button>
                <button
                  type="button"
                  class="btn btn-default btn-sm"
                  style="background-color: #ffffff; color: #acb7bf !important"
                  data-dismiss="modal"
                  (click)="closeForm()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="editingQuestionModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="editQuestionMasterModalLabel"
        >
          <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
              <div
                class="modal-header"
                style="justify-content: unset !important"
              >
                <h4 class="modal-title" id="editQuestionMasterModalLabel">
                  Edit Question
                </h4>
                <button
                  type="button"
                  class="close"
                  style="right: 5px; position: absolute"
                  data-dismiss="modal"
                  (click)="closeForm()"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div class="modal-body">
                <form [formGroup]="editquestionForm">
                  <div class="row pt-1 pb-1">
                    <label class="col-md-4"
                      >Criteria <sup style="color: red">*</sup>:</label
                    >
                    <div class="col-sm-8">
                      <select class="form-control" [formControl]="editquestionForm.controls['editEvaluationCriteria']" [(ngModel)]="editCriteriaId" >
                        <option value="">Select</option>
                        <option
                          *ngFor="
                            let evaluationCriteriaData of evaluationevalCriteriaList
                          "
                          value="{{ evaluationCriteriaData._id }}"
                          (change)="editCriteria($event)"
                        >
                          {{ evaluationCriteriaData.EvaluationCriteria }}
                        </option>
                      </select>
                      <div
                        *ngIf="
                          editquestionForm.controls[
                            'editEvaluationCriteria'
                          ].hasError('required') &&
                          editquestionForm.controls['editEvaluationCriteria']
                            .touched
                        "
                        class="form-control-error-custom"
                      >
                        Select criteria
                      </div>
                    </div>
                  </div>
                  <div class="row pt-1 pb-1">
                    <label class="col-md-4"
                      >Question <sup style="color: red">*</sup>:</label
                    >
                    <div class="col-sm-8">
                        <textarea placeholder="Enter Question text" class="form-control" name="questionText" (keyup)="editquestionTextChange($event)" (change)="editquestionTextChange($event)" [formControl]="editquestionForm.controls['editQuestionText']" type="text"></textarea>
                      <div *ngIf="editquestionForm.controls['editQuestionText'].hasError('required') && editquestionForm.controls['editQuestionText'].touched"
                        class="form-control-error-custom">
                        Question text is required
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div class="modal-footer">
                <div style="width: 100%; text-align: left">
                  <span style="color: #ff0000">*</span> Indicates Mandatory
                  field
                </div>
                <button
                  type="button"
                  class="btn btn-primary btn-sm"
                  [disabled]="!editquestionForm.valid || disableupdatebutton"
                  (click)="udpateQuestionDetails()"
                >
                  Update
                </button>
                <button
                  type="button"
                  class="btn btn-default btn-sm"
                  style="background-color: #ffffff; color: #acb7bf !important"
                  data-dismiss="modal"
                  (click)="closeForm()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="deleteQuestionMasterModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="deleteQuestionMasterModalLabel"
        >
          <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
              <div
                class="modal-header"
                style="justify-content: unset !important"
              >
                <h4 class="modal-title" id="deleteQuestionMasterModalLabel">
                  Delete Question
                </h4>
                <button
                  type="button"
                  class="close"
                  style="right: 5px; position: absolute"
                  data-dismiss="modal"
                  (click)="closeForm()"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div class="modal-body">Are you sure you want to delete ?</div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary btn-sm"
                  (click)="deleteQuestionDetails()"
                >
                  Delete
                </button>
                <button
                  type="button"
                  class="btn btn-default btn-sm"
                  style="background-color: #ffffff; color: #acb7bf !important"
                  data-dismiss="modal"
                  (click)="closeForm()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="messagePopUp"
          tabindex="-1"
          role="dialog"
          aria-labelledby=""
          aria-hidden="true"
          style="top: 50px; z-index: 9999"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div
                id="alertPopUpTitle"
                class="modal-header"
                style="font-size: 20px; font-weight: bold; padding: 5px 15px"
              ></div>
              <div class="modal-body" style="padding: 10px 15px">
                <div id="alertMessage" style="font-size: 16px"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
