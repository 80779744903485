import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs'


@Injectable()

export class BoardEvaluationExecutionService {

  public commentsEvent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  commentsObservable = this.commentsEvent$.asObservable();

  public usersExecutionFormEvent$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  usersExecutionObservable = this.usersExecutionFormEvent$.asObservable();


    constructor(private http: HttpClient) {
    }

    getEvaluationFormDetails(formId): Promise<any> {
        return this.http.get(environment.clientUrl + 'evaluationMaster/getEvaluationForm/' + formId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
    }

    // to get Assinged Form Details
    getAssignedEvalutionFormDetails(assignedFormId): Promise<any> {
        return this.http.get(environment.clientUrl + 'evaluationMaster/getAssignedEvaluationForm/' + assignedFormId)
        .toPromise()
        .then(response => {
            return Promise.resolve(response)
        })
    }


    getEvaluationReviewFormDetails(userId): Promise<any> {
        return this.http.get(environment.clientUrl + 'evaluationMaster/getReviewEvaluationFormListById/' + userId)
        .toPromise()
        .then(response => {
            return Promise.resolve(response)
        })
    }

    
    // to answer evaluation question execution 
    answerEvaluationFormExecution(answerObject): Promise<any> {
        return this.http.post(environment.clientUrl + 'evaluationMaster/executeEvaluationFormAnswer' , answerObject)
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
    }

    submitEvaluationExecutionForm(formId): Promise<any> {
        return this.http.post(environment.clientUrl + 'evaluationMaster/submitEvaluationExecutionForm' , formId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
    }

    submitComments(commentsObject): Promise<any> {
        return this.http.put(environment.clientUrl + 'evaluationMaster/commentEvaluation' , commentsObject)
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
    } 


     // to get Assinged Form Details
    getAssignedEvalutionFormDetailsByArrayOfId(IdObject): Promise<any> {
        return this.http.post(environment.clientUrl + 'evaluationMaster/getAssignedEvaluationFormByIds', IdObject)
        .toPromise()
        .then(response => {
            return Promise.resolve(response)
        })
    }

    submitOverAllEvaluationExecutionForm(objectArray): Promise<any> {
        return this.http.put(environment.clientUrl + 'evaluationMaster/updateOverAllEvaluationExecutionFormStatus' , objectArray)
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
    }
 

    // to answer evaluation question execution 
    answerOverallEvaluationFormExecution(answerObject): Promise<any> {
        return this.http.post(environment.clientUrl + 'evaluationMaster/submitOverallAnswersByEvaluationFormId' , answerObject)
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
    }

}