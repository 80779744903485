import { Component, OnInit, ViewChild, Renderer2, ChangeDetectorRef } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  FormBuilder,
} from '@angular/forms';

import { DataTableDirective } from 'angular-datatables';
import { RoleMasterService } from '../../../services/rolemaster.service';

import { PermissionLinks } from './../../../enums/permissionLinks';

import { Subject } from 'rxjs';
import { EvalCategoryService } from '../../../services/evalCategory.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// import { HasPermissionDirective } from '../../directive/has-permission.directive';
import * as _ from 'lodash';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
declare var $: any;

declare var jQuery: any;

@Component({
  selector: 'app-evaluate-category',
  templateUrl: './evaluate-category.component.html',
  styleUrls: ['./evaluate-category.component.css'],
})
export class EvaluateCategoryComponent implements OnInit {
  roleForm: FormGroup;
  evalId: any;
  defineCategory: any;
  evaluationType: any;
  evaluationTypeList: any[]; //show list of evaluate type
  evalCategoryTypeData: any[]; //save values of database in table
  errorMessage: any;
  headerSuccessFlag: boolean = false;
  headerErrorFlag: boolean = false;
  headerSuccessMessage: string = 'Success !';
  headerErrorMessage: string = 'Failure !';
  currentEvaluationIDToUpdate: any;
  disableSavebutton: boolean = false;
  disableupdatebutton: boolean = false;

  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};

  dtTrigger: Subject<any> = new Subject();

  constructor(
    private cdr: ChangeDetectorRef,
    private evalCategoryService: EvalCategoryService,
    public fb: FormBuilder,
    public renderer: Renderer2,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    $('body').removeClass('dashboard-page');

    this.dtOptions = {
      destroy: true,
      pagingType: 'full_numbers',
      paging: true,
      // scrollY: "440px",
      // scrollCollapse: true,
      stateSave: true,
      stateDuration: -1,
      pageLength: 10,
      search: true,
      language: {
        //made changes for datatables for next previous button
        paginate: {
          first: '<<',
          last: '>>',
          next: '>',
          previous: '<',
        },
      },
    };

    this.roleForm = new FormGroup({
      defineCategory: new FormControl('', [Validators.required]),
    });

    this.getevaluationCategoryDataList();
    this.getEvaluationTypeList();
  }

  resetFormValues() {
    // this.defineCategory = "";
    // this.evaluationType = "";
    this.roleForm.reset();
  }

  //get data from database
  getevaluationCategoryDataList() {
    this.evalCategoryService.getEvaluationCategoryList().then((res) => {
      //this.listOfRoleNames = res.RolesList;
      //sort by created on
      console.log("res", res)
      this.evalCategoryTypeData = res.evalCriteriaList;
      this.dtTrigger.next();
      // setTimeout(() => {this.dtTrigger.next()});
    });
  }

  closeForm(id) {
    $('#evaluationMasterTable').DataTable({ destroy: true }).destroy();
    ////console.log("form closed");
    $('#' + id).modal('toggle');
    this.ngOnInit();
  }

  defineCriteriaChangeEvent(event) {
    //console.log("event.target.value",event.target.value)
    if (event.target.value.trim() == '') {
      //console.log("event.target.value",event.target.value)
      this.roleForm.controls.defineCategory.setValue(event.target.value.trim());
      this.roleForm.controls.defineCategory.updateValueAndValidity();
    }
     else {
      this.defineCategory = event.target.value.trim();
     }
  }

  submitEvaluation() {
    this.disableSavebutton = true;
    
    const evaluationDatalist = {
      EvaluationCriteria: this.defineCategory,
      // 'EvaluationTypeID': this.evaluationType
    }; 
  
    this.evalCategoryService.addEvaluation(evaluationDatalist).then((evalData) => {
      this.disableSavebutton = false;
      
      // Hide the main modal and reset backdrop
      $('#myModal').modal('hide');
      this.removeBackdrop(); // Ensure backdrop is removed
  
      // Check for success/failure and update UI accordingly
      const isSuccess = evalData.status === 0;
      const alertColor = isSuccess ? 'black' : '#f00';
      const alertTitle = isSuccess ? 'Success!' : 'Failure!';
      const alertMessage = evalData.message;
  
      // Update alert message in pop-up
      $('#alertTitle').text(alertTitle).css('color', alertColor);
      $('#alertMessage').text(alertMessage).css('color', alertColor);
      $('#messagePopUp').modal('toggle');
      this.messagePopUpTimeoutToogle(); // Call timeout function for message pop-up
  
      // Optional: If confirmation modal is needed, uncomment the following section
      // if (isSuccess) {
      //   const modalRef = this.modalService.open(ConfirmationPopupComponent, {
      //     backdrop: 'static',
      //   });
      //   modalRef.componentInstance.Message = evalData.message;
  
      //   modalRef.result.then(
      //     (resp) => {
      //       this.handlePostModalActions();
      //     },
      //     (error) => {
      //       this.handlePostModalActions();
      //     }
      //   );
      // }
  
      // Handle data update
      if (isSuccess) {
        this.getevaluationCategoryDataList(); // Reload category data
        window.location.reload();// Ensure UI update if necessary
     } else {
        this.handlePostModalActions();
     }
    });
  }
  
  removeBackdrop() {
    // Manually remove any remaining backdrop classes after modal closes
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }
  
  handlePostModalActions() {
    // Cleanup DataTable and refresh the table after modal actions
    $('#evaluationMasterTable').DataTable({ destroy: true }).destroy();
    this.ngOnInit();  // Re-initialize the component state
  }
  

  //values for evaluation type dropdown
  getEvaluationTypeList() {
    this.evalCategoryService.getEvaluationTypeList().then((updateEvalData) => {
      if (updateEvalData.status == 0) {
        //console.log("updateEvalData", updateEvalData.evalTypeList)
        this.evaluationTypeList = updateEvalData.evalTypeList; //put the evaluation type list data
      } else {
      }
    });
  }

  //edit entity
  editEntity(evalCategoryTypeData) {
    //console.log("evalCategoryTypeData", evalCategoryTypeData)
    $('myModalLabel').modal('hide');
    $('#editingEvaluationModal').modal('toggle');

    //this.evaluationTypeList = evalCategoryTypeData.EvaluationType
    // this.roleForm.controls.evaluationType.setValue(evalCategoryTypeData.EvaluationTypeID._id);
    this.roleForm.controls.defineCategory.setValue(
      evalCategoryTypeData.EvaluationCriteria
    );
    $('#evaluationMasterTable').DataTable({ destroy: true }).destroy();
    //this.evaluationType = evalCategoryTypeData.EvaluationTypeID._id;
    //this.defineCategory = evalCategoryTypeData.EvaluationCriteria
    this.currentEvaluationIDToUpdate = evalCategoryTypeData._id;
  }

  //update evaluation category
  updateEvaluation() {
    this.disableupdatebutton = true;
    var updatedEvaluation = {
      EvaluationCriteriaID: this.currentEvaluationIDToUpdate,
      // 'EvaluationTypeID': this.evaluationType,
      EvaluationCriteria: this.defineCategory,
    };
    this.evalCategoryService
      .updateEvaluationCategory(updatedEvaluation)
      .then((updateEvalData) => {
        this.disableupdatebutton = false;
        if (updateEvalData.status == 0) {
          const modalRef = this.modalService.open(ConfirmationPopupComponent, {
            backdrop: 'static',
          });
          modalRef.componentInstance.Message = updateEvalData.message;
          $('#editingEvaluationModal').modal('toggle');
          modalRef.result.then(
            (resp) => {
              $('#evaluationMasterTable')
                .DataTable({ destroy: true })
                .destroy();
              this.getevaluationCategoryDataList();

              //this.ngOnInit();
            },
            (error) => {
              $('#editingEvaluationModal').modal('toggle');
              $('#evaluationMasterTable')
                .DataTable({ destroy: true })
                .destroy();

              //this.ngOnInit();
            }
          );
          this.roleForm.reset();
        } else {
          const modalRef = this.modalService.open(ConfirmationPopupComponent, {
            backdrop: 'static',
          });
          modalRef.componentInstance.Message = updateEvalData.message;

          modalRef.result.then(
            (resp) => {
              $('#evaluationMasterTable')
                .DataTable({ destroy: true })
                .destroy();
              $('#editingEvaluationModal').modal('toggle');
              this.getevaluationCategoryDataList();
              //this.ngOnInit();
            },
            (error) => {
              $('#evaluationMasterTable')
                .DataTable({ destroy: true })
                .destroy();
              $('#editingEvaluationModal').modal('toggle');
              //this.ngOnInit();
            }
          );
        }
      });
  }

  confirmdeleteEntity(evaluationTypeData) {
    this.evalId = evaluationTypeData._id;

    $('#roleDeletionMessageModal').modal('toggle');
    //console.log("evaluationTypeData", this.evalId)
    //this.headerSuccessFlag = true;
    //this.headerErrorMessage= "success"
  }

  //delete evaluation
  deleteEvaluation() {
    // $('#roleDeletionMessageModal').modal('toggle');
    //var evalId = evalCategoryTypeDatas._id;
    this.evalCategoryService
      .deleteEvaluation(this.evalId)
      .then((deleteEvalData) => {
        if (deleteEvalData.status == 0) {
          $('#evaluationMasterTable').DataTable({ destroy: true }).destroy();
          //console.log("deleteEvalData",deleteEvalData)
          $('#errorMessageModal').modal('show');
          this.errorMessage = deleteEvalData.message;
          $('#roleDeletionMessageModal').modal('hide');
          this.headerSuccessFlag = true;
          //this.messagePopUpTimeoutToogle();

          setTimeout(() => {
            $('#errorMessageModal').modal('hide');

            //this.inputRoleName = "";

            // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            //   dtInstance.destroy();
            this.getevaluationCategoryDataList();
            //console.log("entity deleted")
            // });

            this.headerSuccessFlag = false;
          }, 3000);
        } else {
          this.errorMessage = deleteEvalData.message;
          $('#roleDeletionMessageModal').modal('hide');
          this.headerErrorFlag = true;
          $('#errorMessageModal').modal('show');

          setTimeout(() => {
            $('#errorMessageModal').modal('hide');
            this.headerErrorFlag = false;
          }, 3000);
        }
      });
  }

  messagePopUpTimeoutToogle() {
    setTimeout(function () {
      var popUpClass = $('#messagePopUp').attr('class');
      if (popUpClass == 'modal fade show') {
        $('#messagePopUp').modal('toggle');
      }
    }, 3000);
  }
}
