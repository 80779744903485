import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  FormBuilder,
} from '@angular/forms';
import {
  ActivatedRoute,
  RouterState,
  Router,
  NavigationEnd,
} from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { TypeOfUser } from '../../../enums/TypeOfUser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BoardEvaluationExecutionService } from '../../../services/boardEvaluationExecution.service';
import { BoardEvaluationService } from '../../../services/boardEvaluation.service';
import { ReviewStatus } from '../../../enums/roadmapReviewStatus';
import { NgxPaginationModule } from 'ngx-pagination';

import * as _ from 'lodash';
declare var $: any;
import * as moment from 'moment';

@Component({
  selector: 'app-evaluation-review',
  templateUrl: './evaluation-review.component.html',
  styleUrls: ['./evaluation-review.component.css'],
})
export class EvaluationReviewComponent implements OnInit {
  reviewForm: FormGroup;
  evaluationReviewTypeDatas = [];
  evaluationName: any;
  scoreRange: any;
  endDate: any;
  evaluationType: any;
  evaluationReviewCriteria = [];
  questionListAgainstCriteria = [];
  evaluationFormId: any;
  userInfo: any;
  disableReviewButton: boolean = false;
  evaluationCriteriaDetails = [];
  evaluationCriterialist = [];
  criteria: any;
  islastCriteria: Boolean = false;
  // collection = [];
  p: number = 1;
  formId: any;

  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  todayDate: Date = new Date();
  isAnonymous: boolean = false;

  loggedInUserId: any;
  reviewerId: any;

  constructor(
    private router: Router,
    private boardEvaluationExecutionService: BoardEvaluationExecutionService,
    private boardEvaluationService: BoardEvaluationService
  ) {
    // for (let i = 1; i <= 100; i++) {
    //   this.collection.push(`item ${i}`);
    // }
  }

  ngOnInit() {
    $('body').removeClass('dashboard-page');

    this.dtOptions = {
      destroy: true,
      pagingType: 'full_numbers',
      paging: true,
      stateSave: true,
      stateDuration: -1,
      pageLength: 10,
      search: true,
      language: {
        //made changes for datatables for next previous button
        paginate: {
          first: '<<',
          last: '>>',
          next: '>',
          previous: '<',
        },
      },
    };

    this.reviewForm = new FormGroup({
      comment: new FormControl('', []),
    });

    this.userInfo = JSON.parse(localStorage.getItem('user'));
    //console.log("userInfo", this.userInfo._id)
    this.getDetails();
  }

  closeForm(id) {
    $('#' + id).modal('toggle');
  }

  getDetails() {
    var self = this;

    $('#evaluationReviewTable').DataTable().destroy();
    this.boardEvaluationExecutionService
      .getEvaluationReviewFormDetails(this.userInfo._id)
      .then((response) => {
        if (response.status == 0) {
          this.dtTrigger.next();
          this.evaluationReviewTypeDatas = response.EvaluationFormList;
          this.formId = response.EvaluationFormList[0]._id;
          this.evaluationCriteriaDetails =
            response.EvaluationFormList[0].EvaluationCriterias;
          var firstCriteriaId =
            this.evaluationCriteriaDetails[0].EvaluationCriteriaID._id;
          this.criteria = _.filter(
            this.evaluationCriteriaDetails,
            function (element) {
              return (
                _.get(_.get(element, 'EvaluationCriteriaID'), '_id') ==
                firstCriteriaId
              );
            }
          );
          //console.log("this.criteria", this.criteria)
          //console.log("this.evaluationCriteriaDetails", this.evaluationCriteriaDetails)
          //console.log("this.evaluationReviewTypeDatas", this.evaluationReviewTypeDatas)
        } else {
          this.dtTrigger.next();
          this.evaluationReviewTypeDatas = response.EvaluationFormList;
          //console.log("this.evaluationReviewTypeDatas", this.evaluationReviewTypeDatas)
        }
      });
  }

  showFormDetails(evaluationGetData) {
    //console.log("evaluationGetData",evaluationGetData);
    this.userInfo = JSON.parse(localStorage.getItem('user'));
    this.router.navigate([
      'structure',
      {
        outlets: {
          'structure-outlet': [
            'questionnaireReview',
            evaluationGetData._id,
            this.userInfo._id,
          ],
        },
      },
    ]);

    // this.evaluationFormId = evaluationGetData._id
    // this.disableReviewButton = this.checkReviewStatus(evaluationGetData);
    //console.log("disableReviewButton", this.disableReviewButton);
    // var self = this;
    // $('#viewFormDetailsDialogBox').modal('toggle');
    //console.log("evaluationGetData", evaluationGetData)
    // self.questionListAgainstCriteria = [];
    // this.evaluationType = evaluationGetData.EvaluationTypeID.EvaluationType;
    // this.evaluationName = evaluationGetData.EvaluationFormTitle;
    // this.scoreRange = evaluationGetData.ScoreRange;
    // var date = new Date(evaluationGetData.EndDate);
    // this.endDate = moment(date, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY");
    // this.evaluationReviewCriteria = evaluationGetData.EvaluationCriterias;
    // this.isAnonymous = evaluationGetData.IsAnonymous.toString();
    // evaluationGetData.EvaluationCriterias.forEach(function (element) {
    //   self.questionListAgainstCriteria.push({ "EvaluationCriteria": element.EvaluationCriteriaID, "EvaluationQuestion": element.EvaluationQuestion });
    //console.log("this.questionListAgainstCriteria", self.questionListAgainstCriteria)
    // })
  }

  reviewApproved() {
    // var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = this.userInfo._id;
    var reviewObject = {
      userId: userId,
      EvaluationFormId: this.evaluationFormId,
    };
    this.boardEvaluationService
      .reviewApproved(reviewObject)
      .then((response) => {
        if (response.status == 0) {
          $('#viewFormDetailsDialogBox').modal('toggle');
          ////console.log("form saved succesfully", response);
          $('#alertTitle').text('Success!');
          $('#alertTitle').css('color', 'black');
          $('#alertMessage').text(response.message);
          $('#alertMessage').css('color', 'black');
          $('#messagePopUp').modal('toggle');
          $('#selectedCriteriatable').DataTable().destroy();
          this.messagePopUpTimeoutToogle();
          // this.dtTrigger.next();
          this.getDetails();
        } else {
          $('#viewFormDetailsDialogBox').modal('toggle');
          $('#alertTitle').css('color', '#f00');
          $('#alertTitle').text('Failure!');
          $('#alertMessage').css('color', '#f00');
          $('#alertMessage').text(response.message);
          $('#messagePopUp').modal('toggle');
          this.messagePopUpTimeoutToogle();
          this.getDetails();
        }
      });
  }

  checkReviewStatus(formDetails) {
    var self = this;
    if (_.size(formDetails.Reviewers)) {
      var index = _.findIndex(formDetails.Reviewers, function (reviewer) {
        return _.get(reviewer, 'ReviewerId') == self.userInfo._id;
      });

      //console.log("index", index)
      if (index >= 0) {
        if (
          formDetails.Reviewers[index].ReviewerStatus == ReviewStatus.Approve
        ) {
          return 'Approved';
        } else if (
          formDetails.Reviewers[index].ReviewerStatus == ReviewStatus.Reject
        ) {
          return 'Rejected';
        } else {
          return 'To Review';
        }
      } else {
        return 'To Review';
      }
    } else {
      return 'To Review';
    }
  }

  messagePopUpTimeoutToogle() {
    setTimeout(function () {
      var popUpClass = $('#messagePopUp').attr('class');
      if (popUpClass == 'modal fade show') {
        $('#messagePopUp').modal('toggle');
      }
    }, 2000);
  }

  questionListAgainstCriteriaList(evalCriteriaId, isLast) {
    //console.log("evalCriteriaId", evalCriteriaId)
    this.criteria = _.filter(
      this.evaluationCriteriaDetails,
      function (element) {
        return (
          _.get(_.get(element, 'EvaluationCriteriaID'), '_id') == evalCriteriaId
        );
      }
    );

    if (isLast) {
      this.islastCriteria = isLast;
    } else {
      this.islastCriteria = isLast;
    }

    //console.log("criteria", this.criteria);
  }

  acceptQuestionWithComments() {}

  rejectQuestionWithComments() {}

  commentChangeEvent(event, questionId) {
    if (event.target.value.trim() == '') {
      this.reviewForm.controls.comment.setValue(event.target.value.trim());
      this.reviewForm.controls.comment.updateValueAndValidity();
    }
    var comment = event.target.value;
    //console.log("comment",comment)
    var questionObjt = {
      questionId: questionId,
      commentText: comment,
      formId: this.formId,
      reviewerId: this.userInfo._id,
    };
    //console.log("questionObjt",questionObjt)
    this.boardEvaluationService
      .updateReviewerComments(questionObjt)
      .then((response) => {
        if (response.status == 0) {
          //console.log("response", response)
        } else {
        }
      });
  }
}
