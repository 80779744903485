import { Component, OnInit, Input, Inject } from '@angular/core';


import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl, EmailValidator } from '@angular/forms';

import { roleMasterMenuname } from '../../../enums/roleMasterMenuName';

import { PermissionLinks } from '../../../enums/permissionLinks';

import { roleMasterIconClass } from '../../../enums/roleMasterMenuName';

import { RoleMasterService } from '../../../services/rolemaster.service';
import * as _ from 'lodash';
import { DOCUMENT } from '@angular/common';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-edit-role-modal',
  templateUrl: './edit-role-modal.component.html',
  styleUrls: ['./edit-role-modal.component.css'],
})
export class EditRoleModalComponent implements OnInit {

  @Input('roleObjectToEdit') roleObjectToEdit: any;


  loggedInUserInfo: any;
  isGuestSuperAdmin: boolean;

  editRoleForm: FormGroup;
  public menuName: string;
  inputRoleName: any;
  masterMenu: any = [];
  boardEvaluationMenu: any = [];
  governanceMenu: any = [];
  complianceMenu: any = [];
  upsiMenu: any = [];
  ncdMenu :any =[];
  documentManagementMenu: any = [];
  adminMenu: any = [];
  dashboardMenu: any = [];
  gemMenu: any = [];
  lawAndRegulationMenu: any = [];
  addedRoles: any = [];
  finalRoleList: any = [];
  isfinalRoleListEmpty: boolean = true;
  roleNamePatternRegEx: any = "^[a-zA-Z][a-zA-Z\-\_\/ ]*$";
  private inputRoleId: any;

  isPSSConfig = environment.Service.PSS ;
  isGovevaConfig = environment.Service.GovEva ;
  isUPSIConfig = environment.Service.UPSI && environment.FinalMenu.UPSI ;
  isAllConfig = environment.Service.All ;
  isNCDConfig = environment.Service.NCD && environment.FinalMenu.NCD ;
  isGEMConfig = environment.Service.GEM ;
  isLawsConfig = environment.FinalMenu.Laws ;
  // isGovernance =  environment.Service.Governance;
   isBoardEvaluationConfig = environment.Service.BoardEvaluation;
  isMeetingMakerWithoutAgenda = environment.Service.MeetingMakerWithoutAgenda;
  isMeetingMakerWithAgenda = environment.Service.MeetingMakerWithAgenda;


  isViewAllActive = false;
  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private roleMasterService: RoleMasterService) {

    this.editRoleForm = this.fb.group({
      roleName: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.pattern(this.roleNamePatternRegEx)])
    })

  }

  ngOnInit() {

    this.loggedInUserInfo = JSON.parse(localStorage.getItem("user")).TypeOfUser;
    if (this.loggedInUserInfo === PermissionLinks.GuestSuperAdmin) {
      this.isGuestSuperAdmin = true;
    } else {
      this.isGuestSuperAdmin = false;
    }

    // this.editRoleForm.controls.roleName.disable();
    this.editRoleForm.controls.roleName.updateValueAndValidity();


    this.roleMasterService.roleActivityCheckboxList().then(response => {
      if (response.status == 0) {
        //console.log("response");
        let resources = response.Resources;
        if (!this.isGuestSuperAdmin) {
          _.remove(resources, function (resourceElement: any) { return resourceElement.MenuDisplayName == 'Roadmap' });
        }


         // if UPSI is not selected then Email templates should be inside Compaliance Menu
         if(this.isAllConfig ){
          _.remove(resources , function(resourceElement : any){
            return ((resourceElement.ParentMenuName == roleMasterMenuname.UPSI) && (resourceElement.Menu == roleMasterMenuname.EmailTemplate))             
          })
        } else {      

          if(this.isPSSConfig && !(this.isGovevaConfig || this.isAllConfig || this.isUPSIConfig || this.isBoardEvaluationConfig)){ //|| this.isBoardEvaluationConfig
            // console.log("resources - isPSSConfig")
            _.remove(resources, function (resourceElement: any) { return (resourceElement.Menu == roleMasterMenuname.EmailTemplate) } )
            _.remove(resources, function (resourceElement: any) { return ( (resourceElement.ParentMenuName != roleMasterMenuname.Master) && (resourceElement.ParentMenuName != roleMasterMenuname.Compliance) && (resourceElement.ParentMenuName != roleMasterMenuname.DocumentManagement) && (resourceElement.ParentMenuName != roleMasterMenuname.LawsAndRegulations) && (resourceElement.ParentMenuName != roleMasterMenuname.UPSI))});
          }
  
          if(this.isGovevaConfig && !(this.isPSSConfig || this.isAllConfig || this.isUPSIConfig || this.isBoardEvaluationConfig)){ //|| this.isBoardEvaluationConfig
            // console.log("resources - isGovevaConfig")
            _.remove(resources, function (resourceElement: any) { return ( (resourceElement.ParentMenuName == roleMasterMenuname.Compliance) || (resourceElement.ParentMenuName == roleMasterMenuname.DocumentManagement) || (resourceElement.ParentMenuName == roleMasterMenuname.UPSI))});
          }
  
          if( this.isBoardEvaluationConfig && !(this.isGovevaConfig || this.isPSSConfig || this.isAllConfig || this.isUPSIConfig || this.isBoardEvaluationConfig)){ //this.isBoardEvaluationConfig &&
            // console.log("resources - Board Evaluation")
            _.remove(resources, function (resourceElement: any) { return ((resourceElement.ParentMenuName != roleMasterMenuname.BoardEvaluation ) || (resourceElement.ParentMenuName != roleMasterMenuname.Master))});
          }
  
          if(this.isUPSIConfig && !(this.isGovevaConfig || this.isPSSConfig || this.isAllConfig || this.isBoardEvaluationConfig)){ //|| this.isBoardEvaluationConfig
            // console.log("resources - UPSI")
            _.remove(resources, function (resourceElement: any) { return !((resourceElement.ParentMenuName == roleMasterMenuname.Master ) || (resourceElement.ParentMenuName == roleMasterMenuname.UPSI))});
            _.remove(resources , function(resourceElement : any){
              return ((resourceElement.ParentMenuName == roleMasterMenuname.Master) && !((resourceElement.Menu == roleMasterMenuname.RoleMenu) || (resourceElement.Menu == roleMasterMenuname.UserMenu) || (resourceElement.Menu == roleMasterMenuname.CompanyMenu)  || (resourceElement.Menu == roleMasterMenuname.UPSIMaster) || (resourceElement.Menu == roleMasterMenuname.UPSIParameter)))             
            })
          }
        if( this.isUPSIConfig && this.isPSSConfig){
          // _.remove(resources, function (resourceElement: any) { return ((resourceElement.Menu == roleMasterMenuname.EmailTemplate) || (resourceElement.ParentMenuName == roleMasterMenuname.GEM)) } )
          _.remove(resources, function (resourceElement: any) { return (resourceElement.Menu == roleMasterMenuname.EmailTemplate) } )
        }


      }

        if (_.size(resources)) {
          resources.forEach(resource => {
            switch (resource.ParentMenuName) {
              case roleMasterMenuname.Master:
                this.masterMenu.push(resource);
                break;
              case roleMasterMenuname.BoardEvaluation:
                this.boardEvaluationMenu.push(resource);
                break;
              case roleMasterMenuname.Governance:

                if (resource.MenuDisplayName == "Assessment") {
                  if (this.isGuestSuperAdmin) {
                    _.remove(resource.ChildResources, function (childElement: any) { return (childElement.DisplayName == 'Execute' || childElement.DisplayName == 'Review') });
                  } else {
                    _.remove(resource.ChildResources, function (childElement: any) { return (childElement.DisplayName == 'IC Execute' || childElement.DisplayName == 'IC Review') });
                  }
                }
                this.governanceMenu.push(resource);
                break;
              case roleMasterMenuname.Compliance:
                this.complianceMenu.push(resource);
                break;
              case roleMasterMenuname.UPSI:
                this.upsiMenu.push(resource);
                break;
              case roleMasterMenuname.DocumentManagement:
                this.documentManagementMenu.push(resource);
                break;
              case roleMasterMenuname.Admin:
                this.adminMenu = resource.ChildResources;
                break;
              case roleMasterMenuname.Dashboard:
                //console.log("resource", resource)
                this.dashboardMenu = resource.ChildResources;
                break;
              case roleMasterMenuname.GEM:
                // console.log("resource", resource)
                this.gemMenu.push(resource);

                break;
              case roleMasterMenuname.LawsAndRegulations:
                //console.log("resource",resource)                       
                this.lawAndRegulationMenu.push(resource);
                break;
                case roleMasterMenuname.NCD:
                this.ncdMenu.push(resource);
                break;
            }
          });
        }

        this.getRoleDetails();

      }
      else {
        //console.log("error getting resources list")
      }
    })

    //console.log("this.roleObjectToEdit",this.roleObjectToEdit);
    this.inputRoleName = this.roleObjectToEdit.RoleName;
    this.inputRoleId = this.roleObjectToEdit._id;
    // this.editRoleForm.get('roleName').setValue(this.inputRoleName);

    this.validationInputRoleName.setValue(this.inputRoleName);
    // console.log("this.finalRoleList---added", this.addedRoles);
    // console.log("this.finalRoleList", this.finalRoleList);
  }


  get validationInputRoleName() {
    return this.editRoleForm.get('roleName');
  }

  inputRoleNameTextboxChanged(event) {
    this.validationInputRoleName.setValue(event.target.value.trim());
    this.validationInputRoleName.updateValueAndValidity();
  }


  getRoleDetails() {
    this.roleMasterService.getRoleToEditByRoleId(this.inputRoleId).then(response => {
      let editRoleActivityList = response.resources;
      console.log("editRoleActivityList",editRoleActivityList);
      editRoleActivityList.forEach(roleActivity => {

        if (roleActivity.Menu == roleMasterMenuname.Dashboard) {

          let childResources = roleActivity.ChildResources;

          childResources.forEach(ChildElement => {
            let IconhtmlElement = document.getElementById(ChildElement.Url);
            this.finalRoleList.push({ 'MenuDisplayName': ChildElement.DisplayName, 'childUrl': ChildElement.Url });
            if(IconhtmlElement){
            var checkBoxHtmlElement = <HTMLInputElement>document.getElementById(ChildElement.DisplayName);
            checkBoxHtmlElement.checked = true;
            //console.log("checkBoxHtmlElement",checkBoxHtmlElement);
            IconhtmlElement.classList.remove("rolemaster-icon-hide");
            IconhtmlElement.classList.remove("icon-unselected");
            IconhtmlElement.classList.add("icon-selected");
            IconhtmlElement.classList.add("rolemaster-icon-display");

          }
            if (this.addedRoles.findIndex(addedRole => addedRole.MenuDisplayName == ChildElement.DisplayName) < 0) {
              this.addedRoles.push({ 'MenuDisplayName': ChildElement.DisplayName, 'id': ChildElement.Url });

            }
            // this.addedRoles.push({'MenuDisplayName' : ChildElement.DisplayName  , 'id' : ChildElement.Url})

          });
          //console.log("this.finalRoleList", this.finalRoleList);
        }
        else if (roleActivity.Menu == roleMasterMenuname.Admin) {
          let childResources = roleActivity.ChildResources;
          childResources.forEach(ChildElement => {
            let IconhtmlElement = document.getElementById(ChildElement.Url);
            console.log('ppppp',ChildElement.Url, IconhtmlElement)
            this.finalRoleList.push({ 'MenuDisplayName': ChildElement.DisplayName, 'childUrl': ChildElement.Url });
            if(IconhtmlElement){
            var checkBoxHtmlElement = <HTMLInputElement>document.getElementById(ChildElement.DisplayName);
            checkBoxHtmlElement.checked = true;

            IconhtmlElement.classList.remove("rolemaster-icon-hide");
            IconhtmlElement.classList.remove("icon-unselected");
            IconhtmlElement.classList.add("icon-selected");
            IconhtmlElement.classList.add("rolemaster-icon-display");
            }
            if (this.addedRoles.findIndex(addedRole => addedRole.MenuDisplayName == ChildElement.DisplayName) < 0) {
              this.addedRoles.push({ 'MenuDisplayName': ChildElement.DisplayName, 'id': ChildElement.Url });

            }
            // this.addedRoles.push({'MenuDisplayName' : ChildElement.DisplayName  , 'id' : ChildElement.Url})

          });

          // console.log("this.finalRoleList", this.finalRoleList);

        }

        else {
          let childResources = roleActivity.ChildResources;
          // console.log("childResources",childResources);
          childResources.forEach(ChildElement => {
            this.finalRoleList.push({ 'MenuDisplayName': roleActivity.MenuDisplayName, 'childUrl': ChildElement.Url });
            let IconhtmlElement = document.getElementById(ChildElement.Url);
            if(IconhtmlElement){
            if(IconhtmlElement != undefined || IconhtmlElement != null){

              IconhtmlElement.classList.remove("rolemaster-icon-hide");
              IconhtmlElement.classList.remove("icon-unselected");
              IconhtmlElement.classList.add("icon-selected");
              IconhtmlElement.classList.add("rolemaster-icon-display");
            }
          }

            if (this.addedRoles.findIndex(addedRole => addedRole.MenuDisplayName == roleActivity.MenuDisplayName) < 0) {
              this.addedRoles.push({ 'MenuDisplayName': roleActivity.MenuDisplayName, 'id': ChildElement.Url });

            }
            // this.addedRoles.push({'MenuDisplayName' : roleActivity.Menu  , 'id' : ChildElement.Url})

          });

          let self = this;
          let parentHtmlElement = document.getElementById(roleActivity.MenuDisplayName) ? document.getElementById(roleActivity.MenuDisplayName).parentElement : null;

          if(parentHtmlElement != undefined || parentHtmlElement != null){

            let icontags = parentHtmlElement.getElementsByTagName("i");
            //console.log("icontags ---- ",icontags);
            let IconSelected = 0;  
  
            if (_.size(icontags)) {
  
              //console.log("_.size(self.finalRoleList)",_.size(self.finalRoleList));
              if (_.size(self.finalRoleList)) {
                for (var i = 0; i < _.size(icontags); i++) {
                  if (icontags[i].classList.contains('icon-selected')) {
                    IconSelected++;
                  }
                }
              }
            }
  
            var iconElement = <HTMLInputElement>document.getElementById(roleActivity.MenuDisplayName);
            if(IconSelected > 0  && IconSelected == _.size(icontags)){            
              iconElement.indeterminate = false;
              iconElement.checked = true;
            }else if(IconSelected > 0  && IconSelected < _.size(icontags)){
              iconElement.checked = false;
              iconElement.indeterminate = true;
            }else if(IconSelected == 0){
              iconElement.checked = false;
              iconElement.indeterminate = false;
            }
          }


          // console.log("this.finalRoleList", this.addedRoles);
        }
      });

      if (_.size(this.finalRoleList)) {
        this.isfinalRoleListEmpty = false;
      } else {
        this.isfinalRoleListEmpty = true;
      }

      //this.viewAll();

      this.selectAllCheckboxInitialisation('masterCheckbox', this.masterMenu);
      this.selectAllCheckboxInitialisation('boardEvaluationCheckbox', this.boardEvaluationMenu);
      this.selectAllCheckboxInitialisation('governanceCheckbox', this.governanceMenu);
      this.selectAllCheckboxInitialisation('complianceCheckbox', this.complianceMenu);
      this.selectAllCheckboxInitialisation('documentManagementCheckbox', this.documentManagementMenu);
      this.selectAllCheckboxInitialisation('adminCheckbox', this.adminMenu);
      this.selectAllCheckboxInitialisation('dashboardCheckbox', this.dashboardMenu);
      this.selectAllCheckboxInitialisation('gemCheckbox', this.gemMenu);
      this.selectAllCheckboxInitialisation('lawAndRegulationCheckbox', this.lawAndRegulationMenu);
      this.selectAllCheckboxInitialisation('upsiCheckbox', this.upsiMenu);
      this.selectAllCheckboxInitialisation('ncdCheckbox',this.ncdMenu)
    },
      error => console.log(""));
  }


  selectAllCheckboxInitialisation(selectAllCheckboxId, menuArray) {

    var totalIconSelected = 0;
    var totalIcons = 0

    var selectAllCheckboxHTMlElement = <HTMLInputElement>document.getElementById(selectAllCheckboxId);

    menuArray.forEach(menu => {

      if (menu.MenuDisplayName == undefined) {
        totalIcons++;
        if (this.finalRoleList.findIndex(role => role.childUrl == menu.Url) >= 0) {
          totalIconSelected++;
        }

      }
      else {
        menu.ChildResources.forEach(chlidElement => {
          totalIcons++;
          if (this.finalRoleList.findIndex(role => role.childUrl == chlidElement.Url) >= 0) {
            totalIconSelected++;
          }
        })
      }
    })


    if (totalIconSelected == 0) {
      if(!_.isUndefined(selectAllCheckboxHTMlElement) && selectAllCheckboxHTMlElement != null){
        selectAllCheckboxHTMlElement.indeterminate = false;
        selectAllCheckboxHTMlElement.checked = false;
      }    
    } else if (totalIconSelected > 0 && totalIconSelected < totalIcons) {
      if(!_.isUndefined(selectAllCheckboxHTMlElement) && selectAllCheckboxHTMlElement != null){
      selectAllCheckboxHTMlElement.indeterminate = true;
      selectAllCheckboxHTMlElement.checked = false;
      }
    } else if (totalIconSelected > 0 && totalIconSelected == totalIcons) {
      if(!_.isUndefined(selectAllCheckboxHTMlElement) && selectAllCheckboxHTMlElement != null){
      selectAllCheckboxHTMlElement.indeterminate = false;
      selectAllCheckboxHTMlElement.checked = true;
      }
    }



  }

  viewAll() {
    console.error("sklog> View All this.isViewAllActive:",this.isViewAllActive)
    if(this.isViewAllActive){
      this.isViewAllActive = false;

      console.error("removing viewall")

      $('#masterMenu').removeClass("show");

      var ele = document.getElementById('masterMenu');
      console.error("removing viewall ele:",ele);
      ele.classList.remove("show");
      console.error("removing viewall ele2:",ele);


      $('#boardEvaluationMenu').removeClass("show");
      $('#governanceMenu').removeClass("show");
      $('#complianceMenu').removeClass("show");
      $('#documentManagementMenu').removeClass("show");
      $('#adminMenu').removeClass("show");
      $('#dashboardMenu').removeClass("show");
      $('#gemMenu').removeClass("show");
      $('#upsiMenu').removeClass("show");
      $('#ncdMenu').removeClass("show");
      $('#lawAndRegulationMenu').removeClass("show");

      $('#masterSubMenu').removeClass("subMenuViewAll");
      $('#boardEvaluationSubMenu').removeClass("subMenuViewAll");
      $('#governanceSubMenu').removeClass("subMenuViewAll");
      $('#complianceSubMenu').removeClass("subMenuViewAll");
      $('#documentManagementSubMenu').removeClass("subMenuViewAll");
      $('#adminSubMenu').removeClass("subMenuViewAll");
      $('#dashboardSubMenu').removeClass("subMenuViewAll");
      $('#gemSubMenu').removeClass("subMenuViewAll");
      $('#upsiSubMenu').removeClass("subMenuViewAll");
      $('#ncdSubMenu').removeClass("subMenuViewAll");
      $('#lawAndRegulationSubMenu').removeClass("subMenuViewAll");

      $('#masterMenu').removeClass("show");
  
      $('dropdown').attr('aria-expanded', 'false');
      //$('.rolemaster-viewall').css('position', 'relative');
      //$('.rolemaster-viewall').css('display', 'block');
      //$('.rolemaster-viewall').css('float', 'right'); //display: block;

    }else{
      this.isViewAllActive = true;

      $('#masterMenu').addClass("show");
      $('#boardEvaluationMenu').addClass("show");
      $('#governanceMenu').addClass("show");
      $('#complianceMenu').addClass("show");
      $('#documentManagementMenu').addClass("show");
      $('#adminMenu').addClass("show");
      $('#dashboardMenu').addClass("show");
      $('#gemMenu').addClass("show");
      $('#upsiMenu').addClass("show");
      $('#ncdMenu').addClass("show");
      $('#lawAndRegulationMenu').addClass("show");

      $('#masterSubMenu').addClass("subMenuViewAll");
      $('#boardEvaluationSubMenu').addClass("subMenuViewAll");
      $('#governanceSubMenu').addClass("subMenuViewAll");
      $('#complianceSubMenu').addClass("subMenuViewAll");
      $('#documentManagementSubMenu').addClass("subMenuViewAll");
      $('#adminSubMenu').addClass("subMenuViewAll");
      $('#dashboardSubMenu').addClass("subMenuViewAll");
      $('#gemSubMenu').addClass("subMenuViewAll");
      $('#upsiSubMenu').addClass("subMenuViewAll");
      $('#ncdSubMenu').addClass("subMenuViewAll");
      $('#lawAndRegulationSubMenu').addClass("subMenuViewAll");
  
      $('dropdown').attr('aria-expanded', 'true');
      // $('.rolemaster-viewall').css('position', 'relative');
      // $('.rolemaster-viewall').css('display', 'block');
      // $('.rolemaster-viewall').css('float', 'right'); //display: block;
    }
    //var ele = document.getElementById('masterMenu');
    //ele.classList.add("show");

    
    

  }

  cancel() {
    this.activeModal.dismiss('dismissed')
  }

  mouseoverDisplay(menuElement, event, parentMenuName, MenuDisplayName) {
    var htmlMenuElement = event.target;
    event.target.style.textDecoration = "none!important";
    let icontags = htmlMenuElement.getElementsByTagName("i");
    let self = this;

    if (_.size(icontags)) {
      for (var i = 0; i < _.size(icontags); i++) {
        icontags[i].classList.remove("rolemaster-icon-hide");
        icontags[i].classList.add("rolemaster-icon-display");

      }
    }

  }

  mouseleaveDisplay(menuElement, event, parentMenuName, MenuDisplayName) {
    var htmlMenuElement = event.target;
    let icontags = htmlMenuElement.getElementsByTagName("i");

    let self = this;

    if (_.size(icontags)) {
      if (_.size(self.finalRoleList)) {

        for (var i = 0; i < _.size(icontags); i++) {
          //console.log("icontags[i].id",icontags[i].id);
          if (self.finalRoleList.findIndex(roleElement => roleElement.childUrl == icontags[i].id) >= 0) {
            icontags[i].classList.remove("rolemaster-icon-hide");
            icontags[i].classList.add("rolemaster-icon-display");
          } else {
            icontags[i].classList.remove("rolemaster-icon-display");
            icontags[i].classList.add("rolemaster-icon-hide");
          }
        }
      } else {

        for (var i = 0; i < _.size(icontags); i++) {
          if (icontags[i].classList.contains("rolemaster-icon-display")) {
            icontags[i].classList.remove("rolemaster-icon-display");
            icontags[i].classList.add("rolemaster-icon-hide");
          }
        }
      }
    }

  }


  iconClicked(event, menuElement, iconType, MenuDisplayName, childDetails, selectAllCheckboxId, menuArray) {
    event.stopPropagation();

    //View Icon Validation
    if (childDetails.IconName == "view") {
      if (menuElement.ChildResources == undefined) {
        // this will be for dashboard
        this.IconClickedFunctionExtension(event, menuElement, iconType, MenuDisplayName, childDetails, selectAllCheckboxId, menuArray);

      } else {
        //if any of url is in finalrole list then do not do not anything with view
        var iconSelected = 0;
        menuElement.ChildResources.forEach(element => {
          if ((element.IconName != "view") && this.finalRoleList.findIndex(role => role.childUrl == element.Url) >= 0) {
            //console.log("cannot click view as others have been selected");
            iconSelected++;
          }

        })

        if (iconSelected == 0) {
          this.IconClickedFunctionExtension(event, menuElement, iconType, MenuDisplayName, childDetails, selectAllCheckboxId, menuArray);
        }
      }
    } else {
      this.IconClickedFunctionExtension(event, menuElement, iconType, MenuDisplayName, childDetails, selectAllCheckboxId, menuArray);
    }
  }

  IconClickedFunctionExtension(event, menuElement, iconType, MenuDisplayName, childDetails, selectAllCheckboxId, menuArray) {
    var htmlMenuElement = event.target;

    // if (this.finalRoleList.indexOf(childDetails.Url) < 0) {
    if (this.finalRoleList.findIndex(role => role.childUrl == childDetails.Url) < 0) {

      if (this.addedRoles.findIndex(addedRole => addedRole.MenuDisplayName == MenuDisplayName) < 0) {
        this.addedRoles.push({ 'MenuDisplayName': MenuDisplayName, 'id': childDetails.Url });
      }

      this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': childDetails.Url });

      if (_.size(this.finalRoleList)) {
        this.isfinalRoleListEmpty = false;
      } else {
        this.isfinalRoleListEmpty = true;
      }

      //---------------------------View Icon Selection based on edit , add , delete

      // if((childDetails.IconName == "add" || childDetails.IconName == "edit" || childDetails.IconName == "delete")){
      if ((childDetails.IconName != "view" && childDetails.IconName !== "edit" && childDetails.IconName !== "sendToAssessee" && childDetails.IconName !== 'status')) {

        if (menuElement.ChildResources == undefined) {

        } else {
          menuElement.ChildResources.forEach(element => {
            if (element.IconName == "view") {

              if (this.finalRoleList.findIndex(role => role.childUrl == element.Url) < 0) {

                var viewIconHTMLElement = document.getElementById(element.Url);
                viewIconHTMLElement.classList.remove("icon-unselected");
                viewIconHTMLElement.classList.add("icon-selected");

                viewIconHTMLElement.classList.add("rolemaster-icon-display");
                this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': element.Url });

              }

            }
          })
        }

      }

      //-----------------------------------------------------------------------


      //  console.log("this.finalrolelist", this.finalRoleList);
      // htmlMenuElement.style.display = "block";
      htmlMenuElement.classList.remove("icon-unselected");
      htmlMenuElement.classList.add("icon-selected");

      htmlMenuElement.classList.add("rolemaster-icon-display");




      // } else if (this.finalRoleList.indexOf(childDetails.Url) >= 0) {
    } else if (this.finalRoleList.findIndex(role => role.childUrl == childDetails.Url) >= 0) {
      // console.log("icon-unselected  is not present");
      // console.log("findindex else final role list",this.finalRoleList.findIndex(role => role.childUrl == childDetails.Url))
      // console.log("this icon was  present --- >> now applying deselection class")

      // htmlMenuElement.style.display = "none";
      // this.finalRoleList.splice(this.finalRoleList.indexOf(childDetails.Url), 1);

      this.finalRoleList.splice(this.finalRoleList.findIndex(role => role.childUrl == childDetails.Url), 1);

      htmlMenuElement.classList.remove("icon-selected");
      htmlMenuElement.classList.add("icon-unselected");
      htmlMenuElement.classList.add("rolemaster-icon-hide");
      // console.log("this.finalrolelist", this.finalRoleList);


      var count = 0;

      if (menuElement.ChildResources == undefined) {

        var b = _.filter(this.finalRoleList, function (role) {
          if (role.childUrl == menuElement.Url) {
            return role
          }
        }).length;
        count = count + b;

      } else {
        menuElement.ChildResources.forEach(childElement => {
          var b = _.filter(this.finalRoleList, function (role) {
            if (role.childUrl == childElement.Url) {
              return role
            }
          }).length;
          count = count + b;
        });

      }

      if (count == 0) {
        // console.log("this.addedRoles.findIndex(role => role.childUrl == childDetails.Url)",this.addedRoles.findIndex(role => role.childUrl == childDetails.Url))
        this.addedRoles.splice(this.addedRoles.findIndex(role => role.MenuDisplayName == MenuDisplayName), 1);
      }

      if (_.size(this.finalRoleList)) {
        this.isfinalRoleListEmpty = false;
      } else {
        this.isfinalRoleListEmpty = true;
      }

      // console.log("count",count);

    }




    let self = this;
    let parentHtmlElement = htmlMenuElement.parentElement;
    let icontags = parentHtmlElement.getElementsByTagName("i");
    //  console.log("icontags",icontags);
    let IconSelected = 0;

    if (_.size(icontags)) {

      // console.log("_.size(self.finalRoleList)",_.size(self.finalRoleList));
      if (_.size(self.finalRoleList)) {
        for (var i = 0; i < _.size(icontags); i++) {
          if (icontags[i].classList.contains('icon-selected')) {
            IconSelected++;
          }
        }
      }
    }

    var checkboxElement = <HTMLInputElement>document.getElementById(MenuDisplayName);
    if (IconSelected > 0 && IconSelected == _.size(icontags)) {
      checkboxElement.indeterminate = false;
      checkboxElement.checked = true;
    } else if (IconSelected > 0 && IconSelected < _.size(icontags)) {
      checkboxElement.checked = false;
      checkboxElement.indeterminate = true;
    } else if (IconSelected == 0) {
      checkboxElement.checked = false;
      checkboxElement.indeterminate = false;
    }

    // this.addedRoles.push({'_id':menuElement._id,'MenuDisplayName': menuElement.MenuDisplayName});
    // this.addedRoles.push(MenuDisplayName);


    this.selectAllCheckBoxStatus(selectAllCheckboxId, menuArray);
    // console.log("this.addedRoles",this.addedRoles);
    //  console.log("this.finalrolelist", this.finalRoleList);

  }



  // Old ParentMenuCheckboxClicked Functionality Starts Here
  //----------------------------------------------------------------------------------------------  
  // ParentMenuCheckboxClicked(menuId, subMenuId, menulist) {

  //   let self = this;
  //   //for master menu
  //   var parentHtmlElement = document.getElementById(subMenuId);
  //   menulist.forEach(menu => {
  //     if (menu.MenuDisplayName == undefined) {
  //       var inputHtmlElement = document.getElementById(menu.DisplayName);
  //       var MenuDisplayName = menu.DisplayName;
  //     } else {
  //       var inputHtmlElement = document.getElementById(menu.MenuDisplayName);
  //       var MenuDisplayName = menu.MenuDisplayName;
  //     }

  //     let parentHtmlElement = inputHtmlElement.parentElement;
  //     let icontags = parentHtmlElement.getElementsByTagName("i");
  //     let IconSelected = 0;

  //     if (_.size(icontags)) {

  //       // console.log("_.size(self.finalRoleList)",_.size(self.finalRoleList));
  //       if (_.size(self.finalRoleList)) {
  //         for (var i = 0; i < _.size(icontags); i++) {
  //           if (icontags[i].classList.contains('icon-selected')) {
  //             IconSelected++;
  //           }
  //         }
  //       }
  //     }

  //     this.checkboxHandling(menu, IconSelected, MenuDisplayName);

  //   })


  //   if (_.size(this.finalRoleList)) {
  //     this.isfinalRoleListEmpty = false;
  //   } else {
  //     this.isfinalRoleListEmpty = true;
  //   }

  // } 

  //----------------------------------------ParentMenuCheckboxClicked Ends Here-------------------------------------


  ParentMenuCheckboxClicked(checkboxMenuId, subMenuId, menulist) {

    // console.log("menulist",menulist)
    let self = this;
    //for master menu
    var parentHtmlElement = document.getElementById(subMenuId);
    let totalIconSelected = 0;  //total icons which have been selected in parent menu
    let totalIconForParentmenu = 0; //total icons with respect to parentmenu

    menulist.forEach(menu => {
      if (menu.MenuDisplayName == undefined) {
        var inputHtmlElement = document.getElementById(menu.DisplayName);
        var MenuDisplayName = menu.DisplayName;
      } else {
        var inputHtmlElement = document.getElementById(menu.MenuDisplayName);
        var MenuDisplayName = menu.MenuDisplayName;
      }
      let parentHtmlElement = inputHtmlElement.parentElement;
      let icontags = parentHtmlElement.getElementsByTagName("i");

      if (_.size(icontags)) {
        // console.log("_.size(self.finalRoleList)",_.size(self.finalRoleList));
        if (_.size(self.finalRoleList)) {
          for (var i = 0; i < _.size(icontags); i++) {
            if (icontags[i].classList.contains('icon-selected')) {
              totalIconSelected++;
            }
          }
        }
      }
      // totalIconForParentmenu = totalIconForParentmenu + _.size(icontags)
      if (menu.MenuDisplayName != 'UPSI Detail All') {
        totalIconForParentmenu = totalIconForParentmenu + _.size(icontags)
      }
    })

    // console.log("IconSelected",totalIconSelected ,totalIconForParentmenu);


    if (totalIconSelected == 0 || totalIconSelected == totalIconForParentmenu) {

      menulist.forEach(menu => {
        if (menu.MenuDisplayName == undefined) {
          var inputHtmlElement = document.getElementById(menu.DisplayName);
          var MenuDisplayName = menu.DisplayName;
        } else {
          var inputHtmlElement = document.getElementById(menu.MenuDisplayName);
          var MenuDisplayName = menu.MenuDisplayName;
        }
        let parentHtmlElement = inputHtmlElement.parentElement;
        let icontags = parentHtmlElement.getElementsByTagName("i");
        let IconSelected = 0;
        if (_.size(icontags)) {

          // console.log("_.size(self.finalRoleList)",_.size(self.finalRoleList));
          if (_.size(self.finalRoleList)) {
            for (var i = 0; i < _.size(icontags); i++) {
              if (icontags[i].classList.contains('icon-selected')) {
                IconSelected++;
              }
            }
          }
        }

        this.checkboxHandling(menu, IconSelected, MenuDisplayName);
      })


      // console.log("added roles",this.addedRoles);
    }
    else  // if from menu few submenus have been selected 
    {

      menulist.forEach(menu => {

        if (menu.MenuDisplayName == undefined) {
          var inputHtmlElement = document.getElementById(menu.DisplayName);
          var MenuDisplayName = menu.DisplayName;
        } else {
          var inputHtmlElement = document.getElementById(menu.MenuDisplayName);
          var MenuDisplayName = menu.MenuDisplayName;
        }

        // console.log("index" ,this.addedRoles.findIndex(role => role.MenuDisplayName == MenuDisplayName) )




        //will check whether that icon is selected or not


        // console.log("menu.ChildResources",menu.ChildResources);

        if (menu.ChildResources == undefined) {

          let iconElement = document.getElementById(menu.Url);

          const isIconElementPresent = _.find(this.finalRoleList, function (roleElement) {
            return roleElement.childUrl == menu.Url;
          })

          if (this.addedRoles.findIndex(role => role.MenuDisplayName == MenuDisplayName) < 0) {
            this.addedRoles.push({ "MenuDisplayName": MenuDisplayName, "id": menu.Url })
          }

          if (_.isNil(isIconElementPresent)) {
            if (iconElement.classList.contains("icon-unselected")) {
              iconElement.classList.remove("icon-unselected");
              iconElement.classList.add("icon-selected");
              iconElement.classList.remove("rolemaster-icon-hide");
              iconElement.classList.add("rolemaster-icon-display");
              this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': menu.Url });
            }
          }


        } else {


          menu.ChildResources.forEach(childElement => {
            let iconElement = document.getElementById(childElement.Url);

            const isIconElementPresent = _.find(this.finalRoleList, function (roleElement) {
              return roleElement.childUrl == childElement.Url;
            })


            if (this.addedRoles.findIndex(role => role.MenuDisplayName == MenuDisplayName) < 0) {
              this.addedRoles.push({ "MenuDisplayName": MenuDisplayName, "id": childElement.Url })
            }


            //if icon is not selected it will add it to the list.
            if (_.isNil(isIconElementPresent)) {
              if (iconElement.classList.contains("icon-unselected")) {
                iconElement.classList.remove("icon-unselected");
                iconElement.classList.add("icon-selected");
                iconElement.classList.remove("rolemaster-icon-hide");
                iconElement.classList.add("rolemaster-icon-display");
                this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': childElement.Url });
              }
            }

          })



        }



        var checkboxMenuHTMLElement = <HTMLInputElement>document.getElementById(MenuDisplayName)

        checkboxMenuHTMLElement.indeterminate = false;

        checkboxMenuHTMLElement.checked = true;

      })

      var selectALLcheckboxHtmlElement = <HTMLInputElement>document.getElementById(checkboxMenuId)
      selectALLcheckboxHtmlElement.checked = true;

    }


    if (_.size(this.finalRoleList)) {
      this.isfinalRoleListEmpty = false;
    } else {
      this.isfinalRoleListEmpty = true;
    }
    this.selectAllCheckBoxStatus(checkboxMenuId, menulist)
    // console.log("self.finalRoleList" , this.finalRoleList)
  }



  checkboxHandling(menu, IconSelected, MenuDisplayName) {

    if (menu.ChildResources == undefined) {

      if (IconSelected > 0) {

        let iconElement = document.getElementById(menu.Url);


        iconElement.classList.remove("icon-selected");
        iconElement.classList.add("icon-unselected");
        iconElement.classList.remove("rolemaster-icon-display");
        iconElement.classList.add("rolemaster-icon-hide");

        this.finalRoleList.splice(this.finalRoleList.findIndex(role => role.childUrl == menu.Url), 1);
        this.addedRoles.splice(this.addedRoles.findIndex(role => role.MenuDisplayName == MenuDisplayName), 1);

        var menucheckbox = <HTMLInputElement>document.getElementById(MenuDisplayName)
        // console.log("menucheckbox",menucheckbox)
        menucheckbox.checked = false;


      } else if (IconSelected == 0) {
        let iconElement = document.getElementById(menu.Url);

        iconElement.classList.remove("icon-unselected");
        iconElement.classList.add("icon-selected");
        iconElement.classList.remove("rolemaster-icon-hide");
        iconElement.classList.add("rolemaster-icon-display");

        this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': menu.Url });
        this.addedRoles.push({ 'MenuDisplayName': MenuDisplayName, 'id': menu.Url });
        var menucheckbox = <HTMLInputElement>document.getElementById(MenuDisplayName)
        menucheckbox.checked = true;

      }


    }//for rest of resources
    else if (menu.ChildResources) {

      // console.log("apart from admin and dashboard");

      //here it will assign selected or unselected class for icons 

      if (IconSelected > 0 && (IconSelected < _.size(menu.ChildResources))) {
        menu.ChildResources.forEach(childElement => {
          let iconElement = document.getElementById(childElement.Url);

          const isIconElementPresent = _.find(this.finalRoleList, function (roleElement) {
            return roleElement.childUrl == childElement.Url;
          })

          if (_.isNil(isIconElementPresent)) {
            if (iconElement.classList.contains("icon-unselected")) {
              iconElement.classList.remove("icon-unselected");
              iconElement.classList.add("icon-selected");
              iconElement.classList.add("rolemaster-icon-display");
              this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': childElement.Url });
            }
          }

        })

        var menucheckbox = <HTMLInputElement>document.getElementById(MenuDisplayName)
        // console.log("menucheckbox",menucheckbox)
        menucheckbox.indeterminate = false;
        menucheckbox.checked = true;

      } else if (IconSelected > 0 && (IconSelected == _.size(menu.ChildResources))) {

        var count = 0;

        menu.ChildResources.forEach(childElement => {
          let iconElement = document.getElementById(childElement.Url);

          const isIconElementPresent = _.find(this.finalRoleList, function (roleElement) {
            return roleElement.childUrl == childElement.Url;
          })

          iconElement.classList.remove("icon-selected");
          iconElement.classList.add("icon-unselected");
          iconElement.classList.remove("rolemaster-icon-display");
          iconElement.classList.add("rolemaster-icon-hide");
          this.finalRoleList.splice(this.finalRoleList.findIndex(role => role.childUrl == childElement.Url), 1);

          var b = _.filter(this.finalRoleList, function (role) {
            if (role.childUrl == childElement.Url) {
              return role
            }
          }).length;

          count = count + b;

        })

        if (count == 0) {
          this.addedRoles.splice(this.addedRoles.findIndex(role => role.MenuDisplayName == MenuDisplayName), 1);
        }

        var menucheckbox = <HTMLInputElement>document.getElementById(MenuDisplayName)
        // console.log("menucheckbox",menucheckbox)
        menucheckbox.checked = false;
        menucheckbox.indeterminate = false;

      } else if (IconSelected == 0) {
        menu.ChildResources.forEach(childElement => {
          if (MenuDisplayName != "UPSI Detail All") {
            document.getElementById(MenuDisplayName).setAttribute('val', 'true')
            let iconElement = document.getElementById(childElement.Url);
            iconElement.classList.remove("icon-unselected");
            iconElement.classList.add("icon-selected");
            iconElement.classList.remove("rolemaster-icon-hide");
            iconElement.classList.add("rolemaster-icon-display");
            this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': childElement.Url });

            if (this.addedRoles.findIndex(addedRole => addedRole.MenuDisplayName == MenuDisplayName) < 0) {
              this.addedRoles.push({ 'MenuDisplayName': MenuDisplayName, 'id': childElement.Url });
            }
          }
        })
        if (MenuDisplayName != "UPSI Detail All") {
          var menucheckbox = <HTMLInputElement>document.getElementById(MenuDisplayName)
          console.log("menucheckbox", menucheckbox)
          menucheckbox.checked = true;
          menucheckbox.indeterminate = false;
        }
      }

    }
  }

  //--------------- Old CHeckboxHandling Functioanlity Starts Here ---------------------------------------

  // checkboxHandling(menu, IconSelected, MenuDisplayName) {

  //   if (menu.ChildResources == undefined) {

  //     if (IconSelected > 0) {

  //       let iconElement = document.getElementById(menu.Url);


  //       iconElement.classList.remove("icon-selected");
  //       iconElement.classList.add("icon-unselected");
  //       iconElement.classList.remove("rolemaster-icon-display");
  //       iconElement.classList.add("rolemaster-icon-hide");

  //       this.finalRoleList.splice(this.finalRoleList.findIndex(role => role.childUrl == menu.Url), 1);
  //       this.addedRoles.splice(this.addedRoles.findIndex(role => role.MenuDisplayName == MenuDisplayName), 1);

  //       var menucheckbox = <HTMLInputElement>document.getElementById(MenuDisplayName)
  //       console.log("menucheckbox",menucheckbox)
  //       menucheckbox.checked = false;


  //     } else if (IconSelected == 0) {
  //       let iconElement = document.getElementById(menu.Url);

  //       iconElement.classList.remove("icon-unselected");
  //       iconElement.classList.add("icon-selected");
  //       iconElement.classList.remove("rolemaster-icon-hide");
  //       iconElement.classList.add("rolemaster-icon-display");

  //       this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': menu.Url });
  //       this.addedRoles.push({ 'MenuDisplayName': MenuDisplayName, 'id': menu.Url });
  //       var menucheckbox = <HTMLInputElement>document.getElementById(MenuDisplayName)
  //       menucheckbox.checked = true;

  //     }


  //   }//for rest of resources
  //   else if (menu.ChildResources) {

  //     console.log("apart from admin and dashboard");

  //     //here it will assign selected or unselected class for icons 

  //     if (IconSelected > 0 && (IconSelected < _.size(menu.ChildResources))) {
  //       menu.ChildResources.forEach(childElement => {
  //         let iconElement = document.getElementById(childElement.Url);

  //         const isIconElementPresent = _.find(this.finalRoleList, function (roleElement) {
  //           return roleElement.childUrl == childElement.Url;
  //         })

  //         if (_.isNil(isIconElementPresent)) {
  //           if (iconElement.classList.contains("icon-unselected")) {
  //             iconElement.classList.remove("icon-unselected");
  //             iconElement.classList.add("icon-selected");
  //             iconElement.classList.add("rolemaster-icon-display");
  //             this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': childElement.Url });
  //           }
  //         }

  //       })

  //       var menucheckbox = <HTMLInputElement>document.getElementById(MenuDisplayName)
  //       console.log("menucheckbox",menucheckbox)
  //       menucheckbox.indeterminate = false;

  //     } else if (IconSelected > 0 && (IconSelected == _.size(menu.ChildResources))) {

  //       var count = 0;

  //       menu.ChildResources.forEach(childElement => {
  //         let iconElement = document.getElementById(childElement.Url);

  //         const isIconElementPresent = _.find(this.finalRoleList, function (roleElement) {
  //           return roleElement.childUrl == childElement.Url;
  //         })

  //         iconElement.classList.remove("icon-selected");
  //         iconElement.classList.add("icon-unselected");
  //         iconElement.classList.remove("rolemaster-icon-display");
  //         iconElement.classList.add("rolemaster-icon-hide");
  //         this.finalRoleList.splice(this.finalRoleList.findIndex(role => role.childUrl == childElement.Url), 1);

  //         var b = _.filter(this.finalRoleList, function (role) {
  //           if (role.childUrl == childElement.Url) {
  //             return role
  //           }
  //         }).length;

  //         count = count + b;

  //       })

  //       if (count == 0) {
  //         this.addedRoles.splice(this.addedRoles.findIndex(role => role.MenuDisplayName == MenuDisplayName), 1);
  //       }

  //       var menucheckbox = <HTMLInputElement>document.getElementById(MenuDisplayName)
  //       console.log("menucheckbox",menucheckbox)
  //       menucheckbox.checked = false;

  //     } else if (IconSelected == 0) {
  //       menu.ChildResources.forEach(childElement => {
  //         let iconElement = document.getElementById(childElement.Url);
  //         iconElement.classList.remove("icon-unselected");
  //         iconElement.classList.add("icon-selected");
  //         iconElement.classList.remove("rolemaster-icon-hide");
  //         iconElement.classList.add("rolemaster-icon-display");
  //         this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': childElement.Url });

  //         if (this.addedRoles.findIndex(addedRole => addedRole.MenuDisplayName == MenuDisplayName) < 0) {
  //           this.addedRoles.push({ 'MenuDisplayName': MenuDisplayName, 'id': childElement.Url });
  //         }
  //       })

  //       var menucheckbox = <HTMLInputElement>document.getElementById(MenuDisplayName)
  //       console.log("menucheckbox",menucheckbox)
  //       menucheckbox.checked = true;

  //     }

  //   }
  // }
  //--------------- Old CHeckboxHandling Functioanlity Ends Here --------------------------------------- 

  @Inject(DOCUMENT) document
  checkboxClicked(menu, event, MenuDisplayName, selectAllCheckboxId, menuArray) {

    event.stopPropagation();
    let inputHtmlElement = event.target;
    // console.log("inputHtmlElement",inputHtmlElement);


    let self = this;
    let parentHtmlElement = inputHtmlElement.parentElement;
    let icontags = parentHtmlElement.getElementsByTagName("i");
    // console.log("icontags",icontags);
    let IconSelected = 0;

    if (_.size(icontags)) {

      // console.log("_.size(self.finalRoleList)",_.size(self.finalRoleList));
      if (_.size(self.finalRoleList)) {
        for (var i = 0; i < _.size(icontags); i++) {
          if (icontags[i].classList.contains('icon-selected')) {
            IconSelected++;
          }
        }
      }
    }

    // console.log("IconSelected",IconSelected);



    //for admin and dashboard
    if (menu.ChildResources == undefined) {

      if (IconSelected > 0) {

        let iconElement = document.getElementById(menu.Url);


        iconElement.classList.remove("icon-selected");
        iconElement.classList.add("icon-unselected");
        iconElement.classList.remove("rolemaster-icon-display");
        iconElement.classList.add("rolemaster-icon-hide");

        this.finalRoleList.splice(this.finalRoleList.findIndex(role => role.childUrl == menu.Url), 1);
        this.addedRoles.splice(this.addedRoles.findIndex(role => role.MenuDisplayName == MenuDisplayName), 1);


      } else if (IconSelected == 0) {
        let iconElement = document.getElementById(menu.Url);

        iconElement.classList.remove("icon-unselected");
        iconElement.classList.add("icon-selected");
        iconElement.classList.remove("rolemaster-icon-hide");
        iconElement.classList.add("rolemaster-icon-display");

        this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': menu.Url });
        this.addedRoles.push({ 'MenuDisplayName': MenuDisplayName, 'id': menu.Url });

      }

      // console.log("this.finalroleList", this.finalRoleList);

    }//for rest of resources
    else if (menu.ChildResources) {


      //here it will assign selected or unselected class for icons 

      if (IconSelected > 0 && (IconSelected < _.size(menu.ChildResources))) {
        menu.ChildResources.forEach(childElement => {
          let iconElement = document.getElementById(childElement.Url);
          // console.log('iconElement',iconElement)

          const isIconElementPresent = _.find(this.finalRoleList, function (roleElement) {
            return roleElement.childUrl == childElement.Url;
          })

          if (_.isNil(isIconElementPresent) && (menu.Menu != "UPSI Detail All" && menu.Menu != "UPSI Detail Individual")) {
            if (iconElement.classList.contains("icon-unselected")) {
              document.getElementById(MenuDisplayName).setAttribute('val', 'true')
              iconElement.classList.remove("icon-unselected");
              iconElement.classList.add("icon-selected");
              iconElement.classList.add("rolemaster-icon-display");
              this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': childElement.Url });
            }
          }

        })
      } else if (IconSelected > 0 && (IconSelected == _.size(menu.ChildResources))) {

        var count = 0;

        menu.ChildResources.forEach(childElement => {
          let iconElement = document.getElementById(childElement.Url);

          const isIconElementPresent = _.find(this.finalRoleList, function (roleElement) {
            return roleElement.childUrl == childElement.Url;
          })
          document.getElementById(MenuDisplayName).removeAttribute('val')
          iconElement.classList.remove("icon-selected");
          iconElement.classList.add("icon-unselected");
          iconElement.classList.remove("rolemaster-icon-display");
          iconElement.classList.add("rolemaster-icon-hide");
          this.finalRoleList.splice(this.finalRoleList.findIndex(role => role.childUrl == childElement.Url), 1);

          var b = _.filter(this.finalRoleList, function (role) {
            if (role.childUrl == childElement.Url) {
              return role
            }
          }).length;

          count = count + b;

        })

        if (count == 0) {
          this.addedRoles.splice(this.addedRoles.findIndex(role => role.MenuDisplayName == MenuDisplayName), 1);
        }

      } else if (IconSelected == 0) {
        if (this.isUPSIConfig && document.getElementById('UPSI Detail All').getAttribute('val') == "true" && MenuDisplayName == "UPSI Detail Individual") {
          document.getElementById('UPSI Detail All').removeAttribute('val')
          document.getElementById("UPSI Detail Individual").setAttribute('val', "true")
          let uspiAll = <HTMLInputElement>document.getElementById('UPSI Detail All')
          uspiAll.checked = false
          let deSelectChildrenIcon = uspiAll.nextElementSibling.children
          for (let index = 0; index < deSelectChildrenIcon.length; index++) {
            deSelectChildrenIcon[index].classList.remove("icon-selected");
            deSelectChildrenIcon[index].classList.add("icon-unselected");
            deSelectChildrenIcon[index].classList.add("rolemaster-icon-hide");
            deSelectChildrenIcon[index].classList.remove("rolemaster-icon-display");
            console.log(deSelectChildrenIcon[index].id)
            let indexOfUPSIAll = _.findIndex(self.finalRoleList, function (element) { return _.get(element, 'childUrl') == deSelectChildrenIcon[index].id })
            if (indexOfUPSIAll != -1) {
              let indexOfaddRole = _.findIndex(self.addedRoles, function (element) { return _.get(element, 'MenuDisplayName') == "UPSI Detail All" })
              if (indexOfaddRole != -1) {
                self.addedRoles.splice(indexOfaddRole, 1)
              }
              self.finalRoleList.splice(indexOfUPSIAll, 1)
            }
          }
          menu.ChildResources.forEach(childElement => {
            let iconElement = document.getElementById(childElement.Url);
            console.log('iconElement', iconElement)
            iconElement.classList.remove("icon-unselected");
            iconElement.classList.add("icon-selected");
            iconElement.classList.remove("rolemaster-icon-hide");
            iconElement.classList.add("rolemaster-icon-display");
            this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': childElement.Url });

            if (this.addedRoles.findIndex(addedRole => addedRole.MenuDisplayName == MenuDisplayName) < 0) {
              this.addedRoles.push({ 'MenuDisplayName': MenuDisplayName, 'id': childElement.Url });

            }
          })
        } else if (this.isUPSIConfig && document.getElementById('UPSI Detail Individual').getAttribute('val') == "true" && MenuDisplayName == "UPSI Detail All") {
          document.getElementById('UPSI Detail Individual').removeAttribute('val')
          document.getElementById("UPSI Detail All").setAttribute('val', "true")
          let uspiAll = <HTMLInputElement>document.getElementById('UPSI Detail Individual')
          uspiAll.checked = false
          let deSelectChildrenIcon = uspiAll.nextElementSibling.children
          for (let index = 0; index < deSelectChildrenIcon.length; index++) {
            deSelectChildrenIcon[index].classList.remove("icon-selected");
            deSelectChildrenIcon[index].classList.add("icon-unselected");
            deSelectChildrenIcon[index].classList.add("rolemaster-icon-hide");
            deSelectChildrenIcon[index].classList.remove("rolemaster-icon-display");
            console.log(deSelectChildrenIcon[index].id)
            let indexOfUPSIAll = _.findIndex(self.finalRoleList, function (element) { return _.get(element, 'childUrl') == deSelectChildrenIcon[index].id })
            if (indexOfUPSIAll != -1) {
              let indexOfaddRole = _.findIndex(self.addedRoles, function (element) { return _.get(element, 'MenuDisplayName') == 'UPSI Detail Individual' })
              if (indexOfaddRole != -1) {
                self.addedRoles.splice(indexOfaddRole, 1)
              }
              self.finalRoleList.splice(indexOfUPSIAll, 1)
            }

          }
          menu.ChildResources.forEach(childElement => {
            let iconElement = document.getElementById(childElement.Url);
            console.log('iconElement', iconElement)
            iconElement.classList.remove("icon-unselected");
            iconElement.classList.add("icon-selected");
            iconElement.classList.remove("rolemaster-icon-hide");
            iconElement.classList.add("rolemaster-icon-display");
            this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': childElement.Url });

            if (this.addedRoles.findIndex(addedRole => addedRole.MenuDisplayName == MenuDisplayName) < 0) {
              this.addedRoles.push({ 'MenuDisplayName': MenuDisplayName, 'id': childElement.Url });

            }
          })
        } else {
          document.getElementById(menu.MenuDisplayName).setAttribute('val', 'true')
          menu.ChildResources.forEach(childElement => {
            let iconElement = document.getElementById(childElement.Url);
            console.log('iconElement', iconElement)
            iconElement.classList.remove("icon-unselected");
            iconElement.classList.add("icon-selected");
            iconElement.classList.remove("rolemaster-icon-hide");
            iconElement.classList.add("rolemaster-icon-display");
            this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': childElement.Url });

            if (this.addedRoles.findIndex(addedRole => addedRole.MenuDisplayName == MenuDisplayName) < 0) {
              this.addedRoles.push({ 'MenuDisplayName': MenuDisplayName, 'id': childElement.Url });

            }
          })
        }

      }

    }


    if (_.size(this.finalRoleList)) {
      this.isfinalRoleListEmpty = false;
    } else {
      this.isfinalRoleListEmpty = true;
    }


    this.selectAllCheckBoxStatus(selectAllCheckboxId, menuArray);

  }

  selectAllCheckBoxStatus(selectAllCheckboxId, menuArray) {

    var totalIconSelected = 0;
    var totalIcons = 0

    var selectAllCheckboxHTMlElement = <HTMLInputElement>document.getElementById(selectAllCheckboxId);

    menuArray.forEach(menu => {

      if (menu.MenuDisplayName == undefined) {
        totalIcons++;
        if (this.finalRoleList.findIndex(role => role.childUrl == menu.Url) >= 0) {
          totalIconSelected++;
        }

      }
      else {
        menu.ChildResources.forEach(chlidElement => {
          totalIcons++;
          if (this.finalRoleList.findIndex(role => role.childUrl == chlidElement.Url) >= 0) {
            totalIconSelected++;
          }
        })
      }
    })


    // console.log("how much selected", totalIconSelected, totalIcons);

    if (totalIconSelected == 0) {
      selectAllCheckboxHTMlElement.indeterminate = false;
      selectAllCheckboxHTMlElement.checked = false;
    } else if (totalIconSelected > 0 && totalIconSelected < totalIcons) {
      selectAllCheckboxHTMlElement.indeterminate = true;
      selectAllCheckboxHTMlElement.checked = false;
    } else if (totalIconSelected > 0 && totalIconSelected == totalIcons) {
      selectAllCheckboxHTMlElement.indeterminate = false;
      selectAllCheckboxHTMlElement.checked = true;
    }



  }



  // Old CheckboxClicked Functionality Starts Here

  // checkboxClicked(menu , event ,MenuDisplayName){
  //   let inputHtmlElement = event.target ;
  //   console.log("inputHtmlElement",inputHtmlElement);
  //   console.log("checkboxClickSelected",menu);


  //   let self = this;
  //   let parentHtmlElement = inputHtmlElement.parentElement;
  //   let icontags = parentHtmlElement.getElementsByTagName("i");
  //   console.log("icontags",icontags);
  //   let IconSelected = 0;

  //         if (_.size(icontags)) {

  //           // console.log("_.size(self.finalRoleList)",_.size(self.finalRoleList));
  //           if (_.size(self.finalRoleList)) {
  //             for (var i = 0; i < _.size(icontags); i++) {
  //               if (icontags[i].classList.contains('icon-selected')) {
  //                 IconSelected++;
  //               }
  //             }
  //           }
  //         }

  //   console.log("IconSelected",IconSelected);



  //   //for admin and dashboard
  //   if(menu.ChildResources == undefined){

  //     if(IconSelected > 0){

  //       let iconElement = document.getElementById(menu.Url);

  //         console.log("class selected added")

  //         iconElement.classList.remove("icon-selected");
  //         iconElement.classList.add("icon-unselected");
  //         iconElement.classList.remove("rolemaster-icon-display");
  //         iconElement.classList.add("rolemaster-icon-hide");

  //           this.finalRoleList.splice(this.finalRoleList.findIndex(role => role.childUrl == menu.Url), 1);
  //           this.addedRoles.splice(this.addedRoles.findIndex(role => role.MenuDisplayName == MenuDisplayName), 1);


  //     }else if(IconSelected == 0){
  //       let iconElement = document.getElementById(menu.Url);

  //       iconElement.classList.remove("icon-unselected");
  //       iconElement.classList.add("icon-selected");
  //       iconElement.classList.remove("rolemaster-icon-hide");
  //       iconElement.classList.add("rolemaster-icon-display");



  //         this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': menu.Url });
  //         this.addedRoles.push({ 'MenuDisplayName': MenuDisplayName, 'id': menu.Url });
  //     }
  //     console.log("this.finalroleList", this.finalRoleList);
  //     console.log("for admin and dashboard");

  //   }//for rest of resources
  //   else if(menu.ChildResources ){

  //     console.log("apart from admin and dashboard");

  //     //here it will assign selected or unselected class for icons 

  //     if(IconSelected >0 && (IconSelected < _.size(menu.ChildResources))){
  //       menu.ChildResources.forEach(childElement => {
  //         let iconElement = document.getElementById(childElement.Url);
  //         console.log('iconElement',iconElement)

  //         const isIconElementPresent = _.find(this.finalRoleList , function(roleElement){
  //           return roleElement.childUrl == childElement.Url;
  //         })

  //         if(_.isNil(isIconElementPresent)){
  //           if(iconElement.classList.contains("icon-unselected")){
  //             iconElement.classList.remove("icon-unselected");
  //             iconElement.classList.add("icon-selected");
  //             iconElement.classList.add("rolemaster-icon-display");
  //             this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': childElement.Url });
  //           }
  //         }

  //       })
  //     }else if(IconSelected >0 && (IconSelected == _.size(menu.ChildResources))){
  //       var count = 0;
  //       menu.ChildResources.forEach(childElement => {
  //         let iconElement = document.getElementById(childElement.Url);
  //         console.log('iconElement--- ',iconElement)

  //         const isIconElementPresent = _.find(this.finalRoleList , function(roleElement){
  //           return roleElement.childUrl == childElement.Url;
  //         })

  //         iconElement.classList.remove("icon-selected");
  //         iconElement.classList.add("icon-unselected");
  //         iconElement.classList.remove("rolemaster-icon-display");
  //         iconElement.classList.add("rolemaster-icon-hide");
  //         this.finalRoleList.splice(this.finalRoleList.findIndex(role => role.childUrl == childElement.Url), 1);

  //         var b = _.filter(this.finalRoleList, function (role) {
  //           if (role.childUrl == childElement.Url) {
  //             return role
  //           }
  //         }).length;

  //         count = count + b;
  //       })

  //       if(count ==0){
  //         this.addedRoles.splice(this.addedRoles.findIndex(role => role.MenuDisplayName == MenuDisplayName), 1);
  //       }   

  //     }else if(IconSelected == 0){
  //       menu.ChildResources.forEach(childElement => {
  //         let iconElement = document.getElementById(childElement.Url);
  //         console.log('iconElement',iconElement)
  //       iconElement.classList.remove("icon-unselected");
  //       iconElement.classList.add("icon-selected");
  //       iconElement.classList.remove("rolemaster-icon-hide");
  //       iconElement.classList.add("rolemaster-icon-display");
  //       this.finalRoleList.push({ 'MenuDisplayName': MenuDisplayName, 'childUrl': childElement.Url });

  //       if (this.addedRoles.findIndex(addedRole => addedRole.MenuDisplayName == MenuDisplayName) < 0) {
  //         this.addedRoles.push({ 'MenuDisplayName': MenuDisplayName, 'id': childElement.Url });

  //       }

  //       })
  //     }
  //   }

  //   if (_.size(this.finalRoleList)) {
  //     this.isfinalRoleListEmpty = false;
  //   } else {
  //     this.isfinalRoleListEmpty = true;
  //   }

  // }






  // Old CheckboxClicked Functionality Ends Here
  //--------------------------------------------------------------------------------------------------------------------------


  // to remove added activites from the role list
  removeAddedRoleList(index, addedRole) {
    this.addedRoles.splice(index, 1);
    // console.log("this.addedroles", this.addedRoles);
    // console.log("roles", addedRole)
    // console.log("finalroleafter removing", this.finalRoleList);

    this.finalRoleList = _.filter(this.finalRoleList, function (roles) {
      if (addedRole.MenuDisplayName != roles.MenuDisplayName) {
        return roles
      }
    })



    var addedRoleHtmlElement = document.getElementById(addedRole.id).parentElement;
    // console.log("addedRoleHtmlElement", addedRoleHtmlElement);

    let iconElementsToUnselect = addedRoleHtmlElement.getElementsByTagName("i");
    var checkboxElement = <HTMLInputElement>document.getElementById(addedRole.MenuDisplayName);
    // console.log("iconElement",checkboxElement);
    checkboxElement.checked = false;
    checkboxElement.indeterminate = false;



    // console.log("iconElementsToUnselect", iconElementsToUnselect);
    if (_.size(iconElementsToUnselect)) {
      for (var i = 0; i < _.size(iconElementsToUnselect); i++) {
        iconElementsToUnselect[i].classList.remove("icon-selected");
        iconElementsToUnselect[i].classList.add("icon-unselected");
        iconElementsToUnselect[i].classList.remove("rolemaster-icon-display");
        iconElementsToUnselect[i].classList.add("rolemaster-icon-hide");
      }
    }

    if (_.size(this.finalRoleList)) {
      this.isfinalRoleListEmpty = false;
    } else {
      this.isfinalRoleListEmpty = true;
    }



    // console.log("iconElement ---->",checkboxElement.parentElement);
    let selectAllContainingDiv = checkboxElement.parentElement;

    let parentElement = selectAllContainingDiv.parentElement;
    let totalIconstags = parentElement.getElementsByTagName("i");
    var totalIconSelected = 0;
    // remove select all remaining iconstags 

    for (var j = 0; j < _.size(totalIconstags); j++) {
      // console.log("totalIconstags[j].classList.contains['icon-selected']",totalIconstags[j].classList.contains['icon-selected']);
      if (totalIconstags[j].classList.contains('icon-selected')) {
        totalIconSelected++;
      }
    }

    // console.log(parentElement.getElementsByTagName("i"));

    // console.log("totalIconSelected , _.size(totalIconstags)", totalIconSelected, _.size(totalIconstags));

    var DivElement = parentElement.getElementsByTagName("div")[0];
    var selectAllCheckboxHTMLElement = DivElement.getElementsByTagName("input")[0];

    if (totalIconSelected == 0) {
      selectAllCheckboxHTMLElement.indeterminate = false;
      selectAllCheckboxHTMLElement.checked = false;
      // console.log("0");
    } else if (totalIconSelected > 0 && totalIconSelected < _.size(totalIconstags)) {
      selectAllCheckboxHTMLElement.indeterminate = true;
      selectAllCheckboxHTMLElement.checked = false;
      // console.log("inde");

    } else if (totalIconSelected > 0 && totalIconSelected == _.size(totalIconstags)) {
      selectAllCheckboxHTMLElement.indeterminate = false;
      selectAllCheckboxHTMLElement.checked = true;
      // console.log("=");

    }

    // console.log("selectAllContainingDiv",selectAllContainingDiv.parentElement,DivElement,selectAllCheckboxHTMLElement);



    // // this will be nodelist
    // var selectAllCheckboxHTMLElement = selectAllContainingDiv.getElementsByTagName("input")[0]; 

    // console.log("selectAllCheckboxHTMLElement",selectAllCheckboxHTMLElement);
    // selectAllCheckboxHTMLElement.checked = false;





    // console.log("list after filtering", this.finalRoleList);



  }


  //----------------------------- Old removeAddedRoleList Starts Here  --------------------------------------------------
  // removeAddedRoleList(index, addedRole) {

  //     this.addedRoles.splice(index, 1);

  //     this.finalRoleList = _.filter(this.finalRoleList, function (roles) {
  //       if (addedRole.MenuDisplayName != roles.MenuDisplayName) {
  //         return roles
  //       }
  //     })
  //     var addedRoleHtmlElement = document.getElementById(addedRole.id).parentElement;

  //     let iconElementsToUnselect = addedRoleHtmlElement.getElementsByTagName("i");

  //     var checkboxElement = <HTMLInputElement>document.getElementById(addedRole.MenuDisplayName);
  //     console.log("iconElement",checkboxElement);
  //     checkboxElement.checked = false;
  //     checkboxElement.indeterminate = false;

  //     if (_.size(iconElementsToUnselect)) {
  //       for (var i = 0; i < _.size(iconElementsToUnselect); i++) {
  //         iconElementsToUnselect[i].classList.remove("icon-selected");
  //         iconElementsToUnselect[i].classList.add("icon-unselected");
  //         iconElementsToUnselect[i].classList.remove("rolemaster-icon-display");
  //         iconElementsToUnselect[i].classList.add("rolemaster-icon-hide");
  //       }
  //     }

  //     if (_.size(this.finalRoleList)) {
  //       this.isfinalRoleListEmpty = false;
  //     } else {
  //       this.isfinalRoleListEmpty = true;
  //     }

  // }
  //----------------------------- Old removeAddedRoleList Starts Here  --------------------------------------------------

  Submenu(event) {
    event.stopPropagation();
  }
  

  LeftMenuClick(subMenu: string) {

    if(this.isViewAllActive){
      this.viewAll();
    }

    switch(subMenu){
      case "Master" : {
        $('#masterMenu').addClass("show");
        break;
      }
      case "governanceMenu" : {
        $('#governanceMenu').addClass("show");
        break;
      }
      case "boardEvaluationMenu" : {
        $('#boardEvaluationMenu').addClass("show");
        break;
      }
      case "complianceMenu" : {
        $('#complianceMenu').addClass("show");
        break;
      }
      case "upsiMenu" : {
        $('#upsiMenu').addClass("show");
        break;
      }
      case "ncdMenu" : {
        $('#ncdMenu').addClass("show");
        break;
      }
      case "documentManagementMenu" : {
        $('#documentManagementMenu').addClass("show");
        break;
      }
      case "adminMenu" : {
        $('#adminMenu').addClass("show");
        break;
      }
      case "dashboardMenu" : {
        $('#dashboardMenu').addClass("show");
        break;
      }
      case "gemMenu" : {
        $('#gemMenu').addClass("show");
        break;
      }
      case "lawAndRegulationMenu" : {
        $('#lawAndRegulationMenu').addClass("show");
        break;
      }
      default : {
        break;
      }

      
    }

    $('.rolemaster-viewall').css('position', '');
    $('.rolemaster-viewall').css('float', '');
  }

  UpdateRole() {
    this.inputRoleName = this.validationInputRoleName.value;
    // console.log("inputRoleName",this.inputRoleName);
    // let finalRoleActivities = [
    //   "/register/depositorList/edit?goveva=true&email=",
    // "/register/depositorList/view?goveva=true&email=",
    // "/register/sh3List/edit?goveva=true&email=",
    // "/register/sh3List/view?goveva=true&email=",
    // "/register/sh2List/edit?goveva=true&email=",
    // "/register/sh2List/view?goveva=true&email=",
    // "/register/mgt1list/edit?goveva=true&email=",
    // "/register/mgt1list/view?goveva=true&email=",
    // "/register/mbp4List/edit?goveva=true&email=",
    // "/register/mbp4List/view?goveva=true&email=",
    // "/register/mastersh6/edit?goveva=true&email=",
    // "/register/mastersh6/view?goveva=true&email=",
    // "/register/mbp3List/edit?goveva=true&email=",
    // "/register/mbp3List/view?goveva=true&email=",
    // "/register/mbp2List/edit?goveva=true&email=",
    // "/register/mbp2List/view?goveva=true&email=",
    // "/register/chg7List/edit?goveva=true&email=",
    // "/register/chg7List/view?goveva=true&email=",
    // "/register/postallballotlist/edit?goveva=true&email=",
    // "/register/postallballotlist/view?goveva=true&email=",
    // "/register/commonSeal/edit?goveva=true&email=",
    // "/register/commonSeal/view?goveva=true&email=",
    // "/register/Ben3/edit?goveva=true&email=",
    // "/register/Ben3/view?goveva=true&email=",
    // "/register/ecslist/edit?goveva=true&email=",
    // "/register/ecslist/view?goveva=true&email=",
    // "/register/investment/edit?goveva=true&email=",
    // "/register/investment/view?goveva=true&email=",
    // "/register/masterbankparticulars/edit?goveva=true&email=",
    // "/register/masterbankparticulars/view?goveva=true&email=",
    // "/register/proxy/edit?goveva=true&email=",
    // "/register/proxy/view?goveva=true&email=",
    // "/register/Inspection/edit?goveva=true&email=",
    // "/register/Inspection/view?goveva=true&email=",
    // "/tasks/activated/edit?goveva=true&email=",
    // "/tasks/activated/view?goveva=true&email=",
    // "roadmap/view",
    // "roadmap/create",
    // "roadmap/view",
    // "/register/director/edit?goveva=true&email=",
    // "/register/director/view?goveva=true&email=",
    // "companyMaster/viewOnly",
    // "companyMaster/edit",
    // "companyMaster/addNewCompany",
    // "/api/v1/secure/boardEvaluationQuestion/getAllEvaluationQuestion",
    // "/api/v1/secure/boardEvaluationQuestion/deleteEvaluationQuestion",
    // "/api/v1/secure/boardEvaluationQuestion/editEvaluationQuestion",
    // "/api/v1/secure/boardEvaluationQuestion/addEvaluationQuestion",
    // "/compliance/view?goveva=true&email=",
    // "/compliance/delete?goveva=true&email=",
    // "/compliance/edit?goveva=true&email=",
    // "/compliance/add?goveva=true&email=",
    // "/api/v1/secure/GEMOther/addGEMOther",
    // "/register/KMP/edit?goveva=true&email=",
    // "/register/KMP/view?goveva=true&email=",
    // "/meeting/edit?goveva=true&email=",
    // "/meeting/view?goveva=true&email=",
    // "download",
    // "viewOnly",
    // "/tasks/library/edit?goveva=true&email=",
    // "/tasks/library/view?goveva=true&email=",
    // "/api/v1/secure/userMaster/authorize",
    // "/api/v1/user/register",
    // "/api/v1/secure/user/editUser",
    // "/api/v1/secure/userMaster/deactivate",
    // "/api/v1/secure/userMaster/getUserList",
    // "/api/v1/secure/GEMModerator/addGEMModerator",
    // "/documentType/edit?goveva=true&email=",
    // "/documentType/view?goveva=true&email=",
    // "/uspiRecipient/view",
    // "/uspiRecipient/delete",
    // "/uspiRecipient/edit",
    // "/uspiRecipient/add",
    // "/api/v1/secure/GEMCreateATRTask/addGEMCreateATRTask",
    // "/api/v1/secure/boardEvaluationReports/getSummaryReport",
    // "/api/v1/secure/boardEvaluationReports/getDetailedReport",
    // "/api/v1/secure/roleMaster/getUserRoles",
    // "/api/v1/secure/roleMaster/deleteRole",
    // "/api/v1/secure/roleMaster/editRole",
    // "/api/v1/secure/roleMaster/addRole",
    // "/api/v1/secure/boardEvaluationFormSetup/getAllEvaluationFormSetup",
    // "/api/v1/secure/boardEvaluationFormSetup/editEvaluationFormSetup",
    // "/api/v1/secure/boardEvaluationFormSetup/addEvaluationFormSetup",
    // "/api/v1/secure/boardEvaluationFormExecution/getAllEvaluationFormExecution",
    // "/api/v1/secure/boardEvaluationFormExecution/executeEvaluationFormExecution",
    // "/api/v1/secure/boardEvaluationType/getAllEvaluationType",
    // "/api/v1/secure/boardEvaluationType/deleteEvaluationType",
    // "/api/v1/secure/boardEvaluationType/editEvaluationType",
    // "/api/v1/secure/boardEvaluationType/addEvaluationType",
    // "/agendaGroup/edit?goveva=true&email=",
    // "/agendaGroup/view?goveva=true&email=",
    // "/api/v1/secure/admin/auditTrail",
    // "/api/v1/secure/boardEvaluationFormMonitoring/getAllEvaluationFormMonitoring",
    // "/api/v1/secure/boardEvaluationFormMonitoring/downloadAllEvaluationFormMonitoring",
    // "/api/v1/secure/boardEvaluationFormMonitoring/editEvaluationFormMonitoring",
    // "/category/edit?goveva=true&email=",
    // "/category/view?goveva=true&email=",
    // "/documentManagement/edit?goveva=true&email=",
    // "/documentManagement/view?goveva=true&email=",
    // "/register/Masterinvestorcomplaints/edit?goveva=true&email=",
    // "/register/Masterinvestorcomplaints/view?goveva=true&email=",
    // "meetings",
    // "organizationChart-ExecutiveManagement",
    // "organizationChartBoardMemeber",
    // "progressMeter",
    // "financialResults",
    // "myTasks",
    // "digitalAssisstanceMyTask",
    // "scoreMeter",
    // "keyRatios",
    // "companyNews",
    // "stockPrice",
    // "digitalAssisstanceTeamTask",
    // "chat",
    // "/api/v1/secure/boardEvaluationFormAssign/editAllEvaluationFormAssign",
    // "/register/dividendMandateList/edit?goveva=true&email=",
    // "/register/dividendMandateList/view?goveva=true&email=",
    // "/ncd-allottees-Library",
    // "/ncd-allottees-Library/delete",
    // "/ncd-allottees-Library/edit",
    // "/ncd-allottees-Library/add",
    // "/api/v1/secure/boardEvaluationBank/viewboardEvaluationBank",
    // "/api/v1/secure/boardEvaluationBank/deleteboardEvaluationBank",
    // "/api/v1/secure/boardEvaluationBank/editboardEvaluationBank",
    // "/defaultMeeting/edit?goveva=true&email=",
    // "/defaultMeeting/view?goveva=true&email=",
    // "reports/Governance",
    // "/ncdIssues/deleteIssue",
    // "/ncd-issues-List",
    // "/ncd-issue/edit",
    // "/ncd-issue/add",
    // "/api/v1/secure/boardEvaluationFormReview/getAllEvaluationFormReview",
    // "/api/v1/secure/boardEvaluationFormReview/addEvaluationFormReview",
    // "/upsiAdmin/all/view",
    // "/upsiAdmin/all/delete",
    // "/upsiAdmin/all/edit",
    // "/upsiAdmin/all/add",
    // "/uspiSharer/view",
    // "/uspiSharer/delete",
    // "/uspiSharer/edit",
    // "/uspiSharer/add",
    // "/register/mgt2List/edit?goveva=true&email=",
    // "/register/mgt2List/view?goveva=true&email=",
    // "/register/paymentOfDividend/edit?goveva=true&email=",
    // "/register/paymentOfDividend/view?goveva=true&email=",
    // "/committee/edit?goveva=true&email=",
    // "/committee/view?goveva=true&email=",
    // "roadmap/approve",
    // "/ncd-Library/edit",
    // "/ncd-Library",
    // "/Templates/edit?goveva=true&email=",
    // "/Templates/view?goveva=true&email=",
    // "icReview",
    // "ICView Only",
    // "icExecution",
    // "ICEdit",
    // "excute",
    // "review",
    // "/group/edit?goveva=true&email=",
    // "/group/view?goveva=true&email=",
    // "/holiday/edit?goveva=true&email=",
    // "/holiday/view?goveva=true&email=",
    // "/register/powerofattorney/edit?goveva=true&email=",
    // "/register/powerofattorney/view?goveva=true&email=",
    // "/api/v1/secure/boardEvaluationCriteria/getAllEvaluationCriteria",
    // "/api/v1/secure/boardEvaluationCriteria/editEvaluationCriteria",
    // "/api/v1/secure/boardEvaluationCriteria/deleteEvaluationCriteria",
    // "/api/v1/secure/boardEvaluationCriteria/addEvaluationCriteria",
    // "/agenda/edit?goveva=true&email=",
    // "/agenda/view?goveva=true&email=",
    // "/process/edit?goveva=true&email=",
    // "/process/view?goveva=true&email=",
    // "/process/activatedTasks/edit?goveva=true&email=",
    // "/process/activatedTasks/view?goveva=true&email=",
    // "/register/mastersh10list/edit?goveva=true&email=",
    // "/register/mastersh10list/view?goveva=true&email=",
    // "/emailReminderTemplates/edit?goveva=true&email=",
    // "/emailReminderTemplates/view?goveva=true&email="
    // ];
    var finalRoleActivities = [];
    this.finalRoleList.forEach(roleList => {
      finalRoleActivities.push(roleList.childUrl);
    });

    this.roleMasterService.editRole(this.roleObjectToEdit._id, this.inputRoleName, finalRoleActivities)
      .then(response => {
        if (response.status == 0) {
          console.log("successfully updated role");
          this.activeModal.close(response);
        } else {
          console.log("role not updated");
          this.activeModal.close(response);
        }
      }, error => console.log("error while adding roles"))
  }


}
