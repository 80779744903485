<section class="card">
  <div
    *ngIf="showSpinner"
    class="modal-backdrop fade in"
    style="background-color: transparent"
  ></div>
  <div class="card-header">
    <span class="cat__core__title">
      <strong>Questionnaire Bank</strong>
    </span>
  </div>
  <div *ngIf="showSpinner" class="center-all">
    <i
      class="fa fa-spinner fa-spin"
      style="font-size: 35px; color: #07a7a5"
    ></i>
  </div>
  <div class="card-block watermark">
    <div class="row">
      <div class="col-lg-12">
        <button type="button" class="btn btn-primary btn-sm mb-3 float-right" data-toggle="modal" data-target="#myModal" (click)="navigateToFormSetup()"
          [appHasPermission]="{ 'Url': '/api/v1/secure/boardEvaluationFormSetup/addEvaluationFormSetup', 'method':'post', 'menu': 'EvaluationFormSetup' }">
          Questionnaire Setup
        </button>

        <!-- table Layout -->
        <div class="table-responsive table-bordered margin-bottom-50">
          <table
            datatable
            [dtOptions]="dtQuestionnaireOptions"
            id="questionnaireTable"
            [dtTrigger]="dtQuestionnaireTrigger"
            class="table table-hover compact cell-border nowrap table-striped dataTable"
            style="background: transparent"
            width="100%"
          >
            <thead>
              <tr>
                <th style="width: 5%">Sr. No.</th>
                <th style="width: 40%">Questionnaire Name</th>
                <th
                  style="width: 5%"
                  [appHasPermission]="{
                    Url: '/api/v1/secure/boardEvaluationBank/editboardEvaluationBank',
                    method: 'put',
                    menu: 'EvaluationType'
                  }"
                >
                  Edit
                </th>
                <th
                  style="width: 10%"
                  [appHasPermission]="{
                    Url: '/api/v1/secure/boardEvaluationBank/editboardEvaluationBank',
                    method: 'put',
                    menu: 'EvaluationType'
                  }"
                >
                  Send for Review To
                </th>
                <th style="width: 10%">Review End Date</th>
                <th
                  style="width: 10%"
                  [appHasPermission]="{
                    Url: '/api/v1/secure/boardEvaluationBank/viewboardEvaluationBank',
                    method: 'get',
                    menu: 'EvaluationType'
                  }"
                >
                  Reviewer Approval Status
                </th>
                <th style="width: 10%">Action</th>
                <th style="width: 10%">Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let evaluationFormData of EvaluationFormListData;
                  let count = index
                "
              >
                <td style="text-align: center; width: 5%">{{ count + 1 }}</td>
                <td style="width: 40%; white-space: normal">
                  {{ evaluationFormData.EvaluationFormTitle }}
                </td>
                <td
                  [appHasPermission]="{
                    Url: '/api/v1/secure/boardEvaluationBank/editboardEvaluationBank',
                    method: 'put',
                    menu: 'EvaluationType'
                  }"
                  style="
                    width: 5%;
                    text-align: center;
                    color: #74708d;
                    cursor: pointer;
                  "
                >
                  <div
                    class="fa fa-pencil"
                    (click)="editEvaluationForm(evaluationFormData._id)"
                  ></div>
                </td>
                <td
                  [appHasPermission]="{
                    Url: '/api/v1/secure/boardEvaluationBank/editboardEvaluationBank',
                    method: 'put',
                    menu: 'EvaluationType'
                  }"
                  style="width: 10%; text-align: center"
                >
                  <button
                    style="width: 30%; cursor: pointer"
                    class="btn btn-sm btn-primary"
                    (click)="
                      selectUser(
                        evaluationFormData._id,
                        evaluationFormData.EvaluationCriterias
                      )
                    "
                  >
                    Send
                  </button>
                </td>
                <td style="width: 10%">
                  <div class="form-group" style="width: 165px">
                    <div class="input-group">
                      <div>
                        {{
                          evaluationFormData.EndDate
                            ? (evaluationFormData.EndDate | date)
                            : ' '
                        }}
                      </div>
                      <button
                        class="input-group-addon"
                        type="button"
                        data-toggle="modal"
                        data-target="editEvaluationFormEndDate"
                        (click)="editFormEndDate(evaluationFormData)"
                      >
                        <span
                          class="fa fa-calendar"
                          style="width: 1.2rem; height: 1rem; cursor: pointer"
                        ></span>
                      </button>
                    </div>
                  </div>
                </td>
                <td [appHasPermission]="{ 'Url': '/api/v1/secure/boardEvaluationBank/viewboardEvaluationBank', 'method':'get', 'menu': 'EvaluationType' }" style="width:10%;text-align:center;">
                  <button style="cursor: pointer;" class="btn btn-sm btn-primary" (click)="reviewPopupDetails(evaluationFormData)">View</button>
                </td>
                <td style="width: 10%; text-align: center">
                  <button
                    style="cursor: pointer"
                    class="btn btn-sm btn-primary"
                    (click)="assignForm(evaluationFormData._id)"
                  >
                    {{ evaluationFormData.isAssigned ? 'Assigned' : 'Assign' }}
                  </button>
                </td>
                <td
                  [appHasPermission]="{
                    Url: '/api/v1/secure/boardEvaluationBank/deleteboardEvaluationBank',
                    method: 'delete',
                    menu: 'EvaluationType'
                  }"
                  style="
                    width: 5%;
                    text-align: center;
                    color: #74708d;
                    cursor: pointer;
                  "
                >
                  <span
                    class="fa fa-trash-o"
                    (click)="
                      confirmEvaluationDeletionPopup(evaluationFormData._id)
                    "
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div
      class="modal fade"
      id="editEvaluationFormEndDate"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editEvaluationFormEndDate"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Edit end date</h4>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <label class="col-lg-6">End Date</label>
              <div
                class="row col-lg-6"
                (click)="$event.stopPropagation()"
                (document:click)="m.close()"
              >
                <input
                  class="form-control col-lg-10"
                  [attr.readonly]="true"
                  [(ngModel)]="evaluationFormEndDate"
                  style="padding: 6px; z-index: 0"
                  placeholder="dd-mm-yyyy"
                  ngbDatepicker
                  #m="ngbDatepicker"
                  [ngModelOptions]="{ standalone: true }"
                  name="endDate"
                />
                <button
                  class="input-group-addon col-lg-2"
                  (click)="m.toggle()"
                  type="button"
                >
                  <span
                    class="fa fa-calendar"
                    style="width: 1.2rem; height: 1rem; cursor: pointer"
                  ></span>
                </button>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm btn-primary"
              data-dismiss="modal"
              (click)="updateEndDate()"
            >
              Update
            </button>
            <button
              type="button"
              class="btn btn-default btn-sm"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- approve status details -->
  <div
    class="modal fade"
    id="approveDialogBox"
    role="dialog"
    aria-labelledby="approveDialogBox"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="approveDialogBox">
            <i class="mr-2"></i>Reviewer Details
          </h5>
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <div class="table-responsive table-bordered margin-bottom-50">
                <table
                  datatable
                  [dtOptions]="dtOptions"
                  id="approveTable"
                  [dtTrigger]="approvedtTrigger"
                  class="table table-hover compact cell-border nowrap table-striped dataTable"
                  style="background: transparent"
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th style="width: 5%">Sr. No.</th>
                      <th style="width: 65%">Reviewer</th>
                      <th style="width: 10%">View</th>
                      <th style="width: 20%">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style="text-align: center; width: 5%">1</td>
                      <td style="width: 65%">first</td>
                      <td style="width: 10%; text-align: center">
                        <span class="fa fa-eye"></span>
                      </td>
                      <td style="width: 20%">completed</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
  </div>

  <!--confirm delete dialogbox-->
  <div
    class="modal fade"
    id="deletionConfimMessageModel"
    role="dialog"
    aria-labelledby="deletionConfimMessageModelLabel"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deletionConfimMessageModelLabel">
            <i class="fa fa-question-circle-o mr-2"></i>Confirm
          </h5>
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Are you sure you want to delete ?</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary btn-sm"
            (click)="deleteQuestionnaire()"
          >
            Confirm
          </button>
          <button
            type="button"
            class="btn btn-default btn-sm"
            data-dismiss="modal"
            (click)="closeForm('deletionConfimMessageModel')"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- delete entity dialogbox-->
  <div
    class="modal fade"
    id="errorMessageModal"
    style="top: 50px; display: none"
    tabindex="-1"
    role="dialog"
    aria-labelledby="errorMessageModal"
  >
    <div class="modal-dialog" style="height: 70%" role="document">
      <div class="modal-content">
        <div
          class="modal-header"
          style="font-size: 20px; font-weight: bold; padding: 5px 15px"
        >
          <span *ngIf="headerSuccessFlag" style="color: rgb(0, 0, 0)">
            <h4>
              <b>{{ headerSuccessMessage }}</b>
            </h4>
          </span>
          <span *ngIf="headerErrorFlag" style="color: rgb(255, 0, 0)">
            <h4>
              <b>{{ headerErrorMessage }}</b>
            </h4>
          </span>
        </div>
        <div class="modal-body" style="padding: 10px 15px">
          <span
            *ngIf="headerSuccessFlag"
            style="font-size: 16px; color: rgb(0, 0, 0)"
            >{{ errorMessage }}</span
          >
          <span
            *ngIf="headerErrorFlag"
            style="font-size: 16px; color: rgb(255, 0, 0)"
            >{{ errorMessage }}</span
          >
        </div>
      </div>
    </div>
  </div>

  <!--save review dialog-->
  <div
    class="modal fade"
    id="saveReviewDialogBox"
    role="dialog"
    aria-labelledby="saveReviewDialogBox"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="saveReviewDialogBox">
            <i class="mr-2"></i>Send For Review To
          </h5>
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <ng-select
            [items]="userListToBind"
            (add)="userSelected($event)"
            placeholder="Select reviewers"
            [ngModel]="selectedUserToSend"
            (remove)="multipleUserDeselection($event)"
            [multiple]="true"
            [clearable]="false"
          ></ng-select>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary btn-sm"
            [disabled]="!enableSaveButton"
            (click)="sendForReview()"
          >
            Send
          </button>
          <button
            type="button"
            class="btn btn-default btn-sm"
            data-dismiss="modal"
            (click)="closeForm('saveReviewDialogBox')"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
