<section>
    <div class="modal-header">
      <h4 class="modal-title" *ngIf="Message !== 'userDefault'">Confirmation</h4>
      <h4 class="modal-title" *ngIf="Message == 'userDefault'">Materiality assessment</h4>
    </div>
  
    <div *ngIf="Message !== 'userDefault'" class="modal-body" style="height:120px">
      {{Message}}
    </div>
    <div *ngIf="Message == 'userDefault'" class="modal-body" style="height:100%">
      A materiality assessment is designed to help you identify and understand the relative importance of specific ESG and
      Sustainability topics to your organization. This involves looking at a variety of factors through two particular
      lenses: Potential impact on your Business and Importance to Stakeholders. <br>
      <b>1)Impact on Business</b> – Highlights the importance of the issue to your organization regarding the expected
      influence
      this issue will have on the organization’s success.
      <br>
      E.g., Carbon emissions leading to global warming and rising sea levels can potentially impact port operations and
      can have a direct impact on a shipping company’s business.
      <br>
      <b>2)Impact on Stakeholders</b> – Prioritizes material topics based on their importance to external stakeholders and
      the
      social, economic, and environmental impact of each topic in the value chain. And the likely influence they might
      have, as a result of the efforts on this issue on the business success.
      <br>
      E.g., Carbon emissions and other discharges affect the quality of water and marine life. Increasingly corporations
      based out of Europe and other jurisdictions tend not to do business with companies who don’t work on mitigating
      these impacts.
      <br>
      You will now be able to take the Materiality Assessment where the assessment will be from two lens: Stakeholder lens
      and Company lens, for Environment, Social and Governance respectively.
    </div>
    <div *ngIf="Message == 'userDefault'" class="modal-footer">
      <button class="btn btn-sm btn-gray" (click)="onClick('close')">Cancel</button>
      <button class="btn btn-sm btn-primary" (click)="onClick('ok')">Start</button>
    </div>
  </section>