import { Component, OnInit } from '@angular/core';
import { ForgotPasswordService } from './../../services/forgot-password.service';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotificationComponent } from './../notification/notification.component';
import * as _ from 'lodash';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-submit-otp',
  templateUrl: './submit-otp.component.html',
  styleUrls: ['./submit-otp.component.css'],
})
export class SubmitOtpComponent implements OnInit {
  public otpCode: string;
  verifyOTPForm = new FormGroup({
    otpCode: new FormControl('', [Validators.required]),
  });
  constructor(
    private router: Router,
    private forgotPasswordService: ForgotPasswordService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    $('body').removeClass('dashboard-page');
    $('.cat__menu-left').hide();
    const userCredential = this.forgotPasswordService.getUserCredential();
    if (!userCredential.length) {
      this.router.navigate(['requestPassword']);
    }
    var token = localStorage.getItem('token');
    if (_.isNil(token)) {
      // this.getCompanies();
      this.hideTopBar();
    } else {
      //if already login
      this.showTopBar();
      // this.router.navigate(["dashboards/alpha"]);
      this.router.navigate([
        'structure',
        { outlets: { 'structure-outlet': 'dashboards/alpha' } },
      ]);
    }
  }

  hideTopBar() {
    $('.cat__top-bar').hide();
    $('.cat__menu-left').hide(); //hide the sidebar
    $('.cat__top-bar__right').hide(); //hide the logout icon
    $('.cat__footer').hide();
  }

  showTopBar() {
    $('.cat__menu-left').show();
    $('.cat__top-bar__right').show();
    $('.cat__top-bar').show();
    $('.cat__footer').show();
  }

  onlyNumber(ev) {
    var keyCode = window.event ? ev.keyCode : ev.which;
    //codes for 0-9
    if (
      (keyCode > 47 && keyCode < 58) ||
      (keyCode > 95 && keyCode < 106) ||
      keyCode == 8
    ) {
      // if (((keyCode > 47 && keyCode < 58) || (keyCode > 95 && keyCode < 106))) {
      // ev.preventDefault();
      // }
    } else {
      if (keyCode != 0 && keyCode != 8 && keyCode != 13 && !ev.ctrlKey) {
        ev.preventDefault();
      } else {
        ev.preventDefault();
      }
    }
  }

  otpCodeChange(event) {
    // @ts-ignore
    return (event.target ? (event.target as HTMLInputElement) : event).value;
  }

  submitOTP() {
    const self = this;
    const userCredential = this.forgotPasswordService.getUserCredential();
    const emailId = localStorage.getItem('emailId');
    const submitObj = {
      userToken: userCredential[0].userToken,
      SecretCode: self.otpCode,
    };
    this.forgotPasswordService.submitOTP(submitObj).then((response) => {
      if (!response.status) {
        // navigate to the next page.
        this.forgotPasswordService.clearUserCredential();
        this.forgotPasswordService.setUserCredential(response);
        this.router.navigate(['resetPassword']);
        // const modalRef = this.modalService.open(NotificationComponent);
        // modalRef.componentInstance.notify = {
        //   status: 'Success',
        //   message: 'OTP is confirmed!',
        // };
      } else {
        // show the error message
        const modalRef = this.modalService.open(NotificationComponent);
        modalRef.componentInstance.notify = {
          status: 'Failure',
          message: response.message,
        };
        if (response.blockOTP === true) {
          this.router.navigate(['login']);
        }
      }
    });
  }

  resendOTP() {
    const emailId = localStorage.getItem('emailId');
    const companyId = localStorage.getItem('companyId');
    const forgotPasswordObject = {
      EmailId: emailId,
      CompanyId: companyId, // check for the company ID
    };

    this.forgotPasswordService
      .wrongOTPAttemptCheck(forgotPasswordObject)
      .then((result) => {
        if (!result.status) {
          if (result.SecretCodeAttempt >= 5) {
            const modalRef = this.modalService.open(NotificationComponent);
            modalRef.componentInstance.notify = {
              status: 'Failure',
              message:
                'You have entered the wrong OTP five times.It will unblock after 15 minutes. Old OTP will not work after 15 minutes, So Generate new OTP after 15 Minutes.',
            };
            this.router.navigate(['login']);
          } else {
            this.forgotPasswordService
              .submitEmail(forgotPasswordObject)
              .then((response) => {
                console.log('response =========>  : ', response);
                if (!response.status) {
                  this.forgotPasswordService.clearUserCredential();
                  this.forgotPasswordService.setUserCredential(response);
                  this.router.navigate(['SubmitOTP']);
                  const modalRef = this.modalService.open(
                    NotificationComponent
                  );
                  modalRef.componentInstance.notify = {
                    status: 'Success',
                    message: response.message,
                  };
                } else {
                  // show error message to the user
                  const modalRef = this.modalService.open(
                    NotificationComponent
                  );
                  modalRef.componentInstance.notify = {
                    status: 'Failure',
                    message: response.message,
                  };
                }
              });
          }
        } else {
          // show error message to the user
          const modalRef = this.modalService.open(NotificationComponent);
          modalRef.componentInstance.notify = {
            status: 'Failure',
            message: result.message,
          };
        }
      });
  }
}
