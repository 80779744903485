<section class="card">
  <div class="card-header">
    <span class="cat__core__title">
      <strong>Criteria</strong>
    </span>
  </div>

  <div class="card-block watermark">
    <div class="row">
      <div class="col-lg-12">
        <button type="button" class="btn btn-primary btn-sm mb-3" data-toggle="modal" data-target="#myModal" (click)="resetFormValues()"
          [appHasPermission]="{ 'Url': '/api/v1/secure/boardEvaluationCriteria/addEvaluationCriteria', 'method':'post', 'menu': 'EvaluationCriteria' }">
          Add Criteria
        </button>

        <!-- table Layout -->
        <div class="table-responsive table-bordered margin-bottom-50">
          <table
            datatable
            [dtOptions]="dtOptions"
            id="evaluationMasterTable"
            [dtTrigger]="dtTrigger"
            class="table table-hover compact cell-border nowrap table-striped dataTable"
            style="background: transparent"
            width="100%"
          >
            <thead>
              <tr>
                <th style="width:5%">Sr. No.</th>
                <th style="width:50%">Criteria</th>
                <th style="width:5%" [appHasPermission]="{ 'Url': '/api/v1/secure/boardEvaluationCriteria/editEvaluationCriteria', 'method':'put', 'menu': 'EvaluationCriteria' }">Edit</th>
                <th style="width:5%" [appHasPermission]="{ 'Url': '/api/v1/secure/boardEvaluationCriteria/deleteEvaluationCriteria', 'method':'delete', 'menu': 'EvaluationCriteria' }">Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let evalCategoryTypeDatas of evalCategoryTypeData;let count=index">
                <td style="text-align:center;width:5%">{{count + 1}}</td>
                <td style="width: 50%">{{evalCategoryTypeDatas.EvaluationCriteria}}</td>
                <td
                  style="
                    width: 5%;
                    text-align: center;
                    color: #74708d;
                    cursor: pointer;
                  "
                  [appHasPermission]="{
                    Url: '/api/v1/secure/boardEvaluationCriteria/editEvaluationCriteria',
                    method: 'put',
                    menu: 'EvaluationCriteria'
                  }"
                >
                  <div
                    class="fa fa-pencil"
                    (click)="editEntity(evalCategoryTypeDatas)"
                  ></div>
                </td>
                <td
                  style="
                    width: 5%;
                    text-align: center;
                    color: #74708d;
                    cursor: pointer;
                  "
                  [appHasPermission]="{
                    Url: '/api/v1/secure/boardEvaluationCriteria/deleteEvaluationCriteria',
                    method: 'delete',
                    menu: 'EvaluationCriteria'
                  }"
                >
                  <div
                    class="fa fa-trash-o"
                    (click)="confirmdeleteEntity(evalCategoryTypeDatas)"
                  ></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Modal for adding new Role-->
        <div
          class="modal fade"
          id="myModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby=""
        >
          <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
              <div
                class="modal-header"
                style="justify-content: unset !important"
              >
                <h4 class="modal-title" id="myModalLabel">Add Criteria</h4>
                <button
                  type="button"
                  class="close"
                  style="right: 5px; position: absolute"
                  data-dismiss="modal"
                  (click)="closeForm('myModal')"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div class="modal-body pt-3 pb-3">
                <form [formGroup]="roleForm" novalidate>
                  <div class="form-group d-inline-flex col-lg-12 p-0 " style="height:30px;margin-bottom:0">
                    <label class="label col-lg-4 p-0 text-left">
                      <span style="vertical-align: -webkit-baseline-middle"
                        >Define Criteria
                        <span class="text-danger">*</span>
                      </span>
                    </label>
                    <div class="col-lg-8 p-0">
                      <input
                        class="form-control"
                        type="text"
                        placeholder="Define Criteria"
                        [formControl]="roleForm.controls['defineCategory']"
                        name="defineCategory"
                        [value]="defineCategory"
                        (keyup)="defineCriteriaChangeEvent($event)"
                        (change)="defineCriteriaChangeEvent($event)"
                      />
                      <div
                        *ngIf="
                          roleForm.controls['defineCategory'].hasError(
                            'required'
                          ) && roleForm.controls['defineCategory'].touched
                        "
                        class="form-control-error-custom p-0 mb-4"
                      >
                        Criteria is required.
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div class="modal-footer">
                <div style="width: 100%; text-align: left">
                  <span style="color: #ff0000">*</span> Indicates Mandatory
                  field
                </div>
                <button
                  type="button"
                  class="btn btn-primary btn-sm"
                  [disabled]="!roleForm.valid || disableSavebutton"
                  (click)="submitEvaluation()"
                >
                  Save
                </button>
                <button
                  type="button"
                  class="btn btn-default btn-sm"
                  style="background-color: #ffffff; color: #acb7bf !important"
                  data-dismiss="modal"
                  (click)="closeForm('myModal')"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="messagePopUp"
          tabindex="-1"
          role="dialog"
          aria-labelledby=""
          aria-hidden="true"
          style="top: 50px"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div
                id="alertTitle"
                class="modal-header"
                style="font-size: 20px; font-weight: bold; padding: 5px 15px"
              ></div>
              <div class="modal-body" style="padding: 10px 15px">
                <div id="alertMessage" style="font-size: 16px"></div>
              </div>
            </div>
          </div>
        </div>

        <!--updating existing category-->
        <div
          class="modal fade"
          id="editingEvaluationModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="editingEvaluationModal"
        >
          <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
              <div
                class="modal-header"
                style="justify-content: unset !important"
              >
                <h4 class="modal-title" id="editingEvaluationModal">
                  Edit Criteria
                </h4>
                <button
                  type="button"
                  class="close"
                  style="right: 5px; position: absolute"
                  data-dismiss="modal"
                  (click)="closeForm('editingEvaluationModal')"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div class="modal-body pt-3 pb-3">
                <form [formGroup]="roleForm" novalidate>
                  <div class="form-group d-inline-flex col-lg-12 p-0 " style="height:30px;margin-bottom:0">
                    <label class="label col-lg-4 p-0 text-left">
                      <span style="vertical-align: -webkit-baseline-middle"
                        >Define Criteria
                        <span class="text-danger">*</span>
                      </span>
                    </label>
                    <div class="col-lg-8 p-0">
                      <input
                        class="form-control"
                        type="text"
                        [formControl]="roleForm.controls['defineCategory']"
                        name="defineCategory"
                        [value]="defineCategory"
                        (keyup)="defineCriteriaChangeEvent($event)"
                        (change)="defineCriteriaChangeEvent($event)"
                      />
                      <div
                        *ngIf="
                          roleForm.controls['defineCategory'].hasError(
                            'required'
                          ) && roleForm.controls['defineCategory'].touched
                        "
                        class="form-control-error-custom p-0 mb-4"
                      >
                        Define Criteria is required.
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div class="modal-footer">
                <div style="width: 100%; text-align: left">
                  <span style="color: #ff0000">*</span> Indicates Mandatory
                  field
                </div>
                <button
                  type="button"
                  class="btn btn-primary btn-sm"
                  [disabled]="!roleForm.valid || disableupdatebutton"
                  (click)="updateEvaluation()"
                >
                  Update
                </button>
                <button
                  type="button"
                  class="btn btn-default btn-sm"
                  style="background-color: #ffffff; color: #acb7bf !important"
                  data-dismiss="modal"
                  (click)="closeForm('editingEvaluationModal')"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        <!--confirm delete entity dialogbox-->
        <div
          class="modal fade"
          id="roleDeletionMessageModal"
          role="dialog"
          aria-labelledby="roleDeletionMessageModalLabel"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="roleDeletionMessageModalLabel">
                  <i class="fa fa-question-circle-o mr-2"></i>Confirm
                </h5>
                <button
                  aria-label="Close"
                  class="close"
                  data-dismiss="modal"
                  type="button"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Are you sure you want to delete this criteria?</p>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary btn-sm"
                  (click)="deleteEvaluation()"
                >
                  Confirm
                </button>
                <button
                  type="button"
                  class="btn btn-default btn-sm"
                  data-dismiss="modal"
                  (click)="closeForm('roleDeletionMessageModal')"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- delete entity dialogbox-->
        <div
          class="modal fade"
          id="errorMessageModal"
          style="top: 50px; display: none"
          tabindex="-1"
          role="dialog"
          aria-labelledby="errorMessageModal"
        >
          <div class="modal-dialog" style="height: 70%" role="document">
            <div class="modal-content">
              <div
                class="modal-header"
                style="font-size: 20px; font-weight: bold; padding: 5px 15px"
              >
                <span *ngIf="headerSuccessFlag" style="color: rgb(0, 0, 0)">
                  <h4>
                    <b>{{ headerSuccessMessage }}</b>
                  </h4>
                </span>
                <span *ngIf="headerErrorFlag" style="color: rgb(255, 0, 0)">
                  <h4>
                    <b>{{ headerErrorMessage }}</b>
                  </h4>
                </span>
              </div>
              <div class="modal-body" style="padding: 10px 15px;">
                <span *ngIf="headerSuccessFlag" style="font-size: 16px;color: rgb(0, 0, 0);">{{errorMessage}}</span>
                <span *ngIf="headerErrorFlag" style="font-size: 16px; color: rgb(255, 0, 0);">{{errorMessage}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
