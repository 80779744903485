<section class="card">
  <div class="card-header">
    <span class="cat__core__title">
      <strong>Summary Report</strong>
    </span>
  </div>
  <div
    *ngIf="showSpinner"
    class="modal-backdrop fade in"
    style="background-color: transparent"
  ></div>
  <div *ngIf="showSpinner" class="center-all">
    <i
      class="fa fa-spinner fa-spin"
      style="font-size: 35px; color: #07a7a5"
    ></i>
  </div>
  <div class="card-block watermark">
    <div class="row">
      <div class="row form-group col-lg-7">
        <label class="col-lg-3">
          <b>Select Period</b>
        </label>
        <ng-select
          class="form-group col-lg-4"
          [items]="periodParameterListToBind"
          (change)="periodParameterSelection($event)"
          [clearable]="false"
        >
        </ng-select>
      </div>
      <div class="col-lg-5">
        <button
          class="btn btn-sm btn-primary"
          [disabled]="!viewReportButtonEnable"
          (click)="viewSummaryReport()"
        >
          View Report
        </button>
      </div>
    </div>

    <hr />

    <div class="row" *ngIf="reportShowEnable">

      <div class="row col-lg-12">
        <h5 style="margin: auto">
          <b>Board Evaluation Overall Summary </b>
        </h5>
      </div>

      <!-- Report Details -->

      <div class="row col-lg-12">
        <div class="table-responsive table-bordered margin-bottom-50">
          <table
            class="table table-hover compact cell-border nowrap table-striped dataTable"
            style="background: transparent"
          >
            <thead>
              <tr>
                <th style="width: 5%">Sr. No.</th>
                <th style="width: 20%">Entity</th>
                <th style="width: 20%">Name</th>
                <th style="width: 20%">Score</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let entityDetails of entityScoreArray;
                  let count = index
                "
              >
                <td>{{ count + 1 }}</td>
                <td style="white-space: normal">
                  {{ entityDetails.EvaluationTypeID?.EvaluationType }}
                </td>
                <td></td>
                <td>{{ entityDetails.totalAverageScoreElement }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row" style="width:100%;margin-top:2%">

          <div id="overallSummaryBarChart" class="m-auto" width="50%" style="cursor: pointer;">
            <chart [options]="barChartOptions"></chart>
          </div>
      </div>

      <div>

      </div>

      <div></div>
    </div>
  </div>
</section>
