import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuditTrailService {

  constructor(private http: HttpClient) { }

  getCompanyNameList(): Promise<any> {
    const url = environment.clientUrl + 'auditTrail/companyNames';
    return this.http.get(url).toPromise()
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(this.handleError)
  }

  takeDatabaseBackup(serverDetails): Promise<any> {
    const url= environment.clientUrl + 'backup/govevaDatabase';
    return this.http.post(url, serverDetails).toPromise()
      .then(response => {
        return Promise.resolve(response)
    })
    .catch(this.handleError)
  }
  
  getauditTrailLogs(): Promise<any> {
    const url= environment.clientUrl + 'auditTrail/logs';
    return this.http.get(url).toPromise()
      .then(response => {
        return Promise.resolve(response)
    })
    .catch(this.handleError)
  }
  
  getFilterLogs(data): Promise<any> {
    const url= environment.clientUrl + 'auditTrail/filterLogs';
    return this.http.post(url, data).toPromise()
      .then(response => {
        return Promise.resolve(response)
    })
    .catch(this.handleError)
  }


  getFilterLogsAll(data): Promise<any> {
    const url= environment.clientUrl + 'auditTrail/filterLogsAll';
    return this.http.post(url, data).toPromise()
      .then(response => {
        return Promise.resolve(response)
    })
    .catch(this.handleError)
  }

  getDatabaseBackupLocation(): Promise<any> {
    const url= environment.clientUrl + 'backup/getDBLocation';
    return this.http.get(url).toPromise()
      .then(response => {
        return Promise.resolve(response)
    })
    .catch(this.handleError)
  }


  // autobackup scheduler call
  setAutoBackupScheduler(data):Promise<any> {
    const url= environment.clientUrl + 'autobackupScheduler/set';
    return this.http.post(url, data).toPromise()
      .then(response => {
        return Promise.resolve(response)
    })
    .catch(this.handleError)
  }

  private handleError(error: any): Promise < any > {
  console.error('An error occurred', error); // for demo purposes only
  return Promise.reject(error.message || error);
}
}
