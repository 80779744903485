<section class="card">
  <div
    *ngIf="showSpinner"
    class="modal-backdrop fade in"
    style="background-color: transparent"
  ></div>
  <div class="card-header">
    <span class="cat__core__title">
      <strong>Questionnaire Review</strong>
    </span>
    <div class="card-block">
      <div class="table-responsive table-bordered margin-bottom-50">
        <table
          class="table table-hover compact cell-border nowrap table-striped"
          style="width: 50%"
        >
          <thead>
            <th style="width: 20px">Score</th>
            <th style="width: 20px">Particular</th>
          </thead>
          <tbody>
            <tr *ngFor="let scoreParticulars of scoreParticular">
              <td style="width: 20px">{{ scoreParticulars.Key }}</td>
              <td style="width: 20px">{{ scoreParticulars.Value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="showSpinner" class="center-all">
    <i class="fa fa-spinner fa-spin" style="font-size: 35px; color: #07a7a5"></i>
  </div>
  <div class="card-block watermark">
    <form [formGroup]="reviewForm" novalidate>
      <div class="row">
        <div class="col-lg-2 col-sm-2" style="border-right: 1px solid lightgray">
          <div
            class="p-3"
            style="text-align: center"
            *ngFor="
              let evaluationCriteriaDetail of evaluationCriteriaDetails;
              let count = index;
              let last = last
            "
          >
            <span
              class="btn btn-squared btn-sm btn-primary"
              id="{{ count }}"
              style="cursor: pointer; width: 100%; white-space: normal"
              (click)="
                questionListAgainstCriteriaList(
                  evaluationCriteriaDetail.EvaluationCriteriaID._id,
                  last,
                  count,
                  $event
                )
              "
              >{{ evaluationCriteriaDetail.EvaluationCriteriaID.EvaluationCriteria }}</span
            >
          </div>
        </div>
        <div class="col-lg-10 col-sm-10">
          <div *ngFor="let criterialist of criteria; let count = index; let last1 = last">
            <pagination-controls
              class="row float-right"
              (pageChange)="p = $event"
              (pageBoundsCorrection)="p = $event"
            ></pagination-controls>
            <br />
            <div
              *ngFor="
                let criterias of criterialist.EvaluationQuestion
                  | paginate: { itemsPerPage: 5, currentPage: p };
                let count1 = index
              "
            >
              <span class="row ml-1">
                <span style="width: 2%">{{ 5 * (p - 1) + (count1 + 1) + " " + "-" }}</span>
                <span style="width: 98%">
                  <b>{{ criterias.EvaluationQuestionId.QuestionText }}</b>
                </span>
              </span>
              <div class="form-group m-3">
                <label for="{{ 'comment' + count1 }}" style="font-family: Times New Roman"
                  >Comment:</label
                >
                <textarea
                  class="form-control"
                  rows="2"
                  [readonly]="!showAcceptButton"
                  id="{{ 'comment' + criterias.EvaluationQuestionId._id }}"
                  [formControl]="reviewForm.controls[criterias.EvaluationQuestionId._id]"
                  (change)="commentChangeEvent($event, criterias.EvaluationQuestionId._id)"
                ></textarea>
              </div>
            </div>

            <pagination-template
              class="float-right"
              *ngIf="islastCriteria"
              #p1="paginationApi"
              (pageChange)="pageChange.emit($event)"
            >
              <div class="pagination-next" [class.disabled]="p1.isLastPage()">
                <a *ngIf="p1.isLastPage() && showAcceptButton" (click)="p1.next()">
                  <button
                    [disabled]="disableSavebutton"
                    [appHasPermission]="{
                      Url: '/api/v1/secure/boardEvaluationFormReview/addEvaluationFormReview',
                      method: 'get',
                      menu: 'EvaluationType'
                    }"
                    class="btn btn-squared btn-sm btn-primary"
                    style="cursor: pointer"
                    (click)="questionWithComments('Approve')"
                  >
                    Accept
                  </button>
                  <button
                    [disabled]="disableSavebutton"
                    [appHasPermission]="{
                      Url: '/api/v1/secure/boardEvaluationFormReview/addEvaluationFormReview',
                      method: 'get',
                      menu: 'EvaluationType'
                    }"
                    class="btn btn-squared btn-sm btn-primary"
                    style="cursor: pointer"
                    (click)="questionWithComments('Reject')"
                  >
                    Reject
                  </button>
                </a>
              </div>
            </pagination-template>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
