<section class="card">
  <div
    *ngIf="showSpinner"
    class="modal-backdrop fade in"
    style="background-color: transparent"
  ></div>
  <div class="card-header">
    <span class="cat__core__title">
      <strong>Evaluation Management And Monitoring</strong>
    </span>
  </div>
  <div *ngIf="showSpinner" class="center-all">
    <i
      class="fa fa-spinner fa-spin"
      style="font-size: 35px; color: #07a7a5"
    ></i>
  </div>

  <div class="card-block watermark">
    <div class="row form-group col-lg-12">
      <label class="col-lg-3">
        <b>Select Period</b>
      </label>
      <ng-select
        class="form-group col-lg-4"
        [items]="periodParameterListToBind"
        (change)="periodParameterSelection($event)"
        [clearable]="false"
        bindLabel="label"
        bindValue="value"
      >
      </ng-select>
    </div>

    <div class="row col-lg-12" *ngIf="evaluationMonitoringTypeDatas">
      <div class="col-lg-12">
        <!-- table Layout -->
        <div class="table-responsive table-bordered margin-bottom-50">
          <table
            datatable
            [dtOptions]="dtOptions"
            id="evaluationMonitoring"
            [dtTrigger]="dtTrigger"
            class="table table-hover compact cell-border nowrap table-striped dataTable"
            style="background: transparent"
            width="100%"
          >
            <thead>
              <tr>
                <th style="width:5%">Sr. No.</th>
                <th style="width:25%">Entity</th>
                <th style="width:20%">Questionnaire</th>
                <th style="width:20%">Assessee</th>
                <th style="width:10%">End Date</th>
                <th style="width:10%">Status</th>
                <th style="width:10%">Reports</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let evaluationMonitoringTypeData of evaluationMonitoringTypeDatas;let count=index">
                <!-- <pre>{{ evaluationMonitoringTypeData | json}}</pre> -->
                <td style="text-align:center;width:5%">{{count + 1}}</td>
                <td style="text-align:center;width:25%;white-space: normal;">{{evaluationMonitoringTypeData.EntityType}}</td>
                <td style="width:20%">{{evaluationMonitoringTypeData.EvaluationFormTitle}}
                  <button [appHasPermission]="{ 'Url': '/api/v1/secure/boardEvaluationFormMonitoring/editEvaluationFormMonitoring', 'method':'put', 'menu': 'EvaluationType' }" class="fa fa-pencil btn btn-sm btn-primary" style="white-space: normal;float:right;" [disabled]="evaluationMonitoringTypeData.IsPublished" (click)="navigateToAssignment(evaluationMonitoringTypeData)"></button>
                </td>
                <td style="text-align:center;width:20%">
                  <div class="row" style="display:block">
                    <span class="pull-left pl-2"><input type="checkbox" [id]="count" [disabled]="evaluationMonitoringTypeData.IsPublished" [checked]="evaluationMonitoringTypeData.IsPublished" (change)="checkboxAssesseeChangeEvent($event,count,evaluationMonitoringTypeData)"/></span>
                    <span style="white-space: normal;">{{evaluationMonitoringTypeData.AssigneeDetails.Name}}</span>
                </div> </td>
                <td style="text-align:center;width:10%">{{evaluationMonitoringTypeData.EndDate ? (evaluationMonitoringTypeData.EndDate | date) : ""}}</td>
                <td style="text-align:center;width:10%" >              
                  <button class="btn btn-sm btn-primary" (click)="assesorsPopup(evaluationMonitoringTypeData)">{{evaluationMonitoringTypeData.Status ? evaluationMonitoringTypeData.Status : " "}}</button>
                </td>
                <td style="text-align:center;width:10%;vertical-align: middle;" *ngIf="isRoleReportView || isRolereportDownload || isRolereportSendToAssessee">
                  <!-- <div style="display: inline-grid">
                    <button [appHasPermission]="{ 'Url': '/api/v1/secure/boardEvaluationFormMonitoring/getAllEvaluationFormMonitoring', 'method':'get', 'menu': 'EvaluationType' }" class="btn btn-sm btn-primary" style="margin-bottom:5%" (click)="viewReport(evaluationMonitoringTypeData)">View</button>
                    <button [appHasPermission]="{ 'Url': '/api/v1/secure/boardEvaluationFormMonitoring/downloadAllEvaluationFormMonitoring', 'method':'get', 'menu': 'EvaluationType' }" class="btn btn-sm btn-primary" style="margin-bottom:5%" (click)="downloadReport(evaluationMonitoringTypeData)">Download</button>
                    <button [appHasPermission]="{ 'Url': '/api/v1/secure/boardEvaluationFormMonitoring/editEvaluationFormMonitoring', 'method':'put', 'menu': 'EvaluationType' }" class="btn btn-sm btn-primary" (click)="updateReportToGenerate(evaluationMonitoringTypeData)">Send To Assessee</button>
                  </div> -->
                  <div style="display: inline-grid">
                    <button *ngIf="isRoleReportView" class="btn btn-sm btn-primary" style="margin-bottom:5%" (click)="viewReport(evaluationMonitoringTypeData)">View</button>
                    <!-- <button *ngIf="isRolereportDownload" class="btn btn-sm btn-primary" style="margin-bottom:5%" (click)="downloadReport(evaluationMonitoringTypeData)">Download</button> -->
                    <button title="Send report to Assessee" *ngIf="isRolereportSendToAssessee" class="btn btn-sm btn-primary" (click)="updateReportToGenerate(evaluationMonitoringTypeData)">Send Report
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-lg-12">
        <button
          [appHasPermission]="{
            Url: '/api/v1/secure/boardEvaluationFormMonitoring/editEvaluationFormMonitoring',
            method: 'put',
            menu: 'EvaluationType'
          }"
          class="btn btn-sm btn-primary pull-right"
          (click)="publish()"
        >
          Publish
        </button>
      </div>
    </div>
  </div>

  <!--Send review dialog-->
  <div
    class="modal fade"
    id="saveReviewDialogBox"
    role="dialog"
    aria-labelledby="saveReviewDialogBox"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="saveReviewDialogBox">
            <i class="mr-2"></i>Send For Review
          </h5>
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <ng-select
            [items]="userListToBind"
            (change)="userSelected($event)"
            placeholder="Select reviewers"
            [clearable]="false"
          ></ng-select>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary btn-sm"
            [disabled]="!enableSaveButton"
            (click)="updateAssignee()"
          >
            Send
          </button>
          <button
            type="button"
            class="btn btn-default btn-sm"
            data-dismiss="modal"
            (click)="closeForm('saveReviewDialogBox')"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="assesseeDialogBox"
    role="dialog"
    aria-labelledby="assesseeDialogBox"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="assesseeDialogBox">
            <i class="mr-2"></i>Assessors Details
          </h5>
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <div class="table-responsive table-bordered margin-bottom-50">
                <table
                  datatable
                  [dtOptions]="dtOptionsAssessor"
                  [dtTrigger]="dtTriggerAssessor"
                  id="evaluationMonitoringAssessor"
                  class="table table-hover compact cell-border nowrap table-striped dataTable"
                  style="background: transparent"
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th style="width: 5%">Sr. No.</th>
                      <th style="width: 10%">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let evaluationMonitoringTypeData of evaluationMonitoringTypeDatas;
                        let count = index
                      "
                    >
                      <td style="text-align: center; width: 5%">
                        {{ count + 1 }}
                      </td>
                      <td style="width: 35%">
                        <table style="width: 100%">
                          <tbody>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary btn-sm"
              [disabled]="!enableSaveButton"
            >
              Send
            </button>
            <button
              type="button"
              class="btn btn-default btn-sm"
              data-dismiss="modal"
              (click)="closeForm('assesseeDialogBox')"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="messagePopUp"
    tabindex="-1"
    role="dialog"
    aria-labelledby=""
    aria-hidden="true"
    style="top: 50px"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div
          id="alertPopUpTitle"
          class="modal-header"
          style="font-size: 20px; font-weight: bold; padding: 5px 15px"
        ></div>
        <div class="modal-body" style="padding: 10px 15px">
          <div id="alertMessage" style="font-size: 16px"></div>
        </div>
      </div>
    </div>
  </div>

  <table
    hidden
    id="ratingScoreTable"
    class="table table-hover compact cell-border nowrap table-striped dataTable"
    style="background: transparent"
  >
    <thead>
      <tr>
        <th style="width: 5%">Rating Scores</th>
        <th style="width: 20%">Particulars</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let particular of scoreParticularsForReportDownload">
        <td style="width: 5%">{{ particular.Key }}</td>
        <td style="width: 20%">{{ particular.Value }}</td>
      </tr>
    </tbody>
  </table>

  <div
    id="weightedBarChart"
    class="m-auto"
    style="cursor: pointer; z-index: -99999; position: absolute"
  >
    <chart [options]="weigthedBarGraphOptions"></chart>
  </div>
</section>
