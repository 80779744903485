import { Component, OnInit } from '@angular/core';
import { BoardEvaluationService } from '../../../services/boardEvaluation.service';
import { BoardEvalStaticComponent} from '../../../board-eval-static/board-eval-static.component'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
declare var $: any;
declare var jQuery: any;
declare var Chart: any;

@Component({
  selector: 'app-summary-report',
  templateUrl: './summary-report.component.html',
  styleUrls: ['./summary-report.component.css'],
  
})
export class SummaryReportComponent implements OnInit {

  periodParameterListToBind : any = [];
  periodList : any = [];
  selectedPeriod : any ="";
  entityScoreArray : any= [];
  reportPeriodParameter : any ="";
  reportShowEnable : boolean = false;
  barChartOptions : Object;
  showSpinner : Boolean = false;
  viewReportButtonEnable : Boolean = false;
  constructor(private boardEvaluationService: BoardEvaluationService, private modalService: NgbModal) { }

  ngOnInit() {
    // this.openBoardEvalPage();
    var self= this;
    this.boardEvaluationService.getPeriodParameterList().then(response => {
      //console.log("response",response);
      this.periodList = response.PeriodList;

      // _.forEach(this.periodList,function(period){
      //   self.periodParameterListToBind.push( { "label" : period.PeriodParameter ,"value" : period.PeriodParameter })
      // })

      this.periodParameterListToBind = this.periodList.map(function (period) {
        return { "label": period.PeriodParameter, "value": period.PeriodParameter }
      })

      if(_.size(this.selectedPeriod)){
        this.viewReportButtonEnable = true ;
      }else{
        this.viewReportButtonEnable = false ;
      }

      //console.log("this.periodParameterListToBind",this.periodParameterListToBind);

    })

  }

  periodParameterSelection(item : any){
    //console.log("item", item);
    this.selectedPeriod = item.value;
    //console.log("this.selectedPeriod", this.selectedPeriod);
    if(_.size(this.selectedPeriod)){
      this.viewReportButtonEnable = true ;
    }else{
      this.viewReportButtonEnable = false ;
    }
  }

  viewSummaryReport(){
    this.showSpinner = true;
    var self = this;
    this.boardEvaluationService.getSummaryReportDetails(this.selectedPeriod).then(response => {
      //console.log("response", response);
      this.showSpinner = false;
      this.entityScoreArray = response.entityScoreArray;

      if(_.size(this.entityScoreArray)){
        self.reportShowEnable = true
      }else{
        self.reportShowEnable = false;
      }

      this.reportPeriodParameter = response.PeriodParameter;

      this.drawBarChart(this.entityScoreArray) 

    })
  }


  drawBarChart(arrayOfResult){
    var newBarData = [];
    if(this.reportShowEnable){
        
    
        if(_.size(arrayOfResult)){
          _.forEach(arrayOfResult , function(element){
            newBarData.push([element.EvaluationTypeID.EvaluationType,element.totalAverageScoreElement])
          })
    
          //console.log("newBarData",newBarData);

        }

      //console.log(" [newBarData]", [newBarData]);
      //.-------------------------------------------------
      this.barChartOptions = {
        chart: {
          type: 'column',
          renderTo: 'overallSummaryBarChart',
        },
        title: {
          text: ''
        },
        // subtitle: {
        //   text: 'Source: <a href="http://en.wikipedia.org/wiki/List_of_cities_proper_by_population">Wikipedia</a>'
        // },
        xAxis: {
          type: 'category',
          title: {
            text : "Overall Score"
          },
          labels: {
            style: {
              fontSize: '10px',
              fontFamily: 'Verdana, sans-serif'
            }
          }
        },
        yAxis: {
          min: 0,
          title: {
            text : "Scores"
          },        
        },
        legend: {
          enabled: false
        },
        tooltip: {
          pointFormat: '{point.y:.2f}'
        },
        series: [{
          name: 'Entity',
          data: arrayOfResult.map(function (point) {
            return [point.EvaluationTypeID.EvaluationType, point.totalAverageScoreElement]}),
          dataLabels: {
            
            rotation: -90,
            color: '#FFFFFF',
            align: 'right',
            format: '{point.y:.2f}', // one decimal
            y: 10, // 10 pixels down from the top
            style: {
              fontSize: '10px',
              fontFamily: 'Verdana, sans-serif'
            }
          }
        }]
  
  
  
      }
      
      //-------------------------------------------------

      
      // ["Board Entity for demo", 5.13],
      //     ["Members  Evaluation Type Entity", 3.67],
      //     ["community entity", 5.39]

    }
    

  
   
  }

  openBoardEvalPage() {
    const modalRef = this.modalService.open(BoardEvalStaticComponent, {
      size: 'lg',
      backdrop: 'static'
    })
  }

}
