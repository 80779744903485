<section>
  <div class="modal-header">
      <h4 class="modal-title">Assesors</h4>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="table-responsive table-bordered margin-bottom-50">
          <table datatable [dtOptions]="dtOptions" id="assesorTable" [dtTrigger]="dtTrigger" class="table table-hover compact cell-border nowrap table-striped dataTable"
            style="background:transparent" width="100%">
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th>Assessor</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
              <tr *ngFor="let assesor of assesorList;let count= index">
                  <td>{{count + 1}}</td>
                  <td>{{assesor.AssessorDetails.Name}}</td>
                  <td>{{assesor.IsFormEvaluated ? "Completed" : "Not Completed"}}</td>
                </tr>
          </tbody>
        </table>
    </div>

  </div>
  
  <div class="modal-footer">
    <button type="button" class="btn btn-default btn-sm" (click)="close()">
      Cancel
    </button>
  </div>
  </section>  
