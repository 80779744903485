import {
  Component,
  OnInit,
  ViewChild,
  Renderer2,
  AfterViewInit,
  OnChanges,
  DoCheck,
  AfterViewChecked,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  FormBuilder,
  FormsModule,
  AbstractControl,
} from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { EvalCategoryService } from '../../../services/evalCategory.service';
import { RoadmapModuleService } from '../../../services/roadmapModule.service';
import { BoardEvaluationService } from '../../../services/boardEvaluation.service';
import { TypeOfUser } from '../../../enums/TypeOfUser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { BoardEvaluationExecutionService } from '../../../services/boardEvaluationExecution.service';
import * as _ from 'lodash';
declare var $: any;
import * as moment from 'moment';

@Component({
  selector: 'app-entity-form-setup',
  templateUrl: './entity-form-setup.component.html',
  styleUrls: ['./entity-form-setup.component.css'],
})
export class EntityFormSetupComponent
  implements OnInit, AfterViewInit, OnChanges, AfterViewChecked
{
  entityForm: FormGroup;
  evaluationType: any;
  evaluationName: any;
  scoreRange: any;
  endDate: any;
  evaluationTypeList: any;
  isAnonymous: boolean = false;
  criterias: any = [];
  criteriaName: any;
  categoryWeightage: any;
  selectedCriteriaList = [];
  selectedCriteriaListData = [];
  questions: any;
  selectQuestion = [];
  selectedQuestionsList = [];
  selectedQuestionsListCache = [];
  count = 0;
  greaterCriteriaWeight: any = false;
  invalidCriteriaWeight: any = false;
  EvaluationCriterias: any = [];
  selectedQuestionListEmpty: boolean = true;
  questionListIsNotEmpty: boolean = false;
  criteriaIdToDelete: any;
  users: any = [];
  userListToBind: any = [];
  selectedUsers: any;
  selectedUserToSend: any = [];
  enableSaveButton: boolean = false;
  Reviewers = [];
  sendEntityFormForReview: boolean = false;
  scoreRangeLimit: boolean = false;
  evaluationFormId: any;
  selectedCriterias: any = [];
  criteriaIdSelected: any;
  editFormSelectedQuestion: any = [];
  criteriaListFromResponse: any;
  formId: any;
  criteriaIsEmpty: boolean = true;
  isAssigned: boolean = false;
  arrayOfScoreParticular = [];
  scorePrticularObject = { scoreParticulars: '' };
  scoreParticularsValues = [];
  statusOfScoreParticular: boolean = false;
  showSpinner: boolean = false;
  disableSavebutton: boolean = false;
  disableupdatebutton: boolean = false;
  //criteriaID: any;

  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};

  dtTrigger: Subject<any> = new Subject();

  todayDate: Date = new Date();
  equalCriteria: boolean = false;


  constructor(private evalCategoryService: EvalCategoryService, private boardEvaluationService: BoardEvaluationService, private modalService: NgbModal, private roadmapService: RoadmapModuleService, private route: ActivatedRoute, private router: Router, private boardEvaluationExecutionService: BoardEvaluationExecutionService) { }

  ngOnInit() {
    this.todayDate = new Date();

    var self = this;
    $('body').removeClass('dashboard-page');

    this.dtOptions = {
      destroy: true,
      pagingType: 'full_numbers',
      paging: true,
      stateSave: true,
      stateDuration: -1,
      pageLength: 10,
      search: true,
      language: {
        //made changes for datatables for next previous button
        paginate: {
          first: '<<',
          last: '>>',
          next: '>',
          previous: '<',
        },
      },
    };

    this.entityForm = new FormGroup({
      evaluationType: new FormControl('', [Validators.required]),
      evaluationName: new FormControl('', [Validators.required]),
      scoreRange: new FormControl('', [Validators.required]),
      endDate: new FormControl('', []),
      evaluationTypeList: new FormControl('', []),
      isAnonymous: new FormControl('', [Validators.required]),
      criterias: new FormControl('', []),
      criteriaName: new FormControl('', []),
      categoryWeightage: new FormControl('', []),
      selectQuestion: new FormControl('', []),
      arrayOfScoreParticular: new FormGroup({}, [Validators.required]),
      //  arrayOfScoreParticular: this.intialiseArrayScoreParticularsFormGroup()
    });

    this.endDate = this.setDate(this.todayDate);
    this.entityForm.controls.endDate.setValue(this.endDate);
    this.entityForm.controls.endDate.updateValueAndValidity();
    this.getEvaluationTypeList();
    this.getEvaluationCriteriaList();
    this.sendForReviewButtonEnable();

    this.route.params.subscribe((params) => {
      // //console.log("params",params);
      if (params)
        //console.log("params", params.id);
        this.evaluationFormId = params.id;
    });
    if (this.evaluationFormId == undefined) {
    } else {
      //console.log("this.evaluationFormId", this.evaluationFormId);
      $('#selectedCriteriatable').DataTable().destroy();
      this.boardEvaluationExecutionService
        .getEvaluationFormDetails(this.evaluationFormId)
        .then((response) => {
          if (response.status == 0) {
            //this.formId = response._id;
            this.isAssigned = response.EvaluationDetails.isAssigned;
            this.scoreParticularsValues =
              response.EvaluationDetails.ScoreParticulars;
            //console.log("response arrayOfScoreParticular", this.scoreParticularsValues)

            this.entityForm.controls.arrayOfScoreParticular =
              this.intialiseArrayScoreParticularsFormGroup();
            var i = 0;
            for (i; i < _.size(this.scoreParticularsValues); i++) {
              this.arrayOfScoreParticular.push({
                scoreParticulars: 'scoreParticulars' + (i + 1),
              });
              var arrayControl: FormGroup = <FormGroup>(
                this.entityForm.controls.arrayOfScoreParticular
              );
              arrayControl.addControl(
                'scoreParticulars' + (i + 1),
                new FormControl('', [Validators.required])
              );
            }
            i = 0;
            var self = this;
            for (i; i < _.size(this.scoreParticularsValues); i++) {
              var arrayControl: FormGroup = <FormGroup>(
                this.entityForm.controls.arrayOfScoreParticular
              );
              arrayControl.controls['scoreParticulars' + (i + 1)].setValue(
                self.scoreParticularsValues[i].Value
              );
              // arrayControl.controls["scoreParticulars" + (i + 1)].setValue(this.scoreParticularsValue[i].Value)
            }

            // for (i; i < this.scoreParticularsValues.length; i++) {

            //   this.arrayOfScoreParticular.push({ "scoreParticulars": "ScoreParticulars" + (i + 1) });
            //   var arrayControl: FormGroup = <FormGroup>this.entityForm.controls.arrayOfScoreParticular;
            //   arrayControl.addControl("scoreParticulars" + (i + 1), new FormControl('', [Validators.required]))
            //   arrayControl.controls["scoreParticulars" + (i+ 1)].setValue(this.scoreParticularsValues[i].Value)
            // }
            //console.log("response getEvaluationFormDetails", response)
            //console.log("response.EvaluationDetails.EvaluationTypeID", response.EvaluationDetails.EvaluationTypeID._id)
            //console.log("this.selectedCriteriaListData", this.selectedCriteriaListData)
            self.criteriaListFromResponse =
              response.EvaluationDetails.EvaluationCriterias;
            //console.log("self.criteriaListFromResponse", self.criteriaListFromResponse)

            // if (_.size(self.selectedCriteriaListData)) {
            //   _.forEach(self.selectedCriteriaListData, function (selectedCriteriadata) {
            //     let criterialistIndex = _.findIndex(self.criteriaListFromResponse, function (criteria) {
            //       return _.get(selectedCriteriadata, '_id') == _.get(criteria, '_id')
            //     })
            //   })
            this.evaluationName =
              response.EvaluationDetails.EvaluationFormTitle;
            this.scoreRange = response.EvaluationDetails.ScoreRange;
            this.evaluationType =
              response.EvaluationDetails.EvaluationTypeID._id;
            this.scoreParticularsValues =
              response.EvaluationDetails.ScoreParticulars;
            // this.isAnonymous = response.EvaluationDetails.IsAnonymous;
            var date = new Date(response.EvaluationDetails.EndDate);
            this.endDate = {
              month: date.getMonth() + 1,
              day: date.getDate(),
              year: date.getFullYear(),
            };
            this.entityForm.controls['isAnonymous'].setValue(
              response.EvaluationDetails.IsAnonymous.toString()
            );
            response.EvaluationDetails.EvaluationCriterias.forEach(function (
              element
            ) {
              //self.entityForm.controls['categoryWeightage'].setValue(element.CriteriaWeight)
              let elementCriteria = element.EvaluationCriteriaID;
              elementCriteria['CriteriaWeight'] = element.CriteriaWeight;
              elementCriteria['CriteriaId'] = element.EvaluationCriteriaID._id;
              self.selectedCriterias.push(elementCriteria);

              if (_.size(element.EvaluationQuestion)) {
                _.forEach(element.EvaluationQuestion, function (questionList) {
                  questionList['evaluationCriteriaId'] = elementCriteria._id;
                  self.editFormSelectedQuestion.push(questionList);
                });
              }
            });
            //console.log("self.selectedCriterias", self.selectedCriterias, self.editFormSelectedQuestion)

            this.boardEvaluationService
              .getEvaluationCriteriaList()
              .then((updateEvalData) => {
                if (updateEvalData.status == 0) {
                  //console.log("criteria list", updateEvalData.evalCriteriaList)

                  this.criterias = updateEvalData.evalCriteriaList; //put the evaluation type list data
                  this.criterias.forEach(function (element) {
                    element.isEnable = true;
                    element.isChecked = false;
                  });

                  if (_.size(self.selectedCriterias)) {
                    _.forEach(
                      self.selectedCriterias,
                      function (selectedCriteria) {
                        let criteriaIndex = _.findIndex(
                          self.criterias,
                          function (criteria) {
                            return (
                              _.get(selectedCriteria, '_id') ==
                              _.get(criteria, '_id')
                            );
                          }
                        );

                        if (criteriaIndex >= 0) {
                          let criteriaToAdd = self.criterias[criteriaIndex];
                          criteriaToAdd['criteriaWeight'] =
                            selectedCriteria.CriteriaWeight;
                          criteriaToAdd['CriteriaId'] =
                            selectedCriteria.CriteriaId;
                          self.selectedCriteriaListData.push(criteriaToAdd);

                          //console.log("self.selectedCriteriaListData", self.selectedCriteriaListData, self.criterias)
                          self.addQuestionsForEditForm(
                            self.criterias[criteriaIndex],
                            function () {
                              //console.log("callback hit");
                              let evaluationQuestion = [];
                              self.sendForReviewButtonEnable();

                              _.forEach(
                                self.selectedQuestionsListCache,
                                function (questions) {
                                  if (
                                    self.criterias[criteriaIndex]._id ==
                                    questions.evaluationCriteriaId
                                  ) {
                                    evaluationQuestion.push({
                                      EvaluationQuestionId: questions._id,
                                    });
                                  }
                                }
                              );

                              self.EvaluationCriterias.push({
                                EvaluationCriteriaID:
                                  self.criterias[criteriaIndex]._id,
                                CriteriaWeight:
                                  self.criterias[criteriaIndex].criteriaWeight,
                                CriteriaId:
                                  self.criterias[criteriaIndex].CriteriaId,
                                EvaluationQuestion: evaluationQuestion,
                              });
                            }
                          );
                        }
                        // console.log("criteriaIndex---self.EvaluationCriterias", self.selectedCriteriaListData, self.EvaluationCriterias);
                      }
                    );
                    self.count = _.size(self.selectedCriteriaListData);
                    console.log("self.selectedCriteriaListData",_.clone(self.selectedCriteriaListData));
                    console.log('self.count', self.count);
                  }

                  //selectedCriteriaList checked enable
                  if (_.size(self.selectedCriterias)) {
                    _.forEach(
                      self.selectedCriterias,
                      function (selectedCriteria) {
                        let criteriaIndex = _.findIndex(
                          self.criterias,
                          function (criteria) {
                            return (
                              _.get(selectedCriteria, '_id') ==
                              _.get(criteria, '_id')
                            );
                          }
                        );

                        if (criteriaIndex >= 0) {
                          //console.log("criteria", self.criterias[criteriaIndex]);
                          self.criterias[criteriaIndex].isChecked = true;
                          self.criterias[criteriaIndex].isEnable = false;
                          //console.log("self.criterias[criteriaIndex].isChecked", self.criterias[criteriaIndex])
                        }
                      }
                    );
                  }

                  this.updateSelectedCriteriaChecboxOnInitForEditForm();
                } else {
                }

                this.dtTrigger.next();
              });

            this.arrayOfScoreParticular = [];
            //console.log("this.scoreParticularsValues[i].Values", this.scoreParticularsValues);
            var i = 0;
            this.entityForm.controls.arrayOfScoreParticular =
              this.intialiseArrayScoreParticularsFormGroup();

            for (i; i < this.scoreRange; i++) {
              this.arrayOfScoreParticular.push({
                scoreParticulars: 'scoreParticulars' + (i + 1),
              });
              var arrayControl: FormGroup = <FormGroup>(
                this.entityForm.controls.arrayOfScoreParticular
              );
              arrayControl.addControl(
                'scoreParticulars' + (i + 1),
                new FormControl('', [Validators.required])
              );

              //console.log("this.scoreParticularsValues[i].Values", this.scoreParticularsValues[i].Value);

              arrayControl.controls['scoreParticulars' + (i + 1)].setValue(
                this.scoreParticularsValues[i].Value
              );
            }
            //console.log("this.arrayOfScoreParticular", this.arrayOfScoreParticular)
          } else {
            //console.log("response", response)
          }
        });
    }
  }

  getInputValueChange(event: Event): string {
    // @ts-ignore
    return (event.target ? (event.target as HTMLInputElement) : event).value;
  }

  onEqualcriteria(){
    if(!this.equalCriteria){
      if(this.selectedCriteriaListData.length > 0){
        this.selectedCriteriaListData.forEach(ele => {
          var countWeight = (100 / this.selectedCriteriaListData.length);
          ele.criteriaWeight = Number(countWeight) % 1 == 0 ? Number(countWeight) : parseFloat(Number(countWeight).toFixed(2));
        })
        this.categoryWeightCalculation(0, this.selectedCriteriaListData[0].criteriaWeight);
      }
    }
    this.equalCriteria = !this.equalCriteria;
  }

  onEqualcriteriaUpdate(){
    if(this.equalCriteria){
      this.selectedCriteriaListData.forEach(ele => {
        var countWeight = (100 / this.selectedCriteriaListData.length);
        ele.criteriaWeight = Number(countWeight) % 1 == 0 ? Number(countWeight) : parseFloat(Number(countWeight).toFixed(2));
      })
      this.categoryWeightCalculation(0, this.selectedCriteriaListData[0].criteriaWeight);
    }
  }

  test() {
    //console.log("666666666666666666", this.entityForm.controls.scoreRange.value)
    //console.log("777777777", this.entityForm.controls.scoreRange.status)
    //console.log(this.entityForm.status);
    //console.log(this.scoreRange);
  }

  intialiseArrayScoreParticularsFormGroup() {
    return new FormGroup({});
  }

  arrayOne(n: number): any[] {
    if (this.scoreRange > 15) {
      return Array(15);
    } else {
      return Array(this.scoreRange);
    }
  }

  ngAfterViewInit() {
    this.updateSelectedCriteriaChecboxOnInitForEditForm();
  }

  ngOnChanges() {
    this.updateSelectedCriteriaChecboxOnInitForEditForm();
  }

  // ngDoCheck(){
  //   this.updateSelectedCriteriaChecboxOnInitForEditForm();
  // }

  ngAfterViewChecked() {
    this.updateSelectedCriteriaChecboxOnInitForEditForm();
  }

  updateSelectedCriteriaChecboxOnInitForEditForm() {
    if (this.evaluationFormId != undefined) {
      var self = this;
      _.forEach(this.selectedCriteriaListData, function (selectedcriteria) {
        var index = _.findIndex(self.criterias, function (criteria) {
          return _.get(selectedcriteria, '_id') == _.get(criteria, '_id');
        });

        if (index >= 0) {
          // //console.log("self.criterias[index]._id", self.criterias[index]._id);
          let inputElement = <HTMLInputElement>(
            document.getElementById(self.criterias[index]._id)
          );
          inputElement.value = self.criterias[index]._id;
          inputElement.checked = true;
          // //console.log("inputElement", inputElement)
        }
      });
    }
  }

  onlyNumber(ev) {
    var keyCode = window.event ? ev.keyCode : ev.which;
    //codes for 0-9
    if (
      (keyCode > 47 && keyCode < 58) ||
      (keyCode > 95 && keyCode < 106) ||
      keyCode == 8
    ) {
      // if (((keyCode > 47 && keyCode < 58) || (keyCode > 95 && keyCode < 106))) {
      // ev.preventDefault();
      // }
    } else {
      if (keyCode != 0 && keyCode != 8 && keyCode != 13 && !ev.ctrlKey) {
        ev.preventDefault();
      } else {
        ev.preventDefault();
      }
    }

    this.checkScoreRangeValue(ev);
  }

  today() {
    var now = new Date();
    return {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate(),
    };
  }

  closeForm(id) {
    //$('#criteriaDeletionMessageModal').modal('hide');
    // $('#EntityQuestionSetup').modal('toggle');
    //$('#saveReviewDialogBox').modal('hide');
    $('#' + id).modal('toggle');
    this.entityForm.controls.endDate.updateValueAndValidity();
  }

  checkScoreRangeValue(event) {
    this.arrayOfScoreParticular = [];
    this.scoreParticularsValues = [];
    this.scoreRange = this.entityForm.controls.scoreRange.value;
    // console.log("this.scoreRange", this.scoreRange)
    if (this.scoreRange <= 15 && this.scoreRange > 0) {
      this.scoreRangeLimit = false;
      //console.log('true', this.scoreRange)
    } else {
      this.scoreRangeLimit = true;

      //console.log('false', this.scoreRange)
    }
    var i = 0;
    this.entityForm.controls.arrayOfScoreParticular =
      this.intialiseArrayScoreParticularsFormGroup();

    // old code for reference
    // for (i; i < this.scoreRange; i++) {
    //   this.arrayOfScoreParticular.push({ "scoreParticulars": "scoreParticulars" + (i + 1) });
    //   var arrayControl: FormGroup = <FormGroup>this.entityForm.controls.arrayOfScoreParticular;
    //   arrayControl.addControl("scoreParticulars" + (i + 1), new FormControl('', [Validators.required]))
    //   arrayControl.controls["scoreParticulars" + (i + 1)].updateValueAndValidity();
    // }

    if (this.scoreRangeLimit == false) {
      for (i; i < this.scoreRange; i++) {
        this.arrayOfScoreParticular.push({
          scoreParticulars: 'scoreParticulars' + (i + 1),
        });
        var arrayControl: FormGroup = <FormGroup>(
          this.entityForm.controls.arrayOfScoreParticular
        );
        arrayControl.addControl(
          'scoreParticulars' + (i + 1),
          new FormControl('', [Validators.required])
        );
        this.entityForm.controls.arrayOfScoreParticular.updateValueAndValidity();
        // console.log(this.entityForm.controls.arrayOfScoreParticular.invalid);
      }
    }
    // console.log("this.arrayOfScoreParticular", this.arrayOfScoreParticular);
    this.statusOfScoreParticular = false;
    // console.log(this.statusOfScoreParticular);
  }

  // sameScoreParticularValidator
  customSameScoreParticularValidator(control: FormControl) {
    return {
      sameScoreParticular: {
        sameParticular: true,
      },
    };
  }

  scoreParticularsValue(event, index) {
    var self = this;
    // console.log("event i", event.target.value, index)

    // console.log("event.target.value", event.target)
    // console.log("this.scoreParticularsValues",this.scoreParticularsValues)

    if (event.target.value.trim() == '') {
      _.forEach(self.scoreParticularsValues, function (elementScore) {
        if (elementScore.Key == index) {
          elementScore.Value = '';
        }
      });
    }

    // console.log("this.scoreParticularsValues",this.scoreParticularsValues)

    var found = _.find(this.scoreParticularsValues, function (element) {
      return (
        element.Value.toLowerCase() == event.target.value.trim().toLowerCase()
      );
    });

    var arrayControl: FormGroup = <FormGroup>(
      this.entityForm.controls.arrayOfScoreParticular
    );
    // console.log("this.entityForm.controls.arrayOfScoreParticular",this.entityForm.controls.arrayOfScoreParticular)
    arrayControl.controls['scoreParticulars' + index].updateValueAndValidity();
    if (_.size(found)) {
      //  console.log("arrayControl.controls[.",arrayControl.controls["scoreParticulars" + (index)].valid);
      arrayControl.controls['scoreParticulars' + index].setValidators([
        self.customSameScoreParticularValidator,
      ]);
      arrayControl.controls[
        'scoreParticulars' + index
      ].updateValueAndValidity();
      self.scoreParticularsValues[index - 1].Value = '';
      return;
    } else {
      arrayControl.controls['scoreParticulars' + index].setValidators([
        Validators.required,
      ]);
      arrayControl.controls[
        'scoreParticulars' + index
      ].updateValueAndValidity();
    }

    // event.target.value = event.target.value.trim();

    arrayControl.controls['scoreParticulars' + index].setValue(
      event.target.value.trim()
    );

    // console.log("arrayControl.controls[scoreParticulars + (index + 1)]", arrayControl.controls["scoreParticulars" + (index)])
    if (event.target.value.trim() != '') {
      if (_.size(this.scoreParticularsValues)) {
        var foundParticular = _.findIndex(
          this.scoreParticularsValues,
          function (element) {
            return element.Key == index;
          }
        );

        if (foundParticular >= 0) {
          this.scoreParticularsValues[foundParticular].Value =
            event.target.value.trim();
        } else {
          this.scoreParticularsValues.push({
            Key: index,
            Value: event.target.value,
          });
        }
      } else {
        this.scoreParticularsValues.push({
          Key: index,
          Value: event.target.value,
        });
      }
    }

    // console.log("this.scoreParticularsValues", this.scoreParticularsValues, this.entityForm.controls.arrayOfScoreParticular.status);

    if (this.entityForm.controls.arrayOfScoreParticular.status == 'VALID') {
      this.statusOfScoreParticular = true;
    } else {
      this.statusOfScoreParticular = false;
    }

    arrayControl.controls['scoreParticulars' + index].updateValueAndValidity();
    // console.log(this.entityForm.controls.arrayOfScoreParticular.invalid);
    // console.log(this.statusOfScoreParticular);
    this.entityForm.updateValueAndValidity();
  }

  clearEndDate() {
    // this.endDate = null;
    this.entityForm.controls.endDate.reset();
    this.entityForm.controls.endDate.updateValueAndValidity();
  }

  selectedEndDateChange() {
    console.log('this.endDate', this.endDate);
  }

  setDate(date) {
    if (date != '' && date != null) {
      //return date.day + "-" + date.month + "-" + date.year;
      return date.month + '-' + date.day + '-' + date.year;
    }
    return null;
  }

  getEvaluationTypeList() {
    this.boardEvaluationService
      .getEvaluationTypeList()
      .then((updateEvalData) => {
        if (updateEvalData.status == 0) {
          //console.log("updateEvalData", updateEvalData)
          this.evaluationTypeList = updateEvalData.evalTypeList; //put the evaluation type list data
        } else {
        }
      });
  }

  getEvaluationCriteriaList() {
    this.boardEvaluationService
      .getEvaluationCriteriaList()
      .then((criteriaListData) => {
        if (criteriaListData.status == 0) {
          //console.log("criteriaListData", criteriaListData.evalCriteriaList)
          this.criterias = criteriaListData.evalCriteriaList;
        } else {
        }
      });
  }

  evaluationNameChangeEvent(event) {
    //console.log("event.target.value", event.target.value)
    if (event.target.value.trim() == '') {
      //console.log("event.target.value", event.target.value)
      this.entityForm.controls.evaluationName.setValue(
        event.target.value.trim()
      );
      this.entityForm.controls.evaluationName.updateValueAndValidity();
    }
  }

  // oninit selectedcrtiterias  to update
  updateSelectedCriteriaCheckbox(evaluationTypeId) {
    var self = this;

    this.selectedCriteriaListData = [];
    this.criterias = [];
    $('#selectedCriteriatable').DataTable().destroy();
    this.entityForm.controls.criteriaName.reset();
    this.entityForm.controls.categoryWeightage.reset();
    this.entityForm.controls.categoryWeightage.updateValueAndValidity();
    this.dtTrigger.next();

    this.boardEvaluationService
      .getEvaluationCriteriaByEvalIdList(evaluationTypeId)
      .then((updateEvalData) => {
        if (updateEvalData.status == 0) {
          this.criterias = updateEvalData.evalCriteriaList;

          if (_.size(this.selectedCriterias)) {
            _.forEach(self.selectedCriterias, function (selectedCriteria) {
              var index = _.findIndex(self.criterias, function (criteria) {
                return _.get(criteria, '_id') == selectedCriteria._id;
              });
              if (index >= 0) {
                self.selectedCriteriaListData.push(self.criterias[index]);
                self.criterias[index].isEnable = true;
                self.criterias[index].isChecked = true;
                //console.log("self.criterias[index]._id", self.criterias[index]._id)
                self.criteriaIdSelected = self.criterias[index]._id;
                var checkBoxHtmlElement = <HTMLInputElement>(
                  document.getElementById(self.criteriaIdSelected)
                );
                //console.log("iconElement", checkBoxHtmlElement);
                // checkboxElement.checked = true;
              }
              //console.log("index------", index)
            });
            //console.log("self.selectedCriteriaListData", self.selectedCriteriaListData, self.criterias)
          } else {
            this.criterias.forEach(function (element) {
              element.isEnable = true;
              element.isChecked = false;
            });
          }
        }
      });
  }

  // getEvaluationCriteriaByEvalIdList(event) {
  //   var self = this;
  //   this.selectedCriteriaListData = [];
  //   this.criterias = [];
  //   $('#selectedCriteriatable').DataTable().destroy();
  //   this.entityForm.controls.criteriaName.reset();
  //   this.entityForm.controls.categoryWeightage.reset();
  //   this.entityForm.controls.categoryWeightage.updateValueAndValidity();
  //   this.dtTrigger.next();
  //   var evaluationTypeId = event
  //   //console.log("event", event)
  //   this.boardEvaluationService.getEvaluationCriteriaByEvalIdList(evaluationTypeId)
  //     .then(updateEvalData => {
  //       if (updateEvalData.status == 0) {
  //         //console.log("criteria list", updateEvalData.evalCriteriaList)
  //         this.criterias = updateEvalData.evalCriteriaList;//put the evaluation type list data

  //         this.criterias.forEach(function (element) {
  //           element.isEnable = true;
  //           element.isChecked = false;
  //         });

  //       } else {

  //       }
  //     });
  // }

  saveCriteria() {
    this.invalidCriteriaWeight = true;
    this.greaterCriteriaWeight = true;
    var self = this;
    $('#selectedCriteriatable').DataTable().destroy();
    this.criterias.forEach(function (element) {
      if (element.isChecked == true) {
        let filteredTask = _.filter(
          self.selectedCriteriaListData,
          function (selectedCriteria) {
            return element._id == selectedCriteria._id;
          }
        );

        if (_.size(filteredTask) == 0) {
          element.criteriaWeight = 0;
          element.isEnable = true;
          self.selectedCriteriaListData.push(element);
        }
        self.selectedCriteriaListData.reverse();
      }
    });

    var selectCount = 0;
    _.forEach(this.selectedCriteriaListData, function (selectedCriteria) {
      var index = _.findIndex(
        self.selectedQuestionsListCache,
        function (question) {
          return (
            _.get(selectedCriteria, '_id') == question.evaluationCriteriaId
          );
        }
      );

      if (index >= 0) {
        selectCount++;
      }

    })
    this.onEqualcriteriaUpdate();
    this.count = 100;
    this.dtTrigger.next();
  }

  submitEntity() {
    $('#saveReviewDialogBox').modal('toggle');
    this.roadmapService.getUserList().then((response) => {
      //console.log('checking for the user list', response);
      if (response.status == 0) {
        //only active user will be filtered
        response.result = response.result.filter(function (o) {
          return o.IsActive != false;
        });
        //filtered the list of IC Checker and IC Maker role.
        this.users = _.filter(response.result, function (user) {
          return (
            user.TypeOfUser == TypeOfUser.Director ||
            user.TypeOfUser == TypeOfUser.KMP
          );
        });

        this.userListToBind = this.users.map(function (user) {
          return {
            label: user.FirstName + ' ' + user.LastName,
            value: user._id,
          };
        });
      }
    });
  }

  sendForReview() {
    this.disableSavebutton = true;
    // $('#saveReviewDialogBox').modal('toggle');

    this.showSpinner = true;
    var ReviewerId;
    var ReviewerStatus;
    var self = this;
    this.evaluationName = this.entityForm.controls.evaluationName.value;
    this.scoreRange = this.entityForm.controls.scoreRange.value;
    this.isAnonymous = this.entityForm.controls.isAnonymous.value;

    var userInfo = JSON.parse(localStorage.getItem('user'));

    var entityObj = {
      EvaluationType: this.evaluationType,
      EvaluationFormTitle: this.evaluationName,
      scoreRange: this.scoreRange,
      isAnonymous: this.isAnonymous,
      // 'endDate': this.endDate,
      EvaluationCriterias: this.EvaluationCriterias,
      // 'Reviewers': this.Reviewers,
      CreatedBy: userInfo._id,
      ScoreParticular: this.scoreParticularsValues,
    };

    if (this.evaluationFormId != undefined) {
      var formObj = {
        _id: this.evaluationFormId,
        EvaluationType: this.evaluationType,
        EvaluationFormTitle: this.evaluationName,
        scoreRange: this.scoreRange,
        isAnonymous: this.isAnonymous,
        // 'endDate': this.endDate,
        EvaluationCriterias: this.EvaluationCriterias,
        // 'Reviewers': this.Reviewers,
        LastEditedBy: userInfo._id,
        ScoreParticular: this.scoreParticularsValues,
      };
      //console.log("formObj", formObj)
      this.boardEvaluationService
        .updateEvaluationForm(formObj)
        .then((response) => {
          this.disableSavebutton = false;
          if (response.status == 0) {
            this.showSpinner = false;
            //console.log("form saved succesfully", response);
            $('#alertTitle').text('Success!');
            $('#alertTitle').css('color', 'black');
            $('#alertMessage').text(response.message);
            $('#alertMessage').css('color', 'black');
            $('#messagePopUp').modal('toggle');
            this.router.navigate([
              'structure',
              { outlets: { 'structure-outlet': 'evaluationForms' } },
            ]);
            $('#selectedCriteriatable').DataTable().destroy();
            this.messagePopUpTimeoutToogle();
            this.entityForm.reset();
            this.selectedCriteriaListData = [];
            this.selectedCriteriaList = [];
            this.criterias = [];
            this.selectedQuestionsListCache = [];
            this.selectedQuestionsList = [];
            this.dtTrigger.next();
          } else {
            this.showSpinner = false;
            $('#alertTitle').css('color', '#f00');
            $('#alertTitle').text('Failure!');
            $('#alertMessage').css('color', '#f00');
            $('#alertMessage').text(response.message);
            $('#messagePopUp').modal('toggle');
            this.messagePopUpTimeoutToogle();
            this.router.navigate([
              'structure',
              { outlets: { 'structure-outlet': 'evaluationForms' } },
            ]);
          }
        });
    } else {
      //console.log("final object", entityObj)
      this.boardEvaluationService
        .saveBoardEntityForm(entityObj)
        .then((response) => {
          this.disableSavebutton = false;

          if (response.status == 0) {
            //console.log("form saved succesfully", response);
            $('#alertTitle').text('Success!');
            $('#alertTitle').css('color', 'black');
            $('#alertMessage').text(response.message);
            $('#alertMessage').css('color', 'black');
            $('#messagePopUp').modal('toggle');
            this.router.navigate([
              'structure',
              { outlets: { 'structure-outlet': 'evaluationForms' } },
            ]);
            $('#selectedCriteriatable').DataTable().destroy();
            this.messagePopUpTimeoutToogle();
            this.entityForm.reset();
            this.selectedCriteriaListData = [];
            this.selectedCriteriaList = [];
            this.criterias = [];
            this.selectedQuestionsListCache = [];
            this.selectedQuestionsList = [];
            this.dtTrigger.next();
          } else {
            this.router.navigate([
              'structure',
              { outlets: { 'structure-outlet': 'evaluationForms' } },
            ]);
          }
          // else {
          //   $('#alertTitle').css('color', '#f00');
          //   $('#alertTitle').text("Failure!");
          //   $('#alertMessage').css('color', '#f00');
          //   $('#alertMessage').text(response.message);
          //   $('#messagePopUp').modal('toggle');
          //   this.messagePopUpTimeoutToogle();
          // }
        });
    }
  }

  messagePopUpTimeoutToogle() {
    setTimeout(function () {
      var popUpClass = $('#messagePopUp').attr('class');
      if (popUpClass == 'modal fade show') {
        $('#messagePopUp').modal('toggle');
      }
    }, 2000);
  }

  userSelected(item: any) {
    this.selectedUserToSend.push(item);
    //console.log('this.selectedUsers', this.selectedUserToSend);
    if (_.size(this.selectedUserToSend)) {
      this.enableSaveButton = true;
    } else {
      this.enableSaveButton = false;
    }
  }

  multipleUserDeselection(item: any) {
    let indexOfItem = this.selectedUserToSend.findIndex(
      (o) => o.value === item.value
    );
    this.selectedUserToSend.splice(indexOfItem, 1);
    //console.log('this.selectedUsers after deselection', this.selectedUserToSend)
    if (_.size(this.selectedUserToSend)) {
      this.enableSaveButton = true;
    } else {
      this.enableSaveButton = false;
    }
  }

  checkSelectedCriteria(event) {
    //console.log("checked value", event.target.checked);
    var self = this;

    if (event.target.checked == true) {
      this.criteriaIsEmpty = false;
      this.criterias.forEach(function (element) {
        if (event.target.value == element._id) {
          element.isEnable = false;
          element.isChecked = true;
        }
      });
      //console.log("=======================", this.criterias)
    } else {
      this.criterias.forEach(function (element) {
        if (event.target.value == element._id && element.isEnable == false) {
          element.isEnable = false;
          element.isChecked = false;
        }
        if (event.target.value == element._id && element.isEnable == true) {
          event.target.checked = true;
        }
      });
    }

    var i;
    for (i = 0; i < this.criterias.length; i++) {
      if (this.criterias[i].isChecked == true) {
        this.criteriaIsEmpty = false;
        i = this.criterias.length;
      } else {
        this.criteriaIsEmpty = true;
      }
    }
  }

  confirmDeleteCriteria(sendSelectedCriteria) {
    //console.log("sendSelectedCriteria", sendSelectedCriteria)
    this.criteriaIdToDelete = sendSelectedCriteria;
    $('#criteriaDeletionMessageModal').modal('toggle');
    console.log('selectedCriteriaListData', this.selectedCriteriaListData);
    console.log('selectedCriteriaList', this.selectedCriteriaList);
    if (this.selectedCriteriaListData.length == 0) {
      console.log('data empty');
    }
  }

  //delete criteria from datatable
  deleteCriteria() {
    $('#criteriaDeletionMessageModal').modal('toggle');

    var self = this;
    var index = _.findIndex(
      this.selectedCriteriaListData,
      function (selectedCriteria) {
        return _.get(selectedCriteria, '_id') == self.criteriaIdToDelete;
      }
    );

    var index1 = _.findIndex(
      this.selectedQuestionsListCache,
      function (selectedCriteria) {
        return (
          _.get(selectedCriteria, 'evaluationCriteriaId') ==
          self.criteriaIdToDelete
        );
      }
    );

    //console.log("index", index);
    //console.log("index1", index1);
    if (_.size(this.selectedQuestionsListCache)) {
      _.remove(self.selectedQuestionsListCache, function (question) {
        return question.evaluationCriteriaId == self.criteriaIdToDelete;
      });
    }

    if (_.size(this.EvaluationCriterias)) {
      _.remove(self.EvaluationCriterias, function (question) {
        return (
          _.get(question, 'EvaluationCriteriaID') == self.criteriaIdToDelete
        );
      });
    }

    //console.log("this.EvaluationCriterias", this.EvaluationCriterias);

    // if (index1 >= 0 ) {
    //   this.selectedQuestionsListCache.splice(index1, 1);
    //   //this.selectedQuestionsListCache.splice(index1, 1);
    //   //console.log("after deletion cache", this.count,this.selectedQuestionsListCache)
    // }

    if (_.size(this.selectedQuestionsList)) {
      _.remove(self.selectedQuestionsList, function (selectedQuestion) {
        return selectedQuestion.evaluationCriteriaId == self.criteriaIdToDelete;
      });
    }

    if (index >= 0) {
      this.selectedCriteriaListData.splice(index, 1);
      //this.selectedQuestionsListCache.splice(index1, 1);
      // this.count--;
      //console.log("after deletion", this.count, this.selectedCriteriaListData)
      //console.log("EvaluationCriterias list", this.EvaluationCriterias)
    }

    $('#selectedCriteriatable').DataTable().destroy();
    //console.log("this.selectedCriteriaListData", this.selectedCriteriaListData);
    this.criterias.forEach(function (element) {
      if (element._id == self.criteriaIdToDelete) {
        element.isEnable = false;
        element.isChecked = false;
        var checkBoxHtmlElement = <HTMLInputElement>(
          document.getElementById(self.criteriaIdToDelete)
        );
        //console.log("++++++++", <HTMLInputElement>document.getElementById(self.criteriaIdToDelete), self.criteriaIdToDelete)
        checkBoxHtmlElement.checked = false;
      }
    });
    this.dtTrigger.next();
    var sum = 0;
    this.count = 100;
    this.selectedCriteriaListData.forEach(function (element) {
      //console.log("element", element)
      sum = sum + element.criteriaWeight;
    });
    if (sum == this.count) {
      this.greaterCriteriaWeight = false;
      this.invalidCriteriaWeight = false;
    } else if (sum > this.count) {
      this.greaterCriteriaWeight = true;
    } else if (sum < this.count) {
      this.invalidCriteriaWeight = true;
    }

    if (_.size(this.selectedCriteriaListData) == 0) {
      this.criteriaIsEmpty = true;
      console.log(
        'selectedCriteriaListData------',
        this.selectedCriteriaListData
      );
      this.invalidCriteriaWeight = false;
      this.greaterCriteriaWeight = false;
    }
    this.onEqualcriteriaUpdate();
    this.sendForReviewButtonEnable();
  }

  showCriteriaQuetionsPopup(selectedCriteria) {
    $('#EntityQuestionSetup').modal('toggle');
    //console.log("selectedCriteria", selectedCriteria)
    this.getQuestionList(selectedCriteria);
    if (_.size(this.selectedQuestionsList)) {
      this.selectedQuestionListEmpty = false;
    } else {
      this.selectedQuestionListEmpty = true;
    }
  }

  selectedNameToShow(criteriaDetails) {
    var self = this;
    if (_.size(this.selectedQuestionsListCache)) {
      var index = _.find(
        this.selectedQuestionsListCache,
        function (selectedQuestion) {
          return selectedQuestion.evaluationCriteriaId == criteriaDetails._id;
        }
      );
      ////console.log("index-selcetd name", index);
      if (!_.isUndefined(index)) {
        return false;
      } else {
        return true;
      }
    } else if (_.size(this.editFormSelectedQuestion)) {
      if (
        _.size(this.editFormSelectedQuestion) &&
        _.size(this.selectedQuestionsListCache) > 0
      ) {
        var editQuestionIndex = _.find(
          self.editFormSelectedQuestion,
          function (editQuestion) {
            return editQuestion.evaluationCriteriaId == criteriaDetails._id;
          }
        );

        if (!_.isUndefined(editQuestionIndex)) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  getCheckEqualCriteria(criteriaWeight){
    var checkCriteriaWeight = [];
    criteriaWeight.forEach(function (element) {
      if(!checkCriteriaWeight.find(ele => ele == element.criteriaWeight)){
        checkCriteriaWeight.push(element.criteriaWeight);
      }
    })
    
    return checkCriteriaWeight.length==1;
  }

  // this function will call as soon as edit form data has to added into question list
  addQuestionsForEditForm(selectCriteria, callback) {
    var self = this;
    this.questions = [];
    var evaluationTypeId = this.entityForm.controls.evaluationType.value;
    var evaluationCriteriaId = selectCriteria._id;

    //console.log("added questions function called")

    this.boardEvaluationService
      .getEvaluationQuestionsByCriteriaIdList(evaluationCriteriaId)
      .then((newdata) => {
        if (newdata.status == 0) {
          // this.questions = newdata.newdata;
          //console.log("New Data", newdata)
          this.questions = newdata.questionList.map(function (o) {
            //
            return {
              _id: o._id,
              questionText: o.QuestionText,
            };
          });
          this.questions.forEach(function (element) {
            element.isEnable = true;
            element.isChecked = false;
            element.evaluationCriteriaId = evaluationCriteriaId;
          });

          //console.log("New Data this.questions", this.questions, self.editFormSelectedQuestion)

          // for selectedQuestions from API edit
          if (this.evaluationFormId != undefined) {
            _.forEach(
              self.editFormSelectedQuestion,
              function (selectedQuestion) {
                var index = _.findIndex(self.questions, function (question) {
                  return (
                    _.get(
                      _.get(selectedQuestion, 'EvaluationQuestionId'),
                      '_id'
                    ) == _.get(question, '_id')
                  );
                });
                if (index >= 0) {
                  //console.log("index", index)
                  self.questions[index].isChecked = true;
                  self.selectedQuestionsList.push(self.questions[index]);
                  self.selectedQuestionsListCache.push(self.questions[index]);
                  self.selectedQuestionListEmpty = false;
                  //console.log("self.selectedQuestionsList", self.selectedQuestionsList)
                }
              }
            );

            this.selectedQuestionsList = _.uniqBy(
              this.selectedQuestionsList,
              '_id'
            );
            this.selectedQuestionsListCache = _.uniqBy(
              this.selectedQuestionsListCache,
              '_id'
            );
            //console.log("self.selectedQuestionsListCache", self.selectedQuestionsListCache)
          }

          callback();
        } else {
        }
      });

    if (_.size(this.selectedQuestionsListCache)) {
      self.selectedQuestionsList = _.filter(
        self.selectedQuestionsListCache,
        function (selectedQuestion) {
          return selectedQuestion.evaluationCriteriaId == evaluationCriteriaId;
        }
      );
    }

    //console.log("self.selectedQuestionsList", this.selectedQuestionsList)
  }

  clickable() {
    //console.log("clearance");
  }

  getQuestionList(selectedCriteria) {
    var self = this;
    this.questions = [];
    var evaluationTypeId = this.entityForm.controls.evaluationType.value;
    var evaluationCriteriaId = selectedCriteria._id;

    this.boardEvaluationService
      .getEvaluationQuestionsByCriteriaIdList(evaluationCriteriaId)
      .then((newdata) => {
        if (newdata.status == 0) {
          // this.questions = newdata.newdata;
          //console.log("New Data", newdata)
          this.questions = newdata.questionList.map(function (o) {
            //
            return {
              _id: o._id,
              questionText: o.QuestionText,
            };
          });
          this.questions.forEach(function (element) {
            element.isEnable = true;
            element.isChecked = false;
            element.evaluationCriteriaId = evaluationCriteriaId;
          });

          //console.log("New Data this.questions", this.questions, self.selectedQuestionsListCache)

          if (_.size(self.selectedQuestionsListCache)) {
            _.forEach(
              self.selectedQuestionsListCache,
              function (selectedQuestion) {
                var index = _.findIndex(self.questions, function (question) {
                  return (
                    _.get(selectedQuestion, '_id') == _.get(question, '_id')
                  );
                });
                if (index >= 0) {
                  //console.log("index", index)
                  self.questions[index].isChecked = true;
                  selectedQuestion.isChecked = true;
                  self.selectedQuestionsList.push(self.questions[index]);
                  // self.selectedQuestionsListCache.push(self.questions[index])

                  // self.selectedQuestionsList[index].isChecked = true;
                  // self.selectedQuestionsListCache[index].isChecked = true;
                  self.selectedQuestionListEmpty = false;
                  //console.log("self.selectedQuestionsList", self.selectedQuestionsList, evaluationCriteriaId)
                }
              }
            );
          }

          this.selectedQuestionsList = _.uniqBy(
            this.selectedQuestionsList,
            '_id'
          );
          this.selectedQuestionsListCache = _.uniqBy(
            this.selectedQuestionsListCache,
            '_id'
          );
          //console.log("self.selectedQuestionsListCache", self.selectedQuestionsListCache)
        } else {
        }
      });
    // this.selectedQuestionsList.forEach(function(element){
    //   if(evaluationCriteriaId == this.selectedQuestionsList.evaluationCriteriaId){
    //     this.selectedQuestionsList =[];
    //   }
    // })

    if (_.size(this.selectedQuestionsListCache)) {
      self.selectedQuestionsList = _.filter(
        self.selectedQuestionsListCache,
        function (selectedQuestion) {
          return selectedQuestion.evaluationCriteriaId == evaluationCriteriaId;
        }
      );
    }

    // if (_.size(self.selectedQuestionsList)) {
    //   //console.log("self.selectedQuestionListEmpty = true")
    //   self.selectedQuestionListEmpty = false;
    // }

    //console.log("self.selectedQuestionsList", this.selectedQuestionsList)
  }

  question_Changed(event) {
    //console.log(event);
    var self = this;

    if (event.target.checked == true) {
      this.questions.forEach(function (element) {
        if (event.target.value == element._id) {
          element.isEnable = false;
          element.isChecked = true;
        }
      });
      //console.log("=======================", this.questions)
    } else {
      this.questions.forEach(function (element) {
        if (event.target.value == element._id && element.isEnable == false) {
          element.isEnable = false;
          element.isChecked = false;
        }
        if (event.target.value == element._id && element.isEnable == true) {
          event.target.checked = true;
        }
      });
    }
  }

  addQuestions() {
    var self = this;
    //$('#selectedCriteriatable').DataTable().destroy();
    // $('#selectedCriteriatable').DataTable().destroy();
    this.questions.forEach(function (element) {
      if (element.isChecked == true) {
        let filteredTask = _.filter(
          self.selectedQuestionsList,
          function (selectedCriteriaQuestion) {
            return element._id == selectedCriteriaQuestion._id;
          }
        );
        //console.log('filteredTask', filteredTask)
        //console.log("element", element)
        if (_.size(filteredTask) == 0) {
          self.selectedQuestionsList.push(element);
          element.isEnable = true;
        }
      }
    });

    this.selectedQuestionsList = _.uniqBy(this.selectedQuestionsList, '_id');
    //console.log("pushed data", this.selectedQuestionsList)
    // this.dtTrigger.next();
    if (self.selectedQuestionsList.length <= 0) {
      self.selectedQuestionListEmpty = true;
    } else {
      self.selectedQuestionListEmpty = false;
    }
  }

  removeQuestions() {
    var self = this;
    var selectedQuestionsIdArray = [];
    //console.log("this.selectedQuestionsList", this.selectedQuestionsList)
    _.forEach(this.selectedQuestionsList, function (selectedCriteriaQuestion) {
      // selectedCriteriaQuestion._id == questionID ;

      if (selectedCriteriaQuestion.isChecked) {
        // let filteredQuestion = _.indexOf(self.selectedQuestionsList, function (selectedQuestion) {
        //   //console.log("selectedCriteriaQuestion._id",selectedCriteriaQuestion._id)

        //   //console.log("selectedQuestion._id",selectedQuestion._id)
        //   return  selectedCriteriaQuestion._id == selectedQuestion._id
        // })

        // //console.log("filteredQuestion",filteredQuestion);

        selectedQuestionsIdArray.push(selectedCriteriaQuestion._id);
      }
    });
    //console.log("selectedQuestionsIdArray", selectedQuestionsIdArray);

    if (_.size(selectedQuestionsIdArray)) {
      _.forEach(selectedQuestionsIdArray, function (element) {
        self.removeSelectedQuestionIntoQuestionList(element);
      });
    }

    // self.removeSelectedQuestionIntoQuestionList("id")s
    // //console.log("index",index);
    // if(index){
    //   this.selectedQuestionsList.splice(index,1);
    // }
    // $('#selectedCriteriatable').DataTable().destroy();
    //console.log("this.selectedCriteriaListData", this.selectedQuestionsList);

    if (this.selectedQuestionsList.length <= 0) {
      this.selectedQuestionListEmpty = true;
    }

    // this.dtTrigger.next();
  }

  removeSelectedQuestionIntoQuestionList(id) {
    var i = 0;
    for (i; i < this.selectedQuestionsList.length; i++) {
      //console.log("id found", this.selectedQuestionsList[i])
      if (this.selectedQuestionsList[i]._id == id) {
        //console.log("id found")

        this.selectedQuestionsList.splice(i, 1);
        this.questions.forEach(function (element) {
          element.isChecked = false;
          element.isEnable = false;
        });
      }
    }

    // var index =  _.findIndex(this.selectedQuestionsListCache, function(selectedQuestion){
    //   return _.get(selectedQuestion, '_id') == id;
    // })

    // if(index >= 0){
    //   this.selectedQuestionsListCache.splice(index, 1);
    // }

    //console.log("indexOfQuestion", id, this.selectedQuestionsListCache);

    //console.log("this.questions", this.questions);
    // this.questions.forEach(function (element) {
    //   if (element._id == id) {

    //     element.isEnable = false;
    //     element.isChecked = false;
    //     // var checkBoxHtmlElement = <HTMLInputElement>document.getElementById(id)
    //     // checkBoxHtmlElement.checked = false;
    //   }
    // })
  }

  categoryWeightCalculation(index, selectedCriteria) {
    this.greaterCriteriaWeight = true;
    this.invalidCriteriaWeight = true;
    
  
    var sum = 0;
    this.count = 100;
    var perCriteriaWeight;
    var criteriaIdOfWeight;
    this.selectedCriteriaListData[index].criteriaWeight = selectedCriteria;
    criteriaIdOfWeight = this.selectedCriteriaListData[index]._id;

    this.selectedCriteriaListData.forEach(function (element) {
      //console.log("element", element)
      // criteriaIdOfWeight = element._id;
      perCriteriaWeight = element.criteriaWeight;
      console.log('element.criteriaWeight', element.criteriaWeight);
      sum = sum + element.criteriaWeight;
    });

    sum =  Math.round(sum);

    console.log("sum", sum)
    console.log("this.count", this.count)
    // console.log("perCriteriaWeight", perCriteriaWeight)
    // console.log("this.sendEntityFormForReview", this.sendEntityFormForReview)
    if (sum == this.count && perCriteriaWeight != 0) {
      this.greaterCriteriaWeight = false;
      this.invalidCriteriaWeight = false;
      
      this.sendForReviewButtonEnable();
    } else if (sum > this.count) {
      this.greaterCriteriaWeight = true;
      this.sendEntityFormForReview = false;
    } else if (sum < this.count) {
      this.invalidCriteriaWeight = true;
      this.sendEntityFormForReview = false;
    }

    var index1 = _.findIndex(this.EvaluationCriterias, function (criteria) {
      return _.get(criteria, 'EvaluationCriteriaID') == criteriaIdOfWeight;
    });

    if (index1 >= 0) {
      this.EvaluationCriterias[index1].CriteriaWeight = selectedCriteria;
      //console.log("this.selectedCriteriaListData._id", criteriaIdOfWeight)
      //console.log("index1", index1);
      //console.log("this.EvaluationCriterias", this.EvaluationCriterias);
    }

    //console.log(" this.sendEntityFormForReview = false;", this.sendEntityFormForReview)
  }

  cancleSelectedQuestionList() {
    //console.log("selected quetion List to get canclled", this.selectedQuestionsList)
    this.selectedQuestionsList = [];
  }

  saveSelectedQuestions() {
    $('#EntityQuestionSetup').modal('toggle');
    var self = this;
    var evaluationCriteriaID;
    var evaluationCriteriaIdForWeight;
    var EvaluationQuestion = [];
    var evaluationQuestionId;
    var evaluationCriteriaWeight;

    this.selectedQuestionsList = _.uniqBy(this.selectedQuestionsList, '_id');
    //console.log("this.selectedQuestionsList--unique byyyyyyyy", this.selectedQuestionsList)

    this.selectedQuestionsList.forEach(function (element) {
      evaluationCriteriaID = element.evaluationCriteriaId;
    });

    //console.log("evaluationCriteriaID", evaluationCriteriaID);
    var cachedArray = [];
    if (_.size(this.selectedQuestionsListCache)) {
      cachedArray = _.remove(
        self.selectedQuestionsListCache,
        function (selectedElement) {
          return selectedElement.evaluationCriteriaId == evaluationCriteriaID;
        }
      );
      //console.log("self.selectedQuestionsListCache - removed", self.selectedQuestionsListCache, self.selectedQuestionsList);
      //console.log("cachedArray", cachedArray)
      _.forEach(self.selectedQuestionsList, function (question) {
        self.selectedQuestionsListCache.push(question);
      });
      //console.log("self.selectedQuestionsListCache", self.selectedQuestionsListCache);
    } else {
      this.selectedQuestionsListCache = this.selectedQuestionsList;
    }

    this.selectedQuestionsListCache = _.uniqBy(
      this.selectedQuestionsListCache,
      '_id'
    );
    //console.log("this.selectedQuestionsListcache--unique byyyyyyyy", this.selectedQuestionsListCache)

    this.selectedQuestionsList.forEach(function (element) {
      evaluationCriteriaID = element.evaluationCriteriaId;
      EvaluationQuestion.push({ EvaluationQuestionId: element._id });
    });

    let criteriaIdIndex = _.findIndex(
      self.selectedCriteriaListData,
      function (selectedCriteria) {
        return _.get(selectedCriteria, '_id') == evaluationCriteriaID;
      }
    );

    if (criteriaIdIndex >= 0) {
      evaluationCriteriaWeight =
        self.selectedCriteriaListData[criteriaIdIndex].criteriaWeight;
    } else {
      evaluationCriteriaWeight = 0;
    }

    //console.log("index", criteriaIdIndex, self.selectedCriteriaListData[criteriaIdIndex].criteriaWeight);

    //console.log("_.size(this.EvaluationCriterias)", _.size(this.EvaluationCriterias))
    if (_.size(this.EvaluationCriterias)) {
      //console.log("inside index dept")

      var index = _.findIndex(self.EvaluationCriterias, function (criteria) {
        return _.get(criteria, 'EvaluationCriteriaID') == evaluationCriteriaID;
      });

      //console.log("index", index);

      if (index >= 0) {
        self.EvaluationCriterias[index].EvaluationQuestion = EvaluationQuestion;
      } else {
        this.EvaluationCriterias.push({
          EvaluationCriteriaID: evaluationCriteriaID,
          CriteriaWeight: evaluationCriteriaWeight,
          EvaluationQuestion: EvaluationQuestion,
        });
      }
    } else {
      this.EvaluationCriterias.push({
        EvaluationCriteriaID: evaluationCriteriaID,
        CriteriaWeight: evaluationCriteriaWeight,
        EvaluationQuestion: EvaluationQuestion,
      });
    }
    //console.log("EvaluationCriterias", this.EvaluationCriterias);

    // this will change the status in question list array , only those questions will have checked property false which are not present in selectedquestionlistarray
    this.questions.forEach(function (element) {
      if (element.evaluationCriteriaId == evaluationCriteriaID) {
        _.forEach(self.selectedQuestionsList, function (question) {
          if (question._id == element._id) {
            element.isEnable = true;
            element.isChecked = true;
          }
        });
      }
    });

    this.sendForReviewButtonEnable();
  }

  sendForReviewButtonEnable() {
    var self = this;
    //console.log("EvaluationTypeID============", this.selectedCriteriaListData)
    var totalCriteriaCount = _.size(this.selectedCriteriaListData);
    if (totalCriteriaCount > 0) {
      var countForCriteriaQuestions = 0;
      //console.log("EvaluationTypeID============count", totalCriteriaCount)
      this.selectedCriteriaListData.forEach(function (element) {
        var entityCriteriaId = element._id;
        //console.log("entityCriteriaId", entityCriteriaId, )
        var index = _.findIndex(
          self.selectedQuestionsListCache,
          function (criteria) {
            return _.get(criteria, 'evaluationCriteriaId') == entityCriteriaId;
          }
        );

        if (index >= 0) {
          //console.log("present", index)
          countForCriteriaQuestions++;
        }
      });

      if (totalCriteriaCount == countForCriteriaQuestions) {
        this.sendEntityFormForReview = false;
      } else {
        this.sendEntityFormForReview = true;
      }
    } else {
      this.sendEntityFormForReview = true;
    }
  }

  // to change selected question checked status in selected question list
  selectedQuestionCheckboxChange(questionDetails, event) {
    var index = _.findIndex(
      this.selectedQuestionsList,
      function (selectedQuestion) {
        return _.get(selectedQuestion, '_id') == questionDetails._id;
      }
    );
    //console.log("index", index)
    if (index >= 0) {
      this.selectedQuestionsList[index].isChecked = event.target.checked;
    }
    //console.log("this.selectedQuestionsList - selectedCheckbox", this.selectedQuestionsList)
  }
}
