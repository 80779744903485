import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { BoardEvaluationService } from '../../../services/boardEvaluation.service';
import * as _ from 'lodash';
import * as moment from 'moment';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-review-details-popup',
  templateUrl: './review-details-popup.component.html',
  styleUrls: ['./review-details-popup.component.css'],
})
export class ReviewDetailsPopupComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};

  dtTrigger: Subject<any> = new Subject();

  @Input('formDetails') formDetails: any;
  reviewerFormDetails: any;
  enableTableShow: Boolean = false;

  constructor(
    private boardEvaluationService: BoardEvaluationService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private router: Router
  ) {}

  ngOnInit() {
    //console.log("formDetails", this.formDetails);
    var self = this;

    this.boardEvaluationService
      .getReviewersDetailsEvaluationForm(this.formDetails._id)
      .then((response) => {
        this.reviewerFormDetails = response.EvaluationQuestionnaire[0];
        if (_.size(this.reviewerFormDetails.Reviewers) > 0) {
          this.enableTableShow = true;
        } else {
          this.enableTableShow = false;
        }
        this.dtTrigger.next();
        //console.log("reviewers Details", this.reviewerFormDetails.Reviewers);
        var reviewerStatus;
        var i = 0;
        for (i; i < this.reviewerFormDetails.Reviewers.length; i++) {
          reviewerStatus = this.reviewerFormDetails.Reviewers[i].ReviewerStatus;
          //console.log("reviewerStatus",reviewerStatus)
          if (reviewerStatus == 'Approve')
            this.reviewerFormDetails.Reviewers[i].ReviewerStatus = 'Approved';
          else if (reviewerStatus == 'Reject')
            this.reviewerFormDetails.Reviewers[i].ReviewerStatus = 'Rejected';
          else if (reviewerStatus == 'Review')
            this.reviewerFormDetails.Reviewers[i].ReviewerStatus =
              'To be Reviewed';
        }
      });
  }

  editEvaluationForm(ReviewerId) {
    //console.log("this.formDetails._id", this.formDetails._id, ReviewerId);
    this.activeModal.close();
    this.router.navigate([
      'structure',
      {
        outlets: {
          'structure-outlet': [
            'questionnaireReview',
            this.formDetails._id,
            ReviewerId,
          ],
        },
      },
    ]);
  }

  close() {
    this.activeModal.close();
  }
}
