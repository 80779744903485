import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { EntityMasterService } from 'src/app/services/entityMaster.service';
import { RoleMasterService } from 'src/app/services/rolemaster.service';
import * as _ from 'lodash';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
declare var $: any;



@Component({
  selector: 'app-entity-master',
  templateUrl: './entity-master.component.html',
  styleUrls: ['./entity-master.component.css']
})
export class EntityMasterComponent implements OnInit {
  entityForm: FormGroup;
  entityId: any;
  updatedEntity: any;
  EvaluationType: any;
  EvaluationTypeData: any;
  errorMessage: any;
  headerSuccessFlag: boolean = false;
  headerErrorFlag: boolean = false;
  headerSuccessMessage: string = 'Success !';
  headerErrorMessage: string = 'Failure !';
  disableSavebutton: boolean = false;
  disableupdatebutton: boolean = false;
  currentEntityIDToUpdate: any;

  constructor( private roleMasterService: RoleMasterService,
    private entityMasterService: EntityMasterService,
    public fb: FormBuilder,
    public renderer: Renderer2,
    private modalService: NgbModal) { }

  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};

  dtTrigger: Subject<any> = new Subject();
  ngOnInit() {
    $('body').removeClass('dashboard-page');

    this.dtOptions = {
      destroy: true,
      pagingType: 'full_numbers',
      paging: true,
      // scrollY: "440px",
      // scrollCollapse: true,
      stateSave: true,
      stateDuration: -1,
      pageLength: 10,
      search: true,
      language: {
        //made changes for datatables for next previous button
        paginate: {
          first: '<<',
          last: '>>',
          next: '>',
          previous: '<',
        },
      },
    };

    this.entityForm = new FormGroup({
      evaltype: new FormControl('', [Validators.required]),
    });

    // get Entity Call
    this.evaluationTypeDataList();
  }

  resetFormValues() {
    this.EvaluationType = '';
  }

  evaluationTypeDataList() {
    this.entityMasterService.getEntityList().then((res) => {
      this.EvaluationTypeData = res.evalTypeList;
      this.dtTrigger.next();
      // setTimeout(() => {this.dtTrigger.next()});
    });
  }

  closeForm(id) {
    $('#entityMasterTable').DataTable({ destroy: true }).destroy();
    // $('#' + id).modal('toggle');
    this.ngOnInit();
  }

  evaluationTypeChangeEvent(event) {
    //console.log("event.target.value",event.target.value)
    if (event.target.value.trim() == '') {
      //console.log("event.target.value",event.target.value)
      this.entityForm.controls.evaltype.setValue(event.target.value.trim());
      this.entityForm.controls.evaltype.updateValueAndValidity();
    }
    else {
      this.EvaluationType = event.target.value.trim()
    }
  }

  //add entity
  submitEntity() {
    this.disableSavebutton = true;
    var entityObj = {
      EvaluationType: this.EvaluationType.trim(),
    };
  
    this.entityMasterService.addEntityMaster(entityObj).then((entity) => {
      this.disableSavebutton = false;
  
      // Close and reset modal
      $('#myModal').modal('hide');
      this.removeBackdrop(); // Ensure backdrop is removed
      
      if (entity.status == 0) {
        $('#alertTitle').text("Success!");
        $('#alertTitle').css("color", "black");
        $('#alertMessage').text("Entity saved successfully.");
        $('#alertMessage').css("color", "black");
        $('#messagePopUp').modal('toggle');
        this.messagePopUpTimeoutToogle();
  
        const modalRef = this.modalService.open(ConfirmationPopupComponent, {
          backdrop: 'static',
        });
        modalRef.componentInstance.Message = entity.message;
  
        modalRef.result.then(
          (resp) => {
            // Cleanup and reload table
            this.cleanupAfterModal();
          },
          (error) => {
            // Cleanup and reload table on error
            this.cleanupAfterModal();
          }
        );
        this.dtTrigger.next();
      } else {
        $('#alertTitle').css('color', '#f00');
        $('#alertTitle').text("Failure!");
        $('#alertMessage').css('color', '#f00');
        $('#alertMessage').text("Unable to save entity.");
        $('#messagePopUp').modal('toggle');
        this.messagePopUpTimeoutToogle();
  
        const modalRef = this.modalService.open(ConfirmationPopupComponent, {
          backdrop: 'static',
        });
        modalRef.componentInstance.Message = entity.message;
  
        modalRef.result.then(
          (resp) => {
            // Cleanup and reload table on success
            this.cleanupAfterModal();
          },
          (error) => {
            // Cleanup and reload table on error
            this.cleanupAfterModal();
          }
        );
      }
    });
  }
  
  removeBackdrop() {
    // Manually remove any remaining backdrop classes after modal closes
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }
  
  cleanupAfterModal() {
    // Destroy the DataTable and reload the component after closing modals
    $('#entityMasterTable').DataTable({ destroy: true }).destroy();
    $('#myModal').modal('hide');
    this.ngOnInit();  // Re-initialize the component state
  }
  

  //edit entity
  editEntity(evaluationTypeData) {
    $('#editingEntityModal').modal('toggle');
    this.entityForm.controls.evaltype.setValue(
      evaluationTypeData.EvaluationType
    );
    // this.EvaluationType = evaluationTypeData.EvaluationType;
    this.currentEntityIDToUpdate = evaluationTypeData._id;
  }

  //update existing entity
  submitUpdatedEntity() {
    this.disableupdatebutton = true;
    var entityObj = {
      EvaluationTypeID: this.currentEntityIDToUpdate,
      EvaluationType: this.EvaluationType,
    };
    this.entityMasterService
      .updateEntityMaster(entityObj)
      .then((updateEntity) => {
        this.disableupdatebutton = false;
        if (updateEntity.status == 0) {
          var enitityData = updateEntity.Data;
          setTimeout(() => {
            $('#myModal').modal('hide');
        }, 300);
          $('#alertTitle').text("Success!");
          $('#alertTitle').css("color", "black");
          $('#alertMessage').text("entity details updated successfully.");
          $('#alertMessage').css("color", "black");
          $('#messagePopUp').modal('toggle');
          this.messagePopUpTimeoutToogle();
          const modalRef = this.modalService.open(ConfirmationPopupComponent, {
            backdrop: 'static',
          });
          modalRef.componentInstance.Message = updateEntity.message;
          modalRef.result.then(
            (resp) => {
              //console.log("updated entity")
              $('#entityMasterTable').DataTable({ destroy: true }).destroy();
              $('#editingEntityModal').modal('hide');
              this.ngOnInit();
            },
            (error) => {
              //console.log("updated entity error")
              $('#entityMasterTable').DataTable({ destroy: true }).destroy();
              $('#editingEntityModal').modal('hide');
              this.ngOnInit();
            }
          );
          this.entityForm.reset();
        } else {
          setTimeout(() => {
            $('#myModal').modal('hide');
        }, 300);
          $('#alertTitle').css('color', '#f00');
          $('#alertTitle').text("Failure!");
          $('#alertMessage').css('color', '#f00');
          $('#alertMessage').text("unable to update entity");
          $('#messagePopUp').modal('toggle');
          this.messagePopUpTimeoutToogle();
          const modalRef = this.modalService.open(ConfirmationPopupComponent, {
            backdrop: 'static',
          });
          modalRef.componentInstance.Message = updateEntity.message;
          modalRef.result.then(
            (resp) => {
              $('#entityMasterTable').DataTable({ destroy: true }).destroy();
              $('#editingEntityModal').modal('hide');
              this.ngOnInit();
            },
            (error) => {
              $('#entityMasterTable').DataTable({ destroy: true }).destroy();
              $('#editingEntityModal').modal('hide');
              this.ngOnInit();
            }
          );
          this.entityForm.reset();
        }
      });
  }

  //delete entity
  confirmdeleteEntity(evaluationTypeData) {
    this.entityId = evaluationTypeData._id;
    $('#roleDeletionMessageModal').modal('toggle');
  }

  deleteEntity() {
    this.entityMasterService
      .deleteEntityMaster(this.entityId)
      .then((deleteEntity) => {
        if (deleteEntity.status == 0) {
          $('#roleDeletionMessageModal').modal('toggle');
          // this.errorMessage = "Entity deleted successfully";
          this.errorMessage = deleteEntity.message;
          $('#errorMessageModal').modal('toggle');
          this.headerSuccessFlag = true;
          //this.messagePopUpTimeoutToogle();

          setTimeout(() => {
            $('#errorMessageModal').modal('toggle');
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.evaluationTypeDataList();
            });
            this.headerSuccessFlag = false;
          }, 3000);
        } else {
          this.errorMessage = deleteEntity.message;
          $('#roleDeletionMessageModal').modal('toggle');
          this.headerErrorFlag = true;
          $('#errorMessageModal').modal('toggle');
          setTimeout(() => {
            $('#errorMessageModal').modal('toggle');
            this.headerErrorFlag = false;
          }, 3000);
        }
      });
  }

  messagePopUpTimeoutToogle() {
    setTimeout(function () {
      var popUpClass = $('#messagePopUp').attr('class');
      if (popUpClass == 'modal fade show') {
        $('#messagePopUp').modal('toggle');
      }
    }, 3000);
  }
}
