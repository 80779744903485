<section>
  <div class="modal-header">
    <h4 class="modal-title">Review Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="table-responsive table-bordered margin-bottom-50">
      <table  datatable [dtOptions]="dtOptions" id="entityMasterTable" [dtTrigger]="dtTrigger" class="table table-hover compact cell-border nowrap table-striped dataTable"
        style="background:transparent" width="100%">
        <thead>
          <tr>
              <th>Sr. No.</th>
              <th>Reviewer Name</th>
              <th>Status</th>
              <th>Action</th>
          </tr>
        </thead>
        <tbody *ngIf="enableTableShow">
          <tr *ngFor="let reviewer of reviewerFormDetails.Reviewers;let count= index;">
            <td>{{count + 1}}</td>
            <td>{{reviewer.ReviewerId ?  (reviewer.ReviewerId.Title + " " + reviewer.ReviewerId.FirstName + " " +reviewer.ReviewerId.LastName) : " "}}</td>
            <td>{{reviewer.ReviewerStatus}}</td>
            <td style="text-align: center">
              <button class="btn btn-sm btn-primary">
                  <div class="fa fa-eye" (click)="editEvaluationForm(reviewer.ReviewerId._id)"></div>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-default btn-sm" (click)="close()">
      Cancel
    </button>
  </div>


</section>