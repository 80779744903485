import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl, EmailValidator } from '@angular/forms';
import { ForgotPasswordService } from './../../services/forgot-password.service';
import { Router } from '@angular/router';

import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { NotificationComponent } from './../notification/notification.component';
import * as _ from 'lodash';
import { SuccessNotificationComponent } from './../success-notification/success-notification.component';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  public resetPasswordForm: FormGroup;
  passwordVisible: boolean = false;  // Flag to track visibility of the password
  rePasswordVisible: boolean = false;  // Flag to track visibility of the password

  constructor(private fb: FormBuilder, private forgotPasswordService: ForgotPasswordService, private modalService: NgbModal, private router: Router) { }

  ngOnInit() {
    $("body").removeClass("dashboard-page");
    $('.cat__menu-left').hide();
    const userCredential = this.forgotPasswordService.getUserCredential();
    if (!userCredential.length) {
      this.router.navigate(['requestPassword'])
    }
    this.resetPasswordForm = new FormGroup({
      'password': new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%\*#?&])[a-zA-Z0-9$@$!%\*#?&]{8,}")
      ]),
      'confirmPassword': new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%\*#?&])[a-zA-Z0-9$@$!%\*#?&]{8,}")
      ])
    }, passwordMatchValidator)
    // console.log(this.resetPasswordForm.get('password'))

    function passwordMatchValidator(g: FormGroup) {
      return g.get('password').value === g.get('confirmPassword').value
        ? null : { 'mismatch': true };
    }

    var token = localStorage.getItem('token');
    if (_.isNil(token)) {
      // this.getCompanies();
      this.hideTopBar();
    }
    else { //if already login    
      this.showTopBar();
      // this.router.navigate(["dashboards/alpha"]);
      this.router.navigate(['structure', { outlets: { 'structure-outlet': 'dashboards/alpha' } }]);
    }
  }

  togglePasswordVisibility(For) {
    if(For == 're'){
      this.rePasswordVisible = !this.rePasswordVisible;
    }else{
      this.passwordVisible = !this.passwordVisible;
    }
  }

  hideTopBar() {
    $(".cat__top-bar").hide();
    $(".cat__menu-left").hide(); //hide the sidebar        
    $(".cat__top-bar__right").hide(); //hide the logout icon
    $(".cat__footer").hide();
  }

  showTopBar() {
    $(".cat__menu-left").show();
    $(".cat__top-bar__right").show();
    $(".cat__top-bar").show();
    $(".cat__footer").show();
  }

  submitPassword() {
    const self = this;
    const userCredential = this.forgotPasswordService.getUserCredential();
    // const localObject = JSON.parse(localStorage.getItem('emailAndCode'))
    const passwordObject = {
      'userToken': userCredential[0].userToken,
      'SecretCode': userCredential[0].secretCode,
      'NewPassword': self.resetPasswordForm.get('password').value
    }
    this.forgotPasswordService.resetPassword(passwordObject).then(response => {
      if (response.status == 0) {
        localStorage.removeItem('emailAndCode');
        // redirect to the login page.
        this.router.navigate(['login'])
        const modalRef = this.modalService.open(NotificationComponent);
        modalRef.componentInstance.notify = { status: 'Success', message: 'Password changed successfully' };
      } else if(response.status == 2){
        const modalRef = this.modalService.open(NotificationComponent);
        modalRef.componentInstance.notify = { status: 'Failure', message:response.message };
      }
      else {
        const modalRef = this.modalService.open(NotificationComponent)
        modalRef.componentInstance.notify = { status: 'Failure', message: response.message };
      }
    })
  }


  
  blinkCur1(){  
    var inputValue = this.resetPasswordForm.get('password').value;
    var numChars = inputValue.length;
    var showText = "";
    var i;

    for (i = 0; i < numChars; i++) {
      showText += "&#8226;";
    }

    $('.fake-input1').html('<span class="blinking-cursor">'+ showText +'|</span>');
    // $('.fake-input span').text('|');
  }
  blinkCurOut1(){
    $('.fake-input1 span').text('');

    var inputValue = this.resetPasswordForm.get('password').value;
    var numChars = inputValue.length;
    var showText = "";
    var i;

    if( inputValue.length > 0){
      for (i = 0; i < numChars; i++) {
        showText += "&#8226;";
      }
  
      $('.fake-input1').html(showText);
    }else{
      $('.fake-input1').html('New Password');
    }
    


    
  }
  RemoveSavePassword1() {
    var inputValue = this.resetPasswordForm.get('password').value;
    var numChars = inputValue.length;
    var showText = "";
    var i;

    if(this.resetPasswordForm.controls['password'].hasError('pattern')){
      $('.input-box-msg').css('height','100px')
    }else{
      $('.input-box-msg').css('height','40px')
    }

    for (i = 0; i < numChars; i++) {
      showText += "&#8226;";
    }

    $('.fake-input1').html(showText);

  }


  blinkCur(){  
    var inputValue = this.resetPasswordForm.get('confirmPassword').value;
    var numChars = inputValue.length;
    var showText = "";
    var i;

    for (i = 0; i < numChars; i++) {
      showText += "&#8226;";
    }

    $('.fake-input').html('<span class="blinking-cursor">'+ showText +'|</span>');
    // $('.fake-input span').text('|');
  }
  blinkCurOut(){
    $('.fake-input span').text('');

    var inputValue = this.resetPasswordForm.get('confirmPassword').value;
    var numChars = inputValue.length;
    var showText = "";
    var i;

    if( inputValue.length > 0){
      for (i = 0; i < numChars; i++) {
        showText += "&#8226;";
      }
  
      $('.fake-input').html(showText);
    }else{
      $('.fake-input').html('Re-enter New Password');
    }
    


    
  }
  RemoveSavePassword() {
    var inputValue = this.resetPasswordForm.get('confirmPassword').value;
    var numChars = inputValue.length;
    var showText = "";
    var i;

    for (i = 0; i < numChars; i++) {
      showText += "&#8226;";
    }

    $('.fake-input').html(showText);

  }

}
