import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.css']
})
export class ConfirmationPopupComponent implements OnInit {

  @Input("Message") Message: any;


  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal) { }

  ngOnInit() {


    setTimeout(() => {
      if (this.Message !== 'userDefault') {
        this.activeModal.close();
      }
    }, 2000);
  }

 

  onClick(type: any){
    this.activeModal.close(type);
  }
}
