<section>
  <div>
      <div class="inner-wrap">
          <p class="text-center">
              <img src="assets/modules/dummy-assets/common/img/GovEVA.png" alt="GovEVA">
          </p>
          <h2>Forgot Password</h2>
          <form [formGroup]="requestPasswordForm">

              <div class="form-group">
                  <input [(ngModel)]="emailId" (focusout)="getCompaniesByEmailId()" class="form-control" placeholder="Email" type="text" formControlName="emailId">
                  <div *ngIf="requestPasswordForm.controls['emailId'].hasError('email') && !requestPasswordForm.controls['emailId'].hasError('required')   && requestPasswordForm.controls['emailId'].touched"
                  class="form-control-error-custom" style="text-align:left;">
                  Please enter the correct Email.
                </div>
              </div>
              <div class="form-group">

                  <ng-select [notFoundMsg]="isEmailForCompany" placeholder="Select Company" [items]="companyList" formControlName="companyName" style="text-align: left;"></ng-select>

                  <div *ngIf="requestPasswordForm.controls['companyName'].hasError('required') && requestPasswordForm.controls['companyName'].touched" class="form-control-error-custom"
                      style="text-align:left;">
                      Company Name is required.
                  </div>
              </div>
              <h6>
                  <a href="#" style="float: right;" novalidate [routerLink]="['/login']">Back to Sign In</a>
              </h6>
              <div class="clearfix"></div>
              <button type="submit" class="btn btn-primary btn-block mr-2 mb-2" style="margin-top: 10px;" [disabled]="!requestPasswordForm.valid" (click)="submitEmail()"><i aria-hidden="true"></i>Send OTP</button>
          </form>
      </div>
  </div>
</section>