import { Component, OnInit, ViewChild } from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { BoardEvaluationService } from "../../../services/boardEvaluation.service";

import * as _ from "lodash";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BoardEvalStaticComponent } from "src/app/board-eval-static/board-eval-static.component";
declare var $: any;
declare var jQuery: any;

@Component({
  selector: "app-individual-report-list",
  templateUrl: "./individual-report-list.component.html",
  styleUrls: ["./individual-report-list.component.css"],
})
export class IndividualReportListComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  userInfo: any;
  evaluationFormDetails: any = [];

  constructor(private boardEvaluationService: BoardEvaluationService, private modalService: NgbModal, private router: Router) { }

  ngOnInit() {
    // this.openBoardEvalPage();
    $("body").removeClass("dashboard-page");

    this.userInfo = JSON.parse(localStorage.getItem("user"));

    this.dtOptions = {
      destroy: true,
      pagingType: "full_numbers",
      paging: true,
      // scrollY: "440px",
      // scrollCollapse: true,
      stateSave: true,
      stateDuration: -1,
      pageLength: 10,
      search: true,
      language: {
        //made changes for datatables for next previous button
        paginate: {
          first: "<<",
          last: ">>",
          next: ">",
          previous: "<",
        },
      },
    };
    this.boardEvaluationService.getIndividualReportList(this.userInfo._id).then((response) => {
      $("#individualReportListTable").DataTable().destroy();
      if (response.status == 0) {
        //console.log("response", response)
        this.evaluationFormDetails = response.evaluationFormDetails;
        this.dtTrigger.next();
      } else {
        this.dtTrigger.next();
      }
    });
  }

  viewReport(evaluationFormDetail) {
    // var evalueeId = evaluationFormDetail.EvaluaeeId;
    var evalueeId = evaluationFormDetail.AssesseeId;

    var evaluationFormId = evaluationFormDetail.EvaluationFormId._id;
    var evaluationTypeId = evaluationFormDetail.EvaluationTypeID._id;
    var periodParameter = evaluationFormDetail.PeriodParameter;

    this.router.navigate([
      "structure",
      {
        outlets: {
          "structure-outlet": [
            "individualReport",
            evalueeId,
            evaluationFormId,
            evaluationTypeId,
            periodParameter,
          ],
        },
      },
    ]);
  }

  openBoardEvalPage() {
    const modalRef = this.modalService.open(BoardEvalStaticComponent, {
      size: 'lg',
      backdrop: 'static'
    })
   }
}
