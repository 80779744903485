import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()

export class EntityMasterService {

    constructor(private http: HttpClient) {

    }

    getEntityList(): Promise<any> {
        // return this.http.get(environment.clientUrl + "roleMaster/getUserRoles")
        return this.http.get(environment.clientUrl + 'evaluationMaster/getEvaluationTypeList')
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
    }

    addEntityMaster(entity): Promise<any> {
        return this.http.post(environment.clientUrl + 'evaluationMaster/addEvaluationType', entity)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    // editEntityMaster(updatedEntity): Promise<any> {
    //     return this.http.put(environment.clientUrl + 'entityMaster/edit/' + updatedEntity.entityId, (updatedEntity))
    //         .toPromise()
    //         .then(response => {
    //             return Promise.resolve(response);
    //         })
    // }

    updateEntityMaster(updatedEntity): Promise<any> {
        return this.http.post(environment.clientUrl + 'evaluationMaster/updateEvaluationType' , updatedEntity)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    deleteEntityMaster(entityId): Promise<any> {
        return this.http.get(environment.clientUrl + 'evaluationMaster/deleteEvaluationType?evaluationTypeID=' + entityId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

}