import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  FormBuilder,
} from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { TypeOfUser } from '../../../enums/TypeOfUser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BoardEvaluationExecutionService } from '../../../services/boardEvaluationExecution.service';
import { BoardEvaluationService } from '../../../services/boardEvaluation.service';
import { ReviewStatus } from '../../../enums/roadmapReviewStatus';
import { NgxPaginationModule } from 'ngx-pagination';
import {
  ActivatedRoute,
  RouterState,
  Router,
  NavigationEnd,
} from '@angular/router';

import * as _ from 'lodash';
declare var $: any;
import * as moment from 'moment';

@Component({
  selector: 'app-questionnaire-review',
  templateUrl: './questionnaire-review.component.html',
  styleUrls: ['./questionnaire-review.component.css'],
})
export class QuestionnaireReviewComponent implements OnInit {
  reviewForm: FormGroup;
  toggle = true;
  evaluationReviewTypeDatas = [];
  evaluationName: any;
  scoreRange: any;
  endDate: any;
  evaluationType: any;
  evaluationReviewCriteria = [];
  questionListAgainstCriteria = [];
  evaluationFormId: any;
  userInfo: any;
  disableReviewButton: boolean = false;
  evaluationCriteriaDetails = [];
  evaluationCriterialist = [];
  criteria: any;
  islastCriteria: Boolean = false;
  // collection = [];
  p: number = 1;
  formId: any;
  scoreParticular: any[];
  shouldShow: boolean = true;
  showSpinner: boolean = false;

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  todayDate: Date = new Date();
  isAnonymous: boolean = false;
  reviewerId: any;
  disableSavebutton: boolean = false;
  showAcceptButton: boolean = false;

  constructor(
    private boardEvaluationExecutionService: BoardEvaluationExecutionService,
    private boardEvaluationService: BoardEvaluationService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    // for (let i = 1; i <= 100; i++) {
    //   this.collection.push(`item ${i}`);
    // }
  }

  ngOnInit() {
    $('body').removeClass('dashboard-page');

    this.dtOptions = {
      destroy: true,
      pagingType: 'full_numbers',
      paging: true,
      stateSave: true,
      stateDuration: -1,
      pageLength: 10,
      search: true,
      language: {
        //made changes for datatables for next previous button
        paginate: {
          first: '<<',
          last: '>>',
          next: '>',
          previous: '<',
        },
      },
    };

    this.reviewForm = new FormGroup({});

    this.userInfo = JSON.parse(localStorage.getItem('user'));
    //console.log("userInfo", this.userInfo._id)
    this.getDetails();
    this.route.params.subscribe((params) => {
      //console.log("params", params);
      this.formId = params.id;
      this.reviewerId = params.reviewerId;
      //console.log("formId", this.formId, this.reviewerId);
    });

    if (this.userInfo._id == this.reviewerId) {
      this.showAcceptButton = true;
    } else {
      this.showAcceptButton = false;
    }

    this.getFormDetailsByFomId(this.formId);
  }

  closeForm(id) {
    $('#' + id).modal('toggle');
  }

  getDetails() {
    var self = this;
    $('#evaluationReviewTable').DataTable().destroy();
    this.boardEvaluationExecutionService
      .getEvaluationReviewFormDetails(this.userInfo._id)
      .then((response) => {
        if (response.status == 0) {
          // this.dtTrigger.next();
          // this.evaluationReviewTypeDatas = response.EvaluationFormList;
          // this.formId = response.EvaluationFormList[0]._id;
          // this.evaluationCriteriaDetails = response.EvaluationFormList[0].EvaluationCriterias;
          // var firstCriteriaId = this.evaluationCriteriaDetails[0].EvaluationCriteriaID._id;
          // this.criteria = _.filter(this.evaluationCriteriaDetails, function (element) {
          //   return _.get(_.get(element, 'EvaluationCriteriaID'), '_id') == firstCriteriaId
          // })
          // //console.log("this.criteria", this.criteria)
          // //console.log("this.evaluationCriteriaDetails", this.evaluationCriteriaDetails)
          // //console.log("this.evaluationReviewTypeDatas", this.evaluationReviewTypeDatas)
        } else {
          // this.dtTrigger.next();
          // this.evaluationReviewTypeDatas = response.EvaluationFormList;
          // //console.log("this.evaluationReviewTypeDatas", this.evaluationReviewTypeDatas)
        }
      });
  }

  getFormDetailsByFomId(formId) {
    var self = this;
    //console.log("formId1", formId)
    this.boardEvaluationService
      .getFormDetailsByFormId(formId)
      .then((response) => {
        if (response.status == 0) {
          //console.log("responseformid", response)
          this.evaluationReviewTypeDatas = response.EvaluationQuestionnaire;

          //console.log("this.evaluationReviewTypeDatas", this.evaluationReviewTypeDatas)

          this.formId = response.EvaluationQuestionnaire[0]._id;
          this.evaluationCriteriaDetails =
            response.EvaluationQuestionnaire[0].EvaluationCriterias;
          //console.log("this.evaluationCriteriaDetails", this.evaluationCriteriaDetails);
          this.toGetLastIndexOfArray(this.evaluationCriteriaDetails);
          _.forEach(this.evaluationCriteriaDetails, function (criteria) {
            if (_.size(criteria)) {
              _.forEach(
                criteria.EvaluationQuestion,
                function (questionDetails) {
                  if (_.size(questionDetails)) {
                    // //console.log("questionDetails",questionDetails)
                    self.reviewForm.addControl(
                      questionDetails.EvaluationQuestionId._id,
                      new FormControl('', [])
                    );
                  }
                }
              );
            }
          });

          // var reviewerCommentsDataById =  this.userInfo._id ;this.reviewerId
          var reviewerCommentsDataById = this.reviewerId;

          var ReviewersData = response.EvaluationQuestionnaire[0].Reviewers; // whole reviewer from API FORM
          this.scoreParticular =
            response.EvaluationQuestionnaire[0].ScoreParticulars;
          //filter our reviewersdata by above ID
          if (_.size(this.scoreParticular)) {
            _.forEach(this.scoreParticular, function (scoreParticularDetails) {
              if (_.size(scoreParticularDetails)) {
                //console.log("scoreParticularDetails===========", scoreParticularDetails.Value, scoreParticularDetails.Key)
              }
            });
          }

          var reviewerDataToShow = _.filter(ReviewersData, function (reviewer) {
            return reviewer.ReviewerId._id == reviewerCommentsDataById;
          });

          if (_.size(reviewerDataToShow)) {
            //console.log("reviewerDataToShow", reviewerDataToShow)
            if (_.size(reviewerDataToShow[0].Comments)) {
              _.forEach(
                reviewerDataToShow[0].Comments,
                function (reviewComment) {
                  // console.log("reviewComments",self.reviewForm, reviewComment)
                  self.reviewForm.controls[reviewComment.EvaluationQuestionId]
                    ? self.reviewForm.controls[
                        reviewComment.EvaluationQuestionId
                      ].setValue(reviewComment.commentText)
                    : console.log('');
                }
              );
            }
          }

          //console.log("reviewForm", this.reviewForm);

          var firstCriteriaId =
            this.evaluationCriteriaDetails[0].EvaluationCriteriaID._id;
          this.criteria = _.filter(
            this.evaluationCriteriaDetails,
            function (element) {
              return (
                _.get(_.get(element, 'EvaluationCriteriaID'), '_id') ==
                firstCriteriaId
              );
            }
          );
          //console.log("this.criteria", this.criteria)
          //console.log("this.evaluationCriteriaDetails", this.evaluationCriteriaDetails)
          //console.log("this.evaluationReviewTypeDatas", this.evaluationReviewTypeDatas);

          $(document).ready(function () {
            document
              .getElementById('0')
              .parentElement.classList.add('categoryHighlight');
          });
        } else {
          this.evaluationReviewTypeDatas = response.EvaluationQuestionnaire;
          //console.log("this.evaluationReviewTypeDatas", this.evaluationReviewTypeDatas)
        }
      });
  }

  toGetLastIndexOfArray(object) {
    var i = 1;
    var length = _.size(this.evaluationCriteriaDetails);
    //console.log("length=====================================", length)
    // for (i; i <= this.evaluationCriteriaDetails.length; i++) {
    //   if (length == i) {
    //     //console.log("found++++++++++++++")
    //     this.islastCriteria = true;
    //   }
    // }
    if (length == 1) {
      this.islastCriteria = true;
    }
  }

  showFormDetails(evaluationGetData) {
    this.evaluationFormId = evaluationGetData._id;
    this.disableReviewButton = this.checkReviewStatus(evaluationGetData);
    //console.log("disableReviewButton", this.disableReviewButton);
    var self = this;
    $('#viewFormDetailsDialogBox').modal('toggle');
    //console.log("evaluationGetData", evaluationGetData)
    self.questionListAgainstCriteria = [];
    this.evaluationType = evaluationGetData.EvaluationTypeID.EvaluationType;
    this.evaluationName = evaluationGetData.EvaluationFormTitle;
    this.scoreRange = evaluationGetData.ScoreRange;
    var date = new Date(evaluationGetData.EndDate);
    this.endDate = moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY');
    this.evaluationReviewCriteria = evaluationGetData.EvaluationCriterias;
    this.isAnonymous = evaluationGetData.IsAnonymous.toString();
    evaluationGetData.EvaluationCriterias.forEach(function (element) {
      self.questionListAgainstCriteria.push({
        EvaluationCriteria: element.EvaluationCriteriaID,
        EvaluationQuestion: element.EvaluationQuestion,
      });
      //console.log("this.questionListAgainstCriteria", self.questionListAgainstCriteria)
    });
  }

  reviewApproved() {
    // var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = this.userInfo._id;
    var reviewObject = {
      userId: userId,
      EvaluationFormId: this.evaluationFormId,
    };
    this.boardEvaluationService
      .reviewApproved(reviewObject)
      .then((response) => {
        if (response.status == 0) {
          $('#viewFormDetailsDialogBox').modal('toggle');
          //console.log("form saved succesfully", response);
          $('#alertTitle').text('Success!');
          $('#alertTitle').css('color', 'black');
          $('#alertMessage').text(response.message);
          $('#alertMessage').css('color', 'black');
          $('#messagePopUp').modal('toggle');
          $('#selectedCriteriatable').DataTable().destroy();
          this.messagePopUpTimeoutToogle();
          // this.dtTrigger.next();
          this.getDetails();
        } else {
          $('#viewFormDetailsDialogBox').modal('toggle');
          $('#alertTitle').css('color', '#f00');
          $('#alertTitle').text('Failure!');
          $('#alertMessage').css('color', '#f00');
          $('#alertMessage').text(response.message);
          $('#messagePopUp').modal('toggle');
          this.messagePopUpTimeoutToogle();
          this.getDetails();
        }
      });
  }

  checkReviewStatus(formDetails) {
    var self = this;
    if (_.size(formDetails.Reviewers)) {
      var index = _.findIndex(formDetails.Reviewers, function (reviewer) {
        return _.get(reviewer, 'ReviewerId') == self.userInfo._id;
      });

      //console.log("index", index)
      if (index >= 0) {
        if (
          formDetails.Reviewers[index].ReviewerStatus == ReviewStatus.Approve
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  messagePopUpTimeoutToogle() {
    setTimeout(function () {
      var popUpClass = $('#messagePopUp').attr('class');
      if (popUpClass == 'modal fade show') {
        $('#messagePopUp').modal('toggle');
      }
    }, 2000);
  }

  questionListAgainstCriteriaList(evalCriteriaId, isLast, count, event) {
    //console.log("event", event)
    // event.target.style.backgroundColor = "green"
    // this.shouldShow = !this.shouldShow;
    // if (this.shouldShow) {
    //   event.target.classList.add("btn-secondary");
    //   event.target.classList.remove("btn-primary");
    // } else {
    //   event.target.classList.add("btn-primary");
    //   event.target.classList.remove("btn-secondary");
    // }

    if (_.size(this.evaluationCriteriaDetails)) {
      var listSize = _.size(this.evaluationCriteriaDetails);
      var i = 0;
      //console.log("i", i, count)
      for (i; i < listSize; i++) {
        if (i == count) {
          document
            .getElementById(i.toString())
            .parentElement.classList.add('categoryHighlight');
        } else {
          if (
            document
              .getElementById(i.toString())
              .parentElement.classList.contains('categoryHighlight')
          ) {
            document
              .getElementById(i.toString())
              .parentElement.classList.remove('categoryHighlight');
          }
        }
      }
    }

    this.toggle = !this.toggle;
    //console.log("evalCriteriaId", evalCriteriaId)
    this.criteria = _.filter(
      this.evaluationCriteriaDetails,
      function (element) {
        return (
          _.get(_.get(element, 'EvaluationCriteriaID'), '_id') == evalCriteriaId
        );
      }
    );

    if (isLast) {
      this.islastCriteria = isLast;
    } else {
      this.islastCriteria = isLast;
    }
    //console.log("this.evaluationCriteriaDetails", this.evaluationCriteriaDetails)
    //console.log("criteria", this.criteria);
  }

  checkCategoryHighlight() {
    //console.log("highlight", )
  }

  questionWithComments(status) {
    this.disableSavebutton = true;
    this.showSpinner = true;
    //console.log("status", status)
    var userId = this.userInfo._id;
    var reviewStatus;
    if (ReviewStatus.Approve == status) {
      reviewStatus = ReviewStatus.Approve;
    }

    if (ReviewStatus.Reject == status) {
      reviewStatus = ReviewStatus.Reject;
    }

    var reviewObject = {
      userId: userId,
      EvaluationFormId: this.formId,
      ReviewStatus: reviewStatus,
    };

    //console.log("reviewObject", reviewObject)
    this.boardEvaluationService
      .reviewApproved(reviewObject)
      .then((response) => {
        this.disableSavebutton = false;
        if (response.status == 0) {
          this.showSpinner = false;
          $('#viewFormDetailsDialogBox').modal('toggle');
          //console.log("form saved succesfully", response);
          $('#alertTitle').text('Success!');
          $('#alertTitle').css('color', 'black');
          $('#alertMessage').text(response.message);
          $('#alertMessage').css('color', 'black');
          $('#messagePopUp').modal('toggle');
          $('#selectedCriteriatable').DataTable().destroy();

          this.messagePopUpTimeoutToogle();
          // to navigate to question review list

          this.router.navigate([
            'structure',
            { outlets: { 'structure-outlet': ['evaluationReview'] } },
          ]);

          // this.dtTrigger.next();
          this.getDetails();
        } else {
          this.showSpinner = false;
          $('#viewFormDetailsDialogBox').modal('toggle');
          $('#alertTitle').css('color', '#f00');
          $('#alertTitle').text('Failure!');
          $('#alertMessage').css('color', '#f00');
          $('#alertMessage').text(response.message);
          $('#messagePopUp').modal('toggle');
          this.messagePopUpTimeoutToogle();
          this.getDetails();
        }
      });
  }

  commentChangeEvent(event, questionId) {
    if (event.target.value.trim() == '') {
      this.reviewForm.controls.comment.setValue(event.target.value.trim());
      this.reviewForm.controls.comment.updateValueAndValidity();
    }
    var comment = event.target.value;
    //console.log("comment", comment)
    var questionObjt = {
      questionId: questionId,
      commentText: comment,
      formId: this.formId,
      reviewerId: this.userInfo._id,
    };
    //console.log("questionObjt", questionObjt)
    this.boardEvaluationService
      .updateReviewerComments(questionObjt)
      .then((response) => {
        if (response.status == 0) {
          //console.log("response", response)
        } else {
        }
      });
  }
}
