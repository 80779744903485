import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonFunction } from '../../app/helpers/commonFunction';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CompanyMasterService {

    constructor(
        private Http: HttpClient, 
        private http: HttpClient, 
        public commonFunction: CommonFunction, 
        private handler: HttpBackend
        ) 
        
    {
        this.Http = new HttpClient(handler);
    }

    getCompanyList(): Promise<any> {
        return this.http.get(environment.clientUrl + 'companyMaster/companyData')
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    getIndustryTypesList(): Promise<any> {
        return this.http.get(environment.clientUrl + 'industry/getIndustryListAll')
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    addCompany(newCompany): Promise<any> {
        //var headers = new Headers();
        //head('Content-Type', 'application/json');
        return this.http.post(environment.clientUrl + 'companyMaster/add', (newCompany))
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    deleteCompany(companyId): Promise<any> {
        //var headers = new Headers();
        //head('Content-Type', 'application/json');
        return this.http.get(environment.clientUrl + 'companyMaster/delete/' + companyId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    deleteHideCompany(companyId): Promise<any> {
        return this.http.get(environment.clientUrl + 'companyMaster/deleteHide/' + companyId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    editCompany(updatedCompany): Promise<any> {
        //var headers = new Headers();
        //head('Content-Type', 'application/json');
        return this.http.post(environment.clientUrl + 'companyMaster/edit/' + updatedCompany._id, (updatedCompany))
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    getRegistrationIDTypes(): Promise<any> {
        return this.http.get(environment.clientUrl + 'companyMaster/getRegistrationIDTypes')
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    getCategoryOfCompany(): Promise<any> {
        return this.http.get(environment.clientUrl + 'companyMaster/getTypesOfCompany')
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    getTypesOfCompany(): Promise<any> {
        return this.http.get(environment.clientUrl + 'companyMaster/getClassesOfCompany')
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    getIndustryTypes(industryCode): Promise<any> {
        // companyMaster/getTypesOfIndustry
        return this.http.get(environment.clientUrl + 'getIndustryList?IndustryCode=' + industryCode)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    getcompanyAutoComplete(): Promise<any> {
        var token = localStorage.getItem('token')
        let header = {};
        let head = (key, value) => {header[key] = value};
        head('x-access-token', token);
        return this.http.get(environment.clientUrl + 'companyMaster/companyAutoComplete', { headers: header })
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    //get industry id and house id of selected company
    getSelectedCompanyDetailsFromFinCode(finCode): Promise<any> {
        return this.http.get(environment.clientUrl + 'companyMaster/selectedCompanyDetailsFromFinCode?Fincode=' + finCode)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    //get industry and housing wise peers
    getindustryAndHousingWisePeers(industryCode, housingCode): Promise<any> {
        return this.http.get(
            `${environment.clientUrl}companyMaster/industryAndHousingWisePeers?IndustryCode=${industryCode}&HousingCode=${housingCode}`, 
            )
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    toggleTradingWindow(value, companyId): Promise<any> {

        return this.http.post(environment.clientUrl + 'companyMaster/updateTradingWindowStatus/' + value, { CompanyId: companyId })
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    getSingleCompnyDetails(companyId) {

        let headers = new Headers();

        var token = localStorage.getItem('token')
        var companyDetails = localStorage.getItem('companyDetailsGoveva')

        return this.Http.get(environment.clientUrl + 'companyMaster/getSingleCompanyCompanyData/' + companyId, {})
            .toPromise()
            .then((response: any) => {
                return Promise.resolve(response)
            })
    }


    getcompanyDetailsNonValidateURL(companyId) {
        return this.Http.get(environment.nonValidateUrl + 'companyMaster/getCompanyInformation/' + companyId)
            .pipe(
                map((res: any) => {
                    res.companyData = JSON.parse(this.commonFunction.decrypt(res.companyData))
                    return res;
                }),
                catchError(error => {
                    if (error.status === 401 || error.status === 403) {
                        console.error(error);
                    }
                    return throwError(error);
                    })
            )
    }


    getCompanyConfig(companyId) {
        return this.Http.get(environment.nonValidateUrl + 'companyMaster/auth/config/' + companyId)
            .pipe(
                map((res: any) => {
                    res.configData = JSON.parse(this.commonFunction.decrypt(res.configData))
                    return res;
                }),
                catchError(error => {
                    if (error.status === 401 || error.status === 403) {
                        console.error(error);
                        }
                    return throwError(error);
                    })
            )
    }



    getImageUrl(companyId): Promise<any> {

        return this.http.get(environment.nonValidateUrl + 'companyMaster/getImageUrl/' + companyId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    // to remove company logo
    removeLogo(companyLogoObj): Promise<any> {
        //var headers = new Headers();
        //head('Content-Type', 'application/json');
        return this.http.post(environment.clientUrl + 'companyMaster/removeImage', (companyLogoObj))
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    getGEMUrl(): Promise<any> {
        return this.http.get(environment.clientUrl + 'companyMaster/getGEMUrl')
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }


    getCompanyDetails(loginDetails): Promise<any> {
        return this.Http.post(environment.nonValidateUrl + 'dashboard/companyDetailsForAD/', loginDetails)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    getCompanyLoginForADUserWithCredentials(loginDetails): Promise<any> {
        return this.Http.post(environment.nonValidateUrl + 'dashboard/companyDetailsForAD/', loginDetails)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    addDetailsForJoinMeeting(joinMeetingDetails): Promise<any> {
        return this.http.post(environment.clientUrl + 'meetingAttendance/joinmeeting', joinMeetingDetails)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    getJoinmeetingDetails(meetingId): Promise<any> {
        return this.http.get(environment.clientUrl + 'meetingAttendance/getJoinmeetingDetails/' + meetingId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    updateDetailsForJoinMeeting(joinMeetingUser, meetingId): Promise<any> {
        return this.http.post(environment.clientUrl + 'meetingAttendance/updatejoinmeeting/' + meetingId, joinMeetingUser)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

}
