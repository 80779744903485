import { Component, OnInit, OnChanges, Input, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { DataTableDirective } from 'angular-datatables';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BoardEvaluationService } from '../../../services/boardEvaluation.service';

import { Subject, of, Observable } from 'rxjs';
import * as _ from 'lodash';
// import { BoardEvaluationService } from '../../../services/boardEvaluation.service';
import { Router } from '@angular/router';
import {
  debounceTime,
  map,
  distinctUntilChanged,
  switchMap,
  tap,
  catchError,
  elementAt,
} from 'rxjs/operators';

declare var $: any;
declare var jQuery: any;
declare var autosize: any;

@Component({
  selector: 'app-questionMasterBoardEval',
  templateUrl: './questionMasterBoardEval.component.html',
  styleUrls: ['./questionMasterBoardEval.component.css'],
})
export class QuestionMasterBoardEvalComponent implements OnInit {
  questionForm: FormGroup;
  editquestionForm: FormGroup;
  questionMasterList: any[];
  evaluationTypeList: any[];
  evaluationCriteriaList: any[];
  evaluationType: any;
  evaluationCriteria: any;
  questionID: any;
  questionIdToDelete: any;
  editCriteriaId: any;
  evaluationevalCriteriaList: any[];
  disableSavebutton: boolean = false;
  disableupdatebutton: boolean = false;

  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};

  dtTrigger: Subject<any> = new Subject();

  constructor(
    private router: Router,
    private boardEvaluationService: BoardEvaluationService
  ) {}

  ngOnInit() {
    this.dtOptions = {
      destroy: true,
      pagingType: 'full_numbers',
      paging: true,
      // scrollY: "440px",
      // scrollCollapse: true,
      stateSave: true,
      stateDuration: -1,
      pageLength: 10,
      search: true,
      language: {
        //made changes for datatables for next previous button
        paginate: {
          first: '<<',
          last: '>>',
          next: '>',
          previous: '<',
        },
      },
    };

    this.questionForm = new FormGroup({
      // evaluationType: new FormControl('', [Validators.required]),
      evaluationCriteria: new FormControl('', [Validators.required]),
      questionText: new FormControl('', [Validators.required]),
    });

    this.editquestionForm = new FormGroup({
      // editEvaluationType: new FormControl('', [Validators.required]),
      editEvaluationCriteria: new FormControl('', [Validators.required]),
      editQuestionText: new FormControl('', [Validators.required]),
    });

    this.getQuestionMasterList();
    this.getEntityListDetails();
    this.getEvaluationCriteriaList();
  }

  getInputValueChange(event: Event): string {
    // @ts-ignore
    return (event.target ? (event.target as HTMLInputElement) : event).value;
  }

  getQuestionMasterList() {
    this.boardEvaluationService
      .getQuestionMasterBoardEvalList()
      .then((response) => {
        if (response.status == 0) {
          $('#questionMasterTable').DataTable().destroy();
          if (_.size(response.questionList)) {
            this.questionMasterList = response.questionList;
            this.dtTrigger.next();
          } else {
            this.questionMasterList = [];
            this.dtTrigger.next();
          }
        } else {
          this.questionMasterList = [];
          this.dtTrigger.next();
        }
      });
  }

  getEntityListDetails() {
    // this.questionForm.reset();

    this.boardEvaluationService.getEvaluationTypeList().then((response) => {
      if (response.status == 0) {
        if (_.size(response.evalTypeList)) {
          this.evaluationTypeList = response.evalTypeList;
        } else {
          this.evaluationTypeList = [];
        }
      } else {
        this.evaluationTypeList = [];
      }
    });
  }

  getEvaluationCriteriaList() {
    this.boardEvaluationService.getEvaluationCriteriaList().then((response) => {
      if (response.status == 0) {
        //console.log("criterialist new",response)
        if (_.size(response.evalCriteriaList)) {
          this.evaluationevalCriteriaList = response.evalCriteriaList;
        } else {
          this.evaluationevalCriteriaList = [];
        }
      } else {
        this.evaluationevalCriteriaList = [];
      }
    });
  }

  // getCriteriaListByEntityId(event) {
  //
  //   this.questionForm.controls.evaluationCriteria.setValue("");
  //   this.questionForm.controls.evaluationCriteria.updateValueAndValidity();
  //   var evaluationTypeId = event.target.value;
  //  //console.log("this.questionForm.controls.evaluationCriteria.",this.questionForm.controls.evaluationType.value)

  //   //console.log("event", event.target.value)
  //   this.boardEvaluationService.getEvaluationCriteriaByEvalIdList(evaluationTypeId).then(response => {
  //     if (response.status == 0) {
  //       if (_.size(response.evalCriteriaList)) {
  //         this.evaluationCriteriaList = response.evalCriteriaList;
  //       } else {
  //         this.evaluationCriteriaList = [];
  //       }
  //     } else {
  //       this.evaluationCriteriaList = [];
  //     }
  //   });
  // }

  // getCriteriaListByEntityIdForEditForm(event) {
  //
  //   this.editquestionForm.controls.editEvaluationCriteria.setValue("");
  //   this.editquestionForm.controls.editEvaluationCriteria.updateValueAndValidity();
  //   var evaluationTypeId = event.target.value;
  //  //console.log("this.questionForm.controls.evaluationCriteria.",this.editquestionForm.controls.editEvaluationCriteria.value)

  //   //console.log("event", event.target.value)
  //   this.boardEvaluationService.getEvaluationCriteriaByEvalIdList(evaluationTypeId).then(response => {
  //     if (response.status == 0) {
  //       if (_.size(response.evalCriteriaList)) {
  //         this.evaluationCriteriaList = response.evalCriteriaList;
  //       } else {
  //         this.evaluationCriteriaList = [];
  //       }
  //     } else {
  //       this.evaluationCriteriaList = [];
  //     }
  //   });
  // }

  closeForm() {
    this.ngOnInit();
  }

  submitQuestionDetails() {
    this.disableSavebutton = true;
    try {
      const self = this;

      var questionObject = {
        EvaluationCriteriaID: this.questionForm.controls.evaluationCriteria.value,
        QuestionText: this.questionForm.controls.questionText.value.trim(),
      };

      this.boardEvaluationService.addQuestionDetails(questionObject).then((response) => {
          this.disableSavebutton = false;
          $('#questionMasterModal').modal('hide');
          this.removeBackdrop(); // Manually remove any remaining backdrops

          if (response.status == 0) {
            $('#alertPopUpTitle').text('Success!').css('color', 'black');
            $('#alertMessage').text('Question text added successfully.').css('color', 'black');
            $('#messagePopUp').modal('toggle');
            this.messagePopUpTimeoutToogle();
            this.questionForm.reset();
            this.getQuestionMasterList();
          } else {
            $('#alertPopUpTitle').text('Failure!').css('color', '#f00');
            $('#alertMessage').text(response.message).css('color', '#f00');
            $('#messagePopUp').modal('toggle');
            this.messagePopUpTimeoutToogle();
            this.questionForm.reset();
            this.getQuestionMasterList();
          }
      });
    } catch (error) {}
}

removeBackdrop() {
    // Manually remove any remaining modal backdrop
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
}
  editQuestionDetails(selectedQuestionID) {
    if (selectedQuestionID != '') {
      //console.log("selectedQuestionID",selectedQuestionID)
      this.questionID = selectedQuestionID;
      // this.boardEvaluationService.getQuestionByQuestionID(this.questionID).then(response => {

      // if (response.status == 0) {
      this.editquestionForm.controls.editQuestionText.setValue(
        selectedQuestionID.QuestionText
      );

      // this.editquestionForm.controls.editEvaluationType.setValue(selectedQuestionID.EvaluationTypeID._id);
      // this.editquestionForm.controls.editEvaluationType.updateValueAndValidity();

      //console.log("hello edit function")
      // this.evaluationCriteriaList = response.evalCriteriaList;
      this.editCriteriaId = selectedQuestionID.EvaluationCriteriaID._id;
      this.editquestionForm.controls.editEvaluationCriteria.setValue(
        selectedQuestionID.EvaluationCriteriaID._id
      );
      this.editquestionForm.controls.editEvaluationCriteria.updateValueAndValidity();
      // this.boardEvaluationService.getEvaluationCriteriaByEvalIdList(selectedQuestionID.EvaluationCriteriaID._id).then(response => {
      //   if (response.status == 0) {
      //     if (_.size(response.evalCriteriaList)) {
      //       //console.log("criteria")
      //       this.evaluationCriteriaList = response.evalCriteriaList;
      //       this.editCriteriaId = selectedQuestionID.EvaluationCriteriaID._id;
      //       this.editquestionForm.controls.editEvaluationCriteria.setValue(selectedQuestionID.EvaluationCriteriaID._id);
      //       this.editquestionForm.controls.editEvaluationCriteria.updateValueAndValidity();
      //     } else {
      //       this.evaluationCriteriaList = [];
      //       this.editCriteriaId = selectedQuestionID.EvaluationCriteriaID._id;
      //       this.editquestionForm.controls.editEvaluationCriteria.setValue(selectedQuestionID.EvaluationCriteriaID._id);
      //       this.editquestionForm.controls.editEvaluationCriteria.updateValueAndValidity();
      //     }
      //   } else {
      //     this.evaluationCriteriaList = [];
      //     this.editCriteriaId = selectedQuestionID.EvaluationCriteriaID._id;
      //     this.editquestionForm.controls.editEvaluationCriteria.setValue(selectedQuestionID.EvaluationCriteriaID._id);
      //     this.editquestionForm.controls.editEvaluationCriteria.updateValueAndValidity();
      //   }
      // });
      // this.editquestionForm.controls.questionText.disable();
      // this.editquestionForm.controls.editEvaluationType.disable();
      // this.editquestionForm.controls.editEvaluationCriteria.setValue(selectedQuestionID.EvaluationCriteriaID);

      $('#editingQuestionModal').modal('toggle');
    } else {
      $('#alertPopUpTitle').css('color', '#f00');
      $('#alertPopUpTitle').text('Failure!');
      $('#alertMessage').text('QUestion Selection Failure');
      $('#alertMessage').css('color', '#f00');
      $('#messagePopUp').modal('toggle');
      this.messagePopUpTimeoutToogle();
    }
    //   });
    // } else {
    //   $('#alertPopUpTitle').css('color', '#f00');
    //   $('#alertPopUpTitle').text("Failure!");
    //   $('#alertMessage').text("Please select valid question.");
    //   $('#alertMessage').css('color', '#f00');
    //   $('#messagePopUp').modal('toggle');
    //   this.messagePopUpTimeoutToogle();
    //   this.questionForm.reset();
    // }
  }

  udpateQuestionDetails() {
    this.disableupdatebutton = true;
    const self = this;

    var questionObject = {
      evaluationQuestionID: this.questionID._id,
      // EvaluationTypeID: this.editquestionForm.controls.editEvaluationType.value,
      EvaluationCriteriaID:
        this.editquestionForm.controls.editEvaluationCriteria.value,
      questionText:
        this.editquestionForm.controls.editQuestionText.value.trim(),
    };
    //console.log("questionObject",questionObject);

    this.boardEvaluationService
      .updateEvaluationQuestionDetails(questionObject)
      .then((response) => {
        this.disableupdatebutton = false;
        if (response.status == 0) {
          $('#questionMasterTable').DataTable().destroy();
          $('#editingQuestionModal').modal('toggle');
          $('#alertPopUpTitle').text('Success!');
          $('#alertPopUpTitle').css('color', 'black');
          $('#alertMessage').text('Question text updated successfully.');
          $('#alertMessage').css('color', 'black');
          $('#messagePopUp').modal('toggle');
          this.messagePopUpTimeoutToogle();
          this.getQuestionMasterList();
          this.editquestionForm.reset();
        } else {
          $('#questionMasterTable').DataTable().destroy();
          $('#editingQuestionModal').modal('toggle');
          $('#alertPopUpTitle').text('Failure!');
          $('#alertPopUpTitle').css('color', '#f00');
          $('#alertMessage').text(response.message);
          $('#alertMessage').css('color', '#f00');
          $('#messagePopUp').modal('toggle');
          this.messagePopUpTimeoutToogle();
          this.getQuestionMasterList();
          this.editquestionForm.reset();
        }
      });
  }

  editquestionTextChange(event) {
    if (event.target.value.trim() == '') {
      this.editquestionForm.controls.editQuestionText.setValue(
        event.target.value.trim()
      );
      this.editquestionForm.controls.editQuestionText.updateValueAndValidity();
    }
  }

  questionFormquestionTextChange(event) {
    if (event.target.value.trim() == '') {
      //console.log("event.target.value",event.target)
      this.questionForm.controls.questionText.setValue(
        event.target.value.trim()
      );
      this.questionForm.controls.questionText.updateValueAndValidity();
    }
  }

  deleteQuestionIdToPass(question) {
    this.questionIdToDelete = question._id;
    //console.log("question",question,this.questionIdToDelete);
  }

  deleteQuestionDetails() {
    //console.log(" this.questionIdToDelete", this.questionIdToDelete);
    this.boardEvaluationService.deleteQuestionDetails(this.questionIdToDelete)
      .then((response) => {
        // Hide the modal and remove any remaining backdrops
        $('#deleteQuestionMasterModal').modal('toggle');
        this.removeBackdrop(); // Manually remove any leftover backdrops

        // Set alert details based on response status
        const isSuccess = response.status == 0;
        const alertColor = isSuccess ? 'black' : '#f00';
        const alertTitle = isSuccess ? 'Success!' : 'Failure!';
        
        $('#alertPopUpTitle').text(alertTitle).css('color', alertColor);
        $('#alertMessage').text(response.message).css('color', alertColor);
        $('#messagePopUp').modal('toggle');
        this.messagePopUpTimeoutToogle();

        // Refresh question list
        this.getQuestionMasterList();
        this.getEntityListDetails();
      });
  }

  messagePopUpTimeoutToogle() {
    setTimeout(function () {
      var popUpClass = $('#messagePopUp').attr('class');
      if (popUpClass == 'modal fade show') {
        $('#messagePopUp').modal('toggle');
      }
    }, 3000);
  }

  editCriteria(event) {
    //console.log("event", event.target.value)
  }
}
