import { CommonModule, DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { DataTablesModule } from 'angular-datatables';
import { ImageCropperModule } from 'ngx-img-cropper';
import { FooterComponent } from '../components/footer/footer.component';
import { MenuLeftComponent } from '../components/menu-left/menu-left.component';
import { MenuRightComponent } from '../components/menu-right/menu-right.component';
import { TopBarComponent } from '../components/top-bar/top-bar.component';
import { SharedModule } from '../shared/sharedmodule';
import { SharedComponentsModuleModule } from 'src/app/shared-components-module/shared-components-module.module';
import { ViewSmtpComponent } from '../smtp/view-smtp/view-smtp.component';
import { UINotificationComponent } from '../structure/notifications/notifications-UI.component';
import { AuthGuard } from '../authGuard/auth.guard';

import { AuditTrailModule } from './audit-trail/audit-trail.module';
import { BISModule } from './BIS/biscommunication.module';
// import { AppsModule } from './apps/apps.module';

import { AgGridModule } from 'ag-grid-angular';
import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey("CompanyName=GovEVA Private Limited,LicensedApplication=Goveva E-Carbon,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-035321,SupportServicesEnd=28_November_2023_[v2]_MTcwMTEyOTYwMDAwMA==f9eba16d2879f28cf7ff6020d0a782d7")

import { ChartsModule } from './charts/charts.module';
import { CompanymasterComponent } from './companymaster/companymaster.component';
import { ComponentsModule } from './components/components.module';
import { DashboardsModule } from './dashboards/dashboards.module';
import { DocumentationModule } from './documentation/documentation.module';
import { EcommerceModule } from './ecommerce/ecommerce.module';
import { InnerFormsModule } from './forms/forms.module';
import { GfmmoduleComponent } from './gfmmodule/gfmmodule.component';
import { GfmDashboardComponent } from './govevaFileManager/gfm-dashboard/gfm-dashboard.component';
import { govevaFileManagerModule } from './govevaFileManager/govevaFileManager.Module';
import { IconsModule } from './icons/icons.module';
import { LayoutModule } from './layout/layout.module';
import { PagesModule } from './pages/pages.module';
import { ReportsModule } from './reports/reports.module';
import { RoleMasterModule } from './role-master/role-master.module';
import { RolemasterComponent } from './rolemaster/rolemaster.component';
import { StructureComponent } from './structure.component';
import { structureRouting } from './structure.routing';
import { TablesModule } from './tables/tables.module';
import { UserMasterModule } from './user-master/user-master.module';
import { DataImportComponent } from '../data-import/data-import.component';
import { EntityMasterComponent } from './boardEvaluation/entity-master/entity-master.component';
import { EntityMasterService } from '../services/entityMaster.service';
import { ConfirmationPopupComponent } from './boardEvaluation/confirmation-popup/confirmation-popup.component';
import { EvaluateCategoryComponent } from './boardEvaluation/evaluate-category/evaluate-category.component';
import { EvalCategoryService } from '../services/evalCategory.service';
import { QuestionMasterBoardEvalComponent } from './boardEvaluation/questionMasterBoardEval/questionMasterBoardEval.component';
import { BoardEvaluationService } from '../services/boardEvaluation.service';
import { RoadmapModuleService } from '../services/roadmapModule.service';
import { BoardEvaluationExecutionService } from '../services/boardEvaluationExecution.service';
import { AssesorsListPopupComponent } from './boardEvaluation/assesors-list-popup/assesors-list-popup.component';
import { ReviewDetailsPopupComponent } from './boardEvaluation/review-details-popup/review-details-popup.component';
import { BrowserModule } from '@angular/platform-browser';
import { EvaluationReviewComponent } from './boardEvaluation/evaluation-review/evaluation-review.component';
import { EvaluationFormsComponent } from './boardEvaluation/evaluation-forms/evaluation-forms.component';
import { SummaryReportComponent } from './boardEvaluation/summary-report/summary-report.component';
import { IndividualReportListComponent } from './boardEvaluation/individual-report-list/individual-report-list.component';
import { EvaluationFormListComponent } from './boardEvaluation/evaluation-form-list/evaluation-form-list.component';
import { EvaluationMonitoringComponent } from './boardEvaluation/evaluation-monitoring/evaluation-monitoring.component';
import { EntityFormSetupComponent } from './boardEvaluation/entity-form-setup/entity-form-setup.component';
import { QuestionnaireAssignmentComponent } from './boardEvaluation/questionnaire-assignment/questionnaire-assignment.component';
import { QuestionnaireReviewComponent } from './boardEvaluation/questionnaire-review/questionnaire-review.component';

@NgModule({
  imports: [
    
    CommonModule,
    ReactiveFormsModule,
    // AppsModule,
    NgSelectModule,
    ChartsModule,
    ComponentsModule,
    DashboardsModule,
    DocumentationModule,
    EcommerceModule,
    InnerFormsModule,
    FormsModule,
    IconsModule,
    LayoutModule,
    PagesModule,
    TablesModule,
    NgbModule,
    DataTablesModule,
    RoleMasterModule,
    UserMasterModule,
    SharedModule,
    SharedComponentsModuleModule,
    ImageCropperModule,
    ReportsModule,
    AuditTrailModule,
    BISModule,
    govevaFileManagerModule,
    structureRouting,
    AgGridModule,
  ],
  providers: [AuthGuard, DatePipe,EntityMasterService,EvalCategoryService,BoardEvaluationService,RoadmapModuleService,BoardEvaluationExecutionService],
  declarations: [
    CompanymasterComponent,
    RolemasterComponent,
    UINotificationComponent,
    TopBarComponent,
    MenuLeftComponent,
    MenuRightComponent,
    FooterComponent,
    StructureComponent,
    GfmmoduleComponent,
    GfmDashboardComponent,
    ViewSmtpComponent,
    DataImportComponent,
    EntityMasterComponent,
    ConfirmationPopupComponent,
    EvaluateCategoryComponent,
    EvaluationReviewComponent,
    QuestionMasterBoardEvalComponent,
    AssesorsListPopupComponent,
    // GfmPopupforShareComponent,
    ReviewDetailsPopupComponent,
    EvaluationFormsComponent,
    EvaluationMonitoringComponent,
    QuestionnaireAssignmentComponent,
    // QuestionnaireReviewComponent,
    // IndividualReportListComponent,
    //EvaluationMonitoringComponent,
    //IndividualReportComponent,
    EvaluationFormListComponent,
    IndividualReportListComponent,
    SummaryReportComponent,
    QuestionnaireReviewComponent,
    //EvaluationFormsComponent,
    //EvaluationReviewComponent,
    //EvaluationFormToAssignComponent,
    EntityFormSetupComponent,
    //EntityMasterComponent,
    //EvaluateCategoryComponent,
    //RoadmapReviewerComponent,
    //SafeHtmlPipe
  ],
  exports: [],
  entryComponents: [],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class StructureModule {
  constructor() {}
}
