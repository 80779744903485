import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()

export class EvalCategoryService {

    constructor(private http: HttpClient) {

    }

    getEvaluationCategoryList(): Promise<any> {
        // return this.http.get(environment.clientUrl + "roleMaster/getUserRoles")
        return this.http.get(environment.clientUrl + 'evaluationMaster/getEvaluationCriteriaList')
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
    }

    addEvaluation(evaluationDatalist): Promise<any> {
        return this.http.post(environment.clientUrl + 'evaluationMaster/addEvaluationCriteria', evaluationDatalist)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    getEvaluationTypeList() : Promise<any>{
        // return this.http.get(environment.clientUrl + "roleMaster/getUserRoles")
        return this.http.get(environment.clientUrl + 'evaluationMaster/getEvaluationTypeList')
        .toPromise()
        .then(response => {
            return Promise.resolve(response)
        })
    }

    // editEvaluation(updatedEntity): Promise<any> {
    //     return this.http.put(environment.clientUrl + 'evaluationMaster/edit/' + updatedEntity.entityId, (updatedEntity))
    //         .toPromise()
    //         .then(response => {
    //             return Promise.resolve(response);
    //         })
    // }

    updateEvaluationCategory(updatedEvaluation): Promise<any> {
        return this.http.post(environment.clientUrl + 'evaluationMaster/updateEvaluationCriteria' , updatedEvaluation)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }


    deleteEvaluation(evalId): Promise<any> {
        return this.http.get(environment.clientUrl + 'evaluationMaster/deleteEvaluationCriteria?evaluationCriteriaID=' + evalId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    

}