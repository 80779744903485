import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import * as CryptoJS from 'crypto-js';
import { CommonFunction } from './../../app/helpers/commonFunction';
import { catchError, map } from 'rxjs/operators';



@Injectable({
  providedIn: 'any',
})
export class UserMasterService {
  constructor(
    private http: HttpClient,
    private httpWithoutClient: HttpClient,
    private handler: HttpBackend,
    private commonFunction: CommonFunction
  ) {
    //this.http = new HttpClient(handler);
  }

  //brings only authroises users.
  getUserList(): Promise<any> {
    const url = environment.clientUrl + 'userMaster/getUserList';
    return this.http
      .get(url)
      .toPromise()
      .then((response:any) => {
        response.result = JSON.parse(this.commonFunction.decrypt(response.result))
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }
  //get roles for excel file bulk upload
  getRoleForEcxcelFile(): Promise<any> {
    const url = environment.clientUrl + 'getRole';
    var token = localStorage.getItem('token');
    // var headers = new Headers();
    // var header;
    // header.append("x-access-token", token)
    return this.http
      .get(url, { headers: { 'x-access-token': token } })
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  //brings all users.
  getAllUserList(): Observable<any> {
    var companyInfo = localStorage.getItem('companyDetailsGoveva');
    var token = localStorage.getItem('token');
     let headers = {};
        let head = (key, header) => {headers[key] = header};
    head('X-Access-Token', token);
    head('govevacompany', companyInfo);    
    const url = environment.clientUrl + 'userMaster/getAllUserList';
    return this.http.get(url, {headers: headers}).pipe(
      map((res: any) => {
        res.result = JSON.parse(this.commonFunction.decrypt(res.result))
        return res;
      }),
      catchError(this.handleError)
    )
  }

  // Getting existing users
  getExistingUsersList(): Promise<any> {
    const url = environment.clientUrl + 'userMaster/getUniqueUserList';
    return this.http
      .get(url)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getInternalUserList(): Promise<any> {

    const url = environment.clientUrl + 'userMaster/getInternalUserList';
    return this.http.get(url).toPromise()
      .then((response: any) => {
        return Promise.resolve({ ...response, result: JSON.parse(this.commonFunction.decrypt(response.result)) });
      })
      .catch(this.handleError);
  }

  getalllUserList(): Promise<any> {

    const url = environment.clientUrl + 'userMaster/getUserListExceptsuperAdmin';
    return this.http.get(url).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  //Getting User details from lead table by User Id

  getUserdataByUserId(userId): Promise<any> {
    const url =
      environment.clientUrl + 'userMaster/getUniqueUserById/' + userId;
    return this.http
      .get(url)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getRoleListOfUser(): Promise<any> {
    const url = environment.clientUrl + 'roleMaster/getUserRoles';
    const token = localStorage.getItem('token');
    const companyDetails = JSON.parse(localStorage.getItem('companyDetails'))
    const headerDict = {
      'x-access-token':token,
      'govevacompany': JSON.stringify({"label":companyDetails.companyName, "value":companyDetails.companyID})
    }

    const requestOptions = { 
      headers: new HttpHeaders(headerDict)
    };
    return this.http
      .get(url, requestOptions)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getUserRoleByAssesmentId(assesmentId): Promise<any> {
    const url = environment.clientUrl + 'roleMaster/getUserRoleByAssesmentId/'+assesmentId;
    const token = localStorage.getItem('token');
    const companyDetails = JSON.parse(localStorage.getItem('companyDetails'))
    const headerDict = {
      'x-access-token':token,
      'govevacompany': JSON.stringify({"label":companyDetails.companyName, "value":companyDetails.companyID})
    }

    const requestOptions = { 
      headers: new HttpHeaders(headerDict)
    };
    return this.http
      .get(url, requestOptions)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getAdminRoles(): Promise<any> {
    const url = environment.clientUrl + 'roleMaster/getAdminRoles';
    const token = localStorage.getItem('token');
    const companyDetails = JSON.parse(localStorage.getItem('companyDetails'))
    const headerDict = {
      'x-access-token':token,
      'govevacompany': JSON.stringify({"label":companyDetails.companyName, "value":companyDetails.companyID})
    }

    const requestOptions = { 
      headers: new HttpHeaders(headerDict)
    };    
    return this.http
      .get(url, requestOptions)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getAssociatedCompanyList(uniqueUserId): Observable<any> {
    var companyInfo = localStorage.getItem('companyDetailsGoveva');
    var token = localStorage.getItem('token');
     let headers = {};
        let head = (key, header) => {headers[key] = header};
    head('X-Access-Token', token);
    head('govevacompany', companyInfo);        
    const url =
      environment.clientUrl +
      'userMaster/getAssociatedCompanyList/' +
      uniqueUserId;
    return this.http
      .get(url, {headers: headers}).pipe(catchError(this.handleError))
  }

  createUser(userObject): Promise<any> {
    const url = environment.nonValidateUrl + 'user/register';
    return this.http
      .post(url, userObject)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  deactivateUser(object): Promise<any> {
    const url = environment.clientUrl + 'userMaster/deactivate';
    return this.http
      .post(url, object)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  deleteHideUser(object): Promise<any> {
    const url = environment.clientUrl + 'userMaster/deletehide';
    return this.http
      .post(url, object)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getCompanyList(): Promise<any> {
    const url = environment.clientUrl + 'companyMaster/companyListForUserManagement';
    return this.http.get(url).toPromise()
      .then((response: any) => {
        return Promise.resolve({ ...response, companyList: JSON.parse(this.commonFunction.decrypt(response.companyList)) });
      })
      .catch(this.handleError);
  }

  activateUser(object): Promise<any> {
    const url = environment.clientUrl + 'user/activateUser';
    return this.http
      .post(url, object)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  // edit
  getUserByUserId(userId): Promise<any> {
    var token = localStorage.getItem('token')
    const url = environment.clientUrl + 'user/getUserByUserId/' + userId;
    return this.http.get(url, { headers: { 'x-access-token': token } }).toPromise()
      .then((response: any) => {
        response.userDetails = JSON.parse(this.commonFunction.decrypt(response.userDetails))
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  editUser(userObject): Promise<any> {
    const url = environment.clientUrl + 'user/editUser';
    let encryptedToken = this.commonFunction.encrypt(JSON.stringify(userObject))
    return this.http.post(url, { encryptedToken }).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getActiveCSMakerUsers(): Promise<any> {
    const url = environment.clientUrl + 'user/getCSMakerUsers';
    return this.http
      .get(url)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getActiveCSCheckerUsers(): Promise<any> {
    const url = environment.clientUrl + 'user/getCSCheckerUsers';
    return this.http
      .get(url)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getActiveICMakerUsers(): Promise<any> {
    const url = environment.clientUrl + 'user/getICMakerUsers';
    return this.http
      .get(url)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getActiveICCheckerUsers(): Promise<any> {
    const url = environment.clientUrl + 'user/getICChecekrUsers';
    return this.http
      .get(url)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  //get Titles(salutations)
  getSalutationTitle(): Promise<any> {
    const url = environment.clientUrl + 'user/salutationTitle';
    const token = localStorage.getItem('token');
    const companyDetails = JSON.parse(localStorage.getItem('companyDetails'))
    const headerDict = {
      'x-access-token':token,
      'govevacompany': JSON.stringify({"label":companyDetails.companyName, "value":companyDetails.companyID})
    }

    const requestOptions = { 
      headers: new HttpHeaders(headerDict)
    };    
    return this.http
      .get(url, requestOptions)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  //get Committee list
  getCommitteeList(): Promise<any> {
    const url = environment.pssServerUrl + 'committee';
    return this.httpWithoutClient
      .get(url)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  //create IC Checker and IC Maker
  createICUsers(userObject): Promise<any> {
    const url = environment.nonValidateUrl + 'user/create/ICUsers';
    return this.httpWithoutClient
      .post(url, userObject)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  getSubsideryList(): Promise<any> {
    const token = localStorage.getItem('token');
    const companyDetails = JSON.parse(localStorage.getItem('companyDetails'))
    const headerDict = {
      'x-access-token':token,
      'govevacompany': JSON.stringify({"label":companyDetails.companyName, "value":companyDetails.companyID})
    }

    const requestOptions = { 
      headers: new HttpHeaders(headerDict)
    };    
    return this.http
      .get(environment.clientUrl + 'companyMaster/companyData', requestOptions)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  authorizeUser(userObject): Promise<any> {
    const url = environment.clientUrl + 'user/authorizeUser';
    return this.http
      .post(url, userObject)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getUserListByPAN(pan): Promise<any> {
    const url =
      environment.clientUrl + 'userMaster/getUserListByPANCard/' + pan;
    return this.http
      .get(url)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  checkEmailIdExists(emailId): Promise<any> {
    const url =
      environment.clientUrl + 'userMaster/IsPrimaryEmailIdExists/' + emailId;
    return this.http
      .get(url)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  checkADIdExists(ADID, userId): Promise<any> {
    const url = environment.clientUrl + 'userMaster/IsADIdExists/' + ADID + "/" + userId;
    return this.http.get(url).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  checkADIdExistsAdd(ADID): Promise<any> {
    const url = environment.clientUrl + 'userMaster/IsADIdExistsAD/' + ADID;
    return this.http.get(url).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  checkSecondaryEmailIdExists(emailId): Promise<any> {
    const url =
      environment.clientUrl + 'userMaster/IsSecondaryEmailIdExists/' + emailId;
    return this.http
      .get(url)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  checkPANExists(PAN): Promise<any> {
    var panNo = this.encodePAN(PAN);
    const url = environment.clientUrl + 'userMaster/IsPANExists/' + panNo;
    return this.http
      .get(url)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  checkDINExists(DIN): Promise<any> {
    const url = environment.clientUrl + 'userMaster/IsDINExists/' + DIN;
    return this.http
      .get(url)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  //check condition while editing user
  checkPANExistsForExistingUser(userObject): Promise<any> {
    const url =
      environment.clientUrl + 'userMaster/checkPANExistsForExistingUser';
    return this.http
      .post(url, userObject)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  //check condition while editing user
  checkDINExistsForExistingUser(userObject): Promise<any> {
    const url =
      environment.clientUrl + 'userMaster/checkDINExistsForExistingUser';
    return this.http
      .post(url, userObject)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  // get UPSI admin view all roles wise user
  getUPSIAdminRoleWiseUser(): Promise<any> {
    return this.http
      .get(environment.clientUrl + 'resourceMaster/getUPSIAdminUser')
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  // unlock user 
  unlockUser(userId): Promise<any> {
    return this.http.get(environment.clientUrl + 'userMaster/unlockUser/' + userId)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  reAuthenticateUser(userPassword): Promise<any> {
    var userDetails = {
      password: userPassword
    }
    return this.http.post(environment.clientUrl + 'userMaster/reAuthenticate', userDetails)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  resetPasswordByAdmin(userId): Promise<any> {
    return this.http.get(environment.clientUrl + 'user/resetPassword/' + userId)
      .toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError)
  }

  resendActivationEmailByAdmin(userId): Promise<any> {
    return this.http.get(environment.clientUrl + 'user/resendActivationEmail/' + userId)
      .toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError)
  }

  encodePAN(panText) {
    const myString = panText; // Utf8-encoded string

    // PROCESS
    const encodedWord = CryptoJS.enc.Utf8.parse(myString); // encodedWord Array object
    const encoded = CryptoJS.enc.Base64.stringify(encodedWord); // string: 'NzUzMjI1NDE='
    return encoded;
  }

  getDepartment = () => {
    var token = localStorage.getItem('token');
    return new Promise((resolve, reject) => {
      const url = environment.pssServerUrl + 'department/master' + "/" + token;
      return this.http.get(url).toPromise()
        .then(response => {
          return resolve(response);
        })
        .catch(this.handleError)
    })
  }

  getBranch = () => {
    var token = localStorage.getItem('token');
    return new Promise((resolve, reject) => {
      const url = environment.pssServerUrl + 'brnch/master' + '/' + token;
      return this.http
        .get(url)
        .toPromise()
        .then((response) => {
          return resolve(response);
        })
        .catch(this.handleError)
    })
  }







}
