<style>


    .input-box,.input-box-msg{
      position:relative;
      height:40px;
    }
    
    input.real-input, .fake-input , .fake-input1{
    
        display: block;
        width: 100%;
        height: 30px;
        padding: .5rem .75rem;
        font-size: 1rem;
        line-height: 1.25;
        color: #74708d;
        background-color: #fff;
        background-image: none;
        background-clip: padding-box;
        /* border: 1px solid rgba(0,0,0,.15); */
        border-radius: .25rem;
        position:absolute;
        text-align: left;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    }
    
    /* input.real-input, .fake-input:hover {
      border: 1px solid #0190fe;
    }
    .form-group.input-box:hover .fake-input, .form-group.input-box:hover .fake-input input:focus {
      border: 1px solid #0190fe;
    }
    
    input.real-input, .fake-input1:hover {
      border: 1px solid #0190fe;
    }
    .form-group.input-box-msg:hover .fake-input1, .form-group.input-box-msg:hover .fake-input1 input:focus {
      border: 1px solid #0190fe;
    }     */
    
    
    .form-group.input-box .fake-input ,.form-group.input-box-msg .fake-input1 {opacity: 0.6;}
    
    input.real-input {
      opacity:0;
      background:transparent;
    }
    
      </style>

<section>
    <div>       
        <div class="inner-wrap">                
            <p>
                <img src="assets/modules/dummy-assets/common/img/GovEVA.png" alt="GovEVA">
            </p>
            <h2>Reset Password</h2>
            <form [formGroup]="resetPasswordForm">
                <div class="form-group  input-box-msg">                  
                    <div id="fake-input" class="fake-input1"><span class="blinking-cursor"></span>Password</div>
                    <div class="password-wrapper">
                      <input
                        [type]="passwordVisible ? 'text' : 'password'"
                        class="form-control" (focusin)="blinkCur1()"  (focusout)="blinkCurOut1()"    (keyup)="RemoveSavePassword1()" placeholder="Password" type="text" formControlName="password">
                      
                      <i class="fa" [ngClass]="passwordVisible ? 'fa-eye' : 'fa-eye-slash'" (click)="togglePasswordVisibility('new')"></i> <!-- Eye icon -->
                    </div>
                    <div style="color:red" *ngIf="resetPasswordForm.controls['password'].hasError('pattern')">
                      <ul style="padding-left: 6%;text-align: left;font-size: 12px;">
                        <li>Please enter a minimum of 8 characters.</li>
                        <li>At least one lowercase and one uppercase letter.</li>
                        <li>At least one number.</li>
                        <li>At least one special character.</li>
                      </ul>
                      
                    </div>
                </div>
                <!-- <div class="form-group  input-box-msg">                  
                    <div id="fake-input" class="fake-input1"><span class="blinking-cursor"></span>Password</div>
                    <input class="real-input"  (focusin)="blinkCur1()"  (focusout)="blinkCurOut1()"    (keyup)="RemoveSavePassword1()" placeholder="Password" type="text" formControlName="password">
                    <div style="color:red;padding-top: 32px;" *ngIf="resetPasswordForm.controls['password'].hasError('pattern')">Please enter atleast one lowercase, one uppercase, one number, one special character and minimum length
                        should be 8.</div>
                </div> -->
                <div class="form-group  input-box">                 
                    <div id="fake-input" class="fake-input"><span class="blinking-cursor"></span>Re-enter New Password</div>
                    <div class="password-wrapper">
                      <input
                        [type]="rePasswordVisible ? 'text' : 'password'"
                        class="form-control"  (focusin)="blinkCur()"  (focusout)="blinkCurOut()"    (keyup)="RemoveSavePassword()" placeholder="Re-enter Password" type="text" formControlName="confirmPassword">
                      
                      <i class="fa" [ngClass]="rePasswordVisible ? 'fa-eye' : 'fa-eye-slash'" (click)="togglePasswordVisibility('re')"></i> <!-- Eye icon -->
                    </div>
                    <div style="color:red ;text-align: left;font-size: 12px;" *ngIf="resetPasswordForm.get('password').touched && resetPasswordForm.get('confirmPassword').touched && resetPasswordForm.get('password').value !== resetPasswordForm.get('confirmPassword').value">Passwords entered do not match.</div>
                    <br>                 
                </div>
                <!-- <div class="form-group  input-box">                 
                    <div id="fake-input" class="fake-input"><span class="blinking-cursor"></span>Re-enter New Password</div>
                    <input class="real-input" (focusin)="blinkCur()"  (focusout)="blinkCurOut()"    (keyup)="RemoveSavePassword()" placeholder="Re-enter Password" type="text" formControlName="confirmPassword">
                    <div style="color:red;padding-top: 32px;" *ngIf="resetPasswordForm.get('password').touched && resetPasswordForm.get('confirmPassword').touched && resetPasswordForm.get('password').value !== resetPasswordForm.get('confirmPassword').value">Password doesnot match</div>
                    <br>                 
                </div> -->
                <div>
                    <button class="btn btn-primary btn-block mr-2 mb-2 mt-0" [disabled]="!resetPasswordForm.valid" (click)="submitPassword()"><i aria-hidden="true"></i>Reset Password</button>
                </div>
            </form>
        </div>
    </div>
</section>