<section class="card">
    <div class="card-header">
      <span class="cat__core__title">
        <strong>Entity</strong>
      </span>
    </div>
  
    <div class="card-block watermark">
      <div class="row">
        <div class="col-lg-12">
          <button type="button" class="btn btn-primary btn-sm mb-3" data-toggle="modal" data-target="#myModal" (click)="resetFormValues()"
            [appHasPermission]="{ 'Url': '/api/v1/secure/boardEvaluationType/addEvaluationType', 'method':'post', 'menu': 'EvaluationType' }">
            Add Entity
          </button>
          <div class="table-responsive table-bordered margin-bottom-50">
            <table
              datatable
              [dtOptions]="dtOptions"
              id="entityMasterTable"
              [dtTrigger]="dtTrigger"
              class="table table-hover compact cell-border nowrap table-striped dataTable"
              style="background: transparent"
              width="100%"
            >
              <thead>
                <tr>
                  <th style="width: 5%">Sr. No.</th>
                  <th style="width: 75%">Entity</th>
                  <th
                    style="width: 5%"
                    [appHasPermission]="{
                      Url: '/api/v1/secure/boardEvaluationType/editEvaluationType',
                      method: 'put',
                      menu: 'EvaluationType'
                    }"
                  >
                    Edit
                  </th>
                  <th
                    style="width: 5%"
                    [appHasPermission]="{
                      Url: '/api/v1/secure/boardEvaluationType/deleteEvaluationType',
                      method: 'delete',
                      menu: 'EvaluationType'
                    }"
                  >
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let evaluationTypeData of EvaluationTypeData;
                    let count = index
                  "
                >
                  <td style="text-align: center; width: 5%">{{ count + 1 }}</td>
                  <td style="width: 75%">
                    {{ evaluationTypeData.EvaluationType }}
                  </td>
                  <td
                    style="
                      width: 5%;
                      text-align: center;
                      color: #74708d;
                      cursor: pointer;
                    "
                    [appHasPermission]="{
                      Url: '/api/v1/secure/boardEvaluationType/editEvaluationType',
                      method: 'put',
                      menu: 'EvaluationType'
                    }"
                  >
                    <div
                      class="fa fa-pencil"
                      (click)="editEntity(evaluationTypeData)"
                    ></div>
                  </td>
                  <td
                    style="
                      width: 5%;
                      text-align: center;
                      color: #74708d;
                      cursor: pointer;
                    "
                    [appHasPermission]="{
                      Url: '/api/v1/secure/boardEvaluationType/deleteEvaluationType',
                      method: 'delete',
                      menu: 'EvaluationType'
                    }"
                  >
                    <div
                      class="fa fa-trash-o"
                      (click)="confirmdeleteEntity(evaluationTypeData)"
                    ></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
  
          <!-- Modal for adding new Role-->
          <div
            class="modal fade"
            id="myModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
          >
            <div class="modal-dialog modal-s" role="document">
              <div class="modal-content">
                <div
                  class="modal-header"
                  style="justify-content: unset !important"
                >
                  <h4 class="modal-title" id="myModalLabel">Add Entity</h4>
                  <button
                    type="button"
                    class="close"
                    style="right: 5px; position: absolute"
                    data-dismiss="modal"
                    (click)="closeForm('myModal')"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
  
                <div class="modal-body pt-3 pb-3">
                  <form [formGroup]="entityForm" novalidate>
                    <div
                      class="form-group d-inline-flex col-lg-12 p-0"
                      style="height: 30px; margin-bottom: 0"
                    >
                      <label class="label col-lg-3 p-0 text-left">
                        <span style="vertical-align: -webkit-baseline-middle"
                          >Entity
                          <span class="text-danger">*</span>
                        </span>
                      </label>
                      <div class="col-lg-9">
                        <input
                          class="form-control"
                          placeholder="Entity"
                          type="text"
                          [formControl]="entityForm.controls['evaltype']"
                          name="EvaluationType"
                          [value]="EvaluationType"
                          (keyup)="evaluationTypeChangeEvent($event)"
                          (change)="evaluationTypeChangeEvent($event)"
                        />
                        <div
                          *ngIf="
                            entityForm.controls['evaltype'].hasError(
                              'required'
                            ) && entityForm.controls['evaltype'].touched
                          "
                          class="form-control-error-custom p-0"
                        >
                          Entity is required.
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
  
                <div class="modal-footer">
                  <div style="width: 100%; text-align: left">
                    <span style="color: #ff0000">*</span> Indicates Mandatory
                    field
                  </div>
                  <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    [disabled]="!entityForm.valid || disableSavebutton"
                    (click)="submitEntity()"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    class="btn btn-default btn-sm"
                    style="background-color: #ffffff; color: #acb7bf !important"
                    data-dismiss="modal"
                    (click)="closeForm('myModal')"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
  
          <div
            class="modal fade"
            id="messagePopUp"
            tabindex="-1"
            role="dialog"
            aria-labelledby=""
            aria-hidden="true"
            style="top: 50px"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div
                  id="alertTitle"
                  class="modal-header"
                  style="font-size: 20px; font-weight: bold; padding: 5px 15px"
                ></div>
                <div class="modal-body" style="padding: 10px 15px">
                  <div id="alertMessage" style="font-size: 16px"></div>
                </div>
              </div>
            </div>
          </div>
  
          <!--model for updating existing entity-->
          <div
            class="modal fade"
            id="editingEntityModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="editingEntityModal"
          >
            <div class="modal-dialog modal-s" role="document">
              <div class="modal-content">
                <div
                  class="modal-header"
                  style="justify-content: unset !important"
                >
                  <h4 class="modal-title" id="editingEntityModal">Edit Entity</h4>
                  <button
                    type="button"
                    class="close"
                    style="right: 5px; position: absolute"
                    data-dismiss="modal"
                    (click)="closeForm('editingEntityModal')"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
  
                <div class="modal-body pt-3 pb-3">
                  <form [formGroup]="entityForm" novalidate>
                    <div
                      class="form-group d-inline-flex col-lg-12 p-0"
                      style="height: 30px; margin-bottom: 0"
                    >
                      <label class="label col-lg-3 p-0 text-left">
                        <span style="vertical-align: -webkit-baseline-middle">
                          Entity
                          <span class="text-danger">*</span>
                        </span>
                      </label>
                      <div class="col-lg-9">
                        <input
                          class="form-control"
                          type="text"
                          [formControl]="entityForm.controls['evaltype']"
                          name="EvaluationType"
                          [value]="EvaluationType"
                          (keyup)="evaluationTypeChangeEvent($event)"
                          (change)="evaluationTypeChangeEvent($event)"
                        />
                        <div
                          *ngIf="
                            entityForm.controls['evaltype'].hasError(
                              'required'
                            ) && entityForm.controls['evaltype'].touched
                          "
                          class="form-control-error-custom p-0"
                        >
                          Entity is required.
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
  
                <div class="modal-footer">
                  <div style="width: 100%; text-align: left">
                    <span style="color: #ff0000">*</span> Indicates Mandatory
                    field
                  </div>
                  <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    [disabled]="!entityForm.valid || disableupdatebutton"
                    (click)="submitUpdatedEntity()"
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    class="btn btn-default btn-sm"
                    style="background-color: #ffffff; color: #acb7bf !important"
                    data-dismiss="modal"
                    (click)="closeForm('editingEntityModal')"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
  
          <!--confirm delete entity dialogbox-->
          <div
            class="modal fade"
            id="roleDeletionMessageModal"
            role="dialog"
            aria-labelledby="roleDeletionMessageModalLabel"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="roleDeletionMessageModalLabel">
                    <i class="fa fa-question-circle-o mr-2"></i>Confirm
                  </h5>
                  <button
                    aria-label="Close"
                    class="close"
                    data-dismiss="modal"
                    type="button"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p>Are you sure you want to delete this entity?</p>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    (click)="deleteEntity()"
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    class="btn btn-default btn-sm"
                    data-dismiss="modal"
                    (click)="closeForm('roleDeletionMessageModal')"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
  
          <!-- delete entity dialogbox-->
          <div
            class="modal fade"
            id="errorMessageModal"
            style="top: 50px; display: none"
            tabindex="-1"
            role="dialog"
            aria-labelledby="errorMessageModal"
          >
            <div class="modal-dialog" style="height: 70%" role="document">
              <div class="modal-content">
                <div
                  class="modal-header"
                  style="font-size: 20px; font-weight: bold; padding: 5px 15px"
                >
                  <span *ngIf="headerSuccessFlag" style="color: rgb(0, 0, 0)">
                    <h4>
                      <b>{{ headerSuccessMessage }}</b>
                    </h4>
                  </span>
                  <span *ngIf="headerErrorFlag" style="color: rgb(255, 0, 0)">
                    <h4>
                      <b>{{ headerErrorMessage }}</b>
                    </h4>
                  </span>
                </div>
                <div class="modal-body" style="padding: 10px 15px">
                  <span
                    *ngIf="headerSuccessFlag"
                    style="font-size: 16px; color: rgb(0, 0, 0)"
                    >{{ errorMessage }}</span
                  >
                  <span
                    *ngIf="headerErrorFlag"
                    style="font-size: 16px; color: rgb(255, 0, 0)"
                    >{{ errorMessage }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  