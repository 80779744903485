import { Component, OnInit, OnChanges, Input, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import {
  ActivatedRoute,
  RouterState,
  Router,
  NavigationEnd,
} from '@angular/router';

import { DataTableDirective } from 'angular-datatables';

import { Subject } from 'rxjs';
import * as _ from 'lodash';

import { BoardEvaluationService } from '../../../services/boardEvaluation.service';
import { BoardEvaluationExecutionService } from '../../../services/boardEvaluationExecution.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { escapeRegExp } from 'lodash';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';

@Component({
  selector: 'app-evaluation-form-list',
  templateUrl: './evaluation-form-list.component.html',
  styleUrls: ['./evaluation-form-list.component.css'],
})
export class EvaluationFormListComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};

  dtTrigger: Subject<any> = new Subject();
  assignedFormList: any;
  selectedExecutedUser: any = [];

  constructor(
    private boardEvaluationService: BoardEvaluationService,
    private router: Router,
    private modalService: NgbModal,
    public boardExecutionService: BoardEvaluationExecutionService
  ) {}

  ngOnInit() {
    this.dtOptions = {
      destroy: true,
      pagingType: 'full_numbers',
      paging: true,
      // scrollY: "440px",
      // scrollCollapse: true,
      stateSave: true,
      stateDuration: -1,
      pageLength: 10,
      search: true,
      language: {
        //made changes for datatables for next previous button
        paginate: {
          first: '<<',
          last: '>>',
          next: '>',
          previous: '<',
        },
      },
    };
    this.getEvaluationFormList();
  }

  getEvaluationFormList() {
    var userInfo = JSON.parse(localStorage.getItem('user'));

    this.boardEvaluationService
      .getAssignedFormsByuserId(userInfo._id)
      .then((response) => {
        $('#assingedFormTable').DataTable().destroy();
        //console.log("Evaluation Form List", response);
        this.assignedFormList = response.AssignedFormList;
        this.dtTrigger.next();
      });
  }

  executeAssignedForm(formDetails, rowIndex) {
    //console.log("formDetails",formDetails);
    _.remove(this.selectedExecutedUser, (ele) => {
      return _.get(ele, 'rowIndex') != rowIndex;
    });

    formDetails.newAssesseDetails.forEach((element) => {
      this.selectedExecutedUser.push(
        this.returnExecutionUserObject(rowIndex, element)
      );
    });

    // console.log("this.selectedExecutedUser",this.selectedExecutedUser)
    var stateObjectSelectedUserToExecute = {
      listOfUsers: this.selectedExecutedUser,
    };

    this.selectedExecutedUser = _.uniqBy(this.selectedExecutedUser, 'id');

    this.boardExecutionService.usersExecutionFormEvent$.next(
      this.selectedExecutedUser
    );

    this.router.navigate([
      'structure',
      { outlets: { 'structure-outlet': ['evaluationFormExecution'] } },
    ]);
  }

  executionFormExecutionList(event, rowIndex, evaluated, elementDetails, form) {
    if (!evaluated) {
      if (_.size(this.selectedExecutedUser) == 0) {
        this.selectedExecutedUser.push(
          this.returnExecutionUserObject(rowIndex, elementDetails)
        );
      } else {
        _.remove(this.selectedExecutedUser, (ele) => {
          return _.get(ele, 'rowIndex') != rowIndex;
        });

        if (_.size(this.selectedExecutedUser) == 0) {
          this.selectedExecutedUser.push(
            this.returnExecutionUserObject(rowIndex, elementDetails)
          );
        } else {
          var foundItem = _.findIndex(this.selectedExecutedUser, (findEle) => {
            return (
              _.get(findEle, 'id') ==
              elementDetails._id + elementDetails.AssesseeId
            );
          });

          if (foundItem < 0) {
            this.selectedExecutedUser.push(
              this.returnExecutionUserObject(rowIndex, elementDetails)
            );
          } else {
            this.selectedExecutedUser.splice(foundItem, 1);
          }
        }
      }

      this.validateCheckboxAfterOperation();
    } else {
      return;
    }
  }

  returnExecutionUserObject(rowIndex, elementDetails) {
    return {
      rowIndex: rowIndex,
      executionFormId: elementDetails._id,
      assesseeId: elementDetails.AssesseeId,
      formEvaluated: elementDetails.IsFormEvaluated,
      id: elementDetails._id + elementDetails.AssesseeId,
    };
  }

  validateCheckboxAfterOperation() {
    if (_.size(this.selectedExecutedUser) > 0) {
      this.assignedFormList.forEach((userElement) => {
        userElement.newAssesseDetails.forEach((childElement) => {
          var foundItem = _.findIndex(
            this.selectedExecutedUser,
            (findElement) => {
              return (
                _.get(findElement, 'id') ==
                  childElement._id + childElement.AssesseeId ||
                _.get(childElement, 'IsFormEvaluated') == true
              );
            }
          );
          if (foundItem < 0) {
            var doc = <HTMLInputElement>(
              document.getElementById(
                childElement._id + childElement.AssesseeId
              )
            );
            if (doc) {
              doc.checked = false;
            }
          }
        });
      });
    } else {
      this.assignedFormList.forEach((userElement) => {
        userElement.newAssesseDetails.forEach((childElement) => {
          if (!childElement.IsFormEvaluated) {
            var doc = <HTMLInputElement>(
              document.getElementById(
                childElement._id + childElement.AssesseeId
              )
            );
            if (doc) {
              doc.checked = false;
            }
          }
        });
      });
    }
  }

  executeSelected(index) {
    _.remove(this.selectedExecutedUser, ele => {
      return (_.get(ele, "rowIndex") != index)
    });
    this.validateCheckboxAfterOperation();
    if (_.size(this.selectedExecutedUser) <= 0) {
      const modRef = this.modalService.open(ConfirmationPopupComponent, {
        backdrop: 'static'
      })
      modRef.componentInstance.Message = "Please select Assessee";
      return;
    } else {
      const modRefMessage = this.modalService.open(ConfirmationPopupComponent, {
        backdrop: 'static'
      })
      modRefMessage.componentInstance.Message = "userDefault";
      modRefMessage.result.then(res => {
        if (res == 'ok') {

          this.selectedExecutedUser = _.uniqBy(this.selectedExecutedUser, "id")
          this.boardExecutionService.usersExecutionFormEvent$.next(this.selectedExecutedUser)
          this.router.navigate(['structure', { outlets: { 'structure-outlet': ['evaluationFormExecution'] } }]);
        }
      })
    }
  }

  disabledEvaluateAll(element, index) {

    if (element) {
      var size = element.newAssesseDetails.length;
      var total = 0;
      element.newAssesseDetails.forEach(item => {
        if (item.IsFormEvaluated) {
          total = total + 1;
        }
      });
      if (size == total) {
        var getElement = <HTMLButtonElement>document.getElementById(element._id.EvaluationFormId + index);
        var getElementButton: any = <HTMLButtonElement>document.getElementById(element._id.EvaluationFormId + index + "completed");

        if (getElementButton) {
          getElementButton.disabled = true;
          getElementButton.firstChild.data = "Evaluation Completed";
          getElement.firstChild.textContent = "View Completed Evaluation"
        }

      }
    }

  }
}
