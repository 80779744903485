import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { CompanySetupService } from '../services/companySetup.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { debounceTime, map, distinctUntilChanged, switchMap, tap, catchError, elementAt } from 'rxjs/operators';

import { AutoBackupSchedule } from '../enums/AutoBackupSchedule';
import { of } from 'rxjs/observable/of';

import { rocketChatRemove } from '../enums/rocketChatRemove';

declare var $: any;
declare var jQuery: any;
@Component({
  selector: 'app-company-setup',
  templateUrl: './companySetup.component.html',
  styleUrls: ['./companySetup.component.css']
})


export class CompanySetupComponent implements OnInit, AfterViewInit {
  companyMasterForm: FormGroup;
  companies: any;
  // classesOfCompany: any[];
  typesOfCompany: any[];
  categoriesOfCompany: any[];
  registrationIDTypes: any[];
  industryTypes: any[];
  companyId: string;
  subsidiaryName: string;

  tempCompanyInputSelected: any;
  tempCompanySelectedCIN: any;

  acceptsDeposits: boolean;
  financialYearFromModel: any;
  financialYearToModel: any;
  dateOfIncorporationModel: any;
  error: any = { isError: false, errorMessage: '' };
  numList: Array<string> = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  companyAutoCompleteList: any;
  subsidiaryAutoCompleteList: any;
  companyNameModel: any;
  subsidiaryNameModel: any;
  peersList: any;
  selectedPeers: any;
  industryCode: any;
  housingCode: any;
  invalidPeers: any;
  chosenPeers: any;
  tradingWindowValue: any;
  pattern = "^[a-zA-Z0-9][a-zA-Z0-9\-\_\.\,\)\('’\"\n ]*$";//Pattern for validating blank input and space at first place
  urlPattern = "^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$";
  subsidiaryArray: Array<any> = [];
  newSubsidiary: any = {};

  debtListingLabelHide: any;
  equityListingFlag: boolean;
  isGEMSubscribedFlag: boolean = false;
  equityListingSMESegmentFlag: boolean;
  onlyDebtListingFlag: boolean;
  debtListingFlag: boolean;
  showSelected: boolean;
  hideListedCompany: boolean;
  getCompanyByNewCINNumber: any;
  checkDropDownCompany: boolean = false;
  isCheckedListedCompany: boolean = false;

  aboveRelationCompanyFincode: any;
  registrationIdTypeFCRN: boolean = false;
  //image cropper
  isInvalidImage: any = true;

  // croppedWidth: number;
  // croppedHeight: number;
  // cropperSettings: CropperSettings;
  base64Code: any;
  errorValidCINForCompany: boolean = false;
  errorUniqueCINForCompany: boolean;
  errorUniqueCIN: boolean;

  companyLogoToUpload: any;
  companyLogoToUploadURL: any;
  @ViewChild('myInput')
  myFileInput: any;
  minDate: any;
  maxDate: any;
  subsidiaryFincode: any;
  companyLabel: any;
  industryTypeName: any;
  private formData = new FormData();
  otherSubsideryName: string;
  isValidSubsdieryName: boolean = true;
  private subsidaryList: any;
  public searchFailed: boolean = false;
  companyNameArray = []
  public selectedCompanyByTypeAhead: any;
  private companyDetails: any;
  private subsidiaryCompanyDetailsList: any;
  subsidiaryCompanyNameArray: any;
  searching: any;
  isADSubscribedFlag: boolean = false;
  adServerArray: any = [];


  isPSSConfig = environment.Service.PSS;
  isGovevaConfig = environment.Service.GovEva;
  isUPSIConfig = environment.Service.UPSI;
  isAllConfig = environment.Service.All;
  isNCDConfig = environment.Service.NCD;
  isBoardEvaluationConfig = environment.Service.BoardEvaluation;
  isCompanySetupWithoutMaster = environment.Service.CompanySetupWithoutMasterApi;
  // isGovernanceConfig = environment.Service.Governance;

  // @ViewChild('cropper', undefined) cropper: ImageCropperComponent;
  constructor(private router: Router, private loginService: LoginService, private companySetupService: CompanySetupService, private http: HttpClient) {
    // console.log("IN Construstor");
    this.loginService.checkCompanyExists().subscribe(companies => {
      // console.log("Companies", companies);
      if (companies.status == 0) {
        let userData: any = localStorage.getItem('user');
        if (userData == undefined || userData == null) {
          this.router.navigate(["login"]);
        }
        var userId = JSON.parse(userData)._id
        if (userId == undefined || userId == null || userId == "") {
          this.router.navigate(["login"]);
        }
        // console.log("UserID", userId);
        this.loginService.getResourcesForUserAuthorisationByUserId(userId).subscribe(userResources => {
          // console.log("User Resources", userResources);
          if (userResources.status == 0) {
            if (userResources.Resources != undefined && userResources.Resources != null) {
              let companyMasterResources: any = _.filter(userResources.Resources, function (o) { return o.Menu == "CompanyMaster" });
              if (companyMasterResources != undefined && companyMasterResources != null && _.size(companyMasterResources) > 0) {
                if (_.size(companyMasterResources[0].ChildResources) > 0) {
                  let childResources: any = _.filter(companyMasterResources.ChildResources, function (o) { return o.Url == "companyMaster/addNewCompany" });
                  // console.log("FINAL IN");
                  if (childResources == undefined || childResources == null) {
                    this.router.navigate(["login"]);
                  }
                } else {
                  this.router.navigate(["login"]);
                }
              } else {
                this.router.navigate(["login"]);
              }
            } else {
              this.router.navigate(["login"]);
            }
          } else {
            this.router.navigate(["login"]);
          }
        });
      }
    });
    // this.loginService.companyListForUserManagement().then(companies => {
    //   if (companies.status == 0) {
    //     this.router.navigate(["login"]);
    //   }
    // });

    //cropper settings

    // this.cropperSettings = new CropperSettings();
    // this.cropperSettings.width = 350;
    // this.cropperSettings.height = 150;

    // this.cropperSettings.croppedWidth = 350;
    // this.cropperSettings.croppedHeight = 150;

    // this.cropperSettings.canvasWidth = 465;
    // this.cropperSettings.canvasHeight = 300;

    // this.cropperSettings.minWidth = 10;
    // this.cropperSettings.minHeight = 10;

    // this.cropperSettings.rounded = false;
    // this.cropperSettings.keepAspect = false;

    // this.cropperSettings.cropperDrawSettings.strokeColor = 'rgba(255,255,255,1)';
    // this.cropperSettings.cropperDrawSettings.strokeWidth = 2;

    // this.cropperSettings.noFileInput = true;


    this.base64Code = {};
    this.incorporationDateValidation();
  }

  ngOnInit() {

    $("#newSubsidiaryName").hide();
    $("#exSubsidiaryname").hide();

    //restrict access to a page after login
    // var token = localStorage.getItem('token');

    // if (!_.isNil(token)) {
    //   this.router.navigate(['structure', { outlets: { 'structure-outlet': 'dashboards/alpha' } }]);
    // }



    this.selectedPeers = [];
    this.getTypesOfCompany();
    this.getCategoryOfCompany();
    this.getRegistrationIDTypes();
    //this.getIndustryTypes();
    this.getcompanyAutoComplete();
    this.getAllIndustryTypeList();

    this.acceptsDeposits = false;
    this.isGEMSubscribedFlag = false;

    this.companyMasterForm = new FormGroup({
      companyName: new FormControl('',
        [Validators.required, Validators.maxLength(100)]
      ),
      /*relationshipAboveCompany: new FormControl('',
        [Validators.required]
      ),*/
      complianceEngineURL: new FormControl('', [Validators.required]),

      chatEngineURL: new FormControl('', []),
      complianceEngine: new FormControl(''),
      dateOfIncorporation: new FormControl('', []),
      ISIN: new FormControl('', [Validators.minLength(12)]),

      //changes for pss tp goveva ---
      registrationIDType: new FormControl('', [Validators.required]),
      registrationNumber: new FormControl('', [Validators.required, Validators.minLength(21), Validators.maxLength(21), Validators.pattern(this.pattern)]),
      // ---------------

      exchangeCode: new FormControl('', []),
      // classOfCompany: new FormControl('', []),
      typeOfCompany: new FormControl('', []),

      // typeOfCompany: new FormControl('', []),
      categoryOfCompany: new FormControl('', []),

      //Validators.pattern(this.pattern)
      registeredOfficeAddress: new FormControl('', [
        Validators.minLength(10), Validators.maxLength(500)
      ]),
      FinancialYearFrom: new FormControl('', []),
      FinancialYearTo: new FormControl('', []),
      nbfcRegulations: new FormControl('', []),
      acceptsDeposits: new FormControl('', []),

      amountoFDeposits: new FormControl('', []),


      listedCompany: new FormControl('', []),
      equityListing: new FormControl('', []),
      isGEMSubscribedFlag: new FormControl(Boolean, []),
      GEMAccessURL: new FormControl('', []),
      equityListingSMESegment: new FormControl('', []),
      onlyDebtListing: new FormControl('', []),
      debtListing: new FormControl('', []),

      noOfShareholders: new FormControl('', []),
      noOfDebentureHolders: new FormControl('', []),
      noOfDepositHolders: new FormControl('', []),
      noOfSecurityHolders: new FormControl('', []),
      typeOfIndustry: new FormControl('', []),
      peersName: new FormControl('', []),

      //--EVA added
      //  EVA: new FormControl('', [Validators.pattern(/^\d{1,5}(\.\d{1,2})?$/)]),
      EVA: new FormControl('', [Validators.pattern(/^\d{1,5}(\.\d{2,2})?$/)]),

      //-------------subsidiary to add
      newSubsidiaryCompanyName: new FormControl('', [Validators.maxLength(100)]),
      newSubsidiaryCIN: new FormControl('', [Validators.minLength(21), Validators.maxLength(21), , Validators.pattern("^([A-Z]){1}([0-9]){5}([A-Z]){2}([0-9]){4}([A-Z]){3}([0-9]){6}?$")]),
      newSubsidiaryType: new FormControl('', []),
      newSubsidiaryShareholding: new FormControl('', [Validators.max(100), Validators.min(0), Validators.maxLength(2)]),
      //-----subsidery to edit
      exSubsidiaryName: new FormControl('', []),
      exSubsidiaryCIN: new FormControl('', []),
      exSubsidiaryType: new FormControl('', []),
      exSubsidiaryShareholding: new FormControl('', []),
      Fincode: new FormControl('', []),
      otherSubsidiaryCompanyName: new FormControl(''),
      //relationshipAbove: new FormControl('', [Validators.required]),
      //relationshipBelow: new FormControl('', [Validators.required]),
      isADSubscribedFlag: new FormControl(Boolean, []),
      // ADUrl:  new FormControl('', []),
      // ADEmailID:  new FormControl('', []),
      // ADPassword: new FormControl('', [])
    });

    this.adServerArray.push({
      ADUrl: '',
      ADEmailID: '',
      ADPassword: ''
    })

    //default false
    this.companyMasterForm.controls['listedCompany'].setValue(false);
    this.companyMasterForm.controls['isGEMSubscribedFlag'].setValue(false);
    this.companyMasterForm.controls['isADSubscribedFlag'].setValue(false);
    // this.companyMasterForm.controls['GEMAccessURL'].setValue("asd");
  }

  incorporationDateValidation() {
    this.minDate = { year: 1900, month: 1, day: 1 };
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    this.maxDate = { year: year, month: month, day: day };
  }

  ngAfterViewInit() {
    $('#companyData').css('display', 'none');
    $('#companyName').css('display', 'none');
    $('#btnCheckMapping').css('display', 'none');
    //set Default value for Compliance Engine is PSS and BIS is hidden.   
    // this.companyMasterForm.patchValue({ complianceEngine: 'PSS', tc: true });
    this.complianceEngineChange("PSS");
  }

  getTypesOfCompany() {
    this.companySetupService.getTypesOfCompany().then(types => {
      if (types.status == 0) {
        // this.classesOfCompany = classes.companyClassList;
        this.typesOfCompany = types.companyClassList.sort();

      }
    })
  }

  getCategoryOfCompany() {
    this.companySetupService.getCategoryOfCompany().then(categories => {
      if (categories.status == 0) {
        // this.typesOfCompany = types.typesOfCompanyList;
        this.categoriesOfCompany = categories.typesOfCompanyList.sort();

      }
    })
  }

  getRegistrationIDTypes() {
    this.companySetupService.getRegistrationIDTypes()
      .then(regTypes => {
        if (regTypes.status == 0) {
          this.registrationIDTypes = regTypes.registrationIDList.sort();
        }
      })
  }

  // showCatDiv() {
  showListingTypeDiv() {
    this.showSelected = true;
    this.setDefaultValue();
    if (this.companyMasterForm.controls.equityListing.value || this.companyMasterForm.controls.equityListingSMESegment.value || this.companyMasterForm.controls.onlyDebtListing.value || this.companyMasterForm.controls.debtListing.value) {
      this.isCheckedListedCompany = true;
    } else {
      this.isCheckedListedCompany = false;
    }
  }

  setDefaultValue() {
    if (this.companyMasterForm.controls.typeOfCompany.value == "Public") {
      //reset listing type checkboxes
      this.resetListingTypeCheckboxes();
      //set default Equity Listing checked
      this.equityListingFlag = true;
      this.companyMasterForm.controls.equityListing.setValue(true);
    }
  }

  resetListingTypeCheckboxes() {
    this.equityListingFlag = false;
    this.debtListingFlag = false;
    this.equityListingSMESegmentFlag = false;
    this.onlyDebtListingFlag = false;
  }

  // hideCatDiv() {
  hideListingTypeDiv() {
    this.showSelected = false;
    this.companyMasterForm.controls.equityListing.setValue("");
    this.companyMasterForm.controls.equityListingSMESegment.setValue("");
    this.companyMasterForm.controls.onlyDebtListing.setValue("");
    this.companyMasterForm.controls.debtListing.setValue("");

  }


  ListedConditionCheck(typeOfCompanyValue) {

    switch (typeOfCompanyValue) {

      case "":
        //this.companyMasterForm.controls.listedCompany.setValue('');
        // this.companyMasterForm.controls.listedCompany.disable();
        this.hideListedCompany = false;
        this.equityListingFlag = false;
        this.equityListingSMESegmentFlag = false;
        this.debtListingFlag = false;
        this.onlyDebtListingFlag = false;
        this.companyMasterForm.controls.listedCompany.setValue(false);
        this.showSelected = false;
        break;
      case "Public":
        this.hideListedCompany = true;
        this.companyMasterForm.controls['equityListing'].enable();
        this.companyMasterForm.controls['equityListingSMESegment'].enable();
        this.companyMasterForm.controls['onlyDebtListing'].enable();
        this.companyMasterForm.controls['debtListing'].enable();
        this.equityListingFlag = true;
        this.equityListingSMESegmentFlag = false;
        this.debtListingFlag = false;
        this.onlyDebtListingFlag = false;
        break;
      case "Private Limited Company (which is not a subsidiary of Public Company)":
        this.hideListedCompany = true;
        this.companyMasterForm.controls['equityListing'].disable();
        this.companyMasterForm.controls['equityListingSMESegment'].disable();
        this.companyMasterForm.controls['onlyDebtListing'].enable();
        this.companyMasterForm.controls['debtListing'].disable();
        this.equityListingFlag = false;
        this.equityListingSMESegmentFlag = false;
        this.debtListingFlag = false;
        this.onlyDebtListingFlag = true;
        break;
      case "Private Limited Company (which is subsidiary of Public Company)":
        this.hideListedCompany = true;
        this.companyMasterForm.controls['equityListing'].disable();
        this.companyMasterForm.controls['equityListingSMESegment'].disable();
        this.companyMasterForm.controls['onlyDebtListing'].enable();
        this.companyMasterForm.controls['debtListing'].disable();
        this.equityListingFlag = false;
        this.equityListingSMESegmentFlag = false;
        this.debtListingFlag = false;
        this.onlyDebtListingFlag = true;
        break;
      case "One Person Company":
        this.hideListedCompany = false;
        this.equityListingFlag = false;
        this.equityListingSMESegmentFlag = false;
        this.debtListingFlag = false;
        this.onlyDebtListingFlag = false;
        this.companyMasterForm.controls.listedCompany.setValue(false);
        this.showSelected = false;

    }
    // console.log("value ", typeOfCompanyValue);
  }

  onListingTypeChange(isChecked, listedType) {
    // console.log("data, checkbox value", listedType);

    switch (listedType) {
      case "equityListing":
        if (isChecked == true) {
          this.equityListingSMESegmentFlag = false;
          this.onlyDebtListingFlag = false;
          this.equityListingFlag = true;
        }
        else {
          this.equityListingFlag = false;
        }
        break;
      case "equityListingSMESegment":
        if (isChecked == true) {
          this.equityListingFlag = false;
          this.onlyDebtListingFlag = false;
          this.equityListingSMESegmentFlag = true;
        }
        else {
          this.equityListingSMESegmentFlag = false;
        }
        break;
      case "onlyDebtListing":
        if (isChecked == true) {
          this.equityListingFlag = false;
          this.equityListingSMESegmentFlag = false;
          this.debtListingFlag = false;
          this.onlyDebtListingFlag = true;
        }
        else {
          this.onlyDebtListingFlag = false;
        }
        break;
      case "debtListing":
        if (isChecked == true) {
          this.onlyDebtListingFlag = false;
          this.debtListingFlag = true;
        }
        else {
          this.debtListingFlag = false;
        }
        break;
    }

  }


  getIndustryTypes() {
    this.companySetupService.getIndustryTypes(this.industryCode)
      .then(indusTypes => {

        if (indusTypes.status == 0) {
          if ('INDUSTRY' in indusTypes == false) {
            indusTypes.industryList.INDUSTRY = indusTypes.industryList[0];
            indusTypes.industryList.IND_CODE = this.industryCode;
            this.getAllIndustryTypeList();
          }
          this.industryTypes = indusTypes.industryList.sort();
          console.log("indusTypes.industryList.IND_CODE", indusTypes.industryList.IND_CODE)
          this.companyMasterForm.controls.typeOfIndustry.setValue(indusTypes.industryList.IND_CODE);
        }
      })
  }

  getCompanyByCIN() {
    var self = this;
    self.companyMasterForm.controls.companyName.setValue(null);
    self.selectedCompanyByTypeAhead = null;
    $("#typeahead-template").val(null);
    // if(self.errorUniqueCINForCompany  && self.errorValidCINForCompany){
    let cinNumber = self.companyMasterForm.controls.registrationNumber.value;
    self.companySetupService.getCompanyByCINNumber(cinNumber).then(function (data) {

      if (data.listOfCompany) {
        var company = {
          FinCode: data.listOfCompany.Fincode,
          CompanyName: data.listOfCompany.COMPNAME,
          ScripCode: data.listOfCompany.SCRIPCODE
        }
        self.selectedCompanyByTypeAhead = company;
        self.companyMasterForm.controls.companyName.setValue(data.listOfCompany.COMPNAME);
        $("#typeahead-template").val(data.listOfCompany.COMPNAME);
      } else {

        self.selectedCompanyByTypeAhead = {
          FinCode: "",
          CompanyName: self.companyMasterForm.controls.companyName.value,
          ScripCode: ""
        }
      }
    });
  }

  setPrivateEnteredCompanyName() {
    //  
    var self = this;
    self.companySetupService.getCompanyByCINNumber(self.companyMasterForm.controls.registrationNumber.value).then(function (data) {
      if (data.listOfCompany) {
        var company = {
          FinCode: data.listOfCompany.Fincode,
          CompanyName: data.listOfCompany.COMPNAME,
          ScripCode: data.listOfCompany.SCRIPCODE
        }
        self.selectedCompanyByTypeAhead = company;
        self.companyMasterForm.controls.companyName.setValue(data.listOfCompany.COMPNAME);
        $("#typeahead-template").val(data.listOfCompany.COMPNAME);
      } else {
        //  
        self.selectedCompanyByTypeAhead = {
          FinCode: "",
          CompanyName: self.companyMasterForm.controls.companyName.value,
          ScripCode: ""
        }
      }
    });
  }

  selectIndustry(event) {
    this.industryCode = event.target.value;
    var industryObj = _.find(this.industryTypes, function (o) { return o.IND_CODE == event.target.value })
    // console.log("obj---------", industryObj);
    //this.companyMasterForm.controls.typeOfIndustry.setValue(industryObj.INDUSTRY);
    if (industryObj != undefined)
      this.industryTypeName = industryObj.INDUSTRY;
    this.getIndustryTypes();

    //get industry And Housing Wise Peers from industry and housing code
    this.companySetupService.getindustryAndHousingWisePeers(this.industryCode, this.housingCode)
      .then(peers => {
        if (peers.status == 0 && peers.IndustryAndHousingPeers.length > 0) {
          //  

          let uniquePeers = [];
          uniquePeers = _.uniqBy(peers.IndustryAndHousingPeers, "Fincode");

          //this.peersList = peers.IndustryAndHousingPeers.map(function (o) {
          this.peersList = uniquePeers.map(function (o) {
            return {
              'label': o.COMPNAME,
              'value': o.Fincode  //o.FINCODE   
            }
          });

          //alphabetically order
          this.peersList = this.peersList.sort(function (a, b) {
            return a.label.localeCompare(b.label);
          });
        }

        //  
        // if (this.peersList.length > 0) {
        //   let indexOfItem = this.peersList.findIndex(o => o.value === itemValue);
        //   if (indexOfItem > -1)
        //     //reomve the peers name (if peers is then same company name which is is selected ) from the collection 
        //     this.peersList.splice(indexOfItem, 1);
        // }
      });
  }

  getAllIndustryTypeList() {

    this.companySetupService.getIndustryTypesList()
      .then(indusTypeList => {

        if (indusTypeList.status == 1) {

          // Declare a new array
          let newArray = [];

          // Declare an empty object
          let uniqueObject = {};

          // Loop for the array elements
          for (let i in indusTypeList.listOfIndustryType) {
            // Extract the title
            var objTitle = indusTypeList.listOfIndustryType[i]['IND_CODE'];
            // Use the title as the index
            uniqueObject[objTitle] = indusTypeList.listOfIndustryType[i];
          }


          for (let i in uniqueObject) {
            newArray.push(uniqueObject[i]);
          }

          this.industryTypes = newArray;

          // Display the unique objects
          console.log(newArray);

          // this.companyMasterForm.controls.typeOfIndustry.setValue(indusTypes.industryList[0]);
        }
      })
  }

  // //get the company autocomplete list
  getcompanyAutoComplete() {

    // this.companySetupService.getcompanyAutoComplete().then(compAutoComplete => {
    // this.companySetupService.getcompanyAutoComplete().then(compAutoComplete => {
    //   if (compAutoComplete.status == 0) {

    //     let uniqueCompanies = [];
    //     uniqueCompanies = _.uniqBy(compAutoComplete.companyList, "FinCode");

    //      
    //     //alphabetically sort
    //     uniqueCompanies = uniqueCompanies.sort(function (a, b) {
    //       return a.CompanyName.localeCompare(b.CompanyName);
    //     });

    //     // this.companyAutoCompleteList = compAutoComplete.companyList.map(function (o) {
    //     this.companyAutoCompleteList = uniqueCompanies.map(function (o) {

    //       return {
    //         // '_id': o.FinCode,
    //         'label': o.CompanyName,
    //         'value': { FinCode: o.FinCode, CIN: o.CIN, CompanySecurityCode: o.ScripCode }
    //       }
    //     })
    //   }
    // })

  }

  // //get the subsidiary autocomplete list
  //OLD CODE START
  getSubsidiaryAutoComplete() {

    this.companySetupService.getcompanyAutoComplete().then(subAutoComplete => {

      if (subAutoComplete.status == 0) {

        let uniqueSubsidiary = [];
        uniqueSubsidiary = _.uniqBy(subAutoComplete.companyList, "FinCode");
        this.subsidaryList = uniqueSubsidiary;

        //alphabetically sort
        uniqueSubsidiary = uniqueSubsidiary.sort(function (a, b) {
          return a.CompanyName.localeCompare(b.CompanyName);
        });

        // to remove Selected company from subsidiary dropdown
        if (this.companyLabel != undefined) {

          let subsidiaryLabel = this.companyLabel;
          let indexOfCompany = _.findIndex(uniqueSubsidiary, function (company) { return _.get(company, 'CompanyName') == subsidiaryLabel })
          uniqueSubsidiary.splice(indexOfCompany, 1);
        }

        this.subsidiaryAutoCompleteList = uniqueSubsidiary.map(function (o) {

          return {
            // '_id': o.FinCode,
            'label': o.CompanyName,
            'value': o.FinCode.toString()
          }
        })

        this.subsidiaryAutoCompleteList = [{ 'label': 'Other', 'value': 'other' }, ...this.subsidiaryAutoCompleteList]
      }
    })

  }
  //END HERE

  complianceEngineChange(value) {
    if (value == "PSS") {
      $('#companyData').css('display', 'block');
      $('#btnCheckMapping').css('display', 'none');
      $('#companyName').css('display', 'block');
    } else if (value == "BIS") {
      $('#companyName').css('display', 'block');
      $('#btnCheckMapping').css('display', 'block');
      $('#companyData').css('display', 'none');
    }
  }

  checkMapping() {

    // var company = this.getCompanyNameByFinCode(this.companyMasterForm.controls.companyName.value);
    // var CIN = company.value.CIN;
    // var FinCode = company.value.FinCode;
  }

  setDate(date) {
    if (date != "" && date != null) {
      return date.month + "-" + date.day + "-" + date.year;
    }
    return null;
  }

  getCompanyNameByFinCode(finCode) {
    if (finCode != "") {
      return this.companyAutoCompleteList.find(e => e.value == finCode);
    }
  }

  messagePopUpTimeoutToogle() {
    setTimeout(function () {
      var popUpClass = $('#messagePopUp').attr('class');
      if (popUpClass == "modal fade show") {
        $('#messagePopUp').modal('toggle');
      }
    }, 3000);
  }

  setFormatDate(date) {
    if (date != null) {
      return new Date(date);
      //.toLocaleDateString();
    }
    return null;
  }

  addCompany() {
    // console.log("_.size(this.companyMasterForm.controls.chatEngineURL.value)", _.size(this.companyMasterForm.controls.chatEngineURL.value))

    // console.log('selectedCompanyByTypeAhead', this.selectedCompanyByTypeAhead);
    //map selected peers details with PeerName & PeerFincode
    let selectedPeersList = [];
    // if (_.size(this.selectedPeers)) {
    if (this.selectedPeers != undefined && this.selectedPeers != null) {
      selectedPeersList = this.selectedPeers.map(function (o) {
        return {
          'PeerName': o.label,
          'PeerFincode': o.value
        }
      });
    }
    if (this.companyMasterForm.status == "VALID") {


      if (this.companyMasterForm.controls.Fincode.value == "other") {
        this.companyMasterForm.value.Fincode = null
      }

      if (this.compareTwoDates()) {
        // get company name by pincode
        // var company = { value: this.selectedCompanyByTypeAhead }//this.getCompanyNameByFinCode(this.selectedCompanyByTypeAhead.FinCode);
        // var CIN = company.value.CIN;
        // var FinCode = company.value.FinCode;
        // var CompanyName = company.value.CompanyName;
        // var CompanySecurityCode = company.value.ScripCode;
        // var companyLogoImage = "";
        // if (Object.keys(this.base64Code).length != 0) {
        //   companyLogoImage = this.base64Code;
        // }
        if (this.checkDropDownCompany == true) {
          var newVal = $('#typeahead-template').val();
          // var company = { value: this.selectedCompanyByTypeAhead }//this.getCompanyNameByFinCode(this.selectedCompanyByTypeAhead.FinCode);
          var CIN = this.companyMasterForm.controls.registrationNumber.value;
          var FinCode = null;
          var CompanyName = newVal;
          var CompanySecurityCode = null;
          var companyLogoImage = "";
          if (Object.keys(this.base64Code).length != 0) {
            companyLogoImage = this.base64Code;
          }
        } else {

          var company = { value: this.selectedCompanyByTypeAhead }//this.getCompanyNameByFinCode(this.selectedCompanyByTypeAhead.FinCode);
          var CIN = this.companyMasterForm.controls.registrationNumber.value;

          if (this.isCompanySetupWithoutMaster) {
            var FinCode = null;
            var CompanyName = this.companyMasterForm.controls.companyName.value;
            var CompanySecurityCode = null;
          } else {
            var FinCode = company.value.FinCode;
            var CompanyName = company.value.CompanyName;
            var CompanySecurityCode = company.value.ScripCode;
          }
          // var CompanyName = company.value.CompanyName;
          // var CompanySecurityCode = company.value.ScripCode;
          var companyLogoImage = "";
          if (Object.keys(this.base64Code).length != 0) {
            companyLogoImage = this.base64Code;
          }
        }

        let amountOfDeposit: any;


        if (this.companyMasterForm.controls.acceptsDeposits.value == "false" && this.companyMasterForm.controls.nbfcRegulations.value == "Yes") {
          amountOfDeposit = this.companyMasterForm.controls.amountoFDeposits.value
        } else {
          amountOfDeposit = null;
        }


        var listingtype = [];
        var listed = false;

        if (this.companyMasterForm.controls.listedCompany.value == "true") {
          listed = true;
          if (this.equityListingFlag) {   //this.companyMasterForm.controls.equityListing.value
            listingtype.push("EquityListing");
          }

          if (this.equityListingSMESegmentFlag) {  //this.companyMasterForm.controls.equityListingSMESegment.value
            listingtype.push("EquityListingSMESegment");
          }
          if (this.onlyDebtListingFlag) {  //this.companyMasterForm.controls.onlyDebtListing.value
            listingtype.push("OnlyDebtListing");
          }
          if (this.debtListingFlag) {  //this.companyMasterForm.controls.debtListing.value
            listingtype.push("DebtListing");
          }
        }
        else {
          listingtype = [];
        }

        // at lease one listed company selection is mandatory if Listed company option is true. 
        if (listed == true && listingtype.length == 0) {
          this.isCheckedListedCompany = false;
          return false;
        }
        else {
          this.isCheckedListedCompany = true;
        }

        // if (company && company.value != "") {
        if ($("#typeahead-template").val()) {
          if (this.companyMasterForm.controls.registeredOfficeAddress.value == null) {
            this.companyMasterForm.controls.registeredOfficeAddress.setValue("");
          }

          var adServerDetails = [];



          var count = _.size(this.adServerArray);
          var intialCount = 0;

          if (this.isADSubscribedFlag) {
            for (intialCount; intialCount < count; intialCount++) {
              adServerDetails.push({
                ADUrl: this.companyMasterForm.controls['ADUrl' + intialCount].value ? this.companyMasterForm.controls['ADUrl' + intialCount].value : null,
                ADEmailID: this.companyMasterForm.controls['ADEmailID' + intialCount].value ? this.companyMasterForm.controls['ADEmailID' + intialCount].value : null,
                ADPassword: this.companyMasterForm.controls['ADPassword' + intialCount].value ? this.companyMasterForm.controls['ADPassword' + intialCount].value : null
              })
            }
          } else {
            adServerDetails = [{
              ADUrl: '',
              ADEmailID: '',
              ADPassword: ''
            }]
          }


          console.log("adServerDetails", adServerDetails)

          var newCompany = {
            //this.companyMasterForm.controls['companyName'].value
            CompanyID: "",
            CIN: CIN,//this.companyMasterForm.controls.CIN.value,
            CompanyName: CompanyName,//company.label,
            FinCode: FinCode,//this.companyMasterForm.controls.companyName.value,
            DateOfIncorporation: this.setDate(this.companyMasterForm.controls.dateOfIncorporation.value),

            TypeOfCompany: ((this.companyMasterForm.controls.typeOfCompany.value == "") ? null : this.companyMasterForm.controls.typeOfCompany.value),
            CategoryOfCompany: ((this.companyMasterForm.controls.categoryOfCompany.value == "") ? null : this.companyMasterForm.controls.categoryOfCompany.value),

            //registrationTypeId & registrationName
            RegistrationIDType: this.companyMasterForm.controls.registrationIDType.value,
            RegistrationNumber: this.companyMasterForm.controls.registrationNumber.value,
            EVA: (this.companyMasterForm.controls.EVA.value == "") ? null : this.companyMasterForm.controls.EVA.value,
            AcceptsDeposits: this.companyMasterForm.controls.acceptsDeposits.value,
            // AmountOfDeposits: amountOfDeposit,
            AssetSize: amountOfDeposit,
            Listed: this.companyMasterForm.controls.listedCompany.value,
            ListingType: listingtype,
            RegisteredOfficeAddress: this.companyMasterForm.controls.registeredOfficeAddress.value.trim(),
            IndustryType: ((this.companyMasterForm.controls.typeOfIndustry.value == "") ? null : this.companyMasterForm.controls.typeOfIndustry.value),
            FinancialYearFrom: this.setDate(this.companyMasterForm.controls.FinancialYearFrom.value),
            FinancialYearTo: this.setDate(this.companyMasterForm.controls.FinancialYearTo.value),
            NBFCRegulations: this.companyMasterForm.controls.nbfcRegulations.value,
            NumberOfShareholders: this.companyMasterForm.controls.noOfShareholders.value,
            NumberOfDebentureHolders: this.companyMasterForm.controls.noOfDebentureHolders.value,
            NumberOfDepositHolders: this.companyMasterForm.controls.noOfDepositHolders.value,
            NumberOfSecurityHolders: this.companyMasterForm.controls.noOfSecurityHolders.value,
            IndustryCode: this.industryCode,
            HousingCode: this.housingCode,
            Peers: selectedPeersList,
            ComplianceEngine: this.companyMasterForm.controls.complianceEngine.value,
            ComplianceEngineUrl: this.companyMasterForm.controls.complianceEngineURL.value.trim(),
            ChatEngineURL: ((this.companyMasterForm.controls.chatEngineURL.value == "" || _.size(this.companyMasterForm.controls.chatEngineURL.value) == 0) ? "" : this.companyMasterForm.controls.chatEngineURL.value.trim()),
            CompanyLogoImage: '',// this.formData,//companyLogoImage,
            SubsidiaryCompanies: this.subsidiaryArray,
            ClientUIHostIP: location.origin,
            // ISIN: this.companyMasterForm.controls.ISIN.value,
            ISIN: (this.companyMasterForm.controls.ISIN.value.trim() != "") ? this.companyMasterForm.controls.ISIN.value.trim() : '',
            // AcceptingDepositNBFC: 
            ExchangeCode: this.companyMasterForm.controls.exchangeCode.value,
            CompanySecurityCode: CompanySecurityCode,//company.CompanySecurityCode,
            GEMAccessURL: this.companyMasterForm.controls.GEMAccessURL.value,
            isGEMSubscribedFlag: this.companyMasterForm.controls.isGEMSubscribedFlag.value,
            //auto backup scheduler details
            AutoBackUpFrequency: AutoBackupSchedule.Daily,
            AutoBackUpLocation: "/home/ec2-user/autoBackup/",
            // ClientLoginAPIUrl: environment.nonValidateUrl + 'user/login'
            ClientLoginAPIUrl: environment.nonValidateUrl,
            IsADSubscribed: this.isADSubscribedFlag,

            ADServersDetails: adServerDetails

          }
          // to add Industry type name to IndustryType-08.10
          newCompany.IndustryType = this.industryTypeName;

          let requestData = {
            body: newCompany,
            logo: this.formData
          }
          // console.log("add company", JSON.stringify(requestData));

          var tocreateICCheckerMakerRole = false;
          if ((this.isAllConfig && this.isGovevaConfig) ) {// || this.isGovernanceConfig
            tocreateICCheckerMakerRole = true;
          }


          this.companySetupService.addCompany(newCompany).then(company => {
            if (company.status == 0) {

              this.companyId = company.result._id;
              const companyinfoObject = {
                CompanyId: company.result.ClientCompanyId,
                CompanyName: company.result.CompanyName,
                isRemoveRocketChat: rocketChatRemove.isRemove,
                isUPSIConfig: this.isUPSIConfig,
                tocreateICCheckerMakerRole: tocreateICCheckerMakerRole
              }
              // if(_.size(this.formData.get('files'))) {
              this.companySetupService.uploadCompanyLogo(company.result.ClientCompanyId, this.formData).then((function (response) {
                // console.log('file is uploaded', response)
              }))
              // }

              this.companySetupService.registerSuperAdmin(companyinfoObject).then(responseRegSuperAdmin => {
                // console.log('responseRegSuperAdmin.status == 0', responseRegSuperAdmin.status == 0)

                if (responseRegSuperAdmin.status == 0) {
                  this.companySetupService.synchistoricalData().then(syncResponse => {

                  })

                  $('#alertPopUpTitle').text("Success!");
                  $('#alertPopUpTitle').css("color", "black");
                  $('#alertMessage').text("Company configured successfully.");
                  $('#alertMessage').css("color", "black");
                  $('#messagePopUp').modal('toggle');
                  this.messagePopUpTimeoutToogle();
                  // this.router.navigate(['/']);
                  setTimeout(this.navigateToLogin.bind(this), 3000);
                  //setTimeout(this.navigateTocreateIntuitConsultantPage.bind(this), 3000);

                }
                else {

                  $('#alertPopUpTitle').css('color', '#f00');
                  $('#alertPopUpTitle').text("Failure!");
                  $('#alertMessage').text(responseRegSuperAdmin.message);
                  $('#alertMessage').css('color', '#f00');
                  $('#messagePopUp').modal('toggle');
                  this.messagePopUpTimeoutToogle();
                }
              });

            } else {
              $('#alertPopUpTitle').css('color', '#f00');
              $('#alertPopUpTitle').text("Failure!");
              $('#alertMessage').text(company.message);
              $('#alertMessage').css('color', '#f00');
              $('#messagePopUp').modal('toggle');
              this.messagePopUpTimeoutToogle();

              //this.companyMasterForm.reset();
              this.resetFields();
            }
          });


        }
      }
      else {
        return false;
      }
    } else {
      // console.log('Invalid form submit.')
      return false;
    }
  }



  navigateToLogin() {
    // this.router.navigate(["/"]);
    window.location.href = '/'
  }

  navigateToSuperAdminSetup() {
    this.router.navigate(["superAdminSetup"]);
  }

  //after company setup redirects to createIntuitConsultant page.
  navigateTocreateIntuitConsultantPage() {
    this.router.navigate(["createIntuitConsultant"]);
  }

  ResetCompanyForm() {
    this.companyMasterForm.reset();
    this.selectedPeers = [];
    this.invalidPeers = false;
  }

  acceptDepositValueTrue() {
    //if Accepts Deposits value is true then asset size invisible irespective of the NBFC Regulations.
    this.acceptsDeposits = false;
    this.companyMasterForm.controls.amountoFDeposits.clearValidators();
    this.companyMasterForm.controls.amountoFDeposits.updateValueAndValidity();
  }

  acceptDepositValueFalse() {
    //if Accepts Deposits value is false nbfcRegulations true asset size visible.
    if (this.companyMasterForm.controls.nbfcRegulations.value == "Yes") {
      this.acceptsDeposits = true;
      this.companyMasterForm.controls.amountoFDeposits.setValidators([Validators.required, Validators.min(0)]);
    }
    else {
      this.acceptsDeposits = false;
      this.companyMasterForm.controls.amountoFDeposits.clearValidators();
      this.companyMasterForm.controls.amountoFDeposits.updateValueAndValidity();
    }
  }

  nbfcRegulationsValueTrue() {
    //if NBFC Regulations is true then asset size visible if accept deposits false.
    if (this.companyMasterForm.controls.acceptsDeposits.value == "false") {
      this.acceptsDeposits = true;
      this.companyMasterForm.controls.amountoFDeposits.setValidators([Validators.required, Validators.min(0)]);
    }
  }

  nbfcRegulationsValueFalse() {
    //if NBFC Regulations is false then asset size is hide.
    this.acceptDepositValueTrue();
  }

  compareTwoDates() {

    if (this.financialYearToModel == null && this.financialYearFromModel == null) {
      this.error = { isError: false, errorMessage: '' }
      return true;
    }

    if (this.financialYearToModel == null || this.financialYearFromModel == null) {
      this.error = {
        isError: true, errorMessage: 'Both date is required.'
      };
      return false;
    }

    if (new Date(this.setDate(this.financialYearToModel)) < new Date(this.setDate(this.financialYearFromModel))) {
      this.error = {
        isError: true, errorMessage: 'Financial year to date cannot be set before Financial year from date.'
      };
      return false;
    }
    else
      this.error = { isError: false, errorMessage: '' }

    return true;
  }

  onlyNumber(ev) {
    var keyCode = window.event ? ev.keyCode : ev.which;
    //codes for 0-9
    if ((keyCode > 47 && keyCode < 58) || (keyCode > 95 && keyCode < 106) || (keyCode > 34 && keyCode < 41) || (keyCode == 16) || (keyCode == 46) || (keyCode == 9)) {

      if (((keyCode > 47 && keyCode < 58) || (keyCode > 95 && keyCode < 106)) && this.numList.indexOf(ev.key) == -1) {
        ev.preventDefault();
      }
      //restrict up to 10 digits
      if ((ev.target.value + ev.key).length > 11) {
        ev.preventDefault();
      }
      //  
    }
    else {
      if (keyCode != 0 && keyCode != 8 && keyCode != 13 && !ev.ctrlKey) {
        ev.preventDefault();
      }
    }
  }


  //validation for numbers not exceeding a limit
  onlyDecimalNumber(ev) {
    var keyCode = window.event ? ev.keyCode : ev.which;

    if (keyCode != 190) {

      //codes for 0-9
      if ((keyCode > 47 && keyCode < 58) || (keyCode > 95 && keyCode < 106) || (keyCode > 34 && keyCode < 41) || (keyCode == 16) || (keyCode == 46) || (keyCode == 9) || (keyCode == 110)) {

        if (((keyCode > 47 && keyCode < 58) || (keyCode > 95 && keyCode < 106)) && this.numList.indexOf(ev.key) == -1) {
          ev.preventDefault();
        }
        //restrict up to 10 digits
        if ((ev.target.value + ev.key).length > 11) {
          ev.preventDefault();
        }
        //  
      }
      else {
        if (keyCode != 0 && keyCode != 8 && keyCode != 13 && !ev.ctrlKey) {
          ev.preventDefault();
        }
      }
    }
  }

  //--DELETE selected category for assessment
  deleteConfirmation(companyId) {
    this.companyId = companyId
    $('#confirmation').modal('toggle');
  }

  deleteCompany() {

    this.companySetupService.deleteCompanyByCompanyId(this.companyId).then(data => {
      if (data.status == 0) {
        return true;
      }
      else {
        return false;
      }
    });
  }

  //get the peers list of selected company and binds to the peer list. 
  getPeersListOnCompanySelected(item: any) {

    // console.log("companyNameModel", this.companyNameModel, item, this.companyMasterForm.controls.companyName.value);
    this.companyLabel = item.label;
    this.tempCompanyInputSelected = this.companyMasterForm.controls.companyName.value;
    this.tempCompanySelectedCIN = this.tempCompanyInputSelected.CIN;
    // console.log("this.tempCompanyInputSelected , this.tempCompanySelectedCIN", this.tempCompanyInputSelected, this.tempCompanySelectedCIN);
    this.bindPeersofSelectedCompany(item.value.FinCode);

    // this.getSubsidiaryAutoComplete();
  }

  //bind peers of selected company
  bindPeersofSelectedCompany(itemValue) {
    // console.log('itemValue', itemValue)
    //get industry and housing code by selected company
    this.companySetupService.getSelectedCompanyDetailsFromFinCode(itemValue).then(selectedCompany => {
      console.log("selectedCompany", selectedCompany)
      if (selectedCompany.status == 0) {
        this.industryCode = selectedCompany.selectedCompanyDetails.IndustryCode;
        this.housingCode = selectedCompany.selectedCompanyDetails.HousingCode;
        this.getIndustryTypes();
        //get industry And Housing Wise Peers from industry and housing code
        this.companySetupService.getindustryAndHousingWisePeers(this.industryCode, this.housingCode)
          .then(peers => {
            if (peers.status == 0 && peers.IndustryAndHousingPeers.length > 0) {
              //  

              let uniquePeers = [];
              uniquePeers = _.uniqBy(peers.IndustryAndHousingPeers, "Fincode");

              //this.peersList = peers.IndustryAndHousingPeers.map(function (o) {
              this.peersList = uniquePeers.map(function (o) {
                return {
                  'label': o.COMPNAME,
                  'value': o.Fincode  //o.FINCODE   
                }
              });

              //alphabetically order
              this.peersList = this.peersList.sort(function (a, b) {
                return a.label.localeCompare(b.label);
              });
            }

            //  
            if (this.peersList.length > 0) {
              let indexOfItem = this.peersList.findIndex(o => o.value === itemValue);
              if (indexOfItem > -1)
                //reomve the peers name (if peers is then same company name which is is selected ) from the collection 
                this.peersList.splice(indexOfItem, 1);
            }
          });
      }
    });
  }

  //bind selected peers id and name to the list
  getSelectedPeers(item: any) {

    // this.selectedPeers.push([item.label, item.value]);
    this.selectedPeers.push(item);
    //invalidPeers
    if (this.selectedPeers.length <= 5)
      this.invalidPeers = false;
    else
      this.invalidPeers = true;
  }

  //remove peers from list which is removed from ng-select control
  multiplePeersDeselected(item: any) {
    //  
    let indexOfItem = this.selectedPeers.findIndex(o => o.value === item.value);
    this.selectedPeers.splice(indexOfItem, 1);

    if (this.selectedPeers.length <= 5)
      this.invalidPeers = false;
    else
      this.invalidPeers = true;
  }

  restrictCIN(ev) {

    var keyCode = window.event ? ev.keyCode : ev.which;

    var keys = [8, 46, 35, 36, 37, 38, 39, 40];

    if (keys.indexOf(keyCode) == -1) {
      if ((keyCode > 95 && keyCode < 106 || keyCode == 32) && this.numList.indexOf(ev.key) == -1) {
        ev.preventDefault();
      }
      else if (keyCode > 185 || keyCode == 16) {
        ev.preventDefault();
      }
      else if (this.companyMasterForm.controls.registrationIDType.value == "FCRN") {

        if ((ev.target.value + ev.key).length > 6) {

          ev.preventDefault();
        }

      }
      else if ((ev.target.value + ev.key).length > 21) {
        ev.preventDefault();
      }
    }
    //   else {
    //     if (keyCode != 0 && keyCode != 8 && keyCode != 13 && !ev.ctrlKey) {
    //       ev.preventDefault();
    //     }     
    // }
  }

  toggleTradingWindow(value) {
    //  
    this.companySetupService.toggleTradingWindow(value, this.companyId).then(response => {
      if (response.status == 0) {
        // this.industryTypes = indusTypes.industryList;
      }
    })
  }

  //Company LOGO upload
  showUploadImagePopUp() {
    $("#uploadCompanyLogoPopUp").modal('toggle');
    this.base64Code = "../../assets/modules/dummy-assets/common/img/avatars/Company-default.png";
    this.myFileInput.nativeElement.value = "";
  }

  removeLogoImage() {

    this.base64Code = "../../assets/modules/dummy-assets/common/img/avatars/Company-default.png";
    $('#companyLogo').attr('src', this.base64Code);
    this.base64Code = "";
    this.formData.delete('files')
  }

  handleFileInput(files: FileList) {
    //  
    // console.log('files', files)
    // let formData = new FormData();
    var file: File = files.item(0);
    var fileType = file["type"];
    var fileSize = file["size"];
    var ValidImageTypes = ["image/jpg", "image/jpeg", "image/png"];

    if (fileSize < 5000 || fileSize > 2000000) {
      //  
      this.isInvalidImage = true;
      $('#alertPopUpTitle').css('color', '#f00');
      $('#alertPopUpTitle').text("Failure!");
      $('#alertMessage').text("Please upload image of size 5 KB to 2 MB");
      $('#alertMessage').css('color', '#f00');
      $('#messagePopUp').modal('toggle');
      this.messagePopUpTimeoutToogle();
    }
    else if ($.inArray(fileType, ValidImageTypes) > 0) {
      // valid file type code goes here.
      this.isInvalidImage = false;
      this.formData.append('files', file)
    }
    else
      this.isInvalidImage = true;

    if (!this.isInvalidImage) {
      this.companyLogoToUpload = file;
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.base64Code = event.target.result;
      }
      reader.readAsDataURL(files.item(0));

    } else {
      this.myFileInput.nativeElement.value = "";
    }
  }

  // handleSubmit(event: any) {
  //   event.preventDefault();

  //   this.fileUploadSub = this.fileUploadService.fileUpload(this.companyLogoToUpload)
  //     .subscribe(event => this.handleProgress(event), error => { console.log("Server error") });
  // }

  // fileUpload(fileItem: File): any {
  //   let apiCreateEndpoint = `${this.apiBaseURL}files/create/`
  //   const formData: FormData = new FormData();
  //   formData.append('fileItem', fileItem, fileItem.name);

  //   const req = new HttpRequest('POST', apiCreateEndpoint, formData, {
  //     reportProgress: true // for progress data
  //   });
  //   return this.http.request(req)
  // }






  //reset profile image & cropper in profile dialog
  // resetImage() {
  //   this.cropper.reset();        //reset cropper 
  //   $("#custom-input").val("");  //reset file name title
  //   this.isInvalidImage = true;
  // }

  //set the image for cropping
  // fileChangeListener($event) {
  //   //  

  //   // this.cropper.reset();
  //   this.isInvalidImage = true;

  //   var image: any = new Image();
  //   var file: File = $event.target.files[0];
  //   var fileType = file["type"];
  //   var ValidImageTypes = ["image/gif", "image/jpeg", "image/png"];
  //   if ($.inArray(fileType, ValidImageTypes) > 0) {
  //     // valid file type code goes here.
  //     this.isInvalidImage = false;
  //   }
  //   else
  //     this.isInvalidImage = true;          //if uploaded file is invalid

  //   var myReader: FileReader = new FileReader();
  //   var that = this;
  //   myReader.onloadend = (loadEvent: any) => {
  //     image.src = loadEvent.target.result;
  //     // that.cropper.setImage(image);
  //   };

  //   myReader.readAsDataURL(file);

  // }

  //test image cropper
  // cropped(bounds: Bounds) {
  //   this.croppedHeight = bounds.bottom - bounds.top;
  //   this.croppedWidth = bounds.right - bounds.left;
  // }

  setCompanyLogo() {

    $('#companyLogo').attr('src', this.base64Code);
    $('#uploadCompanyLogoPopUp').modal('toggle');
  }

  clearData() {
    // this.companyMasterForm.controls.newSubsidiaryCompanyName.setValue('');
    // this.companyMasterForm.controls.newSubsidiaryCIN.setValue('');
    // this.companyMasterForm.controls.newSubsidiaryType.setValue('');
    // this.companyMasterForm.controls.newSubsidiaryType.markAsUntouched();
    // this.companyMasterForm.controls.newSubsidiaryShareholding.setValue('');   

    document.location.reload();
  }


  CINCompareParentAndSubsidiary() {
    // if(this.companyMasterForm.controls.newSubsidiaryCIN.value == this.tempCompanySelectedCIN){
    //   console.log("Cannot have CIN number of your parent.please enter different ");
    // }
  }

  addSubsidiaryData() {


    var newSubsidiaryCIN = (this.companyMasterForm.controls.newSubsidiaryCIN.value.trim())

    if (newSubsidiaryCIN == "") {
      this.addSubsideryInfo();
      this.hideOtherSubsideryNameField();
    }
    else if (this.companyMasterForm.controls.newSubsidiaryCIN.value === this.tempCompanySelectedCIN) {
      // console.log("Cannot have CIN number of your parent.please enter different ");
      $('#alertPopUpTitle').css('color', '#f00');
      $('#alertPopUpTitle').text("Failure!");
      $('#alertMessage').text("Please enter CIN different from parent company CIN");
      $('#alertMessage').css('color', '#f00');
      $('#messagePopUp').modal('toggle');
    } else {
      this.addSubsideryInfo();
      this.hideOtherSubsideryNameField();
    }
  }


  addSubsideryInfo() {

    let rowIndex = 1;
    if (this.subsidiaryArray.length) {
      rowIndex = rowIndex + this.subsidiaryArray.length;
    }

    var isFoundOtherSubsidery = false;

    if (this.companyMasterForm.controls['newSubsidiaryCompanyName'].value == "other") {
      isFoundOtherSubsidery = true;
    }

    this.newSubsidiary = {
      Index: rowIndex,
      Name: this.companyMasterForm.controls.newSubsidiaryCompanyName.value,//$('#newSubsidiaryName').val(),
      CIN: this.companyMasterForm.controls.newSubsidiaryCIN.value,//$('#newSubsidiaryCIN').val(),
      Type: this.companyMasterForm.controls.newSubsidiaryType.value,
      Shareholding: $('#newSubsidiaryShareholding').val(),
      //added on 20Mar
      isEdit: false,
      //dirty properties
      dirtySubsideryName: this.companyMasterForm.controls.newSubsidiaryCompanyName.value,//$('#newSubsidiaryName').val(),
      dirtyCIN: this.companyMasterForm.controls.newSubsidiaryCIN.value,//$('#newSubsidiaryCIN').val(),
      dirtySubsideryType: this.companyMasterForm.controls.newSubsidiaryType.value,//$('#newSubsidiaryType').val(),
      dirtyShareholding: $('#newSubsidiaryShareholding').val(),
      //validation
      isUniqueCIN: true,
      isValidCIN: true, //21 digits
      Fincode: _.toString(this.subsidiaryFincode),
      isFoundOtherSubsidery: isFoundOtherSubsidery,
      isFoundDirtyOtherSubsidery: isFoundOtherSubsidery,
      dirtyFincode: _.toString(this.subsidiaryFincode),
      otherSubsideryName: this.companyMasterForm.controls.newSubsidiaryCompanyName.value,//$('#newSubsidiaryName').val(),
      dirtyOtherSubsideryName: this.companyMasterForm.controls.newSubsidiaryCompanyName.value,// $('#newSubsidiaryName').val(),
      isValidExSubsideryName: true
    }

    //this.subsidiaryArray[i].isFoundOtherSubsidery = subsideryModel.isFoundDirtyOtherSubsidery;  

    //subsideryModel.dirtyFincode; 
    // console.log("this.companyMasterForm.controls['exSubsidiaryName'].value", this.subsidiaryFincode)

    if (this.newSubsidiary.Name == "") {
      this.newSubsidiary.Name = this.subsidiaryName;
      this.newSubsidiary.dirtySubsideryName = this.subsidiaryName;
    }
    // if(this.newSubsidiary.Fincode == "other"){
    //   this.newSubsidiary.Fincode = null;
    // }

    this.subsidiaryArray.push(this.newSubsidiary)

    this.newSubsidiary = {};
    this.companyMasterForm.controls.newSubsidiaryCompanyName.setValue('');
    this.companyMasterForm.controls.newSubsidiaryCIN.setValue('');
    this.companyMasterForm.controls.Fincode.setValue('');
    this.companyMasterForm.controls.newSubsidiaryType.setValue('');
    this.companyMasterForm.controls.newSubsidiaryType.markAsUntouched();
    this.companyMasterForm.controls.newSubsidiaryShareholding.setValue('');
    // $('#newSubsidiaryName').val('');
    // $('#newSubsidiaryCIN').val('');
    // $('#newSubsidiaryType').val('');
    // $('#newSubsidiaryShareholding').val('');
    $('#newSubsidiaryName').focus();
  }

  deleteSubsidiaryData(index) {

    this.subsidiaryArray.splice(index, 1);
    this.hideOtherSubsideryNameField();
  }

  checkForRepeatCINForCompany(CIN) {

    if (this.companyMasterForm.controls['registrationIDType'].value == "CIN") {

      //check for valid CIN
      var regExp = new RegExp("^([A-Z]){1}([0-9]){5}([A-Z]){2}([0-9]){4}([A-Z]){3}([0-9]){6}?$");

      if (regExp.test(CIN)) {
        this.errorValidCINForCompany = false;
      }
      else
        this.errorValidCINForCompany = true;


      let findCIN = this.subsidiaryArray.find(function (subsidiary) { return subsidiary.CIN == CIN })

      if (!_.isNil(findCIN)) {
        this.errorUniqueCINForCompany = true;
      } else {
        this.errorUniqueCINForCompany = false;
      }
    }
    else {
      this.errorUniqueCINForCompany = false;
      this.errorValidCINForCompany = false;
    }
  }

  checkForRepeatCINForExistingSubsidery(subsideryModel) {

    if (subsideryModel.dirtyCIN != undefined && subsideryModel.dirtyCIN != "") {

      //check for valid CIN
      var regExp = new RegExp("^([A-Z]){1}([0-9]){5}([A-Z]){2}([0-9]){4}([A-Z]){3}([0-9]){6}?$");

      if (regExp.test(subsideryModel.dirtyCIN)) {
        subsideryModel.isValidCIN = true;
      }
      else
        subsideryModel.isValidCIN = false;


      let findCIN = this.subsidiaryArray.find(function (subsidiary) { return subsidiary.CIN == subsideryModel.dirtyCIN && subsidiary.Index != subsideryModel.Index })


      if (!_.isNil(findCIN) || (this.companyMasterForm.controls['registrationNumber'].value == subsideryModel.dirtyCIN && this.companyMasterForm.controls['registrationIDType'].value == "CIN")) {
        subsideryModel.isUniqueCIN = false;
      } else {
        subsideryModel.isUniqueCIN = true;
      }
    }
    else {
      subsideryModel.isValidCIN = true;
      subsideryModel.isUniqueCIN = true;
    }
  }

  onSelectRegistrationId() {
    // console.log("this.companyMasterForm.controls.registrationIDType.value", this.companyMasterForm.controls.registrationIDType.value)

    if (this.companyMasterForm.controls.registrationIDType.value == "FCRN") {
      // console.log("hello");
      this.companyMasterForm.controls.registrationNumber.setValue("");
      this.companyMasterForm.controls.registrationNumber.setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern(this.pattern)])

    }
    this.checkForRepeatCINForCompany(this.companyMasterForm.controls['registrationNumber'].value);
  }

  checkForRepeatCIN(CIN) {

    CIN = CIN.trim();
    if (CIN == "") {
      this.errorUniqueCIN = false;
    }
    else {
      let findCIN = this.subsidiaryArray.find(function (subsidiary) { return subsidiary.CIN == CIN })

      if (!_.isNil(findCIN) || (this.companyMasterForm.controls['registrationNumber'].value == CIN && this.companyMasterForm.controls['registrationIDType'].value == "CIN")) {
        this.errorUniqueCIN = true;
      } else {
        this.errorUniqueCIN = false;
      }
    }
  }

  selectSubsiderydata(subsideryModel) {
    subsideryModel.isEdit = true;
    //once record is editable other records will not.
    for (var i in this.subsidiaryArray) {
      // if (this.subsidiaryArray[i]._id != subsideryModel._id) {
      if (this.subsidiaryArray[i].Index != subsideryModel.Index) {
        this.subsidiaryArray[i].isEdit = false;
      }
    }
  }


  cancelDirtySelection(subsideryModel) {

    if (this.subsidiaryArray == undefined || this.subsidiaryArray == null) {
      return false;
    }



    for (var i in this.subsidiaryArray) {
      // if (this.subsidiaryArray[i]._id == subsideryModel._id) {
      if (this.subsidiaryArray[i].Index == subsideryModel.Index) {

        if (subsideryModel.dirtyFincode == "other") {
          if (subsideryModel.dirtyOtherSubsideryName != "") {
            this.subsidiaryArray[i].Name = subsideryModel.dirtyOtherSubsideryName;
          }
        }

        this.subsidiaryArray[i].dirtySubsideryName = subsideryModel.Name;
        this.subsidiaryArray[i].dirtyCIN = subsideryModel.CIN;
        this.subsidiaryArray[i].dirtySubsideryType = subsideryModel.Type;
        this.subsidiaryArray[i].dirtyShareholding = subsideryModel.Shareholding;
        this.subsidiaryArray[i].isEdit = false;
        this.subsidiaryArray[i].dirtyFincode = subsideryModel.Fincode;
        //  this.subsidiaryArray[i].dirtyOtherSubsideryName = subsideryModel.otherSubsideryName;
        this.subsidiaryArray[i].isFoundOtherSubsidery = subsideryModel.isFoundDirtyOtherSubsidery;
        this.subsidiaryArray[i].otherSubsideryName = subsideryModel.dirtyOtherSubsideryName;
        this.subsidiaryArray[i].isValidExSubsideryName = true;

        break; //Stop this loop, we found it!
      }
    }
  }

  //added on 13 mar
  updateSubsidiaryData(subsideryModel) {


    if (subsideryModel.isValidExSubsideryName == false || subsideryModel.isValidCIN == false) {
      return;
    }


    if (this.subsidiaryArray == undefined || this.subsidiaryArray == null) {
      return false;
    }

    //check unique cin no with company cin no
    this.checkForRepeatCINForExistingSubsidery(subsideryModel);

    if (subsideryModel.isUniqueCIN == false) {
      return false;
    }

    if (subsideryModel.isFoundOtherSubsidery == false) {
      subsideryModel.dirtyOtherSubsideryName = "";
      subsideryModel.otherSubsideryName = "";
    }

    // if (this.subsidiaryName != undefined)
    //   subsideryModel.dirtySubsideryName = this.subsidiaryName;

    for (var i in this.subsidiaryArray) {
      // if (this.subsidiaryArray[i]._id == subsideryModel._id) {
      if (this.subsidiaryArray[i].Index == subsideryModel.Index) {

        if (subsideryModel.dirtyFincode == "other") {
          //this.subsidiaryArray[i].Name = subsideryModel.dirtyOtherSubsideryName;
          this.subsidiaryArray[i].Name = subsideryModel.otherSubsideryName;
        }
        else {
          this.subsidiaryArray[i].Name = subsideryModel.dirtySubsideryName;
        }

        this.subsidiaryArray[i].CIN = subsideryModel.dirtyCIN;
        this.subsidiaryArray[i].Type = subsideryModel.dirtySubsideryType;
        this.subsidiaryArray[i].Shareholding = subsideryModel.dirtyShareholding;
        this.subsidiaryArray[i].isEdit = false;
        this.subsidiaryArray[i].Fincode = subsideryModel.dirtyFincode;
        this.subsidiaryArray[i].dirtyOtherSubsideryName = subsideryModel.otherSubsideryName;
        this.subsidiaryArray[i].isValidExSubsideryName = true;
        if (this.subsidiaryArray[i].dirtyOtherSubsideryName == "") {
          this.subsidiaryArray[i].isFoundOtherSubsidery = false;
          this.subsidiaryArray[i].isFoundDirtyOtherSubsidery = false;
        }
        //this.subsidiaryArray[i].otherSubsideryName = subsideryModel.dirtyOtherSubsideryName;
        break; //Stop this loop, we found it!
      }
    }

    this.hideOtherSubsideryNameField();

    // this.newSubsidiary = {
    //   Name: $('#newSubsidiaryName').val(),
    //   CIN: $('#newSubsidiaryCIN').val(),
    //   Type: $('#newSubsidiaryType').val(),
    //   Shareholding: $('#newSubsidiaryShareholding').val()
    // }

    // this.subsidiaryArray.push(this.newSubsidiary)
    // this.newSubsidiary = {};
    // this.companyMasterForm.controls.newSubsidiaryCompanyName.setValue('');
    // this.companyMasterForm.controls.newSubsidiaryCIN.setValue('');

    // this.companyMasterForm.controls.newSubsidiaryType.setValue('');
    // this.companyMasterForm.controls.newSubsidiaryType.markAsUntouched();
    // this.companyMasterForm.controls.newSubsidiaryShareholding.setValue('');
    // $('#newSubsidiaryName').val('');
    // $('#newSubsidiaryCIN').val('');
    // $('#newSubsidiaryType').val('');
    // $('#newSubsidiaryShareholding').val('');

  }

  hideOtherSubsideryNameField() {
    $("#newSubsidiaryName").hide();
  }

  getSubsidiarySelected(event) {

    // console.log('event.value.FinCode', event.value)
    if (event.value == 'other') {
      $("#newSubsidiaryName").show();
      $("#exSubsidiaryname").show();
      this.subsidiaryFincode = event.value;
      this.otherSubsideryName = "";
      //if 'other' is slected as a subsidery  & other subsidery name is blank.
      this.isValidSubsdieryName = false;
      this.companyMasterForm.controls['newSubsidiaryCompanyName'].setValue(event.value);
    } else {
      $("#newSubsidiaryName").hide();
      $("#exSubsidiaryname").hide();
      this.subsidiaryFincode = event.value;
      this.subsidiaryName = event.label;
      this.isValidSubsdieryName = true;
    }
  }

  getExistingSubsidiarySelected(event, subsidiary) {
    // console.log('getExistingSubsidiarySelected', event, subsidiary)

    var isFoundOtherSubsidery = false;
    let subsidiaryobject = null;
    // console.log("event.value == 'other'", event.value == 'other')
    //subsidiary.dirtyOtherSubsideryName = "";
    if (event.item == 'other') {
      isFoundOtherSubsidery = true;
      //isValidExSubsideryName -- if other subsidery name is blank then update button disable.
      subsidiary.isValidExSubsideryName = false;
      subsidiary.dirtyFincode = event.item;
      // subsidiary.dirtySubsideryName = event;
      // subsidiary.dirtySubsideryName = "";     
    }
    else {
      isFoundOtherSubsidery = false;
      subsidiary.isValidExSubsideryName = true;
      // subsidiaryobject = _.find(this.subsidaryList, function (companyObject) { return companyObject.Fincode == subsidiary })
      subsidiaryobject = _.find(this.subsidiaryCompanyDetailsList, function (companyObject) { return companyObject.CompanyName == event.item })
      // console.log('subsidiaryobject', subsidiaryobject)
      subsidiaryobject.Fincode = subsidiaryobject.FinCode
      subsidiary.dirtyFincode = subsidiaryobject.FinCode
      subsidiary.dirtyOtherSubsideryName = subsidiaryobject.CompanyName
      subsidiary.Name = subsidiaryobject.CompanyName
      subsidiary.Fincode = subsidiaryobject.FinCode
      isFoundOtherSubsidery = false
      let newSubsidiaryObject = {
        Fincode: subsidiaryobject.FinCode,
        CIN: subsidiaryobject.CIN
      }

      this.companySetupService.checkSubsidiaryCompany(subsidiaryobject).then(function (responseForSubsidiary) {
        // this.companySetupService.checkSubsidiaryCompany(subsidiaryobject).then(function (responseForSubsidiary) {
        console.log('responseForSubsidiary', responseForSubsidiary)
      })
    }

    subsidiary.dirtySubsideryName = event.item;
    // subsidiary.dirtyFincode = event.value;

    for (var i in this.subsidiaryArray) {
      if (this.subsidiaryArray[i].Index == subsidiary.Index) {
        this.subsidiaryArray[i].dirtySubsideryName = subsidiary.dirtySubsideryName;
        this.subsidiaryArray[i].dirtyCIN = subsidiary.dirtyCIN;
        this.subsidiaryArray[i].dirtySubsideryType = subsidiary.dirtySubsideryType;
        this.subsidiaryArray[i].dirtyShareholding = subsidiary.dirtyShareholding;
        this.subsidiaryArray[i].isFoundOtherSubsidery = isFoundOtherSubsidery;
        this.subsidiaryArray[i].dirtyFincode = subsidiary.dirtyFincode;
        this.subsidiaryArray[i].dirtyOtherSubsideryName = subsidiary.dirtyOtherSubsideryName;
        this.subsidiaryArray[i].isValidExSubsideryName = subsidiary.isValidExSubsideryName;
        break; //Stop this loop, we found it!
      }
    }
  }

  //when new subsidery is to be added
  onLeaveEventOfOtherSubsideryName() {

    if (this.otherSubsideryName.trim() == "")
      this.isValidSubsdieryName = false;
    else {
      this.subsidiaryName = this.otherSubsideryName.trim();
      this.isValidSubsdieryName = true;
    }
  }

  onLeaveEventOfExistingOtherSubsidery(event, subsideryModel) {


    //putting here if cancel then we should get orignal value from dirty other subsidery name.
    subsideryModel.dirtyOtherSubsideryName = subsideryModel.otherSubsideryName;
    subsideryModel.otherSubsideryName = event.currentTarget.value;
    if (event.currentTarget.value == "") {
      subsideryModel.isValidExSubsideryName = false;
    }
    else
      subsideryModel.isValidExSubsideryName = true;

    for (var i in this.subsidiaryArray) {
      if (this.subsidiaryArray[i].Index == subsideryModel.Index) {
        this.subsidiaryArray[i].otherSubsideryName = subsideryModel.otherSubsideryName;
        this.subsidiaryArray[i].dirtyOtherSubsideryName = subsideryModel.dirtyOtherSubsideryName;
        this.subsidiaryArray[i].Name = subsideryModel.otherSubsideryName;
        this.subsidiaryArray[i].isValidExSubsideryName = subsideryModel.isValidExSubsideryName;
        break; //Stop this loop, we found it!
      }
    }
  }

  resetFields() {
    this.companyMasterForm.reset();
    // this.removeLogoImage();
    //set Default value for Compliance Engine is PSS and BIS is hidden.   
    this.companyMasterForm.patchValue({ complianceEngine: 'PSS', tc: true });
    this.complianceEngineChange("PSS");
    this.subsidiaryArray = [];
    this.subsidiaryAutoCompleteList = [];
    this.peersList = [];
    this.ListedConditionCheck("");
    this.companyMasterForm.controls.newSubsidiaryCompanyName.setValue('');
    this.companyMasterForm.controls.newSubsidiaryCIN.setValue('');
    this.companyMasterForm.controls.newSubsidiaryType.setValue('');
    this.companyMasterForm.controls.newSubsidiaryType.markAsUntouched();
    this.companyMasterForm.controls.newSubsidiaryShareholding.setValue('');
    this.newSubsidiary = [];
    $("#newSubsidiaryName").hide();
    $("#exSubsidiaryname").hide();

  }

  // search(term: string) {
  //   console.log('checking for this', term)



  //   // let self = this;
  //   // if (term === '') {
  //   //   return [];
  //   // }
  //   // console.log('search parameter', term, this)
  //   // return self.companySetupService.getCompanyListTypeAhead(term).then(response => {
  //   //     console.log('response==========>', response)
  //   // })

  // }


  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      // tap(() => this.searching = true),
      switchMap(term => (_.isNil(term) || _.isEmpty(term)) ? [] :
        this.companySetupService.getCompanyListTypeAhead(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
          .toPromise()
          .then((response: any) => {
            // console.log('checking for the API response ', response)
            if (response.listOfCompany.length > 0) {
              this.checkDropDownCompany = false;
            } else {
              this.checkDropDownCompany = true;
            }

            if (!response.status && _.size(response.listOfCompany)) {
              this.companyDetails = response.listOfCompany
              this.companyNameArray = _.map(response.listOfCompany, function (element) {
                return _.get(element, 'CompanyName')
              })
              return this.companyNameArray;
              // this.companyMasterForm.controls['companyName'].setValue(companyNameArray)
            } else {
              // this.searchFailed = true;
              // this.companyMasterForm.controls['companyName'].setValue([])
              return []
            }
          })
      ),
      // tap(() => this.searching = false)
    )
  formatter = (x: { CompanyName: string }) => {
    // console.log(x); return x.CompanyName;
  }

  selectedItem(data) {
    // console.log('data=========', data);

    this.selectedCompanyByTypeAhead = _.find(this.companyDetails, function (element) { return element.CompanyName == data.item })
    // console.log('ac==========>', this.selectedCompanyByTypeAhead);
    $('#typeahead-template').val(this.selectedCompanyByTypeAhead);
    this.companyMasterForm.controls.registrationNumber.setValue(null);

    // console.log('subsidiaryCompanyDetails', this.selectedCompanyByTypeAhead)
    this.tempCompanyInputSelected = this.companyMasterForm.controls.companyName.value;
    this.tempCompanySelectedCIN = this.selectedCompanyByTypeAhead.CIN;
    this.bindPeersofSelectedCompany(this.selectedCompanyByTypeAhead.FinCode);
  }

  searchSubsidiaryCompany = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      // tap(() => this.searching = true),
      switchMap(term => (_.isNil(term) || _.isEmpty(term)) ? [] :
        this.companySetupService.getCompanyListTypeAhead(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
          .toPromise()
          .then((response: any) => {
            // console.log('checking for the API response', response)
            if (!response.status && _.size(response.listOfCompany)) {
              this.subsidiaryCompanyDetailsList = response.listOfCompany

              if (this.companyLabel != undefined) {

                let subsidiaryLabel = this.companyLabel;
                let indexOfCompany = _.findIndex(this.subsidiaryCompanyDetailsList, function (company) { return _.get(company, 'CompanyName') == subsidiaryLabel })
                this.subsidiaryCompanyDetailsList.splice(indexOfCompany, 1);
              }
              var otherCompanyName = ['other']
              this.subsidiaryCompanyNameArray = _.map(response.listOfCompany, function (element) {
                return _.get(element, 'CompanyName')
              })
              this.subsidiaryCompanyNameArray = _.concat(otherCompanyName, this.subsidiaryCompanyNameArray)
              return this.subsidiaryCompanyNameArray;
              // this.companyMasterForm.controls['companyName'].setValue(companyNameArray)
            } else {
              // this.searchFailed = true;
              // this.companyMasterForm.controls['companyName'].setValue([])
              return []
            }
          })
      ),
      // tap(() => this.searching = false)
    )
  formatterForSubsidiaryCompany = (x: { CompanyName: string }) => {
    // console.log(x); return x.CompanyName;
  }

  selectedSubsidiaryCompanyItem(data) {
    this.companyLabel = data.item
    if (data.item != 'other') {
      let subsidiaryCompanyDetails = _.find(this.subsidiaryCompanyDetailsList, function (element) { return element.CompanyName == data.item })
      // console.log('subsidiaryCompanyDetails', subsidiaryCompanyDetails)
      this.tempCompanyInputSelected = this.companyMasterForm.controls.companyName.value;
      if (this.selectedCompanyByTypeAhead == undefined) {
        this.tempCompanySelectedCIN = this.companyMasterForm.controls.registrationNumber.value;
      } else {
        this.tempCompanySelectedCIN = this.companyMasterForm.controls.registrationNumber.value;
      }
      // this.bindPeersofSelectedCompany(subsidiaryCompanyDetails.FinCode);
      this.subsidiaryFincode = subsidiaryCompanyDetails.FinCode;
      this.subsidiaryName = data.item;
      this.otherSubsideryName = "";
    } else {
      this.tempCompanyInputSelected = this.companyMasterForm.controls.companyName.value;
      if (this.selectedCompanyByTypeAhead == undefined) {
        this.tempCompanySelectedCIN = this.companyMasterForm.controls.registrationNumber.value;
      } else {
        this.tempCompanySelectedCIN = this.companyMasterForm.controls.registrationNumber.value;
      }
      // this.tempCompanySelectedCIN = this.selectedCompanyByTypeAhead.CIN;
      // this.bindPeersofSelectedCompany('');

      $("#newSubsidiaryName").show();
      $("#exSubsidiaryname").show();
      this.subsidiaryFincode = data.item;
      this.otherSubsideryName = "";
      //if 'other' is slected as a subsidery  & other subsidery name is blank.
      this.isValidSubsdieryName = false;
      this.companyMasterForm.controls['newSubsidiaryCompanyName'].setValue(data.item);
    }

    // this.getPeersListOnCompanySelected(subsidiaryCompanyDetails)
  }

  clearDateOfIncoporation() {
    this.dateOfIncorporationModel = null;
    this.companyMasterForm.controls.dateOfIncorporation.setValue(null);
  }

  clearFinancialYearFrom() {
    this.financialYearFromModel = null;
    this.companyMasterForm.controls.FinancialYearFrom.setValue(null);
  }

  clearFinancialYearTo() {
    this.financialYearToModel = null;
    this.companyMasterForm.controls.FinancialYearTo.setValue(null);
  }


  onGEMSubscriptionChange(event) {
    //  
    if (event.target.checked) {
      this.isGEMSubscribedFlag = event.target.checked;
      this.companyMasterForm.controls.GEMAccessURL.reset();
      this.companyMasterForm.controls.isGEMSubscribedFlag.setValue(true);
      this.companyMasterForm.controls.GEMAccessURL.setValidators([Validators.required])
      this.companyMasterForm.controls.GEMAccessURL.updateValueAndValidity();
    } else {
      this.isGEMSubscribedFlag = event.target.checked;
      this.companyMasterForm.controls.isGEMSubscribedFlag.setValue(false);
      this.companyMasterForm.controls.GEMAccessURL.reset();
      this.companyMasterForm.controls.GEMAccessURL.clearValidators();
      this.companyMasterForm.controls.GEMAccessURL.updateValueAndValidity();
    }
  }

  onADSubscriptionChange(event) {

    if (event.target.checked) {
      this.isADSubscribedFlag = event.target.checked;
      this.companyMasterForm.controls.isADSubscribedFlag.setValue(true);
      this.setAdServerControls(true)
    } else {
      this.isADSubscribedFlag = event.target.checked;
      this.companyMasterForm.controls.isADSubscribedFlag.setValue(false);
      this.setAdServerControls(false)
    }
  }

  setAdServerControls(flag) {
    if (flag) {
      this.companyMasterForm.addControl('ADUrl' + 0, new FormControl('', [Validators.required]))
      this.companyMasterForm.addControl('ADEmailID' + 0, new FormControl('', [Validators.required, Validators.email]))
      this.companyMasterForm.addControl('ADPassword' + 0, new FormControl('', [Validators.required]))
    } else {
      this.companyMasterForm.setControl('ADUrl' + 0, new FormControl('', []))
      this.companyMasterForm.setControl('ADEmailID' + 0, new FormControl('', []))
      this.companyMasterForm.setControl('ADPassword' + 0, new FormControl('', []))

      var count = _.size(this.adServerArray);
      var intialCount = 0;
      for (count; count > intialCount; count--) {
        this.companyMasterForm.removeControl('ADUrl' + count)
        this.companyMasterForm.removeControl('ADEmailID' + count)
        this.companyMasterForm.removeControl('ADPassword' + count)
      }
      this.companyMasterForm.updateValueAndValidity();

      this.adServerArray = [{
        ADUrl: '',
        ADEmailID: '',
        ADPassword: ''
      }]

    }
  }


  addAdServerControls() {

    var count = _.size(this.adServerArray);

    // console.log("count",count)

    this.companyMasterForm.addControl('ADUrl' + count, new FormControl('', [Validators.required]))
    this.companyMasterForm.addControl('ADEmailID' + count, new FormControl('', [Validators.required, Validators.email]))
    this.companyMasterForm.addControl('ADPassword' + count, new FormControl('', [Validators.required]))

    this.companyMasterForm.updateValueAndValidity();

    // console.log("this.", this.companyMasterForm)

    this.adServerArray.push({
      ADUrl: '',
      ADEmailID: '',
      ADPassword: ''
    })

  }

  removeADServerConfirguration(index) {
    this.companyMasterForm.removeControl('ADUrl' + index)
    this.companyMasterForm.removeControl('ADEmailID' + index)
    this.companyMasterForm.removeControl('ADPassword' + index)
    this.companyMasterForm.updateValueAndValidity();
    this.adServerArray.splice(index, 1)
  }

  checkForCINForCompanyWithoutMaster(CIN) {
    if (this.isCompanySetupWithoutMaster) {
      if (this.companyMasterForm.controls['registrationIDType'].value == "CIN") {

        //check for valid CIN
        var regExp = new RegExp("^([A-Z]){1}([0-9]){5}([A-Z]){2}([0-9]){4}([A-Z]){3}([0-9]){6}?$");

        if (regExp.test(CIN)) {
          this.errorValidCINForCompany = false;
        }
        else {
          this.errorValidCINForCompany = true;
        }
      }
    }
  }

}
