<section class="card">

  <div class="card-header">
    <span class="cat__core__title">
      <strong>Detailed Report List</strong>
    </span>
  </div>

  <div class="card-block watermark">
    <div class="row">
      <div class="col-lg-12">
        <div class="table-responsive table-bordered margin-bottom-50">
          <table datatable [dtOptions]="dtOptions" id="individualReportListTable" [dtTrigger]="dtTrigger" class="table table-hover compact cell-border nowrap table-striped dataTable"
            style="background:transparent" width="100%">
            <thead>
              <tr>
                <th style="width:5%">Sr. No.</th>
                <th style="width:65%">Evaluation Reports</th>
                <th style="width:20%">Assessee</th>
                <th style="width:10%">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let evaluationFormDetail of evaluationFormDetails;let count=index">
                <td style="text-align:center;width:5%">{{count + 1}}</td>
                <td style="width: 65%">{{evaluationFormDetail.EvaluationFormId.EvaluationFormTitle}}</td>
                <td style="width:20%">{{evaluationFormDetail.newAssesseDetails ? evaluationFormDetail.newAssesseDetails : " "}}</td>
                <td style="width: 10%;text-align: center;color: #74708d;cursor: pointer;">
                  <button type="button" class="btn btn-primary btn-sm " (click)="viewReport(evaluationFormDetail)">view Report</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>