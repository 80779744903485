<section class="card">
  <div class="card-header">
    <span class="cat__core__title">
      <strong>Questionnaire Review</strong>
    </span>
  </div>

  <div class="card-block watermark">
    <div class="row">
      <div class="col-lg-12">
        <div class="table-responsive table-bordered margin-bottom-50">
          <table datatable [dtOptions]="dtOptions" id="evaluationReviewTable" [dtTrigger]="dtTrigger" class="table table-hover compact cell-border nowrap table-striped dataTable"
            style="background:transparent" width="100%">
            <thead>
              <tr>
                <th style="width:5%">Sr. No.</th>
                <th style="width:80%">Evaluation Name</th>
                <th style="width:15%">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let evaluationReviewTypeData of evaluationReviewTypeDatas;let count=index">
                <td style="text-align:center;width:5%">{{count + 1}}</td>
                <td style="width:80%;white-space: normal">{{evaluationReviewTypeData.EvaluationFormTitle}}</td>
                <td style="width:15%">
                  <button style="width:40%;text-align: center;cursor: pointer;" class="btn btn-sm btn-primary" (click)="showFormDetails(evaluationReviewTypeData)">{{checkReviewStatus(evaluationReviewTypeData)}}</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>