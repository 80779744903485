<section class="card">
  <div style="display: none" class="card-header">
    <span class="cat__core__title">
      <strong>Auto Backup</strong>
    </span>
  </div>

  <div style="display: none" class="card-block p-3">
    <form [formGroup]="autoBackupForm">
      <div class="row">
        <div class="col-md-12 form-inline form-group" style="margin-top: 10px">
          <label>Server IP<sup style="color: red">*</sup>: &nbsp;</label>
          <input
            type="text"
            class="form-control mb-2 mr-sm-2 mb-sm-0"
            formControlName="autoBackupServerName"
          />

          <label>UserName<sup style="color: red">*</sup>: &nbsp;</label>
          <input
            type="text"
            class="form-control mb-2 mr-sm-2 mb-sm-0"
            formControlName="autoBackupServerUserName"
          />

          <label>Password<sup style="color: red">*</sup>: &nbsp;</label>
          <input
            type="password"
            class="form-control mb-2 mr-sm-2 mb-sm-0"
            formControlName="autoBackupPassword"
          />

          <label>Folder Location<sup style="color: red">*</sup>: &nbsp;</label>
          <input
            type="text"
            class="form-control mb-2 mr-sm-2 mb-sm-0"
            formControlName="autoBackupLocation"
          />
        </div>
      </div>

      <div class="row form-inline" style="margin-top: 10px">
        <div class="col-md-12" style="padding: 0px">
          <div class="col-md-2">Schedule Frequency :</div>
          <div class="col-md-3">
            <ng-select
              [items]="backupSchedulerFrequencyList"
              formControlName="autoBackupFrequency"
              [clearable]="false"
            ></ng-select>
          </div>

          <div class="col-md-1">
            <button
              class="btn btn-sm btn-primary"
              (click)="setAutoBackupScheduler()"
              [disabled]="!autoBackupForm.valid"
            >
              Set Scheduler
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>

<section style="display: none" class="card">
  <div class="card-header">
    <span class="cat__core__title">
      <strong>Database Backup</strong>
    </span>
  </div>
  <div class="card-block p-3">
    <div class="col-md-12">
      <div class="row">
        <label>
          <b>Last Database backup location:</b>
        </label>
        <label>{{ path }}</label>
      </div>
      <div class="row">
        <form class="form-inline" [formGroup]="databaseBackupForm">
          <label>Server IP<sup style="color: red">*</sup>: &nbsp;</label>
          <input
            type="text"
            class="form-control mb-2 mr-sm-2 mb-sm-0"
            formControlName="serverName"
          />

          <label>UserName<sup style="color: red">*</sup>: &nbsp;</label>
          <input
            type="text"
            class="form-control mb-2 mr-sm-2 mb-sm-0"
            formControlName="serverUserName"
          />

          <label>Password<sup style="color: red">*</sup>: &nbsp;</label>
          <input
            type="password"
            class="form-control mb-2 mr-sm-2 mb-sm-0"
            formControlName="password"
          />

            <label>Folder Location<sup style="color:red;">*</sup>: &nbsp;</label>
            <input type="text" class="form-control mb-2 mr-sm-2 mb-sm-0" formControlName="location" >
            <button class="btn btn-sm btn-primary mb-2" [disabled]="!databaseBackupForm.valid" (click)="takeDatabaseBackUp()">Take backup</button>
          </form>
      </div>
    </div>
  </div>
</section>

<section class="card">
  <div class="card-header">
    <span class="cat__core__title">
      <strong>Audit Trail</strong>
    </span>
  </div>
  <div class="card-block watermark p-3">
    <form [formGroup]="auditTrailFilter">
      <div class="row">
        <div class="col-md-12">
          <div class="col-md-3 pull-left">
            <label>Action Type:</label>
            <ng-select
              placeholder="All"
              [items]="actionType"
              formControlName="actionType"
              [clearable]="false"
            ></ng-select>
          </div>
          <div class="col-md-3 pull-left">
            <label>User Name:</label>
            <ng-select
              placeholder="All"
              [items]="userList"
              formControlName="username"
              [clearable]="false"
            ></ng-select>
          </div>
          <div class="col-md-3 pull-left">
            <label>Company Name:</label>
            <ng-select
              placeholder="All"
              [items]="companyList"
              formControlName="companyName"
              [clearable]="false"
            ></ng-select>
          </div>
          <div class="col-md-3 pull-left">
            <label>Date Range:</label>
            <div class="form-group">
              <div
                class="input-group"
                (click)="$event.stopPropagation()"
                style="float: left; width: 50%; padding-right: 5px"
              >
                <span style="padding: 4px 5px 0 0">From</span>
                <input
                  class="form-control"
                  [value]="DateRangeFrom"
                  (change)="DateRangeFrom = getInputValueChange($event)"
                  style="padding: 6px"
                  placeholder="dd-mm-yyyy"
                  name="dpDateRangeFrom"
                  formControlName="DateRangeFrom"
                  ngbDatepicker
                  #d="ngbDatepicker"
                  (click)="d.toggle(); s.close()"
                  (document:click)="d.close()"
                />
                <button
                  class="input-group-addon"
                  (click)="d.toggle(); s.close()"
                  type="button"
                >
                  <span
                    class="fa fa-calendar"
                    style="width: 1.2rem; height: 1rem; cursor: pointer"
                  ></span>
                </button>
              </div>
              <div
                class="input-group"
                (click)="$event.stopPropagation()"
                style="width: 50%"
              >
                <span style="padding: 4px 5px 0 0">To</span>
                <input
                  class="form-control"
                  [value]="DateRangeToModel"
                  (change)="DateRangeTo = getInputValueChange($event)"
                  style="padding: 6px"
                  placeholder="dd-mm-yyyy"
                  name="dpDateRangeTo"
                  formControlName="DateRangeTo"
                  ngbDatepicker
                  #s="ngbDatepicker"
                  (click)="s.toggle(); d.close()"
                  (document:click)="s.close()"
                  [minDate]="DateRangeFrom"
                />
                <button
                  class="input-group-addon"
                  (click)="s.toggle(); d.close()"
                  type="button"
                >
                  <span
                    class="fa fa-calendar"
                    style="width: 1.2rem; height: 1rem; cursor: pointer"
                  ></span>
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-3 pull-left">
            <button
              class="btn btn-sm btn-primary mb-2"
              style="margin-top: 27px"
              [disabled]="!auditTrailFilter.valid"
              (click)="filterLog()"
            >
              Search
            </button>
            <button
              class="btn btn-sm btn-primary mb-2"
              style="margin-top: 27px"
              [disabled]="!auditTrailFilter.valid"
              (click)="clearFilter()"
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-md-12">
        <table
          datatable
          [dtOptions]="dtOptions"
          [dtTrigger]="dtTrigger"
          id="logtable"
          class="table table-hover compact cell-border nowrap table-striped dataTable"
          width="100%"
        >
          <thead>
            <tr>
              <th>Sr.No</th>
              <th>Action Type</th>
              <th style="display: none" >Details Of Action</th>
              <th>Created By</th>
              <th>Role</th>
              <th>Company Name</th>
              <th>Satus</th>
              <th>Created On</th>

              <th>Approved By</th>
              <th>Approved On</th>

              <th>isLogin</th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr *ngFor="let log of loglist; let count=index">
              <td>{{count + 1}}</td>
              <td>{{ (log.UserId ? log.UserId.Title : " ") + ' ' + (log.UserId ? log.UserId.FirstName : " " )  + ' ' + (log.UserId ?  log.UserId.LastName : " " )}}</td>              
              <td>{{log.CollectionName}}</td>
              <td>{{log.Event}}</td>
              <td title="{{log.Data|json}}">{{log.Data| json | slice:0:100}}......</td>
              <td>{{log.CreatedOn | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
            </tr> -->
            <tr *ngFor="let log of loglist; let count=index">
              <td>{{count + 1}}</td>
              <td>{{log.url}}</td>
              <td style="display: none" >{{log.body}}</td>
              <td>{{ maskEmail(log.emailId) }}</td>
              <td>{{ log.userRole ? log.userRole : "Maker" }}</td>
              <td>{{log.companyName}}</td>
              <td>{{ log.status ? log.status : "Approved" }}</td>
              <td>{{log.datetime | date: 'dd/MM/yyyy HH:mm:ss' }}</td>

              <td>{{ log.approvedByEmailId  ? maskEmail(log.approvedByEmailId) : "NA"}}</td>
              <td>{{ log.status=="Approved" ? ( log.approvedOn | date: 'dd/MM/yyyy HH:mm:ss' )  : "NA" }}</td>

              <td>{{log.isLogin}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-12 text-center" *ngIf="errorValue">
        <span>No data found</span>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="messagePopUp"
    tabindex="-1"
    role="dialog"
    aria-labelledby=""
    aria-hidden="true"
    style="top: 50px"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div
          id="alertTitle"
          class="modal-header"
          style="font-size: 20px; font-weight: bold; padding: 5px 15px"
        ></div>
        <div class="modal-body" style="padding: 10px 15px">
          <div id="alertMessage" style="font-size: 16px"></div>
        </div>
      </div>
    </div>
  </div>
</section>
